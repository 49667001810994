import * as React from 'react';

import { Alert } from 'react-bootstrap';

export default class PageNotFound extends React.Component<any, any> {

    constructor(props: any) {
        super(props);
    }

    render() {

        return (
            <Alert variant="danger">
                Oops... pagina niet gevonden
            </Alert>
        );
    }

    componentDidMount() {
        //console.log("404 mounted");
    }

    componentWillUnmount() {
        //console.log("404 will be unmounted");
    }
}