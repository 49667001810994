import * as React from 'react';


//import { Button, ListGroup, ListGroupItem } from 'react-bootstrap'
//import { Alert } from 'react-bootstrap'

import { Jumbotron, Badge, Button } from 'react-bootstrap'
import { Row, Col } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

//import { Tooltip, OverlayTrigger } from 'react-bootstrap'
//import { Styles } from '../HelperClasses/Styles';
//import { CardNavigator } from "./../UIComponents/CardNavigator";
//import { InfoOverlay } from "./../UIComponents/InfoOverlay";
import { LoadingSpinner } from '../UIComponents/MySpinners';
import { Formatters } from '../HelperClasses/Formatters'
//import { MyDateTime, MyTimeSpan } from '../HelperClasses/MyDate';
//import { ICustomError } from "../HelperClasses/CustomError";
import { Http } from "../HelperClasses/Http";
import { ApplicationState } from "../HelperClasses/ApplicationState";

import { IMember } from "./../DTO/Member";
import { IFlightCostsTransaction, FlightCostsTransaction } from "./../DTO/FlightCostsTransaction";
import { MyDateTime } from '../HelperClasses/MyDate';
//import { MyDateTime } from '../HelperClasses/MyDate';

interface IMyCostsOverviewProps {

}


interface IMyCostsOverviewState {
    member: IMember;

    isLoading: boolean;

    lastUpdateDate: MyDateTime;

    costsOverviewIsLoading: boolean;
    costsOverview: Array<IFlightCostsTransaction>;
}

export default class MyCostsOverview extends React.Component<IMyCostsOverviewProps, IMyCostsOverviewState> {


    constructor(props: any) {
        super(props);

        this.state = {member: null, isLoading: false, lastUpdateDate: null, costsOverviewIsLoading: false, costsOverview: null }

        this.handleDownload = this.handleDownload.bind(this);
    }

    render() {
        
        const jsx =
            <LoadingSpinner active={this.state.isLoading} >
                <Jumbotron className='m-0 ' >
                    {this.getInnerJSX()}
                </Jumbotron>
            </LoadingSpinner>
        return jsx;
    }

    //======================== Get Form JSX elements =============================//

    private getInnerJSX() {
        const jsx = 
        <>
            {this.getTitelJSX()}
            {this.getHeaderJSX()}
            <hr />
            {this.getDashboardJSX()}
            <hr />
            {this.getLastUpdateDate()}
        </>

        return jsx;
    }

    private getTitelJSX() {
        const jsx =
            <>
                <Row>
                    <Col className='col-auto mr-auto'>
                        <h3 >
                            Saldo {this.getSaldo()}
                        </h3>
                    </Col>
                    <Col className='col-auto'>
                        { this.getDownload()}
                    </Col>
                </Row>
                
                <br />
                <span >Transactie overzicht {MyDateTime.today.year.toString()}</span>
                <br />
            </>
        return jsx;
    }

    private getHeaderJSX() {

        

        const jsx = 
            <Row key={-1} style={{ fontWeight: 'bold' }}>
                <Col xs={3} sm={2} className=''>
                    Datum
                </Col>

                <Col sm={2} className='text-right d-none d-sm-block'>
                    Startbalans
                </Col>

                <Col xs={3} sm={2} className='text-right'>
                    Betaling
                </Col>

                <Col sm={2} className='text-right  d-none d-sm-block'>
                    Minuten
                </Col>

                <Col sm={2} className='text-right  d-none d-sm-block'>
                    Starts
                </Col>

                <Col xs={3} className='text-right  d-xs-block d-sm-none'>
                    Kosten
                </Col>

                <Col xs={3} sm={2} className='text-right'>
                    Saldo
                </Col>

            </Row>
        
        return jsx;

    }

    private getDashboardJSX() {
        if (!this.state.costsOverview) return null;

        const jsx =
            this.state.costsOverview.map(
                (transLine, index) => {
                    //console.log(transLine);

                    const style = (index % 2 == 0 ? { background: '#ebf5fb' } : { background: 'white' });

                    const jsxRow =
                        
                        <Row key={index} style={style} >
                            <Col xs={3} sm={2} className='' >
                                {Formatters.getShortMonthDayString(transLine.date)}
                            </Col>

                            <Col sm={2} className='text-right d-none d-sm-block'>
                                {this.displayEuro(transLine.startBalance)}
                            </Col>

                            <Col xs={3} sm={2} className='text-right px-1'>
                                {this.displayEuro(transLine.payments)}
                            </Col>

                            <Col sm={2} className='text-right d-none d-sm-block'>
                                {this.displayEuro(-transLine.flightCosts)}
                            </Col>

                            <Col sm={2} className='text-right d-none d-sm-block'>
                                {this.displayEuro(-transLine.launchCosts)}
                            </Col>

                            <Col xs={3} className='text-right  d-xs-block d-sm-none px-1'>
                                {this.displayEuro(-(transLine.flightCosts + transLine.launchCosts))}
                            </Col>

                            <Col xs={3} sm={2} className='text-right px-1'>
                                {Formatters.toEuro(transLine.balance)}
                            </Col>

                            </Row>


                    return jsxRow;
                }
            );


        return jsx;
    }

    private getLastUpdateDate() {
        const jsx =
            <span>
                Betalingen bijgewerkt tot&nbsp;
                <Badge variant='dark'>
                    {this.state.lastUpdateDate && this.state.lastUpdateDate.isValid ? Formatters.getMediumDateString(this.state.lastUpdateDate) : '--'}
                </Badge>
            </span>

        return jsx;
    }

    private getSaldo() {
        if (!this.state.costsOverview) return null;
        if (this.state.costsOverview.length == 0) return null;

        const saldo = this.state.costsOverview[this.state.costsOverview.length - 1].balance;

        let variant = 'success';
        if (saldo < 0) {
            variant = 'danger';
        }
        else if (saldo < 50) {
            variant='warning'
        }

        const jsx =
            <Badge variant={variant}>{Formatters.toEuro(saldo)}</Badge>

        return jsx;
    }

    private getDownload() {
        const jsx = 
            <Button variant="primary" onClickCapture={this.handleDownload}>
                <FontAwesomeIcon icon="cloud-download-alt" />
                <span className='d-none d-sm-inline'>
                        &nbsp;&nbsp;
                        Download
                </span>
            </Button>

        return jsx;
    }

    private displayEuro(amount: number) {
        if (amount == 0) return null;

        const jsx =
        <>
            { Formatters.toEuro(amount) }
        </>
        return jsx;
    }

    //==================== Handlers =======================================//

    private async handleDownload(): Promise<void> {
        this.downLoadReportAsync()
    }

    //==================== Data layer =======================================//

    private async loadBaseTables() {
        //don't await
        this.loadFlichtCostsTransactionsAsync();
        this.loadLastUpdateAsync();
    }

    private async loadFlichtCostsTransactionsAsync(): Promise<Array<IFlightCostsTransaction>> {
        
        if (!this.state.member) return null;

        this.setState({ isLoading: true, costsOverviewIsLoading: true });

        const http = new Http();

        const res = await http.getAsyncObject<IFlightCostsTransaction>('api/member/costsoverview/' + this.state.member.id, FlightCostsTransaction) as Array<IFlightCostsTransaction>;

        if (http.error == null) {
            this.setState({ isLoading: false, costsOverviewIsLoading: false, costsOverview: res as Array<IFlightCostsTransaction> });
            return (res as Array<IFlightCostsTransaction>);
        }
        else {
            this.setState({ isLoading: false, costsOverviewIsLoading: false, costsOverview: null });
            ApplicationState.get().setError(http.error);
            return null;
        }
    }


    private async loadLastUpdateAsync() : Promise<MyDateTime> {

        const http = new Http();

        //this.setState({ isLoading: true });

        let res = null;
        res = await http.getAsyncPrimitive<string>("api/cashbookentry/lastupdate/") as string;

        if (http.error == null) {
            const lastUpdateDate = MyDateTime.fromJson(res);
            this.setState({ lastUpdateDate: lastUpdateDate  });
            return lastUpdateDate;
        }
        else {
            this.setState({ lastUpdateDate: null });
            ApplicationState.get().setError(http.error);
            return null;
        }
    }

    private async downLoadReportAsync() : Promise<void> {
        if (!this.state.member) return null;

        const http = new Http();
        await http.getAsyncFile("api/report/flightspermember/" + this.state.member.id + "/" + MyDateTime.today.year.toString());

        if (http.error == null) {
        }
        else {
            ApplicationState.get().setError(http.error);
        }
    }

    //==================== Lifecycle =================================//

    async componentDidMount() {
        //ApplicationState.get().onErrorChange.registerHandler(this.handleStateError);
        //const memberId = 'F541F784-33D6-478B-A0B2-1B77DD3E0769';
        
        await this.setState({ member: ApplicationState.get().sessionInfo.account.member })
        
        await this.loadBaseTables();
    }

    componentWillUnmount() {
        //ApplicationState.get().onErrorChange.unregisterHandler(this.handleStateError);
    }
}