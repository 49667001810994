
import { MyDateTime } from "./../HelperClasses/MyDate";
import { ISerializable } from './ISerializable';
import { IValidation, IValidationState } from './IValidation'
//import moment = require('moment');




export interface IEventDay extends ISerializable<IEventDay>, IValidation {
    
    id: string;
    eventId: string;

    date: MyDateTime;
    description: string;

    clone(): IEventDay;
}

export interface IEventValidationState extends IValidationState {       
    dateIsValid: boolean;
}

export class EventDay implements IEventDay {

    public id: string = null;
    eventId: string = null;

    public date: MyDateTime = null;
    public description: string = null;;

    constructor() {
    }


    public getValidationState(): IEventValidationState {

        let vs = {
            dateIsValid: this.date ? this.date.isValid : false,
            stateIsValid: false
        } as IEventValidationState;

        vs.stateIsValid = (vs.dateIsValid); 

        return vs;
    }



    public fromJson(json: Object): EventDay {
        //DO NOT USE 'this.' RETURN A NEW OBJECT
        //console.log("Aircraft.fromJson");

        const trueObject = new EventDay();
        Object.assign<IEventDay, Object>(trueObject, json);

        //trueObject.key = trueObject.id;

        //Overloads
        trueObject.date = MyDateTime.fromJson(trueObject.date);


        //Objects

        //console.log(trueObject);
        return trueObject;
    }


    public toJson(): Object {
        //console.log("Aircraft.toJson");

        let x = JSON.parse(JSON.stringify(this));

        //Overloads

        if (this.date) x.date = this.date.toJson();

        //Objects

        //console.log(x);
        return x;
    }

    public clone(): IEventDay {
        const eventDay = Object.assign<EventDay, IEventDay>(new EventDay(), this);
        eventDay.date = eventDay.date.clone()

        return eventDay;
    }
}