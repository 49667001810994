import * as React from 'react';


//import { Button, ListGroup, ListGroupItem } from 'react-bootstrap'
//import { Alert } from 'react-bootstrap'

import { Jumbotron } from 'react-bootstrap'
import { Row, Col } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

//import { LoadingSpinner } from '../UIComponents/MySpinners';

//import { Http } from "../HelperClasses/Http";
//import { ApplicationState } from "../HelperClasses/ApplicationState";
import { MyDateTime } from '../HelperClasses/MyDate';

import { IFlightDay } from "./../DTO/FlightDay";
import { IMember } from "./../DTO/Member";

import { FlightDaysCalendar } from "./../UIComponents/FlightDaysCalendar";
import { FlightDayDetails } from "./../UIComponents/FlightDayDetails";
import { Formatters } from '../HelperClasses/Formatters';
import { ApplicationState } from '../HelperClasses/ApplicationState';



interface IFlightDaysviewProps {

}


interface IFlightDaysState {
    member: IMember;
    memberIsLoading: boolean;

    showCalendar: boolean;
    selectedDay: IFlightDay;
}

export default class FlightDays extends React.Component<IFlightDaysviewProps, IFlightDaysState> {


    constructor(props: any) {
        super(props);

        this.state = { member: null, memberIsLoading: false, showCalendar: true, selectedDay: null }

        this.handleDaySelected = this.handleDaySelected.bind(this);
        this.handleFlightDayDetailsClosed = this.handleFlightDayDetailsClosed.bind(this);
        this.handleFlightDayChanged = this.handleFlightDayChanged.bind(this);
    }

    render() {
        
        const jsx =
                <Jumbotron className='m-0 py-sm-2' >
                    {this.getInnerJSX()}
                </Jumbotron>
        return jsx;
    }

    //======================== Get Form JSX elements =============================//

    private getInnerJSX() {
        const jsx = 
            <div style={{maxWidth: 600} }>
                {this.getTitelJSX()}
                {this.state.showCalendar ? this.getFlightDaysCalendarJSX() : null}
                {!this.state.showCalendar ? this.getFlightDayDetailsJSX() : null}
        </div>

        return jsx;
    }

    private getTitelJSX() {
        const jsx =
            <>
                <Row>
                    <Col className='col-auto mr-auto'>
                        <h3 >
                            <FontAwesomeIcon icon="calendar-alt" />&nbsp;
                            {this.state.showCalendar ? "Vliegdagen" : Formatters.getMediumDateString(this.state.selectedDay.day)}
                        </h3>
                    </Col>
                    <Col className='col-auto'>
                        
                    </Col>
                </Row>

            </>
        return jsx;
    }

    private getFlightDaysCalendarJSX() {
        const jsx =
            <FlightDaysCalendar year={MyDateTime.today.year} month={MyDateTime.today.month} onDaySelected={this.handleDaySelected} />

        return jsx;
    }

    private getFlightDayDetailsJSX() {
        if (!this.state.selectedDay) return null;

        const jsx =
            <FlightDayDetails
                flightDayInput={this.state.selectedDay}
                member={this.state.member}
                onFlightDayChanged={this.handleFlightDayChanged}
                onFlightDayClosed={this.handleFlightDayDetailsClosed} />;

        return jsx;
    }



    //==================== Handlers =======================================//

    private handleDaySelected(aFlightDay: IFlightDay) {
        this.setState({ selectedDay: aFlightDay, showCalendar: false });
    }
    
    private handleFlightDayDetailsClosed() {
        this.setState({ selectedDay: null, showCalendar: true });
    }

    private handleFlightDayChanged() {
        //nothing to handle as the calendar will reload the days anyway
    }

    //==================== Data layer =======================================//

    private async loadBaseTables() {
       
    }

    //==================== Lifecycle =================================//

    async componentDidMount() {
        //ApplicationState.get().onErrorChange.registerHandler(this.handleStateError);

        await this.loadBaseTables();
        await this.setState({member: ApplicationState.get().sessionInfo.account.member})
    }

    componentWillUnmount() {
        //ApplicationState.get().onErrorChange.unregisterHandler(this.handleStateError);
    }
}