import { Http } from "./Http"
import { ApplicationState } from "./ApplicationState"


import { IFlightType } from "./../DTO/FlightType"
import { ILaunchType } from "./../DTO/LaunchType"
import { ILaunchPaymentMethod } from "./../DTO/LaunchPaymentMethod"
import { IAircraft, Aircraft } from "./../DTO/Aircraft"
import { IAircraftType } from "./../DTO/AircraftType"
import { IAircraftTypeSkillsGroup } from "./../DTO/AircraftTypeSkillsGroup"
import { IMember, Member } from "./../DTO/Member"
import { IPowerOptions } from "./../DTO/PowerOptions"
import { ISkillsGroup } from "./../DTO/SkillsGroup"
import { ICountry } from "./../DTO/Country"
import { IRole } from "./../DTO/Role"
import { ISex } from "./../DTO/Sex"
import { ICashBookCategory } from "./../DTO/CashBookCategory"
import { IEvent, Event } from "./../DTO/Event"
import { IEventRole } from "./../DTO/EventRole"
import { IReservable, Reservable } from "./../DTO/Reservable"
import { IPaymentMethod } from "./../DTO/PaymentMethod"
import { IExternal, External } from "./../DTO/External"
import { ILibraryCategory, LibraryCategory } from "./../DTO/LibraryCategory"
import { ILibraryDoc, LibraryDoc } from "./../DTO/LibraryDoc"


export class Cache {

    private static flightTypes: Array<IFlightType> = null;
    private static launchTypes: Array<ILaunchType> = null;
    private static launchPaymentMethods: Array<ILaunchPaymentMethod> = null;
    private static sailPlanes: Array<IAircraft> = null;
    private static towPlanes: Array<IAircraft> = null;
    private static activeMembers: Array<IMember> = null;
    private static allMembers: Array<IMember> = null;
    private static allExternals: Array<IExternal> = null;
    private static towPilots: Array<IMember> = null;
    private static instructors: Array<IMember> = null;
    private static powerOptions: Array<IPowerOptions> = null;
    private static skillsGroups: Array<ISkillsGroup> = null;
    private static aircraftTypes: Array<IAircraftType> = null;
    private static aircraftTypeSkillsGroups: Array<IAircraftTypeSkillsGroup> = null;
    private static countries: Array<ICountry> = null;
    private static roles: Array<IRole> = null;
    private static sexes: Array<ISex> = null;
    private static cashBookCategories: Array<ICashBookCategory> = null;
    private static eventRoles: Array<IEventRole> = null;
    private static currentCamp: IEvent = null;
    private static currentCampOrg: boolean = null;
    private static currentCampReservables: Array<IReservable> = null;
    private static paymentMethods: Array<IPaymentMethod> = null;
    private static libraryCategories: Array<ILibraryCategory> = null;
    private static libraryDocs: Array<ILibraryDoc> = null;

    public static async loadFlightTypesAsync(): Promise<Array<IFlightType>> {

        if (this.flightTypes != null) return this.flightTypes;

        const http = new Http();

        let res = await http.getAsyncPrimitive<IFlightType>("api/flighttype") as Array<IFlightType>;

        if (http.error == null) {
            this.flightTypes = res;
            return this.flightTypes;
        }
        else {
            ApplicationState.get().setError(http.error);
            return null;
        }
    }
    public static invalidateFlightTypes() { this.flightTypes = null;}

    public static async loadLaunchTypesAsync(): Promise<Array<ILaunchType>>{

        if (this.launchTypes != null) return this.launchTypes;

        const http = new Http();

        let res = await http.getAsyncPrimitive<ILaunchType>("api/launchtype") as Array<ILaunchType>;

        if (http.error == null) {
            this.launchTypes = res;
            return this.launchTypes;
        }
        else {
            ApplicationState.get().setError(http.error);
            return null;
        }
    }
    public static invalidateLaunchTypes() { this.launchTypes = null; }

    public static async loadLaunchPaymentMethodsAsync(): Promise<Array<ILaunchPaymentMethod>>{

        if (this.launchPaymentMethods != null) return this.launchPaymentMethods;

        const http = new Http();

        let res = await http.getAsyncPrimitive<ILaunchPaymentMethod>("api/launchpaymentmethod") as Array<ILaunchPaymentMethod>;

        if (http.error == null) {
            this.launchPaymentMethods = res;
            return this.launchPaymentMethods;
        }
        else {
            ApplicationState.get().setError(http.error);
            return null;
        }
    }
    public static invalidateLaunchPaymentMethodsTypes() { this.launchPaymentMethods = null; }

    public static async loadSailPlanesAsync(): Promise<Array<IAircraft>>{

        if (this.sailPlanes != null) return this.sailPlanes;

        const http = new Http();

        let res = await http.getAsyncObject<IAircraft>("api/aircraft/inuse/sailplanes", Aircraft) as Array<IAircraft>;

        if (http.error == null) {
            this.sailPlanes = res;
            return this.sailPlanes;
        }
        else {
            ApplicationState.get().setError(http.error);
            return null;
        }
    }
    public static invalidateSailPlanes() { this.sailPlanes = null; }

    public static async loadTowPlanesAsync(): Promise<Array<IAircraft>> {

        if (this.towPlanes != null) return this.towPlanes;

        const http = new Http();

        let res = await http.getAsyncObject<IAircraft>("api/aircraft/inuse/towplanes", Aircraft) as Array<IAircraft>;

        if (http.error == null) {
            this.towPlanes = res;
            return this.towPlanes;
        }
        else {
            ApplicationState.get().setError(http.error);
            return null;
        }
    }
    public static invalidateTowPlanes() { this.towPlanes = null; }

    public static async loadActiveMembersAsync(): Promise<Array<IMember>> {

        if (this.activeMembers != null) return this.activeMembers;

        const http = new Http();

        let res = await http.getAsyncObject<IMember>("api/member/active", Member) as Array<IMember>;

        if (http.error == null) {
            this.activeMembers = res;
            return this.activeMembers;
        }
        else {
            ApplicationState.get().setError(http.error);
            return null;
        }
    }
    public static invalidateActiveMembers() { this.activeMembers = null; }

    public static async loadAllMembersAsync(): Promise<Array<IMember>> {

        if (this.allMembers != null) return this.allMembers;

        const http = new Http();

        let res = await http.getAsyncObject<IMember>("api/member", Member) as Array<IMember>;

        if (http.error == null) {
            this.allMembers = res;
            return this.allMembers;
        }
        else {
            ApplicationState.get().setError(http.error);
            return null;
        }
    }
    public static invalidateAllMembers() { this.allMembers = null; }

    public static async loadAllExternalsAsync(): Promise<Array<IExternal>> {

        if (this.allExternals != null) return this.allExternals;

        const http = new Http();

        let res = await http.getAsyncObject<IExternal>("api/event/external" , External) as Array<IExternal>;

        if (http.error == null) {
            this.allExternals = res;
            return this.allExternals;
        }
        else {
            ApplicationState.get().setError(http.error);
            return null;
        }
    }
    public static invalidateAllExternals() { this.allExternals = null; }

    public static async loadTowPilotsAsync(): Promise<Array<IMember>>{

        if (this.towPilots != null) return this.towPilots;

        const http = new Http();

        let res = await http.getAsyncObject<IMember>("api/member/towpilots", Member) as Array<IMember>;

        if (http.error == null) {
            this.towPilots = res;
            return this.towPilots;
        }
        else {
            ApplicationState.get().setError(http.error);
            return null;
        }
    }
    public static invalidateTowPilots() { this.towPilots = null; }

    public static async loadInstructorsAsync(): Promise<Array<IMember>> {

        if (this.instructors != null) return this.instructors;

        const http = new Http();

        let res = await http.getAsyncObject<IMember>("api/member/instructors", Member) as Array<IMember>;

        if (http.error == null) {
            this.instructors = res;
            return this.instructors;
        }
        else {
            ApplicationState.get().setError(http.error);
            return null;
        }
    }
    public static invalidateInstructors() { this.instructors = null; }


    public static async loadPowerOptionsAsync(): Promise<Array<IPowerOptions>> {

        if (this.powerOptions != null) return this.powerOptions;

        const http = new Http();

        let res = await http.getAsyncPrimitive<IPowerOptions>("api/poweroptions") as Array<IPowerOptions>;

        if (http.error == null) {
            this.powerOptions = res;
            return this.powerOptions;
        }
        else {
            ApplicationState.get().setError(http.error);
            return null;
        }
    }
    public static invalidatePowerOptions() { this.powerOptions = null; }

    public static async loadSkillGroupsAsync(): Promise<Array<ISkillsGroup>> {

        if (this.skillsGroups != null) return this.skillsGroups;

        const http = new Http();

        let res = await http.getAsyncPrimitive<ISkillsGroup>("api/skillsgroup/active") as Array<ISkillsGroup>;

        if (http.error == null) {
            this.skillsGroups = res;
            return this.skillsGroups;
        }
        else {
            ApplicationState.get().setError(http.error);
            return null;
        }
    }
    public static invalidateSkillsGroups() { this.skillsGroups = null; }

    public static async loadAircraftTypesAsync(): Promise<Array<IAircraftType>> {

        if (this.aircraftTypes != null) return this.aircraftTypes;

        const http = new Http();

        let res = await http.getAsyncPrimitive<IAircraftType>("api/aircrafttype/active") as Array<IAircraftType>;

        if (http.error == null) {
            this.aircraftTypes = res;
            return this.aircraftTypes;
        }
        else {
            ApplicationState.get().setError(http.error);
            return null;
        }
    }
    public static invalidateAircraftTypes() { this.aircraftTypes = null; }

    public static async loadAircraftTypeSkillsGroupsAsync(): Promise<Array<IAircraftTypeSkillsGroup>> {

        if (this.aircraftTypeSkillsGroups != null) return this.aircraftTypeSkillsGroups;

        const http = new Http();

        let res = await http.getAsyncPrimitive<IAircraftTypeSkillsGroup>("api/aircrafttype/activeskillsgroups") as Array<IAircraftTypeSkillsGroup>;

        if (http.error == null) {
            this.aircraftTypeSkillsGroups = res;
            return this.aircraftTypeSkillsGroups;
        }
        else {
            ApplicationState.get().setError(http.error);
            return null;
        }
    }
    public static invalidateAircraftTypeSkillsGroups() { this.aircraftTypeSkillsGroups = null; }

    public static async loadCountriesAsync(): Promise<Array<ICountry>> {

        if (this.countries != null) return this.countries;

        const http = new Http();

        let res = await http.getAsyncPrimitive<ICountry>("api/country") as Array<ICountry>;

        if (http.error == null) {
            this.countries = res;
            return this.countries;
        }
        else {
            ApplicationState.get().setError(http.error);
            return null;
        }
    }
    public static invalidateCountries() { this.countries = null; }

    public static async loadSexesAsync(): Promise<Array<ISex>> {
        if (this.sexes != null) return this.sexes;

        const http = new Http();

        let res = await http.getAsyncPrimitive<ISex>("api/sex") as Array<ISex>;

        if (http.error == null) {
            this.sexes = res;
            return this.sexes;
        }
        else {
            ApplicationState.get().setError(http.error);
            return null;
        }
    }
    public static invalidateSexes() { this.sexes = null; }

    public static async loadRolesAsync(): Promise<Array<IRole>> {

        if (this.roles != null) return this.roles;

        const http = new Http();

        let res = await http.getAsyncPrimitive<IRole>("api/role") as Array<IRole>;

        if (http.error == null) {
            this.roles = res;
            return this.roles;
        }
        else {
            ApplicationState.get().setError(http.error);
            return null;
        }
    }
    public static invalidateRoles() { this.roles = null; }

    public static async loadCashBookCategoriesAsync(): Promise<Array<ICashBookCategory>> {
        if (this.cashBookCategories != null) return this.cashBookCategories;

        const http = new Http();

        let res = await http.getAsyncPrimitive<ICashBookCategory>("api/cashbookcategory") as Array<ICashBookCategory>;

        if (http.error == null) {
            this.cashBookCategories = res;
            return this.cashBookCategories;
        }
        else {
            ApplicationState.get().setError(http.error);
            return null;
        }
    }
    public static invalidateCashBookCategories() { this.cashBookCategories = null; }

    public static async loadEventRolesAsync(): Promise<Array<IEventRole>> {
 
        if (this.eventRoles != null) return this.eventRoles;

        const http = new Http();

        let res = await http.getAsyncPrimitive<IEventRole>("api/event/eventrole") as Array<IEventRole>;

        if (http.error == null) {
            this.eventRoles = res;
            return this.eventRoles;
        }
        else {
            ApplicationState.get().setError(http.error);
            return null;
        }
    }
    public static invalidateEventRoles() { this.eventRoles = null; }

    public static async loadCurrentCampAsync(): Promise<IEvent> {

        if (this.currentCamp != null) return this.currentCamp;

        const http = new Http();

        let res = await http.getAsyncObject<IEvent>("api/camp/current", Event) as IEvent;

        if (http.error == null) {
            this.currentCamp = res;
            return this.currentCamp;
        }
        else {
            ApplicationState.get().setError(http.error);
            return null;
        }
    }
    public static invalidateCurrentCamp() { this.currentCamp = null; }

    public static async amICurrentCampOrg(): Promise<boolean> {

        if (this.currentCampOrg != null) return this.currentCampOrg;

        const camp = await Cache.loadCurrentCampAsync();
        if (!camp) return false;

        const http = new Http();

        let res = await http.getAsyncPrimitive<boolean>("api/camp/amiorg/" + camp.id) as boolean;

        if (http.error == null) {
            this.currentCampOrg = res;
            return this.currentCampOrg;
        }
        else {
            ApplicationState.get().setError(http.error);
            return null;
        }
    }
    public static invalidateAmICurrentCampOrg() { this.currentCampOrg = null; }

    public static async loadCurrentCampReservablesAsync(): Promise<Array<IReservable>> {

        if (this.currentCampReservables != null) return this.currentCampReservables;

        const http = new Http();

        const camp = await Cache.loadCurrentCampAsync();
        if (!camp) return new Array<IReservable>();

        let res = await http.getAsyncObject<IReservable>("api/event/reservable/" + camp.id, Reservable) as Array<IReservable>;

        if (http.error == null) {
            this.currentCampReservables = res;
            return this.currentCampReservables;
        }
        else {
            ApplicationState.get().setError(http.error);
            return null;
        }
    }
    public static invalidateCurrentCampReservables() { this.currentCampReservables = null; }

    public static async loadPaymentMethodsAsync(): Promise<Array<IPaymentMethod>> {

        if (this.paymentMethods != null) return this.paymentMethods;

        const http = new Http();

        let res = await http.getAsyncPrimitive<IPaymentMethod>("api/event/paymentmethod") as Array<IPaymentMethod>;

        if (http.error == null) {
            this.paymentMethods = res;
            return this.paymentMethods;
        }
        else {
            ApplicationState.get().setError(http.error);
            return null;
        }
    }
    public static invalidatePaymentMethods() { this.paymentMethods = null; }

    public static async loadLibraryCategoriesAsync(): Promise<Array<ILibraryCategory>> {
        if (this.libraryCategories != null) return this.libraryCategories;

        const http = new Http();

        let res = await http.getAsyncObject<ILibraryCategory>("api/library/category", LibraryCategory) as Array<ILibraryCategory>;

        if (http.error == null) {
            this.libraryCategories = res;
            return this.libraryCategories;
        }
        else {
            ApplicationState.get().setError(http.error);
            return null;
        }
    }
    public static invalidateLibraryCategories() { this.libraryCategories = null; }

    public static async loadLibraryDocsAsync(): Promise<Array<ILibraryDoc>> {
        if (this.libraryDocs != null) return this.libraryDocs;

        const http = new Http();

        let res = await http.getAsyncObject<ILibraryDoc>("api/library", LibraryDoc) as Array<ILibraryDoc>;

        if (http.error == null) {
            this.libraryDocs = res;
            return this.libraryDocs;
        }
        else {
            ApplicationState.get().setError(http.error);
            return null;
        }
    }
    public static invalidateLibraryDocs() { this.libraryDocs = null; }
}
