
export class EmailHelpers {

    public static isValidEmailAddress(emailAddress: string): boolean {
        //return true;
        if (emailAddress == null) return false;
        if (emailAddress.length == 0) return false;

        const regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

        return regexp.test(emailAddress);
    }
}