import * as React from 'react';

import { Card } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Row, Col } from 'react-bootstrap'

//var NumericInput = require('react-numeric-input');

import { ApplicationState } from '../HelperClasses/ApplicationState';
import { Http } from '../HelperClasses/Http';
import { MyDateTime } from '../HelperClasses/MyDate';
//import { FiletypePicker } from '../UIComponents/FiletypePicker';
import { Filetype } from '../HelperClasses/Filetypes';
import { Styles } from '../HelperClasses/Styles';
import { FormModus, ModalResult, ModalButtons } from './../HelperClasses/Enums';
import { SimpleModal } from "../UIComponents/SimpleModal";

import { ILibraryDoc } from './../DTO/LibraryDoc'

import { LibraryDocModal } from './../UIComponents/LibraryDocModal';
import { ILibraryCategory } from '../DTO/LibraryCategory';

export interface ILibraryCardProps {
    adminMode: boolean;
    category: ILibraryCategory;
    docs: Array<ILibraryDoc>;

    onChanged?: () => void;
}

export interface ILibraryCardState {
    selectedYear: number;
    selectedDoc: ILibraryDoc;

    showLibraryDocModal: boolean;
    showDeleteModal: boolean;
}



export class LibraryCard extends React.Component<ILibraryCardProps, ILibraryCardState> {

    //selectedDoc: ILibraryDoc = null;
    //private docList = new Array<ILibraryDoc>();

    constructor(props: any) {
        super(props);

        this.props.docs.sort((a, b) => { return (a.sortNr < b.sortNr ? -1 : 1) });

        this.state = {
            selectedYear: MyDateTime.today.year,
            selectedDoc: null,

            showLibraryDocModal: false,
            showDeleteModal: false,
        };

        this.handleOnCloseLibraryDocModal = this.handleOnCloseLibraryDocModal.bind(this);
        this.handleDocDownload = this.handleDocDownload.bind(this);

        this.handleNewDoc = this.handleNewDoc.bind(this);
        this.handleDocUp = this.handleDocUp.bind(this);
        this.handleDocDown = this.handleDocDown.bind(this);
        this.handleEditDoc = this.handleEditDoc.bind(this);

        this.handleDeleteDoc = this.handleDeleteDoc.bind(this);
        this.handleCloseDeleteModal = this.handleCloseDeleteModal.bind(this);
        
    }

    render() {

        const jsx =
            <>
                {this.state.showLibraryDocModal ? this.getLibraryDocModalJSX(this.state.selectedDoc) : null}
                {this.state.showDeleteModal ? this.getDeleteModalJSX(this.state.selectedDoc) : null }
                {this.getCardJSX()}
            </>
        return jsx;
    }



    private getCardJSX() {
        let style = { width: '20rem'};
        if (this.props.adminMode == true) {
            style = { width: '20rem' };
        }

        const jsx =
            <Card
                className="h-100 mx-auto"
                style={style}
                border="primary"
            >
                <Card.Header as="h5" >{this.props.category.name}</Card.Header>
                <Card.Body>

                    {this.getDocListHeaderJSX()}
                    {this.getDocListJSX()}


                </Card.Body>

                <Card.Footer>

                </Card.Footer>

            </Card>
        return jsx;
    }

    private getDocListHeaderJSX() {
        const jsxRow =
            <Row style={{ backgroundColor: "lightGray" }}>

                <Col xs={1} className='text-left px-1'>

                </Col>
                <Col xs={9} className='text-left px-1'>
                    &nbsp;
                </Col>
                <Col xs={1} className='text-center pl-1 pr-1'>

                </Col>
                <Col xs={1} className='text-center pl-1 pr-1'>
                    {this.getNewDocButtonJSX()}
                </Col>


            </Row>

        return jsxRow;
    }

    private getDocListJSX() {

        if (!this.props.docs) return null;

        this.props.docs.sort(this.sortDocs)

        const jsx =
            this.props.docs.map(
                (doc, index) => {

                    const style = (index % 2 == 0 ? { background: '#ebf5fb' } : { background: 'white' });

                    const jsxRow =
                        <Row key={index} style={style}>
                            <Col xs={this.props.adminMode ? 8 : 11} className='text-left px-1 my-auto' >
                                <FontAwesomeIcon
                                    icon={Filetype.getFiletypeIcon(Filetype.getFiletypeFromMime(doc.filetype))}
                                    color={Filetype.getFiletypeColor(Filetype.getFiletypeFromMime(doc.filetype))}
                                    fixedWidth={true}
                                />
                                &nbsp;
                                <span style={{ cursor: 'pointer' }} onClickCapture={(e) => { this.handleDocDownload(doc) }}>
                                    {doc.displayName}
                                </span>
                            </Col>
                            {this.getRowButtons(doc, index)}

                        </Row>

                    return jsxRow;
                }
            )

        return jsx;
    }

    private getRowButtons(doc: ILibraryDoc, index: number) {
        if (this.props.adminMode) {

            const jsx =
                <>
                    <Col xs={1} className='text-center pl-1 pr-1 my-auto'>
                        {this.getUpButtonJSX(doc, index)}
                    </Col>
                    <Col xs={1} className='text-center pl-1 pr-1 my-auto'>
                        {this.getDownButtonJSX(doc, index)}
                    </Col>
                    <Col xs={1} className='text-center pl-1 pr-1 my-auto'>
                        {this.getEditDocButtonJSX(doc, index)}
                    </Col>
                    <Col xs={1} className='text-center pl-1 pr-1 my-auto'>
                        {this.getDeleteDocButtonJSX(doc, index)}
                    </Col>
                </>
            return jsx;
        }
        else {
            const jsx =
                <>
                    <Col xs={1} className='text-right pl-1 pr-1 py-1 my-auto'>
                        {this.getDownloadButtonJSX(doc, index)}
                    </Col>
                </>
            return jsx;

        }


    }

    private getNewDocButtonJSX() {
        if (!this.props.adminMode) return null;

        return <span onClick={this.handleNewDoc} style={{ cursor: 'pointer' }}>
            <FontAwesomeIcon icon="plus-square" color='green' />
        </span>
        //return <span onClick={() => this.handleNewEventDay()}><FontAwesomeIcon icon="plus-square" /></span>
    }

    private getUpButtonJSX(doc: ILibraryDoc, index: number) {
        if (!this.props.adminMode) return null;

        return <span
            onClick={() => { this.handleDocUp(doc, index) }} style={{ cursor: 'pointer' }}
        >
            <FontAwesomeIcon icon="arrow-circle-up" color={(index <= 0) ? 'lightgray' : 'black'} />
        </span>
        //return <span onClick={() => this.handleNewEventDay()}><FontAwesomeIcon icon="plus-square" /></span>
    }

    private getDownButtonJSX(doc: ILibraryDoc, index: number) {
        if (!this.props.adminMode) return null;

        return <span
            onClick={() => { this.handleDocDown(doc, index) }} style={{ cursor: 'pointer' }}
            >
            <FontAwesomeIcon icon="arrow-circle-down" color={(index >= this.props.docs.length - 1) ? 'lightgray' : 'black'} />
        </span>
        //return <span onClick={() => this.handleNewEventDay()}><FontAwesomeIcon icon="plus-square" /></span>
    }


    private getEditDocButtonJSX(doc: ILibraryDoc, index: number) {
        return  <span onClick={() => this.handleEditDoc(doc, index)} style={{ cursor: 'pointer' }} >
                    <FontAwesomeIcon icon="pen" />
                </span>
    }

    private getDeleteDocButtonJSX(doc: ILibraryDoc, index: number) {
        return <span onClick={() => this.handleDeleteDoc(doc, index)} style={{ cursor: 'pointer' }}>
            <FontAwesomeIcon icon="trash-alt"  />
        </span>
    }

    private getDownloadButtonJSX(doc: ILibraryDoc, index: number) {

        const jsx = 
            <span onClickCapture={(e) => { this.handleDocDownload(doc) }} style={{ cursor: 'pointer' }}>
                <FontAwesomeIcon icon="cloud-download-alt" size="sm" color={Styles.getPrimaryColor()} />
            </span>
        return jsx;
    }

    private getLibraryDocModalJSX(doc: ILibraryDoc) {
        const jsx =
            <LibraryDocModal
                formMode={doc ? FormModus.Edit : FormModus.New}
                nextSortNr={this.props.docs ? this.props.docs.length : 0}
                entityIn={this.state.selectedDoc}
                defaultCategory={ this.props.category}
                onClose={this.handleOnCloseLibraryDocModal} />

        return jsx;
    }

    private getDeleteModalJSX(doc: ILibraryDoc) {
        if (!doc) return null;

        const jsx = <SimpleModal
            headingText="Document verwijderen"
            bodyText={
                <span>
                    Bent u zeker dat u <i><b>{doc.displayName}</b></i> wilt verwijderen?
                </span>
            }
            buttons={ModalButtons.YesNo}
            onClose={(res) => { this.handleCloseDeleteModal(res, doc) }}
        />

        return jsx;
    }


    //======================== Get Form Handlers =============================//

    private handleNewDoc() {
        this.setState({showLibraryDocModal: true})
    }

    private handleEditDoc(doc: ILibraryDoc, index: number) {
        this.setState({ showLibraryDocModal: true, selectedDoc: doc })
    }

    private async handleDocUp(doc: ILibraryDoc, index: number) {

        this.props.docs.sort(this.sortDocs);

        const curIndex = this.props.docs.findIndex(x => x.id == doc.id);
        if (curIndex <= 0) return;

        this.props.docs.splice(curIndex, 1);
        this.props.docs.splice(curIndex-1, 0, doc);

        this.resetSortNrs(this.props.docs);

        const res = await this.UploadDocsAsync(this.props.docs);
        if (res) {
            if (this.props.onChanged) this.props.onChanged();
        }
    }

    private async handleDocDown(doc: ILibraryDoc, index: number) {

        this.props.docs.sort(this.sortDocs);

        const curIndex = this.props.docs.findIndex(x => x.id == doc.id);
        if (curIndex >= this.props.docs.length-1) return;

        this.props.docs.splice(curIndex, 1);
        this.props.docs.splice(curIndex + 1, 0, doc);

        this.resetSortNrs(this.props.docs);

        const res = await this.UploadDocsAsync(this.props.docs);
        if (res) {
            if (this.props.onChanged) this.props.onChanged();
        }
    }

    private async handleOnCloseLibraryDocModal(result: ModalResult, doc: ILibraryDoc) {

        if (result == ModalResult.OK && this.props.onChanged) {
            this.props.onChanged();
        }

        this.setState({ showLibraryDocModal: false, selectedDoc: null })
    }

    private async handleDeleteDoc(doc: ILibraryDoc, index: number) {
        this.setState({ showDeleteModal: true, selectedDoc: doc });
    }

    private async handleCloseDeleteModal(res: ModalResult, doc: ILibraryDoc) {

        if (res == ModalResult.OK) {
            if (await this.deleteDocAsync(doc)) {
                if (this.props.onChanged) this.props.onChanged();
            }
        }

        this.setState({ showDeleteModal: false, selectedDoc: null });
    }

    private async handleDocDownload(doc: ILibraryDoc) {
        const http = new Http();
        await http.getAsyncFile("api/library/download/" + doc.id);

        if (http.error == null) {
        }
        else {
            ApplicationState.get().setError(http.error);
        }
    }


    //========================    Implementation =============================//

    private sortDocs(a: ILibraryDoc, b: ILibraryDoc): number {
        if (a.sortNr < b.sortNr) return -1;
        if (a.sortNr > b.sortNr) return 1;

        return 0;
    }

    private resetSortNrs(docs: Array<ILibraryDoc>) {
        for (var i = 0; i < docs.length; i++) {
            docs[i].sortNr = i;
        }
    }

    //========================    Data Layer =============================//

    private async UploadDocsAsync(docs: Array<ILibraryDoc>) {
        const http = new Http();

        const res = await http.postAsyncPrimitive<boolean>("api/library/documents", docs);
        if (http.error == null) {
            return res as boolean;
        }
        else {
            ApplicationState.get().setError(http.error);
            return false;
        }
    }

    private async deleteDocAsync(doc: ILibraryDoc) {
        if (!doc) return false;
        const http = new Http();
        const res = await http.deleteAsyncPrimitive<boolean>("api/library/" + doc.id);

        if (http.error == null) {
            return res as boolean;
        }
        else {

            ApplicationState.get().setError(http.error);
            return null;
        }

    }


    async componentDidMount() {
        //ApplicationState.get().onErrorChange.registerHandler(this.handleStateError);
        //console.log("Mounting Reports");


    }

    componentWillUnmount() {
        //ApplicationState.get().onErrorChange.unregisterHandler(this.handleStateError);
    }

}