import * as React from 'react';



import { Row, Col, Button, Form, Image, Jumbotron, Badge } from 'react-bootstrap'
//import { Alert } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

//import { ICustomError } from "../HelperClasses/CustomError";
import { Http } from "../HelperClasses/Http";
import { Cache } from "../HelperClasses/Cache";
import { ApplicationState } from "../HelperClasses/ApplicationState";
//import { EmailHelpers } from '../HelperClasses/EmailHelpers';
import { Routing } from "../HelperClasses/Routing";

//import { MyDateTime } from "../HelperClasses/MyDate";

import { ModalResult, FormModus, ModalButtons } from './../HelperClasses/Enums';
import { SimpleModal } from "../UIComponents/SimpleModal";
import {ICampRegistration, CampRegistration} from './../DTO/CampRegistration'
import { HttpStatus } from '../HelperClasses/CustomError';

import { CampRegistrationForm } from './../AppForms/CampRegistrationForm'

import { IEvent } from './../DTO/Event'
//import { MyDateTime } from '../HelperClasses/MyDate';
import { Formatters } from '../HelperClasses/Formatters';

interface ICampRegistrationInfoProps {
}


interface ICampRegistrationInfoState {
    isLoading: boolean;
    isValidated: boolean;

    currentCamp: IEvent;
    campRegistration: ICampRegistration

    code: string;
    codeInvalid: boolean;
    codeUnknown: boolean;

    showRegistrationForm: boolean;
    showConfirmModal: boolean;
    updateSuccess: boolean;
}

export default class CampRegistrationInfo extends React.Component<ICampRegistrationInfoProps, ICampRegistrationInfoState> {


    constructor(props: any) {
        super(props);

        this.state = {
            isLoading: false,
            isValidated: false,

            currentCamp: null,
            campRegistration: null,

            code: this.getRegistrationCode(),

            codeInvalid: false,
            codeUnknown: false,

            showRegistrationForm: false,
            showConfirmModal: false,
            updateSuccess: false,
        };

        this.handleCodeChange = this.handleCodeChange.bind(this);
        this.handleNewRegistration = this.handleNewRegistration.bind(this);
        this.handleMyRegistration = this.handleMyRegistration.bind(this);
        this.handleCloseCampRegistrationForm = this.handleCloseCampRegistrationForm.bind(this);
        this.handleCloseConfirmModal = this.handleCloseConfirmModal.bind(this);

    }


    //====================== Rendering ============================================================//

    render() {

        if (this.state.showRegistrationForm) {
            return this.getRegistrationFormJSX();
        }

        if (this.state.showConfirmModal) {
            return this.getConfirmModalJSX();
        }

        //if (this.state.currentCamp && this.state.currentCamp.deadline.isBeforeDate(MyDateTime.today)) {
        if (this.state.currentCamp && !this.state.currentCamp.isRegistrationOpen) {
            const jsx =
                <>
                    <Jumbotron className='m-0 py-1'>
                        {this.getHeaderJSX()}
                        <hr />
                        {this.getRegistrationClosedJSX()}
                        <hr />
                        {this.getIntroductionJSX()}
                        {this.getPicture()}
                        {this.getBrochureJSX()}


                    </Jumbotron>
                </>
            return jsx;
        }


        //if (this.state.currentCamp && this.state.currentCamp.deadline.isAfterOrOnDate(MyDateTime.today)) {
        if (this.state.currentCamp && this.state.currentCamp.isRegistrationOpen) {
            const jsx =
                <>
                    <Jumbotron className='m-0 py-1'>

                        {this.getHeaderJSX()}

                        <hr />
                        {this.getIntroductionJSX()}
                        {this.getPicture()}
                        {this.getBrochureJSX()}
                        <hr />
                        {this.getRegistrationChoiceJSX()}
                    </Jumbotron>
                </>
            return jsx;
        }

        return "Loading..."
    }

    private getHeaderJSX() {
        if (!this.state.currentCamp) return null;

        const jsx =
            <>
                <h1 className="text-center">{"Zweefkamp " + this.state.currentCamp.startDate.year}</h1>
                <h4 className="text-center">{Formatters.getLongMonthDayString(this.state.currentCamp.startDate) + " t/m " + Formatters.getLongMonthDayString(this.state.currentCamp.endDate) }</h4>
            </>
        return jsx;
    }

    private getIntroductionJSX() {
        const jsx =
            <>
                Welkom op de inschrijvingspagina van het zweefkamp, jaarlijks georganiseerd door de KAZM te zoersel.
                <br />
                <br />
            </>
        return jsx;

    }

    //private getConfirmationJSX() {
    //    return null;
    //    const jsx =
    //        <>
    //            <br />
    //            <h3>
    //            Uw aanvraag werd succesvol ontvangen en verwerkt.
    //            <br />
    //            U ontvangt nog een email ter bevestiging op : {this.state.campRegistration.email }
    //            </h3>
    //        </>

    //    return jsx;

    //}

    private getPicture() {
        const jsx = 
            <div className='d-none d-sm-inline'>
                <Image src="imgs/gliders.png" rounded fluid />
                <br />
                <br />
            </div>

        return jsx;
    }

    private getBrochureJSX() {
        return null;
        const doc = 'FolderZweefvliegstage' + this.state.currentCamp.startDate.year + '.pdf'
         const jsx =
             <>
                 Download onze folder met alle details van het zweefkamp {this.state.currentCamp.startDate.year}:&nbsp;&nbsp;

                 <Button>
                     <a href={"camp/" + doc}
                         target="_blank"
                         type="application/octet-stream" download={doc}
                         style={{ color: 'white', textDecoration: 'none' }}
                     >
                         <FontAwesomeIcon icon="cloud-download-alt" />
                         <span className='d-none d-sm-inline'>
                             &nbsp;&nbsp;
                             Download
                         </span>
                     </a>
                 </Button>

            </>
        return jsx;
    }

    private getRegistrationChoiceJSX() {
        const jsx =
            <>
                <Row style={{ maxWidth: '600px' }} className='py-2' hidden={true}>
                    <Col xs={12} className='text-center'>
                        Inschrijven
                    </Col>
                </Row>

                <Row style={{ maxWidth: '600px' }}>
                    <Col xs={6} className='my-auto'>
                        Nieuwe inschrijving
                    </Col>
                    <Col xs={6}>
                        <Button onClickCapture={this.handleNewRegistration} style={{ width: '125px' }}>Nieuw</Button>
                    </Col>
                </Row>
                                    
                <Row style={{ maxWidth: '600px'}} className='py-2'>
                    <Col xs={12} className='text-center'>
                        <h3>of</h3>
                    </Col>
                </Row>
                <Row style={{ maxWidth: '600px' }}>
                    <Col xs={12} className=''>
                        Inschrijving aanpassen
                    </Col>
                </Row>
                <Row style={{maxWidth:'600px'}}>
                    <Col xs={6} >

                            <Form.Control autoFocus type="input" placeholder="inschrijvings code" ref="code"
                                    value={this.state.code ? this.state.code : ''}
                                    onChangeCapture={this.handleCodeChange}
                                    isInvalid={ (this.state.codeInvalid || this.state.codeUnknown) && this.state.isValidated}
                                    //disabled={this.props.formMode != FormModus.New}
                                tabIndex={1} onChange={() => { }} />

                            <Form.Control.Feedback type="invalid" hidden={!this.state.codeUnknown} >
                                Onbekende code
                            </Form.Control.Feedback>
                            <Form.Control.Feedback type="invalid" hidden={!this.state.codeInvalid} >
                                Ongeldige code.
                            </Form.Control.Feedback>

                    </Col>
                    <Col xs={6} >
                        <Button onClickCapture={this.handleMyRegistration} disabled={!this.state.code} style={{width: '125px'}} >Aanpassen</Button>
                    </Col>

                </Row>
            </>
        return jsx;

    }

    private getRegistrationFormJSX() {
        const jsx =
            <>
                <CampRegistrationForm
                    formMode={this.state.campRegistration ? FormModus.Edit : FormModus.New}
                    event={this.state.currentCamp}
                    entityIn={this.state.campRegistration}
                    onClose={this.handleCloseCampRegistrationForm} />
            </>

        return jsx;
    }

    private getRegistrationClosedJSX() {

        const campYear = this.state.currentCamp.startDate.year.toString();
        const jsx =
            <h4><i>
                De inschrijvingen voor het zweefkamp {campYear} zijn gesloten. Voor meer informatie contacteer ons op <a href='mailto://info@zweefkamp.be'>info@zweefkamp.be</a>
                </i>
            </h4>
        return jsx;
    }

    private getConfirmModalJSX() {
        if (this.state.updateSuccess) {
            const body =
                <div>
                    Bedankt voor je inschrijving!
                    <br />
                    {"Je ontvangt een bevestigings email op " + (this.state.campRegistration ? this.state.campRegistration.email : "")}
                </div>

            const jsx = <SimpleModal
                headingText="Inschrijving is succesvol verstuurd"
                bodyText={body}
                buttons={ModalButtons.OK}
                onClose={this.handleCloseConfirmModal}
            />


            return jsx;
        }
        else {
            const body =
                <div>
                    Er is iets misgegaan je inschrijving..
                    <br />
                    Probeer het aub opnieuw en indien het alsnog niet lukt neem dan contact met ons op via&nbsp;
                    <Badge variant='secondary' >
                        <a style={{ color: 'white' }} href='mailto:info@zweefkamp.be'>
                            info@zweefkamp.be &nbsp;
                            <FontAwesomeIcon icon="envelope" />
                        </a>
                    </Badge>
                </div>

            const jsx = <SimpleModal
                headingText="Inschrijving is niet gelukt"
                bodyText={body}
                buttons={ModalButtons.OK}
                onClose={this.handleCloseConfirmModal}
            />
            return jsx;
        }
    }
    //====================== Handlers ============================================================//


    private handleCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let x = event.currentTarget.value

        if (!x) {
            this.setState({ code: null, isValidated: false, codeUnknown: false, codeInvalid: false });
        }
        else {
            this.setState({ code: x, codeInvalid: !(x && x.length == 5), codeUnknown: false });
        }
    }

    private handleNewRegistration() {
        this.setState({ isValidated: true, campRegistration: null, showRegistrationForm: true })
    }

    private async handleMyRegistration() {

        if (this.state.code && !this.state.codeInvalid) {
            const cr = await this.loadCampRegistrationAsync(this.state.code);
            if (cr) {
                this.setState({ campRegistration: cr, codeInvalid: false, codeUnknown: false, showRegistrationForm: true });
            }
            else {
                this.setState({ campRegistration: null, showRegistrationForm: false })
            }
        }

        this.setState({isValidated: true})
    }

    private async handleCloseCampRegistrationForm(result: ModalResult, campRegistration: ICampRegistration) {
        if (result == ModalResult.OK) {
            //console.log(campRegistration);
            
            campRegistration = await this.UpdateCampRegistration(campRegistration);
            if (!campRegistration) {
                await this.setState({ campRegistration: campRegistration, showRegistrationForm: false, showConfirmModal: true, updateSuccess: false });
            }
            else {
                await this.setState({ campRegistration: campRegistration, showRegistrationForm: false, showConfirmModal: true, updateSuccess: true });
            }
        }
        else {
            this.setState({ showRegistrationForm: false})
        }
    }

    private handleCloseConfirmModal() {
        this.setState({ showRegistrationForm: false, showConfirmModal: false });
    }

    //======================== Helpers =============================================================//

    private getRegistrationCode() {
        const code = Routing.getQueryString();

        //HACK
        //return 'mwyno'
        return code;
    }

    private async LoadBaseTables() {
        this.setState({ currentCamp: await Cache.loadCurrentCampAsync() })

    }

    //======================== Data layer =============================================================//
    private async loadCampRegistrationAsync(code: string): Promise<ICampRegistration> {

        const http = new Http();

        this.setState({ isLoading: true });

        let res = null;
        res = await http.getAsyncObject<ICampRegistration>("api/campregistration/bycode/" + code, CampRegistration) as ICampRegistration;

        if (http.error == null) {
            return res as ICampRegistration;
        }
        else {
            this.setState({ campRegistration: null });
            if (http.error.httpStatus == HttpStatus.http404) {
                this.setState({codeUnknown: true})
            }
            else {
                ApplicationState.get().setError(http.error);
            }
            return null;
        }
    }

    private async UpdateCampRegistration(cr: ICampRegistration) : Promise<ICampRegistration> {
        const http = new Http();

        this.setState({ isLoading: true });
        let res = await http.postAsyncObject<ICampRegistration>("api/campregistration/", cr, CampRegistration);
        this.setState({ isLoading: false });

        if (http.error == null) {
            return res as ICampRegistration;
        }
        else {
            ApplicationState.get().setError(http.error);
            return null;
        }
    }


    //====================== Lyfecycle ============================================================//

    async componentDidMount() {
        //ApplicationState.get().onErrorChange.registerHandler(this.handleStateError);
        //console.log("Mounting CampRegistration");

        await this.LoadBaseTables();

        if (this.state.code != null) {
            this.handleMyRegistration();
        }
    }

    componentWillUnmount() {
        //ApplicationState.get().onErrorChange.unregisterHandler(this.handleStateError);
    }
}