import * as React from 'react';

import { Alert } from 'react-bootstrap';

import { ICustomError, HttpStatus } from "./../HelperClasses/CustomError";
import { Errors } from '../HelperClasses/Errors'

interface IErrorHandlerProps {
    error: ICustomError;

    onDismissed: () => void;
}


class ErrorHandler extends React.Component<IErrorHandlerProps, any> {

    constructor(props: any) {
        super(props);

        //this.state = {error : null};

        this.handleClose = this.handleClose.bind(this);
        this.getMessageJSX = this.getMessageJSX.bind(this);
        this.getURLJSX = this.getURLJSX.bind(this);
        this.getDetailsJSX = this.getDetailsJSX.bind(this);
    }


    render() {

        if (this.props.error == null) return null;

        const jsx =
            <div className="ErrorHandler" >


                <Alert variant="danger" dismissible={true} onClose={this.handleClose} >
                    {this.getErrorStructJSX()}
                </Alert>

            </div>

        return jsx;
    }

    private getErrorStructJSX() {
        //console.log(this.props.error)
        if (this.props.error.httpStatus == HttpStatus.httpCustomError) {
            const jsx = Errors.fromCustomError(this.props.error.customErrorNr, this.props.error.customErrorData);
            return jsx;
        }
        else {
            const jsx =
            <>
            { this.getMessageJSX() }
            { this.getURLJSX() }
            { this.getDetailsJSX() }
            </>
            return jsx;
        }
    }

    private getMessageJSX() {

        if (!this.props.error.errorMessage) return null;

        const msg =
            <h5>
                {this.props.error.errorMessage}
            </h5>

        return msg;
    }

    private getURLJSX() {
        if (!this.props.error.url) return null;

        const url =
            <h5>
                Url: <a href={this.props.error.url} target="_blank">
                    {this.props.error.url}
                </a>
            </h5>
        return url;
    }

    private getDetailsJSX() {
        if (!this.props.error.details) return null;
        const details =
            <h5>
                Details: {this.props.error.details.toString()}
            </h5>
        return details;
    }

    handleClose() {
        this.props.onDismissed();
    }

    componentDidMount() {
        //console.log("ErrorHandler mounted");
    }

    componentWillUnmount() {
        //console.log("ErrorHandler will be unmounted");
    }
}

export default ErrorHandler;