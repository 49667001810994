
export enum Roles { Admin = "ADMIN", Mngt = "MNGT", Incasso="INCASSO"};

export interface IRole {
    id: string;
    code: string;
    name: string;
}

//export class Role {

//    public id: string;
//    public code: number;
//    public name: string;

//    constructor() {
//        this.id = null;
//        this.code = null;
//        this.name = null;
//    }

//    public fromJson(json: Object): Role {
//        //DO NOT USE 'this.' RETURN A NEW OBJECT
//        const trueObject = new Role();
//        Object.assign<IRole, Object>(trueObject, json);

//        //Overloads

//        return trueObject;
//    }


//    public toJson(): Object {

//        let x = JSON.parse(JSON.stringify(this));

//        //Overloads

//        return x;
//    }
//}
