import * as React from 'react';

import { Row, Col } from 'react-bootstrap'
import { Jumbotron } from 'react-bootstrap'

import { CardNavigator } from "./../UIComponents/CardNavigator";
import { Sections, Modules } from "./../HelperClasses/Routing";



interface IHomeSiteMngtProps {
}


interface IHomeSiteMngtState {

}

export default class HomeSiteMngt extends React.Component<IHomeSiteMngtProps, IHomeSiteMngtState> {


    constructor(props: any) {
        super(props);

        this.state = { };
    }

    render() {

        const jsx =
            <>
                <Jumbotron className='m-0'>
                    {this.getModulesJSX()}
                </Jumbotron>
            </>
        return jsx;

    }

 //======================== Get Form JSX elements =============================//

    private getModulesJSX() {
        const jsx =
            <>
                <Row>
                    <Col className="p-2" >
                        <CardNavigator section={Sections.siteAdmin} module={Modules.accounts} headerText="Accounts" iconName="users-cog" />
                    </Col>
                    <Col className="p-2" >
                        <CardNavigator section={Sections.siteAdmin} module={Modules.tools} headerText="Tools" iconName="tools" />
                    </Col>
                    <Col className="p-2" >
                        <CardNavigator section={Sections.siteAdmin} module={Modules.about} headerText="About" iconName="info" />
                    </Col>
                </Row>
            </>
        return jsx;
    }


//======================== Get Menu Handlers =============================//



//======================== Get Form Handlers =============================//




//========================    Data Layer =============================/



//========================  Lifecycle =============================/
    //public handleStateError(error: ICustomError): void {
    //    if (error) {
    //        this.setState({  });
    //    }
    //}

    async componentDidMount() {
        //ApplicationState.get().onErrorChange.registerHandler(this.handleStateError);
        //console.log("Mounting Reports");
    }

    componentWillUnmount() {
        //ApplicationState.get().onErrorChange.unregisterHandler(this.handleStateError);
    }
}