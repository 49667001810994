import * as React from 'react';

//import Container from 'react-bootstrap/Container';
import { Row, Col } from 'react-bootstrap';
import { Jumbotron } from 'react-bootstrap';
import { Form, Button } from "react-bootstrap";

import { ApplicationState } from "../HelperClasses/ApplicationState";
import { IAccount } from "../DTO/Account";
import { ResetPasswordModal } from "./../UIComponents/ResetPasswordModal";
import { ModalResult } from "./../HelperClasses/Enums"; 
import { Http } from "./../HelperClasses/Http";
import { Cache } from "./../HelperClasses/Cache";
//import { HttpStatus } from '../HelperClasses/CustomError';
import { Errors } from '../HelperClasses/Errors';
import { ProfilePictureEditor } from './../UIComponents/ProfilePictureEditor'
import { ProfilePicture } from './../UIComponents/ProfilePicture'
//import { MyDateTime } from '../HelperClasses/MyDate';

//import { Modules, Routing, Sections } from "./../HelperClasses/Routing";


interface IMyAccountProps {


}
interface IMyAccountState {
    profilePictureURL: string;

    showResetPasswordModal: boolean;
    showRequestFeedback: boolean;
    showProfilePictureEditor: boolean;

    requestfeedbackMsg: string | JSX.Element;
}




export default class MyAccount extends React.Component<IMyAccountProps, IMyAccountState> {

    account: IAccount;

    constructor(props: any) {
        super(props);

        this.account = ApplicationState.get().sessionInfo.account;

        this.state = {
            profilePictureURL: this.account.profilePictureUrl, 
            showResetPasswordModal: false, showRequestFeedback: false, showProfilePictureEditor: false, requestfeedbackMsg: null
        };


        this.openPasswordResetForm = this.openPasswordResetForm.bind(this);
        this.closePasswordResetForm = this.closePasswordResetForm.bind(this);
        this.handleEditProfilePicture = this.handleEditProfilePicture.bind(this);
        this.handleNewProfilePicture = this.handleNewProfilePicture.bind(this);
        this.handleSessionChange = this.handleSessionChange.bind(this);
    }

    render() {
        if (ApplicationState.get().sessionInfo.account == null) return null;

        if (this.state.showResetPasswordModal) {
            return this.getResetPasswordFormJSX(this.account);
        }

        const y =
            <Jumbotron className='m-0 '>
                {this.getAccountDetailsJSX()}
                {this.state.showProfilePictureEditor ? <ProfilePictureEditor account={this.account} onChange={this.handleNewProfilePicture } /> : null}
            </Jumbotron>

        return y;

    }

    private getAccountDetailsJSX() {
        const frm =
            <>
                <h3>Mijn account</h3>
                <hr />
                <Row className='py-2'>
                    <Col xs={4} md={2} lg={2}>
                        Naam
                    </Col>
                    <Col xs={8} md={10} lg={10}>
                        <b>
                            {this.account ? this.account.firstName + " " + this.account.surName : ""}
                        </b>
                    </Col>
                </Row>

                <Row className='py-2'>
                    <Col xs={4} md={2} lg={2}>
                        Email
                    </Col>
                    <Col xs={8} md={10} lg={10}>
                        <b>
                            {this.account ? this.account.userName : ""}
                        </b>
                    </Col>
                </Row>

                <Row className='py-2'>
                    <Col xs={4} md={2} lg={2}>
                        Rollen
                    </Col>
                    <Col xs={8} md={10} lg={10}>
                        <b>
                            {this.account ? this.account.getRolesString() : ""}
                        </b>
                    </Col>
                </Row>

                <Row className='py-2'>
                    <Col xs={4} md={2} lg={2}>
                        Wachtwoord
                    </Col>
                    <Col xs={3} md={2} lg={1}>
                        
                        <span style={{ fontWeight: 'bolder', fontSize: '100%' }}>&bull;&bull;&bull;&bull;&bull;&bull;</span>
                    </Col>
                    <Col xs={5} md={8} lg={9}>
                        <Button size='sm' variant="outline-primary" onClickCapture={this.openPasswordResetForm} >
                            wijzigen
                        </Button>
                        <Form.Text hidden={!this.state.showRequestFeedback} >{this.state.requestfeedbackMsg}</Form.Text>
                    </Col>
                </Row>

                <Row className='py-2'>
                    <Col xs={4} md={2} lg={2} className='my-auto'>
                        Profiel foto
                    </Col>
                    <Col xs={3} md={2} lg={1} className='my-auto'>
                        <ProfilePicture size='md' srcURL={this.state.profilePictureURL} alt={this.account ? this.account.firstName + " " + this.account.surName : ""} fluid />
                    </Col>
                    <Col xs={5} md={8} lg={9} className='my-auto'>
                        <Button size='sm' variant="outline-primary" onClickCapture={this.handleEditProfilePicture} >
                            {this.state.showProfilePictureEditor ? 'annuleren' : 'wijzigen'}
                        </Button>
                    </Col>
                </Row>

            </>


        return frm;
    }


    private getResetPasswordFormJSX(account: IAccount) {
        const frm = <ResetPasswordModal userName={account.userName} onClose={this.closePasswordResetForm} />;

        return frm;
    }



    //======================== Handlers =============================//

    private openPasswordResetForm() {
        this.setState({ showResetPasswordModal: true });
    }

    private async closePasswordResetForm(result: ModalResult, newPwd: string) {
        this.setState({ showResetPasswordModal: false });

        if (result == ModalResult.OK) {
            await this.setPwdAsync(newPwd);
        }
    }

    private handleEditProfilePicture() {
        this.setState({ showProfilePictureEditor: !this.state.showProfilePictureEditor})
    }

    private async handleNewProfilePicture(URL: string) {

        ApplicationState.get().sessionInfo.account.profilePictureUrl = URL;
        this.setState({
            profilePictureURL: URL,
            showProfilePictureEditor: false
        })
        Cache.invalidateActiveMembers();
    }


    //======================== Data Layer =============================//

    private async setPwdAsync(password: string): Promise<boolean> {

        const http = new Http();
        const res = await http.postAsyncPrimitive<boolean>("api/account/setpassword", password);

        if (http.error == null) {
            this.setState({ showRequestFeedback: true, requestfeedbackMsg: "Wachtwoord gewijzigd"})
            return res;
        }
        else {

            if (http.error.isHttpCustomError()) {
                const errMsg = Errors.fromCustomError(http.error.customErrorNr, http.error.customErrorData);
                this.setState({ showRequestFeedback: true, requestfeedbackMsg: errMsg })
                return false;
            }
            
            ApplicationState.get().setError(http.error);
            return false;
        }
    }


    //======================== Lifecycle =============================//
    private handleSessionChange() {
        
    }


    componentDidMount() {
        //console.log("mounting MyProfile");
        ApplicationState.get().sessionInfo.onChange.registerHandler(this.handleSessionChange);
    }

    componentWillUnmount() {
        //console.log("unmounting MyProfile");
        ApplicationState.get().sessionInfo.onChange.unregisterHandler(this.handleSessionChange);
    }
}