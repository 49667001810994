import * as React from 'react';
import { RouteComponentProps, withRouter } from "react-router-dom";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { Dropdown, Image } from 'react-bootstrap'

import { ApplicationState } from "../HelperClasses/ApplicationState";
import { Routing, Sections } from "../HelperClasses/Routing";
import { CustomError } from '../HelperClasses/CustomError';
import { Cache } from '../HelperClasses/Cache';
import { Roles } from "./../DTO/Role";


export interface ISectionsMenuProps extends RouteComponentProps {

}

export interface ISectionsMenuState {
    iAmCampOrg : boolean;
}



export class SectionsMenu extends React.Component<ISectionsMenuProps, ISectionsMenuState> {

    private account = ApplicationState.get().sessionInfo.account;

    constructor(props: ISectionsMenuProps) {
        super(props);

        this.state = { iAmCampOrg: false };

    }

    render() {

         const menuJsx =
            <Dropdown>
                 
                 {this.hasSubmenuItems() ? this.getToggleJSX() : this.getCurrentLocationJSX()}

                 <Dropdown.Menu className="dropdown-menu-left " >
                     {this.getMyeSoarMenuItem()}
                     {this.getSecretariatMenuItem()}
                     {this.getCampMenuItem()}
                     {this.getSiteMngtMenuItem()}

                 </Dropdown.Menu>

            </Dropdown>;

        return menuJsx;
    }


    private getToggleJSX() {
        const jsx =
                <Dropdown.Toggle variant="dark" id="dropdown-basic">
                    { this.getCurrentLocationJSX() }
                </Dropdown.Toggle>
        return jsx;
    }

    private getCurrentLocationJSX() {
        
        
        const currentSection = Routing.getSection();

        let jsx = <></>;

        switch (currentSection) {
            case Sections.home: jsx = <>Ga naar...</>;

            case Sections.myEsoar:
                jsx = <><Image src="imgs/kazmlogo24x24.gif" roundedCircle fluid />&nbsp;&nbsp;{Routing.getCurrentSectionName()}</>
                break;

            case Sections.secretariat:
                jsx = <><FontAwesomeIcon icon="book" />&nbsp;&nbsp;{Routing.getCurrentSectionName() }</>
                break;

            case Sections.camp:
                jsx = <><FontAwesomeIcon icon="campground" />&nbsp;&nbsp;{Routing.getCurrentSectionName()}</>
                break;

            case Sections.siteAdmin:
                jsx = <><FontAwesomeIcon icon="cogs" />&nbsp;&nbsp;{Routing.getCurrentSectionName()}</>
                break;

            default: throw new CustomError("unknown section: " + currentSection.toString(), null, null, null, null, null)

        }

        jsx =
            <span style={{ color: 'white' }}>
                {jsx}
            </span>

        return jsx;

    }

    private getMyeSoarMenuItem() {
        if (Routing.getSection() == Sections.myEsoar) return null;
        if (!this.isMyeSoarAllowed()) return null;

        const jsx =
            <Dropdown.Item active={false} href={"#" + Routing.getPath(Sections.myEsoar) } >
                <span ><Image src="imgs/kazmlogo24x24.gif" roundedCircle fluid />&nbsp;&nbsp;{Routing.getSectionName(Sections.myEsoar)}</span>
            </Dropdown.Item>

        return jsx;
    }

    private getSecretariatMenuItem() {
        if (Routing.getSection() == Sections.secretariat) return null;
        if (!this.isSecretariatAllowed()) return null;

        const jsx =
            <Dropdown.Item active={false} href={"#" + Routing.getPath(Sections.secretariat)} className="">
                <span><FontAwesomeIcon icon="book" />&nbsp;&nbsp;{Routing.getSectionName(Sections.secretariat)}</span>
        </Dropdown.Item>

        return jsx;
    }

    private getCampMenuItem() {
        if (Routing.getSection() == Sections.camp) return null;
        if (!this.isCampAllowed()) return null;

        const jsx =
            <Dropdown.Item active={false} href={"#" + Routing.getPath(Sections.camp)} className="">
                <span><FontAwesomeIcon icon="campground" />&nbsp;&nbsp;{Routing.getSectionName(Sections.camp)}</span>
            </Dropdown.Item>

        return jsx;
    }

    private getSiteMngtMenuItem() {
        if (Routing.getSection() == Sections.siteAdmin) return null;
        if (!this.isSiteMngtAllowed()) return null;


        const jsx =
            <Dropdown.Item active={false} href={"#" + Routing.getPath(Sections.siteAdmin)} className="">
                <span><FontAwesomeIcon icon="cogs" />&nbsp;&nbsp;{Routing.getSectionName(Sections.siteAdmin)}</span>
            </Dropdown.Item>

        return jsx;
    }

    //===============  Logic ============================//
    private isMyeSoarAllowed() {
        if (this.account) return true;
        return false;
    }

    private isSecretariatAllowed() {
        if (this.account.hasRole(Roles.Admin) || this.account.hasRole(Roles.Mngt)) return true;
        return false;
    }

    private isCampAllowed() {
        if (this.account.hasRole(Roles.Admin) || this.account.hasRole(Roles.Mngt) || this.account.hasRole(Roles.Incasso)) return true;
        if (this.state.iAmCampOrg) return true;
        return false;
    }

    private isSiteMngtAllowed() {
        if (this.account.hasRole(Roles.Admin)) return true;
        return false;
    }

    private hasSubmenuItems() {
        if (this.isMyeSoarAllowed() && Routing.getSection() != Sections.myEsoar) return true;
        if (this.isSecretariatAllowed() && Routing.getSection() != Sections.secretariat) return true;
        if (this.isCampAllowed() && Routing.getSection() != Sections.camp) return true;
        if (this.isSiteMngtAllowed() && Routing.getSection() != Sections.siteAdmin) return true;

        return false;
    }


    //===================== Lifdecycle ==============================================//
    async componentDidMount() {
        //ApplicationState.get().sessionInfo.onChange.registerHandler(this.handleSessionChange);
        await this.setState({ iAmCampOrg: await Cache.amICurrentCampOrg() })
    }

    componentWillUnmount() {
        //ApplicationState.get().sessionInfo.onChange.unregisterHandler(this.handleSessionChange);
    }

}

export default withRouter(SectionsMenu)