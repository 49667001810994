import * as React from 'react';

import { Form, InputGroup } from "react-bootstrap";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Styles } from '../HelperClasses/Styles';
//import { JsxElement } from 'typescript';

//import { Formatters } from "./../HelperClasses/Formatters"

//import { CustomError, HttpStatus } from '../HelperClasses/CustomError';


export interface INumericFieldProps {

    isInvalid?: boolean;
    tabIndex?: number;
    disabled?: boolean;

    value: number;
    allowNegativeValue?: boolean;
    precision?: number;

    unit?: string | JSX.Element;

    step?: number;

    onChangeCapture?: (newValue: number) => void;

    controlMode?: boolean;

    style?: React.CSSProperties;
}

interface INumericFieldState {
    valueString: string;
    value: number;
}




export class NumericField extends React.Component<INumericFieldProps, INumericFieldState> {

    constructor(props: any) {
        super(props);

        //if (this.props.min && !Number.isInteger(this.props.min)) throw new Error("Invalid value for property min. Should be an integer");
        //if (this.props.max && !Number.isInteger(this.props.max)) throw new Error("Invalid value for property max. Should be an integer");

        this.state = {
            valueString: this.getDisplayFormattedValueString(this.props.value, this.props.precision),
            value: this.props.value
        };

        this.handleOnChangeCapture = this.handleOnChangeCapture.bind(this);
        this.handleIncrement = this.handleIncrement.bind(this);
        this.handleLostFocus = this.handleLostFocus.bind(this);
    }

    render() {

        if (this.props.controlMode == undefined || this.props.controlMode == true) {
            return this.getControlModeJSX();
        }
        else {
            return this.getTouchModeJSX();
        }
    }

    private getControlModeJSX() {

        let placeholder = '-';
        if (this.props.precision != undefined && this.props.precision>0) {
            placeholder += this.getUniversalDecimalSeparator();
            for (let i = 0; i < this.props.precision; i++) {
                placeholder += '-';
            }
        }

        const jsx =
            <>
                <InputGroup>
                    {this.getStepJSX(false)}
                    <Form.Control
                        autoFocus
                        ref='refInput'
                        type="input"
                        placeholder={placeholder}
                        value={this.state.valueString ? this.state.valueString : ""}
                        onChangeCapture={this.handleOnChangeCapture}
                        onChange={() => { }}
                        onBlur={this.handleLostFocus}
                        isInvalid={this.props.isInvalid}
                        tabIndex={this.props.tabIndex ? this.props.tabIndex : 999}
                        disabled={this.props.disabled ? this.props.disabled : false}
                        style={{ ...this.props.style, ...{} }}
                    />
                    {this.getStepJSX(true)}
                    {this.getUnitJSX()}
                </InputGroup>
            </>
        return jsx;
    }

    private getTouchModeJSX() {

        const blue = Styles.getPrimaryColor();
        const jsx =
            <span className=''>
                <span className='border' hidden={this.props.disabled} onClick={() => { this.handleIncrement(false) }} style={{ borderColor: blue}}>
                    <FontAwesomeIcon fixedWidth size='sm' inverse={false} icon='minus' color={blue} />
                </span>
                <span className=''>
                    &nbsp;&nbsp;{this.state.value}&nbsp;&nbsp;
                </span>
                <span className='border' hidden={this.props.disabled} onClick={() => { this.handleIncrement(true) }} style={{ borderColor: blue }}>
                    <FontAwesomeIcon fixedWidth size='sm' inverse={false} icon='plus' color={blue} />
                </span>

            </span>

        return jsx;
        <span className="fa-layers fa-fw">
            <FontAwesomeIcon size='sm' icon='circle' transform='' />
            <FontAwesomeIcon size='sm' icon='plus'  />

        </span>


    }

    private getStepJSX(increment: boolean) {
        if (Number(this.props.step) != this.props.step) return null;

        let icon: IconProp = 'minus'
        let color = 'blue'
        let disabled = false;

        if (increment) {
            icon = 'plus'
        }
        else {
            if (this.props.allowNegativeValue != undefined && !this.props.allowNegativeValue && this.state.value <= 0) {
                disabled = true;
                color = 'gray';
            }
        }

        //

        const jsx =
            <>
                <InputGroup.Text>
                    <span onClick={(e: any) => { !disabled ? this.handleIncrement(increment) : null }} >
                        <FontAwesomeIcon inverse={false} color={color} icon={icon} />
                    </span>
                </InputGroup.Text>
            </>

        return jsx;

    }

    private getUnitJSX() {
        if (!this.props.unit) return null;

        const jsx =
            <>
                <InputGroup.Text>{this.props.unit}</InputGroup.Text>
            </>

        return jsx;
    }

    //============= Handlers ===============================================================//

    private async handleOnChangeCapture(event: React.ChangeEvent<HTMLInputElement>) {
        const stringValue = event.currentTarget.value;
        await this.setNewValue(stringValue);
    }

    private async handleLostFocus(event: React.ChangeEvent<HTMLInputElement>) {
        const formattedString =  this.getDisplayFormattedValueString(this.state.value, this.props.precision);
        await this.setState({ valueString: formattedString });
    }

    private async handleIncrement(increment: boolean) {
        if (Number(this.props.step) != this.props.step) return;

        const oldValue = this.state.value;

        let newValue = this.state.value;
        if (increment) {
            newValue = newValue + this.props.step;
        }
        else {
            newValue = newValue - this.props.step;
        }

        if (newValue < 0 && (this.props.allowNegativeValue != undefined && !this.props.allowNegativeValue)) {
            newValue = 0;
        }

        let newValueString = newValue.toString();
        if (this.props.precision != undefined) {
            newValue = Number.parseFloat(newValue.toFixed(this.props.precision));
            newValueString = newValue.toFixed(this.props.precision);
        }



        await this.setState({valueString: newValueString, value: newValue })

        if (this.props.onChangeCapture && (newValue != oldValue)) {
            this.props.onChangeCapture(newValue);
        }
    }

    

    //========================================  Helpers ====================================//

    private async setNewValue(newStringValue: string) {

        if (!newStringValue || newStringValue.length == 0) {
            await this.setState({ valueString: null, value: null });
            return;
        }


        let newString = "";
        const oldValue = this.state.value;
        const allowNegative: boolean = (this.props.allowNegativeValue == undefined ? true : this.props.allowNegativeValue);

        //Replace any local decimal seperators by the universal one
        newStringValue = newStringValue.replace(this.getLocalDecimalSeparator(), this.getUniversalDecimalSeparator());

        //Filter only Numeric chars, 1 dot/comma if precision>0 and minus symbol '-' if negative nuimbers allowed
        let nrDots = 0;
        for (let i = 0; i < newStringValue.length; i++) {

            if (i == 0 && newStringValue[0] == '-' && allowNegative) {
                newString = newString + newStringValue[0];
            }
            else if (this.isUniversalCommaSeperator(newStringValue[i])) {
                if (this.props.precision && this.props.precision > 0) {
                    if (nrDots == 0) {
                        newString = newString + newStringValue[i];
                    }
                    nrDots++;
                }
            }
            else if (this.isDigitCode(newStringValue[i])) {
                newString = newString + newStringValue[i];
            }
        }

        //If after filtering there is nothing left...
        if (!newString || newString.length <= 0) {
            await this.setState({ valueString: null, value: null });
            return;
        } 

        //prepend a 0
        if (this.isUniversalCommaSeperator(newString[0])) {
            newString = '0' + newString;
        }
        else if (newString[0] == '-' && this.isUniversalCommaSeperator(newString[1])) {
            newString = '-0' + newString.slice(1);
        }

        let newValue = Number.parseFloat(newString);//This only works with universal '.' decimal seperators
        if (isNaN(newValue)) {
            newValue = null;
        }

        await this.setState({ valueString: newString, value: newValue });
        if (this.props.onChangeCapture && (newValue != oldValue)) {
            this.props.onChangeCapture(newValue);
        }
    }

    private getDisplayFormattedValueString(value: number, precision: number) {

        let valueString = null;
        if (value != null) {
            valueString = value.toString();
            if (precision && precision>0) {
                valueString = Number(value).toFixed(precision)
            }
        }
        return valueString;
    }

    private isDigitCode(n: string) {
        return (n.charCodeAt(0) >= "0".charCodeAt(0) && n.charCodeAt(0) <= "9".charCodeAt(0));
    }

    private isUniversalCommaSeperator(char: string) {
        if (!char) return false;
        if (char.length != 1) false;

        if (char[0] == this.getUniversalDecimalSeparator()[0]) return true;

        //if (char[0] == '.') return true;
        //if (char[0] == ',') return true;

        return false;
    }

    private getLocalDecimalSeparator(): string{
        let n = 1.1;
        const ds = n.toLocaleString().substring(1, 2);
        return ds;
    }

    private getUniversalDecimalSeparator(): string {
        return ".";
    }

    //static getDerivedStateFromProps(props: any, current_state: any) {
    //    if (props.precision && !Number.isInteger(props.precision) && props.precision < 0)
    //        throw new Error("Invalid value for property min. Should be a positive integer");


    //    if (current_state.value !== props.value) {
    //        return {
    //            valueString: NumericField.getValueString(props.value, props.precision),
    //            value: props.value,
    //        }
    //    }
    //    return null
    //}

    async componentDidUpdate(prevProps: INumericFieldProps) {

        if (this.props.value != prevProps.value || this.props.precision != prevProps.precision) { //simple check if changed
            await this.setState({
                valueString: this.getDisplayFormattedValueString(this.props.value, this.props.precision),
                value: this.props.value
            });
        }
    }
}