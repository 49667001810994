import * as React from 'react';


//import { Button, ListGroup, ListGroupItem } from 'react-bootstrap'
//import { Alert } from 'react-bootstrap'

import { Jumbotron } from 'react-bootstrap'
import { Row, Col } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


//import { Tooltip, OverlayTrigger } from 'react-bootstrap'
//import { Styles } from '../HelperClasses/Styles';
//import { FiletypePicker, fileTypes } from "./../UIComponents/FiletypePicker";
import { InfoOverlay } from "./../UIComponents/InfoOverlay";
import { LoadingSpinner } from '../UIComponents/MySpinners';
//import { Formatters } from '../HelperClasses/Formatters'
//import { MyDateTime, MyTimeSpan } from '../HelperClasses/MyDate';
//import { ICustomError } from "../HelperClasses/CustomError";
import { ProfilePicture } from "./../UIComponents/ProfilePicture";
import { ContactInfo } from "./../UIComponents/ContactInfo";

//import { Http } from "../HelperClasses/Http";
import { Cache } from "../HelperClasses/Cache";
import { ApplicationState } from "../HelperClasses/ApplicationState";


import { IMember } from "./../DTO/Member";
//import { ModalResult } from '../HelperClasses/Enums';
//import { MyDateTime } from '../HelperClasses/MyDate';

interface IContactsProps {

}


interface IContactsState {
    member: IMember;
    isLoading: boolean;

    arrMembers: Array<IMember>

    mngtOnly: boolean;
}

export default class Contacts extends React.Component<IContactsProps, IContactsState> {


    constructor(props: any) {
        super(props);

        this.state = {
            member: null,
            isLoading: false,

            arrMembers: null,

            mngtOnly: false,
        }

        //this.handleChangeMngtOnly = this.handleChangeMngtOnly.bind(this);

    }

    render() {
        
        const jsx =
            <LoadingSpinner active={this.state.isLoading} >
                <Jumbotron className='m-0 ' >
                    {this.getInnerJSX()}
                </Jumbotron>
            </LoadingSpinner>
        return jsx;
    }

    //======================== Get Form JSX elements =============================//

    private getInnerJSX() {
        const jsx = 
        <>
            {this.getTitelJSX()}
            <hr />
            {this.getTableJSX()}

        </>

        return jsx;
    }

    private getTitelJSX() {
        
        const jsx =
            <>
                <Row style={{ maxWidth: '600px' }} >
                    <Col className='col-auto mr-auto'  >
                        <h3 >
                            <FontAwesomeIcon icon="address-book" />&nbsp;
                            Contacteer 
                        </h3>
                    </Col>
                    <Col className='col-auto my-auto'>
                        <InfoOverlay tooltip={this.getShareStatusJSX()} />
                    </Col>
                </Row>
                

                

            </>
        return jsx;
    }

    //private getMngtSelectorJSX() {

    //    const jsx =
    //        <div className='text-right'>
    //            <Form.Switch

    //                id="switchMngtOnly"
    //                label="enkel bestuur"
    //                inline={ false}
    //                checked={this.state.mngtOnly}
    //                onChangeCapture={this.handleChangeMngtOnly}
    //                onChange={() => { }}
    //            />
    //        </div>

    //    return jsx;
    //}

    private getShareStatusJSX() {
        const jsx =
            <div className='text-left'>

                <u><i>Toestemmingen</i></u>
                <br />
                <br />
                Om te kunnen bellen, WhatsAppen, e-mailen of SMS'en met een ander lid moeten jullie hier beiden toestemming voor geven.
                <br />
                <br />
                Ga naar <i>"Instellingen - Toestemmingen"</i> om je telefoon nummer en/of email zichtbaar te maken.
            </div>

        return jsx;
    }

    private getTableJSX() {

        if (!this.state.arrMembers || this.state.arrMembers.length == 0) {
            return null;
        }

        //let sortedArr = new Array<IMemberFlightDay>();
        //for (let x of this.state.arrMemberFlightDays) {
        //    //Exclude current member
        //    if (x.member.id != this.props.member.id) {
        //        sortedArr.push(x);
        //    }
        //}

        ////Sort
        //sortedArr = sortedArr.sort((o1, o2) => {
        //    if (o1.member.firstName[0] > o2.member.firstName[0]) return 1;
        //    if (o1.member.firstName[0] < o2.member.firstName[0]) return -1;
        //    return 0;
        //})

        //<div className="position-relative">

        const sortedArr = this.state.arrMembers;

        const tableJSX =
            <>

                {sortedArr.map(
                    (member, index) => {

                        const style = (index % 2 == 0 ? { background: '#ebf5fb', maxWidth: '600px'} : { background: 'white', maxWidth: '600px'  });

                        const jsx =

                            

                            <Row key={index} style={style} className='px-0 py-1 mx-0 my-0' >
                                <Col xs={2} className='text-center my-auto'>
                                    <ContactInfo key={index} size='sm' member={member} showAuthorization={true} >
                                        {this.getProfilePictureJSX(member)}
                                    </ContactInfo>
                                </Col>

                                <Col xs={10} style={style} className='my-auto' >
                                    <ContactInfo key={index} size='sm' member={member} showAuthorization={true} >
                                        <b>
                                            {member.getFullNameReverse()}
                                        </b>
                                    </ContactInfo>
                                </Col>

                                </Row>

                            
                        return jsx;
                    }
                )
                }

            </>

        return tableJSX;
    }

    private getProfilePictureJSX(member: IMember) {

        const jsx =
            //<span className='p-0 m-0' style={{ cursor: 'pointer' }} onClick={() => this.handleEdit(a.original as IMember)}>
            //<span className='p-0 m-0'>
            <ProfilePicture size='sm' srcURL={member.profilePictureUrl} fluid={false} alt={member.getFullName()} />
            //</span>

        return jsx;
    }







    //==================== Handlers =======================================//

    

    //private handleChangeMngtOnly(e: any) {

    //    this.setState({ mngtOnly: !this.state.mngtOnly });
    //}


    //==================== Data layer =======================================//

    private async loadBaseTables() {
        //don't await
        this.loadMembersAsync();
    }

    private async loadMembersAsync(): Promise<Array<IMember>> {

        this.setState({ isLoading: true });

        const res = await Cache.loadActiveMembersAsync();
        this.setState({ isLoading: false, arrMembers: res as Array<IMember> });
        return (res as Array<IMember>);
        

        //const http = new Http();

        //const res = await http.getAsyncObject<IMember>('api/member/active', Member) as Array<IMember>;

        //if (http.error == null) {
        //    this.setState({ isLoading: false, arrMembers: res as Array<IMember> });
        //    return (res as Array<IMember>);
        //}
        //else {
        //    this.setState({ isLoading: false, arrMembers: null });
        //    ApplicationState.get().setError(http.error);
        //    return null;
        //}
    }



    //==================== Lifecycle =================================//

    async componentDidMount() {
        //ApplicationState.get().onErrorChange.registerHandler(this.handleStateError);
        await this.setState({ member: ApplicationState.get().sessionInfo.account.member })

        await this.loadBaseTables();
    }

    componentWillUnmount() {
        //ApplicationState.get().onErrorChange.unregisterHandler(this.handleStateError);
    }
}