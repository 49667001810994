import * as React from 'react';


//import { Redirect } from "react-router-dom";

//import Container from 'react-bootstrap/Container';

import { Row, Col, Badge } from 'react-bootstrap';

import { Form, Button } from 'react-bootstrap';
//import { InputGroup } from 'react-bootstrap';
import { Card, Accordion } from 'react-bootstrap';

import 'rc-datepicker/lib/style.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

var NumericInput = require('react-numeric-input');

import { ModalResult, FormModus } from './../HelperClasses/Enums';
//import { Styles } from './../HelperClasses/Styles';

import { ApplicationState } from "./../HelperClasses/ApplicationState";
import { ISessionInfo } from "./../HelperClasses/SessionInfo";

import { MyDateTime } from "../HelperClasses/MyDate";
import { CustomError } from '../HelperClasses/CustomError';
import { TimeField } from './../UIComponents/TimeField';



import { IMemberFlightDay, MemberFlightDay, IMemberFlightDayValidationState } from '../DTO/MemberFlightDay';


export interface IMemberFlightDayFormProps {
    formMode: FormModus;

    date: MyDateTime;
    entityIn?: IMemberFlightDay;

    onClose: (result: ModalResult, entity: IMemberFlightDay) => void;
}

interface IMemberFlightDayFormState {

    entity: IMemberFlightDay;

    isValidated: boolean;
    validationState : IMemberFlightDayValidationState
}

export class MemberFlightDayForm extends React.Component<IMemberFlightDayFormProps, IMemberFlightDayFormState> {

    private entity: IMemberFlightDay;
    constructor(props: any) {
        super(props);

        
        //var initialEntity;
        if (this.props.formMode == FormModus.Edit) {

            if (this.props.entityIn == null) {
                ApplicationState.get().setError(new CustomError("No form entity provided.", null, null, null, null, null));
                return;
            }
            this.entity = Object.assign<MemberFlightDay,IMemberFlightDay>(new MemberFlightDay(), this.props.entityIn);
        }
        else {
            //Outside world id to create
            this.entity = Object.assign<MemberFlightDay, IMemberFlightDay>(new MemberFlightDay(), this.props.entityIn);
        }

        //Set initial state
        this.state = {
            entity: this.entity,
            isValidated: false,
            validationState : (this.entity.getValidationState() as IMemberFlightDayValidationState)
        };

        //Form methods
        this.handleSessionChange = this.handleSessionChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleReset = this.handleReset.bind(this);
        this.validateForm = this.validateForm.bind(this);

        this.handleArrivalTimeChange = this.handleArrivalTimeChange.bind(this);
        this.handleDepartureTimeChange = this.handleDepartureTimeChange.bind(this);
        this.handleNrPassengerFlightsChange = this.handleNrPassengerFlightsChange.bind(this);
        
    }

    render() {

        let form = this.getForm();

        return (
            <>
                {form}

            </>   
        );
    }

    //======================== Get Form JSX elements =============================//

    private getForm() {
        const form =
            <Form noValidate validated={false} onSubmitCapture={this.handleSubmit} onResetCapture={this.handleReset} className='m-2'>

                {this.getInnerFormJSX()}

                <hr />
                <div style={{ textAlign: 'right' }}>
                    <Button type="reset" variant="secondary" tabIndex={998}>Annuleren</Button>
                    &nbsp;&nbsp;
                    <Button type="submit" tabIndex={999}>Opslaan</Button>
                </div>

            </Form>

        return form;
    }

    private getInnerFormJSX() {

        const jsx = 
            <>
                <h5>
                    <Badge variant="secondary">{this.state.entity ? this.state.entity.member.getFullName() : null}</Badge>
                </h5>
                <Accordion defaultActiveKey="0" >
                    <Card style={this.accordeonValid()}>
                        <Accordion.Toggle as={Card.Header} eventKey="0">
                            <FontAwesomeIcon inverse={false} size="lg" icon='check' color='green' />&nbsp;&nbsp;Aanwezigheid
                        </Accordion.Toggle>

                        <Accordion.Collapse eventKey="0">
                            <Card.Body>
                                {this.getMemberFlightDayJSX()}
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>
            </>
        return jsx;
    }
        
    private getMemberFlightDayJSX() : JSX.Element
    {
        const jsx =
            <>
                {this.getLine1()}
                <hr />
                {this.getLine2()}
            </>
        return jsx; 
    }

    private getLine1() {
        //console.log((this.entity.departureTime == null || this.entity.departureTime.isAfter(this.entity.arrivalTime)))
        //console.log(this.state.validationState.departureTimeIsValid)
        const jsx = 
            <>
            <Row>
                    <Col xs={6}>
                        <Form.Group controlId="frmArrivalTime" >
                                <Form.Text><FontAwesomeIcon inverse={false} icon="clock" />&nbsp;&nbsp;Vanaf</Form.Text>
                                <TimeField date={this.props.date} time={this.entity.arrivalTime}
                                    onChangeCapture={this.handleArrivalTimeChange}
                                    isInvalid={
                                        !this.state.validationState.arrivalTimeIsValid
                                        && this.state.isValidated
                                    }
                                    ref='refArrivalTime'
                                    tabIndex={1} />
                        </Form.Group>
                </Col>
                <Col xs={6}>
                        <Form.Group controlId="frmDepartureTime" >
                            <Form.Text><FontAwesomeIcon inverse={false} icon="clock" />&nbsp;&nbsp;Tot</Form.Text>
                            <TimeField date={this.props.date} time={this.entity.departureTime}
                                onChangeCapture={this.handleDepartureTimeChange}
                                isInvalid={!this.state.validationState.departureTimeIsValid
                                    && this.state.isValidated
                                }
                                tabIndex={2} />
                        </Form.Group>
                </Col>
            </Row>

                </>
        return jsx;
    }

    private getLine2() {
        const jsx =
            <>
            <Row>
                    <Col xs={12}>
                        <Form.Group controlId="frmPassenger flights" >
                            <Form.Text><FontAwesomeIcon inverse={false} icon="user-friends" />&nbsp;&nbsp;Initiatie vluchten (max. 3)</Form.Text>
                            <NumericInput min={0} max={3} step={1} precision={0} className="form-control"
                                snap
                                value={this.state.entity.nrPassengerFlights ? this.state.entity.nrPassengerFlights : null}
                                onChange={this.handleNrPassengerFlightsChange}
                                tabIndex={3} />
                        </Form.Group>
                    </Col>

            </Row>

        </>
        return jsx;
    }

    //======================== Form Validation =============================//

    private handleArrivalTimeChange(time: MyDateTime) {
        this.entity.arrivalTime = time;
        this.setState({ entity: this.entity })
    }

    private handleDepartureTimeChange(time: MyDateTime) {
        this.entity.departureTime = time;
        this.setState({ entity: this.entity })
    }

    private handleNrPassengerFlightsChange(value: number | null, stringValue: string, input: HTMLInputElement) {
        this.entity.nrPassengerFlights = Math.min(value,3);

        this.setState({ entity: this.entity });
    }
    //======================== Form Validation =============================//

    private accordeonValid() {
        return {};
    //    let vs = this.entity.getValidationState() as IFlightDayValidationState;
    //    return !vs.stateIsValid && this.state.isValidated ?
    //        Styles.getDivErrorStyle() : {};
    }

    private validateForm() {
        let vs = this.entity.getValidationState() as IMemberFlightDayValidationState;

        this.setState({validationState: vs});

        ////console.log("Valid: " + vs.isValid.toString());
        return vs.stateIsValid;
    }

    private handleReset() {
        event.preventDefault();
        event.stopPropagation();
        this.setState({ isValidated: false });

        this.props.onClose(ModalResult.Cancel, null);
    }

    private async handleSubmit(event: React.FormEvent<HTMLFormElement>) {

        event.preventDefault();
        event.stopPropagation();

        this.setState({ isValidated: true });  //toggle the validation for all controls
        // this.validateForm() && event.currentTarget.checkValidity() //checkValidity checks the required fields but we need to set invalid manually so makes no sense to use
        
        if (this.validateForm()) {
            //console.log("Closing AircraftForm")
            this.props.onClose(ModalResult.OK, this.entity);
        }
    }

    //======================== Data Layer =============================//

    private async loadBaseTables() {

    }


    //======================== Lifecycle =============================//

    handleSessionChange = (sessionInfo: ISessionInfo) => {
    }

    async componentDidMount() {
        //console.log("mounting AircraftForm");
        ApplicationState.get().sessionInfo.onChange.registerHandler(this.handleSessionChange);

        this.loadBaseTables();

        ((this.refs.refArrivalTime as any).refs.refInput as HTMLInputElement).focus();
    }

    componentWillUnmount() {
        //console.log("unmounting AircraftForm");
        ApplicationState.get().sessionInfo.onChange.unregisterHandler(this.handleSessionChange);
    }
}
