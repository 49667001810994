import * as React from 'react';

//import { RouteComponentProps } from "react-router-dom";


//import { Button, ListGroup, ListGroupItem } from 'react-bootstrap'
//import { Alert, Button } from 'react-bootstrap'

import { Alert, Jumbotron, Button } from 'react-bootstrap';
import { LoadingSpinner } from '../UIComponents/MySpinners';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { ICustomError } from "../HelperClasses/CustomError";
import { ModalResult } from '../HelperClasses/Enums';
import { ResetPasswordModal } from "../UIComponents/ResetPasswordModal";
import { Errors } from '../HelperClasses/Errors';

import { Http } from "../HelperClasses/Http";
import { Routing } from "../HelperClasses/Routing";
import { ApplicationState } from "../HelperClasses/ApplicationState";



interface IResetPwdProps {
}


interface IResetPwdState {
    isLoading: boolean;

    wasProcessed: boolean;
    wasReset: boolean;
    errMsg: string | JSX.Element;

    showResetPasswordModal: boolean;
    resetCode: string;
    userName: string;
}

export default class ResetPwdPage extends React.Component<IResetPwdProps, IResetPwdState> {


    constructor(props: any) {
        super(props);

        //LOG OFF FIRST!
        if (ApplicationState.get().sessionInfo.account != null) {
            ApplicationState.get().sessionInfo.logOff();
        }

        this.state = { isLoading: false, wasProcessed: false, wasReset: false, errMsg: null, showResetPasswordModal: true, resetCode: this.getResetCode(), userName: null };

        this.handleStateError = this.handleStateError.bind(this);
        this.closePasswordResetForm = this.closePasswordResetForm.bind(this);
    }

    render() {

        if (this.state.resetCode) {

            if (this.state.isLoading) {
                return this.getLoadingJSX();
            }
            else if (this.state.wasProcessed) {
                if (this.state.wasReset) {
                    return this.getSuccesJSX();
                }
                else {
                    return this.getFailureJSX();
                }
            }
            else {
                return this.getDialogJSX();
            }
        }
        else {
            return <Alert variant="danger">Invalid reset code.</Alert>
        }

    }

    //================== Rendering ====================//

    private getDialogJSX() {
        if (!this.state.showResetPasswordModal) return null;

        const jsx = <ResetPasswordModal userName={this.state.userName ? this.state.userName : this.state.resetCode} onClose={this.closePasswordResetForm} />

        return jsx;
    }
    private getLoadingJSX() {
        const jsx =
            <LoadingSpinner active={this.state.isLoading} >
                <Jumbotron className='m-0'>
                    Uw wachtwoord wordt momenteel gereset...
                </Jumbotron>
            </LoadingSpinner>

        return jsx;
    }

    private getSuccesJSX() {
        const jsx =
            <Jumbotron>
                <Alert variant="info">Uw wachtwoord wijziging is gelukt!</Alert>
                <p />
                <Button title="onzin" href="#login">
                    <FontAwesomeIcon icon="sign-in-alt" />&nbsp;&nbsp;Aanmelden
            </Button>
            </Jumbotron>

        return jsx;
    }

    private getFailureJSX() {
        const jsx =
            <Jumbotron>
                <Alert variant="danger">Wachtwoord reset is mislukt: {this.state.errMsg}
                    <br /> Ga naar 'Aanmelden' en klik op 'Wachtwoord vergeten?' om opnieuw te proberen.</Alert>
                <p />
                <Button title="onzin" href="#login">
                    <FontAwesomeIcon icon="sign-in-alt" />&nbsp;&nbsp;Aanmelden
            </Button>
            </Jumbotron>

        return jsx;
    }
    //================== Handlers ====================//

    private getResetCode() {
        const code = Routing.getQueryString();
        return code;
    }

 
    private async closePasswordResetForm(result: ModalResult, newPwd: string) {
        this.setState({ showResetPasswordModal: false });

        if (result == ModalResult.OK) {
            const res = await this.resetPwdAsync(this.state.resetCode, newPwd);
            this.setState({ wasProcessed: true, wasReset: res });
        }
    }

    //================== Http ====================//

    private async loadBaseDataAsync(): Promise<boolean> {
        const http = new Http();
        const res = await http.getAsyncPrimitive<string>("api/account/resetcode/" + this.state.resetCode);

        if (http.error == null) {
            this.setState({ userName: (res as string) })
            return true;
        }
        else {
            if (http.error.isHttpCustomError()) {
                this.setState({ wasProcessed: false, wasReset: false, showResetPasswordModal: false });
            }
            ApplicationState.get().setError(http.error);
            return false;
        }
    }

    private async resetPwdAsync(resetCode: string, password: string): Promise<boolean> {
        if (this.state.isLoading) return false;

        this.setState({ isLoading: true })

        const http = new Http();
        const res = await http.postAsyncPrimitive<boolean>("api/account/resetpassword", { resetCode: resetCode, Password: password });

        this.setState({ isLoading: false })

        if (http.error == null) {
            return res;
        }
        else {
            if (http.error.isHttpCustomError()) {
                const errMsg = Errors.fromCustomError(http.error.customErrorNr, http.error.customErrorData);
                this.setState({ errMsg: errMsg });
                return false;
            }

            ApplicationState.get().setError(http.error);
            return false;
        }
    }


    
    //================== State Mngt ====================//



    public handleStateError(error: ICustomError): void {
        if (error) {
            this.setState({  });
        }
    }

    async componentDidMount() {
        ApplicationState.get().onErrorChange.registerHandler(this.handleStateError);

        this.loadBaseDataAsync();

   }

    componentWillUnmount() {
        ApplicationState.get().onErrorChange.unregisterHandler(this.handleStateError);
    }
}