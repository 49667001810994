import { IAircraftType } from "./AircraftType";
import { IPowerOptions } from "./PowerOptions";
import { Member, IMember } from "./Member";
import { MyDateTime } from "./../HelperClasses/MyDate";
import { ISerializable } from './ISerializable';
import { IValidation, IValidationState } from './IValidation'
//import moment = require('moment');


export interface IAircraft extends ISerializable<IAircraft>, IValidation {
    id: string;
    registration: string;
    type: string;
    isClubOwned: boolean;
    isInUse: boolean;
    isSingleSeat: boolean;
    costPerMinute: number;
    insuranceCompany: string;
    insuranceContractId: string;
    insuranceValidUntil: MyDateTime;
    arcValidUntil: MyDateTime;


    aircraftType: IAircraftType;
    powerOptions: IPowerOptions;
    godfather: IMember;
}

export interface IAircraftValidationState extends IValidationState {       
    registrationIsValid: boolean;
    typeIsValid: boolean;

    powerOptionsIsValid: boolean;

    insuranceCompanyValid: boolean;
    insuranceContractIdValid: boolean;
    insuranceValidUntilValid: boolean;

    arcValidUntilIsValid: boolean ;

    aircraftTypeIsValid: boolean;
    costPerMinuteIsValid: boolean;
    godfatherIsValid: boolean;
}

export class Aircraft implements IAircraft {
    //key: string = null;

    public readonly id: string = null;
    public registration: string = null;
    public type: string = null;
    public isClubOwned: boolean = true;
    public isInUse: boolean = true;
    public isSingleSeat: boolean = true;
    public costPerMinute: number = 0;
    public insuranceCompany: string = null;
    public insuranceContractId: string = null;

    public insuranceValidUntil: MyDateTime = null;
    public arcValidUntil: MyDateTime = null;

    aircraftType: IAircraftType = null;
    powerOptions: IPowerOptions = null;
    godfather: IMember = null;

    constructor() {
    }

    public getValidationState(): IAircraftValidationState {
        //console.log("arc");
        //console.log(this.costPerMinute);

        let vs = {
            registrationIsValid: (this.registration != null && this.registration.length > 0),
            typeIsValid: (this.type != null && this.type.length > 0),

            powerOptionsIsValid: (this.powerOptions != null),

            insuranceCompanyValid: true,
            insuranceContractIdValid: true,
            insuranceValidUntilValid: this.isClubOwned ? (this.insuranceValidUntil != null && this.insuranceValidUntil.isValid) : true,
            arcValidUntilIsValid: this.isClubOwned ? (this.arcValidUntil != null && this.arcValidUntil.isValid) : true,

            aircraftTypeIsValid: !(this.isClubOwned && this.aircraftType == null),
            costPerMinuteIsValid: !(this.isClubOwned && (this.costPerMinute == null || this.costPerMinute == 0)),

            godfatherIsValid: true,

            stateIsValid: false
        } as IAircraftValidationState;

        vs.stateIsValid = (vs.registrationIsValid && vs.typeIsValid && vs.powerOptionsIsValid && vs.insuranceCompanyValid && vs.insuranceContractIdValid && vs.insuranceValidUntilValid && vs.arcValidUntilIsValid && vs.aircraftTypeIsValid && vs.costPerMinuteIsValid && vs.godfatherIsValid); 

        return vs;
    }


    public fromJson(json: Object): Aircraft {
        //DO NOT USE 'this.' RETURN A NEW OBJECT
        //console.log("Aircraft.fromJson");

        const trueObject = new Aircraft();
        Object.assign<IAircraft, Object>(trueObject, json);

        //trueObject.key = trueObject.id;

        //Overloads
        trueObject.arcValidUntil = MyDateTime.fromJson(trueObject.arcValidUntil);
        trueObject.insuranceValidUntil = MyDateTime.fromJson(trueObject.insuranceValidUntil);

        //Objects
        if (trueObject.godfather != null) trueObject.godfather = (new Member()).fromJson(trueObject.godfather);

        //console.log(trueObject);
        return trueObject;
    }


    public toJson(): Object {
        //console.log("Aircraft.toJson");

        let x = JSON.parse(JSON.stringify(this));

        //Overloads
        if (this.insuranceValidUntil) {
            x.insuranceValidUntil = this.insuranceValidUntil.toJson();
        }
        if (this.arcValidUntil) {
            x.arcValidUntil = this.arcValidUntil.toJson();
        }

        if (this.godfather) {
            x.godfather = this.godfather.toJson();
        }


        //console.log(x);
        return x;
    }
}