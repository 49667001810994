
export class Styles {

    public static getDivErrorStyle() {
        return (
            {
                borderColor: '#dc3545',
                borderStyle: 'solid',
                borderWidth: 'thin',
                borderRadius: '5px'
            }
        );
    }

    public static getNumericInputErrorStyle() {
        return (
            {
                wrap:
                {
                    borderColor: '#dc3545',
                    borderStyle: 'solid',
                    borderWidth: 'thin',
                    borderRadius: '5px'
                }
            }
        );
    }

    public static getErrorColor() {
        return '#DC3545'
    }
    public static getErrorColorStyle() {
        return { color: '#DC3545' }
    }
    public static getOKColorStyle() {
        return { color: "#6DB65B" };
    }
    public static getMeeuwColorStyle() {
        return { color: "#0a4874" };
    }
    public static getDarkBackColorStyle() {
        return { backgroundColor: "#343a40" };
    }
    public static getAlertColorStyle() {
        return { color: "#DC3545" };
    }

    public static getPrimaryColor() {
        return "#007bff";
    }

    public static getMeeuwColor() {
        return "#0a4874";
    }
    public static getDarkBackColorNr() {
        return '#343a40';
    }

    public static getWhatsAppColor() {
        return '#01e675';
    }

    public static getWordAppColor() {
        //24, 90, 189
        return '#185abd';
    }

    public static getExcelAppColor() {
        //16, 124, 65
        return '#107c41';
    }

    public static getPdfAppColor() {
        //250, 15, 0
        return '#fa1100';
    }

    public static getZipAppColor() {
        //249, 207, 58
        return '#f9cf3a';
    }


    
}