import { ICustomError, CustomError, HttpStatus } from "./CustomError";
import { ICustomEvent, CustomEvent} from "./CustomEvent";
import { ISessionInfo, SessionInfo } from "./SessionInfo";


export interface IApplicationState {

    error: ICustomError;
    onErrorChange: ICustomEvent<ICustomError>;
    setError(newError: ICustomError): void;
    resetError(): void;

    sessionInfo: ISessionInfo;
}

export class ApplicationState implements IApplicationState {

    private static _applicationState: ApplicationState;

    public readonly onErrorChange: ICustomEvent<ICustomError> = new CustomEvent<CustomError>();
    private _error: ICustomError;
    public get error(): ICustomError { return this._error; };

    private _sessionInfo: ISessionInfo;
    public get sessionInfo(): ISessionInfo { return this._sessionInfo; };

    constructor() {
        if (ApplicationState._applicationState != null) {
            throw new CustomError("Cannot create instance of ApplicationState. use ApplicationState.get() instead", HttpStatus.httpUnknown, null, null, null, null);
        }

        ApplicationState._applicationState = this;

        this._error = null;
        this._sessionInfo = new SessionInfo();
    }

    public static createOnce() : void{
        new ApplicationState(); //Create the state object once.
    }

    public static get(): ApplicationState{
        return ApplicationState._applicationState
    };

    // Error helpers
    public setError(newError: ICustomError): void {
        this._error = newError;
        this.onErrorChange.trigger(this._error);
    }

    public resetError(): void {
        this.setError(null);
    }

}

