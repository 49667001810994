import * as React from 'react';

import { Row, Col } from 'react-bootstrap';
import { Form } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Http } from "../HelperClasses/Http";
import { Formatters } from '../HelperClasses/Formatters';
//import { MyDateTime } from "../HelperClasses/MyDate";
//import { FormModus, ModalResult, ModalButtons } from "./../HelperClasses/Enums";
import { ApplicationState } from "../HelperClasses/ApplicationState";
//import { Styles } from "../HelperClasses/Styles";
//import { DotsSpinner } from "./../UIComponents/MySpinners";
import { SimpleModal } from "./../UIComponents/SimpleModal";
import { ModalButtons, ModalResult } from './../HelperClasses/Enums';

import { IMember } from "../DTO/Member"
import { IInstructorLog, InstructorLog } from '../DTO/InstructorLog';
import { MyDateTime } from '../HelperClasses/MyDate';



export interface IInstructorLogsProps {
    member: IMember;
}

export interface IInstructorLogsState {
    isLoading: boolean;
    showDeleteDlg: boolean;

    arrLogs: Array<IInstructorLog>;

    newLog: IInstructorLog;
    selectedLog: IInstructorLog;
    editingLog: IInstructorLog;
    toBeDeletedLog: IInstructorLog;
}

export class InstructorLogs extends React.Component<IInstructorLogsProps, IInstructorLogsState> {

    constructor(props: any) {
        super(props);

        this.state = {
            isLoading: false,
            showDeleteDlg: false,
            
            arrLogs: null,
            newLog: null,
            editingLog: null,
            toBeDeletedLog: null,
            selectedLog: null,
        };

        this.handleOnChangeLog = this.handleOnChangeLog.bind(this);

        this.handleSelectLog = this.handleSelectLog.bind(this);
        this.handleFocusNewLog = this.handleFocusNewLog.bind(this);
        this.handleEditExistingLog = this.handleEditExistingLog.bind(this);
        this.handleEscapeEditingLog = this.handleEscapeEditingLog.bind(this);
        this.handleSaveLog = this.handleSaveLog.bind(this);
        this.handleDeleteExistingLog = this.handleDeleteExistingLog.bind(this);

        this.handleCloseDelete = this.handleCloseDelete.bind(this);

    }

    render() {

        const JSX =
            <>
                {this.state.showDeleteDlg ? this.getDeleteDlgJSX() : null }
                {this.getInnerForm()}
            </>

        return JSX;
    }

    private getInnerForm() {

        if (this.state.isLoading) return <div>Loading....</div>
        if (!this.state.arrLogs) return null;

        const jsx =
            <div>
                <Row key={-1} className='my-2'>
                    {this.getLogJSX(this.state.newLog)}
                </Row>
                {this.getLogListJSX()}

            </div>
        return jsx;
    }

    private getLogListJSX() {

        const jsx =
            <>{

                this.state.arrLogs.map((log, index) => {
                    const isSelectedLog = log && this.state.selectedLog && (log.id === this.state.selectedLog.id);
                    return (
                        <Row key={index + 1} className='my-2 rounded-lg' style={isSelectedLog ? {backgroundColor: 'lightgray'} : null} >
                            {this.getLogJSX(log)}
                        </Row>
                    )
                }
                )
            }
        </>
        return jsx;
    }

    private getLogJSX(log: IInstructorLog) {
        if (!log) return null;

        const isNewLog : boolean = log && !log.id
        const isDeleted: boolean = log && log.dateDeleted && log.dateDeleted.isValid;
        const isInEditMode: boolean = log && this.state.editingLog && (this.state.editingLog.id === log.id);
        const isMyLog: boolean = log && (log.instructor.id === ApplicationState.get().sessionInfo.account.member.id);
        const isSelectedLog = log && this.state.selectedLog && (log.id === this.state.selectedLog.id);


        if (isInEditMode) {

            const jsx =
                <>
                    <Col xs={10} >
                        {this.getLogEditFieldJSX(this.state.editingLog, "lightYellow")}
                    </Col>
                    <Col xs={2} className='p-0' >
                        {this.getUndoSaveButtonsJSX(this.state.editingLog) }
                    </Col>
                </>

            return jsx;
        }
        else {
            const jsx = 
                <>
                    <Col xs={10}>
                        {this.getLogMsgFieldJSX(log, isDeleted, isMyLog)}
                    </Col>
                    <Col xs={2} className='p-0'>
                        {isMyLog && !isDeleted && !isNewLog && isSelectedLog ? this.getEditDeleteButtonsJSX(log) : null }
                    </Col>
                </>


            return jsx;
        }
    }

    private getEditDeleteButtonsJSX(log: IInstructorLog) {
        if (!this.state.editingLog) return null;

        const jsx =
        <>
            <span onClickCapture={(e) => { this.handleEditExistingLog(log) }}>
                <FontAwesomeIcon icon='pen' size='1x' color='black' />
            </span>
            &nbsp;&nbsp;&nbsp;
            <span onClickCapture={(e) => { this.handleDeleteExistingLog(log) }}>
                <FontAwesomeIcon icon='trash' size='1x' color='black' />
            </span>
        </>

        return jsx;
    }

    private getUndoSaveButtonsJSX(log: IInstructorLog) {
        const hasContent: boolean = log && log.msg && log.msg.length > 0;

        const jsx =
            <>
                <span onClickCapture={(e) => { this.handleEscapeEditingLog(log) }} >
                    <FontAwesomeIcon icon='undo' size='1x' color={hasContent ? 'black' : 'lightgrey'} />
                </span>
                &nbsp;&nbsp;&nbsp;
                <span onClickCapture={hasContent ? (e) => { this.handleSaveLog(log) } : null} >
                    <FontAwesomeIcon icon='save' size='1x' color={hasContent ? 'black' : 'lightgrey'} />
                </span>
            </>
        return jsx;
    }

    private getLogMsgFieldJSX(log: IInstructorLog, isDeleted: boolean, isMyLog: boolean) {

        const backColor: string = isMyLog ? 'lightYellow' : 'lightBlue';

        const nieuwBericht = '...';
        const verwijderdBericht = <><span>&nbsp;</span><span style={{ fontSize: 'small', fontStyle: 'italic', color: 'grey' }}>bericht is verwijderd</span></>;

        const jsx =
            <div    key={log.id}
                    className='rounded-lg pl-2 text-break word-wrap'                   
                    style={{ backgroundColor: backColor }}
                    onClickCapture={() => { this.handleSelectLog(log) }}
                >

                {!log.id ? nieuwBericht : (isDeleted ? verwijderdBericht : this.toHTML(log.msg))}
                
                <div className='p-1'
                    style={{ textAlign: 'right', fontSize: 'small', fontStyle: 'italic', color: 'grey' }}>{this.getDateStampJSX(log)}
                </div>
            </div>


        return jsx;
    }

    private getLogEditFieldJSX(log: IInstructorLog, color: string) {

        let nrRows = 1;
        if (log && log.msg) {
            nrRows = log.msg.split('\n').length;
        }

        const jsx =
        <Form.Control as="textarea" type='text' rows={nrRows} placeholder="..." style={{ whiteSpace: 'pre-line' }}
            value={log && log.msg ? log.msg : ''}
            ref="refFirstFocus"
            onChangeCapture={(e: React.ChangeEvent<HTMLInputElement>) => { this.handleOnChangeLog(e, log) }}
            onChange={() => { }}
            onFocus={() => {this.handleFocusNewLog(log) } }
            isInvalid={false}
            //disabled={this.props.formMode != FormModus.New}
            tabIndex={99}
        />

        return jsx;
    }

    private getDateStampJSX(log: IInstructorLog) {
        if (!log) return null;
        if (!log.dateCreated) return null;

        let status: string = null;
        let date = log.dateCreated;

        if (log.dateModified && log.dateModified.isValid && log.dateModified.isAfter(log.dateCreated)) {
            date = log.dateModified;
            status = ' bewerkt ';
        }
        if (log.dateDeleted && log.dateDeleted.isValid && log.dateDeleted.isAfter(log.dateModified)) {
            date = log.dateDeleted;
            status = ' verwijderd ';
        }

        const jsx = <>
            {log.instructor ? log.instructor.firstName : ''}
            ,&nbsp;{status}
            {date.isSameDate(MyDateTime.today) ?
                Formatters.getShortTimeString(date) :
                Formatters.getMediumDateString(date)
            }
        </>
        return jsx;  
    }

    private getDeleteDlgJSX() {
        const jsx =

        <SimpleModal
            headingText='Log verwijderen'
            bodyText='Bent u zeker dit u deze log wilt verwijderen?'
            buttons={ModalButtons.OKCancel}
                onClose={this.handleCloseDelete} />

        return jsx;
    }



    //======================== Logic handlers =============================//

    private getNewLog() : IInstructorLog {
        const newLog = new InstructorLog();
        newLog.member = this.props.member;
        newLog.instructor = ApplicationState.get().sessionInfo.account.member;

        return newLog;
    }

    private updateLogArray(log: IInstructorLog, updatedLog : IInstructorLog) {
        //Add the updated to the array

        const index = this.state.arrLogs.findIndex(x => x.id === log.id);
        if (index < 0) {
            this.state.arrLogs.push(updatedLog)
        }
        else {
            this.state.arrLogs[index] = updatedLog;
        }
        this.state.arrLogs.sort((a, b) => {return -MyDateTime.compare(a.dateCreated,b.dateCreated,false) })
    }

    private toHTML(s: string) {
        if (!s) return null;

        return s.split('\n').map((obj, index) => {
            const jsx =
                <span key={index}>
                    {obj}
                    <br />
                </span>
            return jsx;
        });
    }

    //======================== Get Handlers =============================//

    private handleSelectLog(log: IInstructorLog) {
        const isNewLog: boolean = log && !log.id
        const isDeleted: boolean = log && log.dateDeleted && log.dateDeleted.isValid;
        //const isInEditMode: boolean = log && this.state.isEditingLog && (this.state.isEditingLog.id === log.id);
        const isMyLog: boolean = log && (log.instructor.id === ApplicationState.get().sessionInfo.account.member.id);

        const newSelectedLog: boolean = !isNewLog && !isDeleted && isMyLog;
        this.setState({ selectedLog: newSelectedLog ? log : null });     
    }

    private handleFocusNewLog(log: IInstructorLog) {
        this.setState({ selectedLog: null });     
    }

    private handleOnChangeLog(event: React.ChangeEvent<HTMLInputElement>, log: IInstructorLog) {
        const stringValue = event.currentTarget.value;
        this.state.editingLog.msg = stringValue;
        this.setState({ selectedLog: log, editingLog: this.state.editingLog });     
    }

    private handleEscapeEditingLog(log: IInstructorLog) {
        const newLog = this.getNewLog();
        this.setState({ newLog: newLog, editingLog: newLog, selectedLog: null});
    }

    private async handleSaveLog(log: IInstructorLog) {
        
        const updatedLog = await this.upsertLog(log);

        //Add the updated to the array
        this.updateLogArray(log, updatedLog);

        const newLog = this.getNewLog();
        this.setState({ newLog: newLog, editingLog: newLog, selectedLog: null });
    }

    private handleEditExistingLog(log: IInstructorLog) {
        this.setState({ editingLog: log.clone() });
    }

    private handleDeleteExistingLog(log: IInstructorLog) {
        this.setState({ toBeDeletedLog: log, showDeleteDlg: true });
    }

    private async handleCloseDelete(res : ModalResult) {
        if (res === ModalResult.OK && this.state.toBeDeletedLog) {

            const deletedLog = await this.deleteLog(this.state.toBeDeletedLog);
            this.updateLogArray(this.state.toBeDeletedLog, deletedLog);

            const newLog = this.getNewLog();
            this.setState({ newLog: newLog, editingLog: newLog,selectedLog: null, toBeDeletedLog: null, showDeleteDlg: false });

            //this.loadLogs(this.props.member);
        }
        else {
            this.setState({ selectedLog: null, toBeDeletedLog:null, showDeleteDlg: false })
        }
    }


    //======================== Get Form Handlers =============================//


    //======================== Get Validation =============================//

    //======================== Data Layer =============================//
    private async loadBaseTables() {
        if (this.props.member) {
            this.initializeForMember(this.props.member);
        }
    }

    private async loadLogs(member: IMember): Promise<Array<IInstructorLog>> {
            const http = new Http();
            this.setState({ isLoading: true });

            let res = await http.getAsyncObject<IInstructorLog>("api/instructorlog/member/" + this.props.member.id, InstructorLog) as Array<IInstructorLog>;

            if (http.error == null) {
                this.setState({ isLoading: false, arrLogs : res });
                return res;
            }
            else {
                ApplicationState.get().setError(http.error);
                this.setState({ isLoading: false, arrLogs: null });
                return null;
            }
    }

    private async upsertLog(log: IInstructorLog): Promise<IInstructorLog> {

        const http = new Http();
        this.setState({ isLoading: true });

        let res = await http.postAsyncObject<IInstructorLog>("api/instructorlog/", log, InstructorLog);

        if (http.error == null) {
            this.setState({ isLoading: false});
            return res;
        }
        else {
            ApplicationState.get().setError(http.error);
            this.setState({ isLoading: false });
            return null;
        }
    }

    private async deleteLog(log: IInstructorLog) : Promise<IInstructorLog> {
        
        const http = new Http();

        this.setState({ isLoading: true });
        let res = await http.deleteAsyncObject<IInstructorLog>("api/instructorlog/" + log.id, InstructorLog);
        this.setState({ isLoading: false });

        if (http.error == null) {
            return res;
        }
        else {
            ApplicationState.get().setError(http.error);
            return null;
        }
    }

    //======================== Lifecycle =============================//
    async componentDidMount() {
        //console.log("mounting InstructorsLog");
        this.loadBaseTables();

    }

    componentDidUpdate(prevProps: IInstructorLogsProps) {

        if (this.props.member && !prevProps.member
            ||
            (this.props.member && prevProps.member && prevProps.member.id != this.props.member.id)
        )
        {
            this.initializeForMember(this.props.member);
        }

    }

    private initializeForMember(member: IMember) {
        this.loadLogs(member);

        const newLog = this.getNewLog();
        this.setState({ newLog: newLog, editingLog: newLog });

    }
}