import * as React from 'react';
import { RouteComponentProps, withRouter } from "react-router-dom";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { Nav } from 'react-bootstrap';
import { Dropdown } from 'react-bootstrap'

import { ApplicationState } from "./../HelperClasses/ApplicationState";
import { Routing, Sections, Modules } from "./../HelperClasses/Routing";
import {ProfilePicture} from './../UIComponents/ProfilePicture'
//import { ISessionInfo } from "./HelperClasses/SessionInfo";
import { IAccount } from "./../DTO/Account";


export interface IProfileMenuProps extends RouteComponentProps{

    account: IAccount;
}

export interface IProfileMenuState {
    
}



export class ProfileMenu extends React.Component<IProfileMenuProps, IProfileMenuState> {

    constructor(props: IProfileMenuProps) {
        super(props);

        

        this.state = {

        };

        //this.handleSessionChange = this.handleSessionChange.bind(this);
        this.handleAccount = this.handleAccount.bind(this);
        this.handleSettings = this.handleSettings.bind(this);
        this.handleManageAccounts = this.handleManageAccounts.bind(this);
        this.handleTools = this.handleTools.bind(this);
        this.handleLogOff = this.handleLogOff.bind(this);
        
    }



    render() {

         const menuJsx =
            <Dropdown>
                 
                 <Dropdown.Toggle variant="dark" id="dropdown-basic" className=''>
                     <ProfilePicture size='sm' srcURL={this.props.account.profilePictureUrl} alt={this.props.account ? this.props.account.firstName + " " + this.props.account.surName : ""} fluid />
                     &nbsp;&nbsp;
                     {this.props.account ? this.props.account.firstName : null}
                 </Dropdown.Toggle>

                 <Dropdown.Menu className="dropdown-menu-right " >

                     <Dropdown.Item active={false} href={"#" + Routing.getPath(Sections.myEsoar, Modules.myAccount)} className="" onClick={this.handleAccount}>
                         <span><FontAwesomeIcon icon="key" />&nbsp;&nbsp;{Routing.getModuleName(Modules.myAccount) }</span>
                     </Dropdown.Item>

                     <Dropdown.Item active={false} href={"#" + Routing.getPath(Sections.myEsoar, Modules.mySettings)} className="" onClick={this.handleSettings}>
                         <span><FontAwesomeIcon icon="cog" />&nbsp;&nbsp;{Routing.getModuleName(Modules.mySettings)}</span>
                     </Dropdown.Item>

                     <Dropdown.Divider />
                     <Dropdown.Item active={false} href={Routing.getPath(Sections.home, Modules.login) }  className="" onClick={this.handleLogOff}><span><FontAwesomeIcon icon="sign-out-alt" />&nbsp;&nbsp;Afmelden</span></Dropdown.Item>

                 </Dropdown.Menu>


            </Dropdown>;

        let signInJsx =
            <Nav.Link href="#login" ><span><FontAwesomeIcon icon="sign-in-alt" inverse />&nbsp;&nbsp;Aanmelden</span>
            </Nav.Link>

        return (

            <>
                {this.props.account ? menuJsx : signInJsx }
            </>
  

            )
    }


    //handleSessionChange(sessionInfo: ISessionInfo) {
    //    console.log("never used")
    //    this.setState( {entity : sessionInfo.account } )
    //}

    handleAccount() {
    }


    handleSettings() {
    }

    handleManageAccounts(){
    }

    handleTools() {
    }



    private async handleLogOff() {
         await ApplicationState.get().sessionInfo.logOff();
    }

    componentDidMount() {
        //ApplicationState.get().sessionInfo.onChange.registerHandler(this.handleSessionChange);
    }

    componentWillUnmount() {
        //ApplicationState.get().sessionInfo.onChange.unregisterHandler(this.handleSessionChange);
    }

    shouldComponentUpdate(nextProps: any, nextState: any) {
        //console.log('profilemenu shouldUpdate')
        //console.log(nextProps)
        //console.log(nextState)
        return true;
    }
}

export default withRouter(ProfileMenu)