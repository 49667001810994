import * as React from 'react';

import { Col, Card } from 'react-bootstrap'

import LoadingOverlay from 'react-loading-overlay';
import { PulseLoader } from 'react-spinners';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { Sections } from "./../HelperClasses/Routing";
import { Routing } from "./../HelperClasses/Routing";
//import { Styles } from "./../HelperClasses/Styles";
//import { LoadingSpinner } from '../UIComponents/MySpinners';
import { TrafficLightIcon } from './TrafficLightIcon';
import { EventIcon} from './MyIcons'


export interface ICardNavigatorProps {
    disabled?: boolean;
    section: Sections;
    module: string;
    headerText: string;
    iconName: any;
    iconColor?: string;
    footerText?: string | JSX.Element;

    isLoading?: boolean;
}

export interface ICardNavigatorState {

}




export class CardNavigator extends React.Component<ICardNavigatorProps, ICardNavigatorState> {

    constructor(props: ICardNavigatorProps) {
        super(props);
        this.state = {};

        this.handleCardClick = this.handleCardClick.bind(this);
    }

    render() {
        //<LoadingSpinner active={this.props.isLoading ? this.props.isLoading : false}>

        const spinner = <PulseLoader size={15} color='#FFFFFF' />

        const jsx =
            <>
                
                <LoadingOverlay
                    active={this.props.isLoading ? this.props.isLoading : false}
                    spinner={spinner}
                    styles={{
                        overlay: (base: any) => ({
                            ...base,
                            background: 'rgba(10,72, 116, 0.70)'
                        })
                    }}
                >
                    { this.getMenuCardJSX(this.props.module, this.props.headerText, this.props.iconName)}
                </LoadingOverlay>
                
            </>

        return jsx
    }

    private getMenuCardJSX(module: string, headerText: string, iconName: any) {

        const jsx =
            <Card
                className="mx-auto"
                style={this.props.disabled ? { width: '9rem', height: '9rem', cursor: 'not-allowed' } : { width: '9rem', height: '9rem', cursor: 'pointer' }}
                border="primary"

                onClickCapture={() => { this.handleCardClick(module) }}
            >
                <Card.Header as="h6" style={{}} className='px-2 text-center' >{headerText}</Card.Header>
                <Card.Body className='px-0'>
                    <Col className='mx-auto text-center'>
                        
                        { this.getIconJSX()}
                        { this.getFooterJSX()}                        
                    </Col>
                    
                </Card.Body>
                
            </Card>
        return jsx;
    }

    private getIconJSX() {

        if (this.props.iconName == 'eventIcon') {
            if (this.props.disabled) {
                return <EventIcon size={3} color={'lightGray'} />
            }
            else {
                return <EventIcon size={3} color={this.props.iconColor ? this.props.iconColor : '#343a40'} />
            }
        }


        if (this.props.iconName == 'TrafficLightIcon') {
            if (this.props.disabled) {
                return <TrafficLightIcon color={'none'} size={3} disabled={true } />
            }
            else {
                return <TrafficLightIcon color={this.props.iconColor as 'red' | 'green' | 'orange' | 'all' | 'none'} size={3} />
            }
        }

        if (this.props.disabled) {
            return <FontAwesomeIcon icon={this.props.iconName} color={'lightgray'} size='3x' />
        }
        else {
            return <FontAwesomeIcon icon={this.props.iconName} color={this.props.iconColor ? this.props.iconColor : '#343a40'} size='3x' />
        }
    }

    private getFooterJSX() {

        if (!this.props.footerText) return '';


        if (typeof this.props.footerText === 'string') { //can detect string but not JSX.Element
            if (this.props.disabled) {
                return <div style={{ fontSize: 18, fontWeight: 'bold', color: 'gray' }} >{this.props.footerText}</div>;
            }
            else {
                return <div style={{ fontSize: 18, fontWeight: 'bold' }} >{this.props.footerText}</div>;
            }
        }

        return this.props.footerText;


    }

    //======================== Get Form Handlers =============================//
    private handleCardClick(module: any) {
        if (this.props.disabled) return;

        Routing.navigateTo(this.props.section, module);
    }

}