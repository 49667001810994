import * as React from 'react';

import { Tooltip, OverlayTrigger } from 'react-bootstrap'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
//import { Styles } from "./../HelperClasses/Styles"; 

export interface IInfoOverlayProps {
    iconName?: any;
    tooltip: string | JSX.Element
}

export interface IInfoOverlayState {

}

export class InfoOverlay extends React.Component<IInfoOverlayProps, IInfoOverlayState> {

    constructor(props: any) {
        super(props);

        this.getTT = this.getTT.bind(this);
    }

    render() {
        const jsx=
        <>
            {this.getOverlayJSX()}
            </>

        return jsx;
    }

    private getOverlayJSX() {

        const jsx =
            <OverlayTrigger
            overlay={this.getTT}
            trigger={['hover', 'click']}
            placement='auto'
            delay={{ show: 250, hide: 250 }}
            defaultShow={false}
            >
                <FontAwesomeIcon icon={this.props.iconName ? this.props.iconName : 'info-circle'} color='#343a40'  />
            </OverlayTrigger>

        return jsx;
    }

    private getTT(props: any) {
        const correctedProps = { ...props };
        correctedProps.show = (correctedProps.show ? 'true' : 'false'); //bug van react.
        const jsx =

            <Tooltip id="button-tooltip" {...correctedProps}>
                { this.props.tooltip }
            </Tooltip>

        return jsx;
    }
}