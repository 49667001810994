

import { MyDateTime } from "./../HelperClasses/MyDate";
import { ISerializable } from './ISerializable';
import { IValidation, IValidationState } from './IValidation'

//import moment = require('moment');


export interface IEasaStatus extends ISerializable<IEasaStatus>, IValidation {


    memberId: string;

    flightMinutes24Months: number;

    nrStarts24Months: number;
    nrStartsForPassengers90Days: number;
    nrStartsTrainingFlights24Months: number;

    endDate5HoursPIC: MyDateTime;
    endDate15Starts: MyDateTime;
    endDate3StartForPassengers: MyDateTime;
    endDateStartTrainingFlights: MyDateTime;
    medicalExpires: MyDateTime;
    firstEndDate: MyDateTime;

    hasLicenseSpl: boolean;
    mayFlyPassenger: boolean;

    nrLogEntries: number;

    getFlightColor(): 'red' | 'orange' | 'green';
}

export interface IEasaStatusValidationState extends IValidationState {       
 
}

export class EasaStatus implements IEasaStatus {

    memberId: string;

    flightMinutes24Months: number;

    nrStarts24Months: number;
    nrStartsForPassengers90Days: number;
    nrStartsTrainingFlights24Months: number;

    endDate5HoursPIC: MyDateTime;
    endDate15Starts: MyDateTime;
    endDate3StartForPassengers: MyDateTime;
    endDateStartTrainingFlights: MyDateTime;
    medicalExpires: MyDateTime;
    firstEndDate: MyDateTime;

    hasLicenseSpl: boolean;
    mayFlyPassenger: boolean;

    nrLogEntries: number;

    getFlightColor(): 'red' | 'orange' | 'green' {

        if (!this.hasLicenseSpl) {
            if (!this.medicalExpires) return 'orange';
            if (!this.medicalExpires.isValid) return 'orange';
            if (this.medicalExpires.isBeforeOrOnDate(MyDateTime.today)) return 'orange';
            if (this.medicalExpires.isBeforeOrOnDate(MyDateTime.today.addDays(30))) return 'orange';
            return 'green';
        }

        if (!this.medicalExpires) return 'red';
        if (!this.medicalExpires.isValid) return 'red';
        if (this.medicalExpires.isBeforeOrOnDate(MyDateTime.today)) return 'red';

        if (!this.endDate15Starts || !this.endDate15Starts.isValid) return 'red';
        if (!this.endDate5HoursPIC || !this.endDate5HoursPIC.isValid) return 'red';
        if (!this.endDateStartTrainingFlights || !this.endDateStartTrainingFlights.isValid) return 'red';

        if (this.endDate15Starts.isBeforeOrOnDate(MyDateTime.today)) return 'red';
        if (this.endDate5HoursPIC.isBeforeOrOnDate(MyDateTime.today)) return 'red';
        if (this.endDateStartTrainingFlights.isBeforeOrOnDate(MyDateTime.today)) return 'red';

        if (this.medicalExpires.isBeforeOrOnDate(MyDateTime.today.addDays(30))) return 'orange';
        if (this.endDate15Starts.isBeforeOrOnDate(MyDateTime.today.addDays(30))) return 'orange';
        if (this.endDate5HoursPIC.isBeforeOrOnDate(MyDateTime.today.addDays(30))) return 'orange';
        if (this.endDateStartTrainingFlights.isBeforeOrOnDate(MyDateTime.today.addDays(30))) return 'orange';

        if (this.mayFlyPassenger && this.endDate3StartForPassengers.isBeforeOrOnDate(MyDateTime.today)) return 'orange';
        if (this.mayFlyPassenger && this.endDate3StartForPassengers.isBeforeOrOnDate(MyDateTime.today.addDays(30))) return 'orange';

        return 'green';
    }

    constructor() {
    }

    public getValidationState(): IEasaStatusValidationState {

        let vs = {

        } as IEasaStatusValidationState;

        vs.stateIsValid = true

        return vs;
    }

    public fromJson(json: Object): EasaStatus {
        //DO NOT USE 'this.' RETURN A NEW OBJECT
        //console.log("Aircraft.fromJson");

        const trueObject = new EasaStatus();
        Object.assign<IEasaStatus, Object>(trueObject, json);

        //Overloads
        trueObject.endDate5HoursPIC = MyDateTime.fromJson(trueObject.endDate5HoursPIC);
        trueObject.endDate15Starts = MyDateTime.fromJson(trueObject.endDate15Starts);
        trueObject.endDate3StartForPassengers = MyDateTime.fromJson(trueObject.endDate3StartForPassengers);
        trueObject.endDateStartTrainingFlights = MyDateTime.fromJson(trueObject.endDateStartTrainingFlights);
        trueObject.medicalExpires = MyDateTime.fromJson(trueObject.medicalExpires);
        trueObject.firstEndDate = MyDateTime.fromJson(trueObject.firstEndDate);


        return trueObject;
    }


    public toJson(): Object {
        //console.log("CostsBalance.toJson");

        let x = JSON.parse(JSON.stringify(this));

        //Overloads
        if (this.endDate5HoursPIC) x.endDate5HoursPIC = this.endDate5HoursPIC.toJson();
        if (this.endDate15Starts) x.endDate15Starts = this.endDate15Starts.toJson();
        if (this.endDate3StartForPassengers) x.endDate3StartForPassengers = this.endDate3StartForPassengers.toJson();
        if (this.endDateStartTrainingFlights) x.endDateStartTrainingFlights = this.endDateStartTrainingFlights.toJson();
        if (this.medicalExpires) x.medicalExpires = this.medicalExpires.toJson();
        if (this.firstEndDate) x.firstEndDate = this.firstEndDate.toJson();
        
        //console.log(x);
        return x;
    }

}