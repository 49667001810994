
//import { FlightDay, IFlightDay } from "./FlightDay";
import { Member, IMember } from "./Member";
import { MyDateTime } from "./../HelperClasses/MyDate";
import { ISerializable } from './ISerializable';
import { IValidation, IValidationState } from './IValidation'
//import moment = require('moment');


export interface IDuty extends ISerializable<IDuty>, IValidation {
    id: string;
    day: MyDateTime;

    member: IMember;
    replacementMember: IMember;

    dutyType: string;
    am: boolean;
    pm: boolean;
}

export interface IDutyValidationState extends IValidationState {

    dayIsValid: boolean;
    memberIsValid: boolean
}

export class Duty implements IDuty {

    public readonly id: string = null;
    public day: MyDateTime = null;

    public member: IMember;
    public replacementMember: IMember;

    public dutyType: string;
    public am: boolean;
    public pm: boolean;

    public static readonly DutyTypeTowPilot: string = 'TOW_PILOT';
    public static readonly DutyTypeInstructor: string = 'INSTRUCTOR';

    constructor() {
    }

    public getValidationState(): IDutyValidationState {

        let vs = {
            dayIsValid: (this.day != null && this.day.isValid),
            memberIsValid: (this.member != null || this.member != undefined),
            stateIsValid: false
        } as IDutyValidationState;

        vs.stateIsValid = (
            vs.dayIsValid && vs.memberIsValid );

        return vs;
    }

    public fromJson(json: Object): Duty {
        //DO NOT USE 'this.' RETURN A NEW OBJECT
        //console.log("Aircraft.fromJson");

        const trueObject = new Duty();
        Object.assign<IDuty, Object>(trueObject, json);

        //trueObject.key = trueObject.id;

        //Overloads
        trueObject.day = MyDateTime.fromJson(trueObject.day);

        //Objects
        if (trueObject.member != null) trueObject.member = (new Member()).fromJson(trueObject.member);
        if (trueObject.replacementMember != null) trueObject.replacementMember = (new Member()).fromJson(trueObject.replacementMember);

        //console.log(trueObject);
        return trueObject;
    }


    public toJson(): Object {
        //console.log("MemberFlightDay.toJson");

        let x = JSON.parse(JSON.stringify(this));

        //Overloads
        if (this.day) {
            x.day = this.day.toJson();
        }

        if (this.member) {
            x.member = this.member.toJson();
        }

        if (this.replacementMember) {
            x.replacementMember = this.replacementMember.toJson();
        }
        //console.log(x);
        return x;
    }
}