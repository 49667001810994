
//import { MyDateTime } from "./../HelperClasses/MyDate";
import { ISerializable } from './ISerializable';
import { IValidation, IValidationState } from './IValidation'
//import moment = require('moment');

import { IEventRole } from './EventRole'



export interface IReservableCost extends ISerializable<IReservableCost>, IValidation {
    
    id: string;
    reservableId: string;

    eventRole: IEventRole;

    amount: number;

    clone() : IReservableCost;

}

export interface IReservableCostValidationState extends IValidationState {
    
}

export class ReservableCost implements IReservableCost {

    public id: string = null;
    public reservableId: string = null;

    public eventRole: IEventRole = null;

    public amount: number = 0;

    constructor() {
    }


    public getValidationState(): IReservableCostValidationState {

        let vs = {

            stateIsValid: false
        } as IReservableCostValidationState;

        vs.stateIsValid = (true);

        return vs;
    }



    public fromJson(json: Object): ReservableCost {
        //DO NOT USE 'this.' RETURN A NEW OBJECT
        //console.log("Aircraft.fromJson");

        const trueObject = new ReservableCost();
        Object.assign<IReservableCost, Object>(trueObject, json);

        //trueObject.key = trueObject.id;

        //Overloads
 
        //Objects

        //console.log(trueObject);
        return trueObject;
    }


    public toJson(): Object {
        //console.log("Aircraft.toJson");

        let x = JSON.parse(JSON.stringify(this));

        //Overloads

        
        //Objects

        //console.log(x);
        return x;
    }

    public clone(): IReservableCost {
        const reservableCost = Object.assign<ReservableCost, IReservableCost>(new ReservableCost(), this);

        return reservableCost;
    }
}