import { Styles } from "./../HelperClasses/Styles";

export enum Filetypes { 'Word', 'Excel', 'Pdf', 'Zip' };

export class Filetype {

    public static readonly WordMimetype = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
    public static readonly ExcelMimetype = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    public static readonly PdfMimetype = 'application/pdf';
    public static readonly ZipMimetype = 'application/x-zip-compressed';

    public static getFiletypeIcon(filetype: Filetypes) {
        switch (filetype) {

            case Filetypes.Word: return 'file-word';
            case Filetypes.Excel: return 'file-excel';
            case Filetypes.Pdf: return 'file-pdf';
            case Filetypes.Zip: return 'file-archive';

            default: return null;
        }
    }

    public static getFiletypeColor(filetype: Filetypes) {
        switch (filetype) {

            case Filetypes.Word: return Styles.getWordAppColor();
            case Filetypes.Excel: return Styles.getExcelAppColor();
            case Filetypes.Pdf: return Styles.getPdfAppColor();
            case Filetypes.Zip: return Styles.getZipAppColor();

            default: return null;
        }
    }

    public static getFiletypeFromMime(mimeType: string) : Filetypes {
        switch (mimeType) {
            case this.WordMimetype: return Filetypes.Word;
            case this.ExcelMimetype: return Filetypes.Excel;
            case this.PdfMimetype: return Filetypes.Pdf;
            case this.ZipMimetype: return Filetypes.Zip;
            default: return null;

        }
    }

    public static getMimetypeFromFiletype(fileType: Filetypes): string {
        switch (fileType) {
            case Filetypes.Word: return this.WordMimetype;
            case Filetypes.Excel: return this.ExcelMimetype;
            case Filetypes.Pdf: return this.PdfMimetype;
            case Filetypes.Zip: return this.ZipMimetype;
            default: return null;

        }
    }
}