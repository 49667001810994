import * as React from 'react';

import { ApplicationState } from "./../HelperClasses/ApplicationState";
import { CustomError, HttpStatus } from "./../HelperClasses/CustomError";

interface IBoundaryErrorState {
    error: Error;
}

interface IBoundaryErrorProps {

}



class ErrorBoundary extends React.Component<IBoundaryErrorProps, IBoundaryErrorState> //this class will catch all exceptions from component creation and rendering EXCEPT exceptions in handlers
{
    constructor(props: any) {
        super(props);
        this.state = { error: null };
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        console.log("ErrorBoundary was raised");

        this.setState({ error: error });
        ApplicationState.get().setError(new CustomError(error && error.toString(), HttpStatus.httpUnknown, null, errorInfo && errorInfo.componentStack, null, null));
    }

    render() {
        if (this.state.error) {
            return null;
        }
        else {
            return this.props.children;
        }
    }

}

export default ErrorBoundary;