import { Moment } from 'moment';

let moment = require('moment');


export interface IMyDateTime {

    moment: Moment;
    isValid: boolean;

    isBefore(aDate: MyDateTime): boolean;
    isAfter(aDate: MyDateTime): boolean;

    isSameDate(aDate: MyDateTime): boolean;
    isBeforeDate(aDate: MyDateTime): boolean;
    isBeforeOrOnDate(aDate: MyDateTime): boolean;
    isAfterDate(aDate: MyDateTime): boolean;
    isAfterOrOnDate(aDate: MyDateTime): boolean;
    isInPeriod(startDate: MyDateTime, endDate: MyDateTime): boolean;

    addDays(number: number): MyDateTime;
    addYears(number: number): MyDateTime;

    date: MyDateTime;
    time: MyDateTime;

    year: number;
    month: number;
    day: number;
    hour: number;
    minute: number;
    second: number;

    toJson(): Object;
    toB64(): string;

    clone(): MyDateTime;
}



export class MyDateTime implements IMyDateTime {

    private _moment: Moment = null;
    public get moment(): Moment { return (this._moment.isValid() ? this._moment : null) as Moment; }

    public get isValid(): boolean { return this._moment.isValid(); }

    constructor(aDate: any) {
        //console.log("constructor MyDate")
        
        //console.log(aDate === "Invalid date")

        if (!aDate || aDate === "Invalid date") {
            this._moment = moment(null);
        }
        else if (aDate instanceof MyDateTime) {
            this._moment = (aDate as MyDateTime)._moment.clone();
        }
        else if (aDate instanceof moment) {
            this._moment = aDate.clone();
        }
        else {
            this._moment = moment(aDate).clone();
        }
        
    }



    //Date helpers
    public isBefore(aDate: MyDateTime): boolean {
        const res = MyDateTime.compare(this, aDate, false);
        if (res == 1) return false
        if (res == 0) return false;

        return true;
    }
    public isAfter(aDate: MyDateTime): boolean {
        const res = MyDateTime.compare(this, aDate, false);
        if (res == -1) return false;
        if (res == 0) return false;

        return true;
    }
    public isSameDate(aDate: MyDateTime): boolean {
        const res = MyDateTime.compare(this, aDate, true);
        if (res == 0) return true;

        return false;
    }
    public isBeforeDate(aDate: MyDateTime): boolean {
        const res = MyDateTime.compare(this, aDate, true);
        if (res == 1) return false
        if (res == 0) return false;

        return true;
    }
    public isBeforeOrOnDate(aDate: MyDateTime): boolean {
        const res = MyDateTime.compare(this, aDate, true);
        if (res == 1) return false
        if (res == 0) return false;

        return true;
    }
    public isAfterDate(aDate: MyDateTime): boolean {
        const res = MyDateTime.compare(this, aDate, true);
        if (res == -1) return false;
        if (res == 0) return false;

        return true;
    }
    public isAfterOrOnDate(aDate: MyDateTime): boolean {
        const res = MyDateTime.compare(this, aDate, true);
        if (res == -1) return false;
        if (res == 0) return true;

        return true;
    }

    public isInPeriod(startDate: MyDateTime, endDate: MyDateTime): boolean {
        return (this.isAfterOrOnDate(startDate) && this.isBeforeOrOnDate(endDate));
    }

    public addDays(number: number): MyDateTime {
        const newDate = this.clone();
        newDate.moment.add(number, 'days'); // https://momentjs.com/docs/#/manipulating/add/
        return newDate;
    }

    public addYears(number: number): MyDateTime {
        const newDate = this.clone();
        newDate.moment.add(number, 'years'); // https://momentjs.com/docs/#/manipulating/add/
        return newDate;
    }

    public static getTimeSpanBetween(startDate: MyDateTime, endDate: MyDateTime): IMyTimeSpan {
        if (!startDate || !endDate) return { isValid: false } as IMyTimeSpan;
        if (!startDate.isValid || !endDate.isValid) return { isValid: false } as IMyTimeSpan;

        const duration = moment.duration(endDate.moment.diff(startDate.moment));

        let days: number = duration.asDays();
        let hours: number = duration.asHours();
        let minutes: number = duration.asMinutes();
        let seconds: number = duration.asSeconds();

        days = Math.floor(days);
        hours = Math.floor(hours);
        minutes = Math.floor(minutes);
        seconds = Math.floor(seconds);

        hours = hours % 24;
        minutes = minutes % 60;
        seconds = seconds % 60;

        const ts = new MyTimeSpan(days, hours, minutes, seconds);
        return ts;
    }

    public static createDate(year: number, month: number, day: number) {
        let x = new MyDateTime(moment([year, month - 1, day]))
        if (!x.isValid) return null; //throw new Error("Invalid time creation HH/mm/ss: " + hour + "/" + minutes + "/" + seconds)
        return x;
    }
    public static createTime(hour: number, minutes: number, seconds: number) {
        let x = new MyDateTime(moment([0, 0, 0, hour, minutes, seconds]))
        //if (!x.isValid) return null; //throw new Error("Invalid time creation HH/mm/ss: " + hour + "/" + minutes + "/" + seconds)
        if (!x.isValid) throw new Error("Invalid time creation HH/mm/ss: " + hour + "/" + minutes + "/" + seconds)
        return x;
    }
    public static createDateTime(year: number, month: number, day: number, hour: number, minutes: number, seconds: number) {
        let x = new MyDateTime(moment([year, month - 1, day, hour, minutes, seconds]))
        if (!x.isValid) return null; //throw new Error("Invalid time creation HH/mm/ss: " + hour + "/" + minutes + "/" + seconds)
        return x;
    }
    public static get invalidDateTime() {
        return new MyDateTime(moment([-1, - 1, -1]));
    }

    public static compare(a: MyDateTime, b: MyDateTime, compareDateOnly: boolean): number {
        if ((!a || !a.isValid) && (!b || !b.isValid)) return 0; //both invalid
        if (!a || !a.isValid) return -1;
        if (!b || !b.isValid) return 1;

        //if (!a) throw new Error("Invalid date compare");
        //if (!b) throw new Error("Invalid date compare");
        //if (!a.isValid) throw new Error("Invalid date compare");
        //if (!b.isValid) throw new Error("Invalid date compare");

        //Only work on copies as to not overwrite the originals
        let acopy = new MyDateTime(a);
        let bcopy = new MyDateTime(b);

        if (compareDateOnly == true) {
            acopy = MyDateTime.datePart(a);
            bcopy = MyDateTime.datePart(b);
        }

        if (acopy.moment == bcopy.moment) return 0;
        if (acopy.moment > bcopy.moment) return 1;
        if (acopy.moment < bcopy.moment) return -1;
        return 0
    }
    public static get now(): MyDateTime {
        return new MyDateTime(moment());
    }
    public static get today(): MyDateTime {
        return MyDateTime.datePart(MyDateTime.now);
    }
    public static datePart(aDate: MyDateTime) {
        let x = MyDateTime.createDate(aDate.year, aDate.month, aDate.day);
        return x;
    }
    public static isSameDay(a: MyDateTime, b: MyDateTime): boolean {
        if (!a || !a.isValid || !b || !b.isValid) return false; //all invalid situations

        return (a.year == b.year && a.month == b.month && a.day == b.day);
    }

    //Accessors
    public get year(): number {
        if (!this.isValid) return -1;
        return this.moment.year();
    }
    public get month(): number {
        if (!this.isValid) return -1;
        return (this.moment.month() + 1);
    }
    public get day(): number {
        if (!this.isValid) return -1;
        return this.moment.date();
    }
    public get hour(): number {
        if (!this.isValid) return -1;
        return this.moment.hour();
    }
    public get minute(): number {
        if (!this.isValid) return -1;
        return this.moment.minute();
    }
    public get second(): number {
        if (!this.isValid) return -1;
        return this.moment.second();
    }
    public get date(): MyDateTime {
        if (!this.isValid) return null;

        return MyDateTime.createDate(this.year, this.month, this.day);
    }
    public get time(): MyDateTime {
        if (!this.isValid) return null;

        return MyDateTime.createTime(this.hour, this.minute, this.second);
    }

    //Serialization
    public toB64(): string {
        return btoa(this.moment.toISOString(true));
    }

    public toJson(): Object {
        if (this.isValid) {
            return this.moment.toISOString(true);
        }
        return null;
    }

    public clone() {
        if (!this.isValid) return MyDateTime.invalidDateTime;
        return MyDateTime.createDateTime(this.year, this.month, this.day, this.hour, this.minute, this.second);
    }

    public static fromJson(json: Object) {
        let x = new MyDateTime(json);
        if (!x.isValid) return null;
        return x;
    }


}

export interface IMyTimeSpan {
    isValid: boolean;
    days: number;
    hours: number;
    minutes: number;
    seconds: number;

    toJson(): Object;
}

export class MyTimeSpan implements IMyTimeSpan {
    isValid: boolean;
    days: number = 0;
    hours: number = 0;
    minutes: number = 0;
    seconds: number = 0;

    constructor(days: number, hours: number, minutes: number, seconds: number) {
        this.seconds = seconds;
        this.minutes = minutes;
        this.hours = hours;
        this.days = days;

        if (this.seconds > 59) {
            this.minutes += Math.floor(this.seconds / 60);
            this.seconds = this.seconds % 60;
        }


        if (this.minutes > 59) {
            this.hours += Math.floor(this.minutes / 60);
            this.minutes = this.minutes % 60;
        }

        if (this.hours > 23) {
            this.days += Math.floor(this.hours / 24);
            this.hours = this.hours % 24;
        }
        //console.log(this)

        this.isValid = true;
    }



    public toJson(): Object {
        if (this.isValid) {

            let str = "";
            str += (this.days * 24 + this.hours).toString().padStart(2, '0');
            str += ":";
            str += this.minutes.toString().padStart(2, '0')
            str += ":";
            str += this.seconds.toString().padStart(2, '0');


            return str;
        }
        return null;
    }
}

