import * as React from 'react';
import './App.css';
import { Redirect } from "react-router-dom";
import { HashRouter, Route, Switch } from "react-router-dom";
import { RouteComponentProps } from 'react-router-dom';
import DummyCatchRoutingProps, { Modules, Sections } from "./HelperClasses/Routing";
import { Routing } from "./HelperClasses/Routing";
import { Container, Alert } from 'react-bootstrap';
import 'moment/locale/nl-be';
let moment = require('moment');
moment.locale("nl-be");


import "react-table/react-table.css";


//import IconLoader from "./HelperClasses/IconLoader";
import { LoadingSpinner } from './UIComponents/MySpinners';
import { ApplicationState } from "./HelperClasses/ApplicationState";
import  MainMenuMobile  from "./Menus/MainMenuMobile";
import  ErrorBoundary  from "./ErrorHelpers/ErrorBoundary";
import  ErrorHandler  from "./ErrorHelpers/ErrorHandler";
import { ICustomError, CustomError } from "./HelperClasses/CustomError";
import { Http } from "./HelperClasses/Http";
import About from "./AppPages/About";
import PagenotFound from "./AppPages/PageNotFound";
import MaintenancePage from "./AppPages/MaintenancePage";


import Login from "./AppPages/Login";
//import LoginStatus from "./AppPages/LoginStatus";
import ResetPwdPage from "./AppPages/ResetPwdPage";
import RedirectPage from "./AppPages/RedirectPage";

import HomeMyeSoar from "./AppPages/HomeMyeSoar";
import HomeSecretariat from './AppPages/HomeSecretariat';
import HomeCamp from './AppPages/HomeCamp';
import HomeSiteMngt from './AppPages/HomeSiteMngt';

import MyAccount from "./AppPages/MyAccount";
import MySettings from "./AppPages/MySettings";
import MyFlightStatus from "./AppPages/MyFlightStatus";
import MyCostsOverview from "./AppPages/MyCostsOverview";
import MyFlights from "./AppPages/MyFlights";
import MyPersonalData from "./AppPages/MyPersonalData";
import Library from "./AppPages/Library";
import FlightDays from "./AppPages/FlightDays";
import Contacts from "./AppPages/Contacts";



//import MyEvents from "./AppPages/MyEvents";
const MyEvents = React.lazy(() => import('./AppPages/MyEvents'))

import CampRegistrationInfo from "./AppPages/CampRegistrationInfo";

import {IAccount} from "./DTO/Account"

//import Flights from "./AppPages/Flights";
//import Aircrafts from "./AppPages/Aircrafts";
//import Mails from "./AppPages/Mails";
//import Members from "./AppPages/Members";
//import CashBook from "./AppPages/CashBook";
//import Reports from "./AppPages/Reports";
const Flights = React.lazy(() => import('./AppPages/Flights'))
const Members = React.lazy(() => import('./AppPages/Members'))
const Aircrafts = React.lazy(() => import('./AppPages/Aircrafts'))
const CashBook = React.lazy(() => import('./AppPages/CashBook'))
const Reports = React.lazy(() => import('./AppPages/Reports'))
const Mails = React.lazy(() => import('./AppPages/Mails'))
const Events = React.lazy(() => import('./AppPages/Events'))

const Participants = React.lazy(() => import('./AppPages/Participants'));
const Meals = React.lazy(() => import('./AppPages/Meals'));

//import Accounts from "./AppPages/Accounts";
//import Tools from "./AppPages/Tools";
const Accounts = React.lazy(() => import('./AppPages/Accounts'))
const Tools = React.lazy(() => import('./AppPages/Tools'))

//interface IMyProps {
//    location: RouteProps["location"];
//    children: RouteProps["children"];
//}

interface IMyProps extends Partial<RouteComponentProps>{
}


interface IAppState {
    error: CustomError;

    appVersion: string;
    environment: string;
    maintenanceMode: boolean;

    account: IAccount;


}


class App extends React.Component<IMyProps, IAppState> {

    constructor(props: any) {
        super(props);

        ApplicationState.createOnce();
        Routing.createOnce();

        //IconLoader.load(); //Load font awesome

        this.state = { error: null, appVersion: null, environment: null, maintenanceMode: false, account: ApplicationState.get().sessionInfo.account };

        this.resetError = this.resetError.bind(this);
        this.handleStateError = this.handleStateError.bind(this);
        this.handleAccountChange = this.handleAccountChange.bind(this);
        this.handleProfilePictureChanged = this.handleProfilePictureChanged.bind(this);
        

    }

    render() {
        

        const jsx =


            <Container fluid className='p-0' >
                <DummyCatchRoutingProps />


               {this.getMainMenu()}

               {this.getAppInfo()}

               {this.state.error != null ? <ErrorHandler error={this.state.error} onDismissed={this.resetError} /> : null}

                <ErrorBoundary>

                    { this.getRouter()}

                </ErrorBoundary>
                    
            </Container>
                

        return jsx;
    }

    

    private getRouter() {
        const jsx =
            <HashRouter >
                <React.Suspense fallback={this.getSpinner()} >
                    <Switch>
                        <Route key="0" path={this.state.maintenanceMode ? null : '/maintenance'} render={(props) => this.renderRoute(MaintenancePage, props)} />
                        
                        <Route key="1" exact path="/" render={(props) => this.renderRouteRedirect(props)} />
                        <Route key="2" exact path="/login" render={(props) => this.renderRoute(Login, props)} />
                        <Route key="3" exact path="/resetpwd" render={(props) => this.renderRoute(ResetPwdPage, props)} />                      
                        <Route key="4" exact path="/redirect" render={(props) => this.renderRoute(RedirectPage, props)} />
                        <Route key="5" exact path="/maintenance" render={(props) => this.renderRoute(MaintenancePage, props)} />
                        <Route key="6" exact path="/inschrijven" render={(props) => this.renderRoute(CampRegistrationInfo, props)} />

                        <Route key="9" exact path="/0/" render={(props) => this.renderRoute(About, props)} />   

                        <Route key="11" exact path="/1/" render={(props) => this.renderRouteSecure(HomeMyeSoar, props)} />
                        <Route key="12" exact path="/1/myAccount" render={(props) => this.renderRouteSecure(MyAccount, props)} />
                        <Route key="13" exact path="/1/mySettings" render={(props) => this.renderRouteSecure(MySettings, props)} />
                        <Route key="14" exact path="/1/myFlightStatus" render={(props) => this.renderRouteSecure(MyFlightStatus, props)} />
                        <Route key="15" exact path="/1/myCostsOverview" render={(props) => this.renderRouteSecure(MyCostsOverview, props)} />
                        <Route key="16" exact path="/1/myFlights" render={(props) => this.renderRouteSecure(MyFlights, props)} />
                        <Route key="17" exact path="/1/myPersonalData" render={(props) => this.renderRouteSecure(MyPersonalData, props)} />
                        <Route key="18" exact path="/1/flightDays" render={(props) => this.renderRouteSecure(FlightDays, props)} />
                        <Route key="19" exact path="/1/contacts" render={(props) => this.renderRouteSecure(Contacts, props)} />
                        <Route key="110" exact path="/1/myEvents" render={(props) => this.renderRouteSecure(MyEvents, props)} />
                        <Route key="111" exact path="/1/library" render={(props) => this.renderRouteSecure(Library, props)} />

                        <Route key="21" exact path="/2/" render={(props) => this.renderRouteSecure(HomeSecretariat, props)} />
                        <Route key="22" exact path="/2/aircrafts" render={(props) => this.renderRouteSecure(Aircrafts, props)} />
                        <Route key="23" exact path="/2/members" render={(props) => this.renderRouteSecure(Members, props)} />
                        <Route key="24" exact path="/2/flights" render={(props) => this.renderRouteSecure(Flights, props)} />
                        <Route key="25" exact path="/2/cashbook" render={(props) => this.renderRouteSecure(CashBook, props)} />
                        <Route key="26" exact path="/2/reports" render={(props) => this.renderRouteSecure(Reports, props)} />
                        <Route key="27" exact path="/2/emails" render={(props) => this.renderRouteSecure(Mails, props)} />
                        <Route key="28" exact path="/2/events" render={(props) => this.renderRouteSecure(Events, props)} />

                        <Route key="31" exact path="/3/" render={(props) => this.renderRouteSecure(HomeCamp, props)} />
                        <Route key="32" exact path="/3/participants" render={(props) => this.renderRouteSecure(Participants, props)} />
                        <Route key="33" exact path="/3/meals" render={(props) => this.renderRouteSecure(Meals, props)} />

                        <Route key="41" exact path="/4/" render={(props) => this.renderRouteSecure(HomeSiteMngt, props)} />
                        <Route key="42" exact path="/4/accounts" render={(props) => this.renderRouteSecure(Accounts, props)} />
                        <Route key="43" exact path="/4/tools" render={(props) => this.renderRouteSecure(Tools, props)} />
                        <Route key="44" exact path="/4/about" render={(props) => this.renderRouteSecure(About, props)} />

                        <Route key="101" render={(props) => this.renderRoute(PagenotFound, props)} />
                        

                    </Switch>
                </React.Suspense>

            </HashRouter>

        return jsx;
    }

    private getAppInfo() {
        if (this.state.environment != "Staging") return null;
        const jsx =
            <Alert variant='warning' className='m-0'>
                Version: {this.state.appVersion}
                &nbsp;&nbsp;
                Environment: {this.state.environment}
            </Alert>

        return jsx;
    }

    private getMainMenu() {
        const jsx =
            <ErrorBoundary>
                <MainMenuMobile account={this.state.account} />
            </ErrorBoundary>

        return jsx;
    }

    private getSpinner() {
        const jsx =
            <LoadingSpinner active={true} >
                <Alert variant="dark" >
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                </Alert>
            </LoadingSpinner>
        return jsx;
    }

    //=====================  Routing functions ===================/

    //We need to capture the RouteComponentProps so do not use 'Component' on Route but the render prop
    //then have all render functions register the props in the singleton Routing
    // ^^ is no longer correct as we now have <DummyCatchRoutingProps />
    //but a functiuon is always nicer and more flexible
    private renderRoute(component: any, props: RouteComponentProps) {
        //Routing.setRoutingProps(props);
        
        return React.createElement(component, props);
    }


    private renderRouteRedirect(props: RouteComponentProps) {
        //Routing.setRoutingProps(props);
        if (this.state.maintenanceMode) {
            return <Redirect to="/maintenance" />
        }
        else if (ApplicationState.get().sessionInfo.account == null) {
            return <Redirect to="/login" />
        }
        else {
            return <Redirect to="/1/" />
        }
    }

    private renderRouteSecure(component: any, props: any) {
        //Routing.setRoutingProps(props);

        if (ApplicationState.get().sessionInfo.account == null) {
            return <Redirect to="/login" />
        }

        //https://migcoder.medium.com/difference-between-render-and-component-prop-on-react-router-v4-368ca7fedbec
        return React.createElement(component, props);
    }

    //==================== Handlers ===================================

    public handleStateError(error: ICustomError): void {
        //console.log("Handling StateError in app");
        //this.setState({ error: ApplicationState.get().error });
        this.setState({ error })
    }

    private handleAccountChange(): void {
        if (ApplicationState.get().sessionInfo.account) {
            ApplicationState.get().sessionInfo.account.onProfilePictureChanged.registerHandler(this.handleProfilePictureChanged);
        }
        this.setState({ account: ApplicationState.get().sessionInfo.account })
    }

    public handleProfilePictureChanged(srcURL: string) {
        this.setState({ account: ApplicationState.get().sessionInfo.account})
    }

    public resetError() {
        ApplicationState.get().resetError();
    }

    async componentDidMount() {
        ApplicationState.get().onErrorChange.registerHandler(this.handleStateError);
        ApplicationState.get().sessionInfo.onChange.registerHandler(this.handleAccountChange);

        await this.setState({ maintenanceMode: await this.loadMaintenanceMode() });
        if (!this.state.maintenanceMode) {
            await this.logonTestUser();
        }

        const p1 = this.loadAppVersion();
        const p2 = this.loadEnvironment();

        this.setState({ appVersion: await p1, environment: await p2});
    }

    componentWillUnmount() {
        ApplicationState.get().onErrorChange.unregisterHandler(this.handleStateError);
        if (ApplicationState.get().sessionInfo) ApplicationState.get().sessionInfo.onChange.unregisterHandler(this.handleAccountChange);
        if (ApplicationState.get().sessionInfo && ApplicationState.get().sessionInfo.account) ApplicationState.get().sessionInfo.account.onProfilePictureChanged.unregisterHandler(this.handleProfilePictureChanged);
    }

    //======================== Data layer ========================================//
    private async loadAppVersion(): Promise<string> {

        const http = new Http();
        const res = await http.getAsyncPrimitive<string>("api/dev/version") as string;

        if (http.error == null) {
            return res;
        }
        else {
            ApplicationState.get().setError(http.error);
            return null;
        }
    }

    private async loadEnvironment(): Promise<string> {

        const http = new Http();
        const res = await http.getAsyncPrimitive<string>("api/dev/environment") as string;

        if (http.error == null) {
            return res;
        }
        else {
            ApplicationState.get().setError(http.error);
            return null;
        }
    }

    private async loadMaintenanceMode(): Promise<boolean> {

        const http = new Http();
        const res = await http.getAsyncPrimitive<boolean>("api/dev/maintenancemode") as boolean;

        if (http.error == null) {
            return res;
        }
        else {
            ApplicationState.get().setError(http.error);
            return null;
        }
    }

    private async logonTestUser() {
        //return;
        const error = await ApplicationState.get().sessionInfo.logOnAdminDebugAsync(); //Admin 

        //Ignore if it fails, may be staging or prod
        //this.setState({ error: error });
        if (!error) {
            Routing.navigateTo(Sections.myEsoar, Modules.nomodule);
        }
    }
}

export default App;
