import * as React from 'react';


//import { Button, ListGroup, ListGroupItem } from 'react-bootstrap'
import { Alert } from 'react-bootstrap'

import { ICustomError } from "../HelperClasses/CustomError";
import { Http } from "../HelperClasses/Http";
import { ApplicationState } from "../HelperClasses/ApplicationState";


interface IAboutProps {
}


interface IAboutState {
    appVersion: string;
    environment: string;
}

export default class About extends React.Component<IAboutProps, IAboutState> {


    constructor(props: any) {
        super(props);

        this.state = { appVersion: null, environment: null};

        this.handleStateError = this.handleStateError.bind(this);

    }

    render() {



        const jsx =
            <>
                {this.getAppInfo()}
            </>
        return jsx;
    }

    private getAppInfo() {
        const jsx =
            <Alert variant='info'>
                Version: {this.state.appVersion}
                &nbsp;&nbsp;
                Environment: {this.state.environment}
            </Alert>

        return jsx;
    }


    private async loadAppVersion(): Promise<string> {

        const http = new Http();
        const res = await http.getAsyncPrimitive<string>("api/dev/version") as string;

        if (http.error == null) {
            return res;
        }
        else {
            ApplicationState.get().setError(http.error);
            return null;
        }
    }

    private async loadEnvironment(): Promise<string> {

        const http = new Http();
        const res = await http.getAsyncPrimitive<string>("api/dev/environment") as string;

        if (http.error == null) {
            return res;
        }
        else {
            ApplicationState.get().setError(http.error);
            return null;
        }
    }



    public handleStateError(error: ICustomError): void {
        if (error) {
            this.setState({  });
        }
    }

    async componentDidMount() {
        ApplicationState.get().onErrorChange.registerHandler(this.handleStateError);
        //console.log("Mounting About");

        const p1 = this.loadAppVersion();
        const p2 = this.loadEnvironment();
        this.setState({ appVersion: await p1 });
        this.setState({ environment: await p2 });
    }

    componentWillUnmount() {
        ApplicationState.get().onErrorChange.unregisterHandler(this.handleStateError);
    }
}