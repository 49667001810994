
export class RRN {
    //https://nl.wikipedia.org/wiki/Rijksregisternummer

    private _rrnString: string = null;
    private _isValid: boolean = false;

    private _year: number = null;
    private _month: number = null;
    private _day: number = null;
    private _isMale: boolean = null;
    private _isBis: boolean = false;

    private _serialNr : number = null;
    private _checkDigit: number = null;
    
    get isValid(): boolean { return this._isValid }

    get year(): number { return this._year }
    get month(): number { return this._month }
    get day(): number { return this._day }
    get isMale(): boolean { return this._isMale }
    get isBis(): boolean { return this._isBis }

    constructor(rrnString: string) {
        this._rrnString = rrnString;
        this.parseRrnString(this._rrnString);
    }

    public static isValid(rrnString: string): boolean {
        return (RRN.YearOfBirth(rrnString) >= 0);
    }

    public toString() {
        if (!this.isValid) return null;

        const str =
            this._year.toString().substr(2,2).padStart(2, '0') + '.'
            + this._month.toString().padStart(2, '0') + '.'
            + this._day.toString().padStart(2, '0') + '-'
            + this._serialNr.toString().padStart(3, '0') + '.'
            + this._checkDigit.toString().padStart(2, '0')

        return str
    }

    private static YearOfBirth(rrnString: string) : number {
        if (rrnString == null) return -1;
        if (rrnString.length != 11) return -1;

        const nr = Number.parseInt(rrnString.substr(0, 9));
        const checkDigit = Number.parseInt(rrnString.substr(9, 2));

        //console.log(nr.toString() + ", " + checkDigit);

        if ((97 - nr % 97) == checkDigit) return 1900;

        //try 2000+
        const nr2000 = Number.parseInt("2" + rrnString.substr(0, 9));
        if ((97 - nr2000 % 97) == checkDigit) return 2000;

        return -1;
    }

    private parseRrnString(rrnString: string): boolean {
        const YearOfBirth = RRN.YearOfBirth(rrnString);
        if (YearOfBirth < 0) {
            this._isValid = false;
            this._year = null;
            this._month = null;
            this._day = null;
            this._isMale = null;
            this._isBis = false;
            return false;
        }

        this._isValid = true;
        this._year = YearOfBirth + Number.parseInt(rrnString.substr(0, 2));

        this._month = Number.parseInt(rrnString.substr(2, 2));
        if (this._month > 40) { //geslacht gekend
            this._month = this._month - 40;
            this._isBis = true;
        }
        else if (this._month > 20) {//geslacht ONgekend
            this._month = this._month - 20;
            this._isBis = true;
        }

        this._day = Number.parseInt(rrnString.substr(4, 2));

        this._serialNr = Number.parseInt(rrnString.substr(6, 3));
        this._checkDigit = Number.parseInt(rrnString.substr(9, 2));

        this._isMale = (Number.parseInt(rrnString.substr(6, 3)) % 2 == 1);
        return true;
    }
}