import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import 'rc-datepicker/lib/style.css';
import * as React from 'react';
//import { Redirect } from "react-router-dom";
//import Container from 'react-bootstrap/Container';
import { Accordion, Button, Card, Col, Form, Row } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead-bs4.css';
import 'react-bootstrap-typeahead/css/Typeahead.css';
//import { Formatters } from '../HelperClasses/Formatters';
import { Duty, IDuty, IDutyValidationState } from '../DTO/Duty';
import { IMember } from '../DTO/Member';
import { CustomError } from '../HelperClasses/CustomError';
import { MyDateTime } from "../HelperClasses/MyDate";
//import { Styles } from './../HelperClasses/Styles';
import { ApplicationState } from "./../HelperClasses/ApplicationState";
import { Cache } from "./../HelperClasses/Cache";
import { ModalResult } from './../HelperClasses/Enums';
import { ISessionInfo } from "./../HelperClasses/SessionInfo";


export interface IDutyFormProps {
    dutyChangeMode: 'Edit' | 'Replace' | 'NewInstructor' | 'NewTowPilot';

    date: MyDateTime;
    duty?: IDuty;

    onClose: (result: ModalResult, duty: IDuty) => void;
}

interface IDutyFormState {

    duty: IDuty;

    allInstructors: Array<IMember>;
    allTowPilots: Array<IMember>;

    isValidated: boolean;
    validationState : IDutyValidationState
}

export class DutyForm extends React.Component<IDutyFormProps, IDutyFormState> {

    
    constructor(props: any) {
        super(props);

        //Clone the array
        if (this.props.dutyChangeMode == 'Edit' || this.props.dutyChangeMode == 'Replace') {

            if (this.props.duty == null) {
                ApplicationState.get().setError(new CustomError("No duty provided.", null, null, null, null, null));
                return;
            }

            const newDuty = Object.assign<Duty, IDuty>(new Duty(), this.props.duty);
            //Set initial state
            this.state = {
                duty: newDuty,
                allInstructors: null,
                allTowPilots: null,
                isValidated: false,
                validationState : (newDuty.getValidationState() as IDutyValidationState)
            };

        } else if (this.props.dutyChangeMode == 'NewInstructor' || this.props.dutyChangeMode == 'NewTowPilot') {

            let newDuty = new Duty();
            newDuty.day = this.props.date;
            newDuty.member = null;
            newDuty.replacementMember = null;
            newDuty.dutyType = this.props.dutyChangeMode == 'NewInstructor' ? Duty.DutyTypeInstructor : Duty.DutyTypeTowPilot;
            newDuty.am = true;
            newDuty.pm = true;

            //Set initial state
            this.state = {
                duty: newDuty,
                allInstructors: null,
                allTowPilots: null,
                isValidated: false,
                validationState: (newDuty.getValidationState() as IDutyValidationState)
            };

        }
        else {
            ApplicationState.get().setError(new CustomError("Mode not supported on DutyForm", null, null, null, null, null));
            return;
        }



        //Form methods
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleReset = this.handleReset.bind(this);
        this.validateForm = this.validateForm.bind(this);

        this.handleMemberChange = this.handleMemberChange.bind(this);
        this.handleAMChange = this.handleAMChange.bind(this);
        this.handlePMChange = this.handlePMChange.bind(this);

       
    }

    render() {

        let form = this.getForm();

        return (
            <>
                {form}

            </>   
        );
    }

    //======================== Get Form JSX elements =============================//

    private getForm() {
        const form =
            <Form noValidate validated={false}
                onSubmitCapture={this.handleSubmit}
                onResetCapture={this.handleReset}
                className='m-2'>

                {this.getInnerFormJSX()}

                <hr />
                <div style={{ textAlign: 'right' }}>
                    <Button type="reset" variant="secondary" tabIndex={998}>Annuleren</Button>
                    &nbsp;&nbsp;
                    <Button type="submit" tabIndex={999}>Opslaan</Button>
                </div>

            </Form>

        return form;
    }

    private getInnerFormJSX() {

        const jsx = 
            <>
                <Accordion defaultActiveKey="0" >
                    <Card style={this.accordeonValid()}>
                        <Accordion.Toggle as={Card.Header} eventKey="0">
                            <FontAwesomeIcon inverse={false} size="lg" icon='scroll' />&nbsp;&nbsp;Beurtrol
                        </Accordion.Toggle>

                        <Accordion.Collapse eventKey="0">
                            <Card.Body>
                                {this.getDutyJSX(this.state.duty)}
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>
            </>
        return jsx;
    }
        
    private getDutyJSX(duty: IDuty): JSX.Element {

        let caption = 'Selecteer lid';
        if (this.props.dutyChangeMode == 'Replace') {
            caption = 'selecteer vervanging voor ' + this.state.duty.member.getFullName();
        }

        if(!duty) return null;
        const jsx =
            <>

                {this.getDutyTypeJSX()}

                <hr />
            <Row >
                    <Col xs={12}>
                        <Form.Group controlId="frmCandidate" >
                            <Form.Text>
                                {caption}
                            </Form.Text>
                            {this.getMemberSelector(duty)}
                        </Form.Group>
                </Col>
            </Row>
            <Row>
                    <Col xs={12}>
                        <Form.Group controlId="frmPresent" >
                        <Form.Text>Aanwezig</Form.Text>
                        <Form.Check
                            type="checkbox"
                            label="VM"
                            name='frmAM'
                            id='frmAM'
                            checked={duty? duty.am : false}
                            onChangeCapture={this.handleAMChange}
                            onChange={() => { }}
                            tabIndex={2}
                        />
                        <Form.Check
                            type="checkbox"
                            label="NM"
                            name='frmPM'
                            id='frmPM'
                            checked={duty? duty.pm : false}
                            onChangeCapture={this.handlePMChange}
                            onChange={() => { }}
                            tabIndex={3}
                        />
                    </Form.Group>
                </Col>

                </Row>
                </>
        return jsx;
    }

    private getMemberSelector(duty: IDuty) {
        let member = duty.member;
        if (this.props.dutyChangeMode == 'Replace') {
            member = duty.replacementMember;
        }

        let candidates = this.state.allInstructors;
        if (this.state.duty.dutyType == Duty.DutyTypeTowPilot) {
            candidates = this.state.allTowPilots;
        }

        const jsx =
            <Typeahead id='lstInstructor'
                positionFixed={true}
                flip={true}
                labelKey={(x: IMember) => x.getFullName()}
                allowNew={false}
                emptyLabel="niet gevonden"
                options={candidates ? candidates : []}
                selected={member ? [member] : []}
                multiple={false}
                clearButton={true}
                placeholder="selecteer..."
                minLength={0}
                selectHintOnEnter={true}
                ref='refInstructor'
                onChange={(selectedOptions) => { this.handleMemberChange(selectedOptions) }}
                isInvalid={this.state.isValidated && !this.state.validationState.stateIsValid}
                inputProps={{ tabIndex: 1 }}
            />

        return jsx;
    }

    private getDutyTypeJSX() {
        if (!this.state.duty) return null;

        if (this.props.dutyChangeMode == 'Replace') {
            if (this.state.duty.dutyType == Duty.DutyTypeInstructor) {
                return <span>Instructeur</span>
            }
            else {
                return <span>Sleeppiloot</span>
            }
        }
        else {
            const jsx =
                <>
                    <Form.Check
                        type="radio"
                        label="Instructeur"
                        name="dutyType"
                        id="INSTRUCTOR"
                        checked={this.state.duty.dutyType == Duty.DutyTypeInstructor}
                        onChangeCapture={() => { this.handleDutyTypeChange(Duty.DutyTypeInstructor) }}
                        onChange={() => { }}
                        tabIndex={13}
                        disabled={false}
                    />
                    <Form.Check
                        type="radio"
                        label="Sleep piloot"
                        name="dutyType"
                        id="TOW_PILOT"
                        checked={this.state.duty.dutyType == Duty.DutyTypeTowPilot}
                        onChangeCapture={() => { this.handleDutyTypeChange(Duty.DutyTypeTowPilot) }}
                        onChange={() => { }}
                        tabIndex={14}
                        disabled={false}
                    />
                </>
            return jsx;
        }

    }
    //======================== Handlers =============================//
    private handleMemberChange(selectedOptions: Array<IMember>) {

        //console.log(selectedOptions[0]);

        if (selectedOptions != null && selectedOptions.length > 0) {
            const candidate = selectedOptions[0];
            if (this.props.dutyChangeMode == 'Replace') {
                this.state.duty.replacementMember = candidate;
            }
            else {
                this.state.duty.member = candidate;
                this.state.duty.replacementMember = null;
            }
        }
        else {
            this.state.duty.replacementMember = null;
        }
        this.setState({ duty: this.state.duty });
    }

    private handleAMChange(event: React.ChangeEvent<HTMLInputElement>) {
        const checked = event.currentTarget.checked;
        this.state.duty.am = checked
        this.setState({ duty : this.state.duty });
    }
    private handlePMChange(event: React.ChangeEvent<HTMLInputElement>) {
        const checked = event.currentTarget.checked;
        this.state.duty.pm = checked
        this.setState({ duty: this.state.duty });
    }

    private handleDutyTypeChange(dutyType: string) {
        this.state.duty.dutyType = dutyType;
        this.setState({ duty: this.state.duty });
    }

    //======================== Form Validation =============================//

    private accordeonValid() {
        return {};
    //    let vs = this.entity.getValidationState() as IFlightDayValidationState;
    //    return !vs.stateIsValid && this.state.isValidated ?
    //        Styles.getDivErrorStyle() : {};
    }

    private async validateForm() {
        let vs = this.state.duty.getValidationState() as IDutyValidationState;

        await this.setState({validationState: vs, isValidated: true});

        //////console.log("Valid: " + vs.isValid.toString());
        return vs.stateIsValid;
    }

    private handleReset() {
        event.preventDefault();
        event.stopPropagation();
        this.setState({ isValidated: false });

        this.props.onClose(ModalResult.Cancel, null);
    }

    private async handleSubmit(event: React.FormEvent<HTMLFormElement>) {

        event.preventDefault();
        event.stopPropagation();

        const validated = await this.validateForm();

        
        if (validated) {
            this.props.onClose(ModalResult.OK, this.state.duty);
        }
        else {
            //this.setState({ isValidated: validated });
        }
    }

    //======================== Data Layer =============================//

    private async loadBaseTables() {
        this.setState({ allInstructors: await Cache.loadInstructorsAsync() });
        this.setState({ allTowPilots: await Cache.loadTowPilotsAsync() });
    }


    //======================== Lifecycle =============================//

    handleSessionChange = (sessionInfo: ISessionInfo) => {
    }

    async componentDidMount() {
        //console.log("mounting DutyForm");
        //ApplicationState.get().sessionInfo.onChange.registerHandler(this.handleSessionChange);

        this.loadBaseTables();

        //((this.refs.refArrivalTime as any).refs.refInput as HTMLInputElement).focus();
    }

    componentWillUnmount() {
        //console.log("unmounting AircraftForm");
        ApplicationState.get().sessionInfo.onChange.unregisterHandler(this.handleSessionChange);
    }
}
