
import { ICustomError, CustomError, HttpStatus } from "./CustomError";
import { ApplicationState } from "./ApplicationState";
import { ISerializable } from '../DTO/ISerializable';
//import { decode } from "punycode";
//import { ISerializableBuilder } from '../DTO/ISerializable';


export interface IHttp
{
    error: ICustomError;

    getAsyncPrimitive<T>(url: string): Promise<T | Array<T>>; //T must be primitive
    getAsyncObject<T extends ISerializable<T>>(url: string, objectType: { new(): T; }): Promise<T | Array<T>>
    getAsyncFile(url: string): Promise<void>;

    postAsyncPrimitive<T>(url: string, postObject: Object): Promise<T>; //T must be primitive
    postAsyncObject<T extends ISerializable<T>>(url: string, postObject: ISerializable<T>, objectType: { new(): T; }): Promise<T>; //T must be Object
    postAsyncFile<T>(url: string, file: Blob, fileName: string): Promise<T>;
    postAsyncFileAndObject<T extends ISerializable<T> | Object>(url: string, file: Blob, postObject: ISerializable<T> | Object, objectType: { new(): T; }): Promise<T | Object>;

    deleteAsyncPrimitive<T>(url: string): Promise<T>; //T must be primitive
    deleteAsyncObject<T extends ISerializable<T>>(url: string, objectType: { new(): T; }): Promise<T>
}

export class Http implements IHttp {

    

    private _error: ICustomError;
    get error(): ICustomError { return this._error;}

    private _response: Response;

    constructor() {
        this._response = null;
    }

 
    public async getAsyncPrimitive<T>(url: string): Promise<T | Array<T>> {
        const data = (await this.getAsync(url));
        //if (data == null) return null; //Error occured

        return data as T;
    }

    public async getAsyncObject<T extends ISerializable<T>>(url: string, objectType: { new(): T; }): Promise<T | Array<T>> {
        const data = (await this.getAsync(url));
        //if (data == null) return null; //Error occured

        return this.getTrueObjects2<T>(data, objectType);

    }

    public async getAsyncFile(url: string): Promise<void> {

        //const blob = await this.getAsyncPrimitive<Blob>(url) as Blob;
        const blob = await this.getAsyncBlob(url);

        if (this.error == null) {

            const fileName = this.getDownloadFilename();
            //console.log(fileName);

            //https://medium.com/yellowcode/download-api-files-with-react-fetch-393e4dae0d9e
            // 2. Create blob link to download
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            // 3. Append to html page
            document.body.appendChild(link);
            // 4. Force download
            link.click();
            // 5. Clean up and remove the link
            link.parentNode.removeChild(link);
        }

    }


    public async postAsyncPrimitive<T>(url: string, postObject: Object): Promise<T> {
        const data = (await this.postAsync(url, postObject));
        //if (data == null) return null; //Error occured

        return data as T;
    }

    public async postAsyncObject<T extends ISerializable<T>>(url: string, postObject: ISerializable<T> | Object, objectType: { new(): T; }): Promise<T> {
        let data = null;
        if ( typeof (postObject as ISerializable<T>).toJson === 'function' ) {
            data = (await this.postAsync(url, (postObject as ISerializable<T>).toJson()));
        }
        else {
            data = (await this.postAsync(url, postObject));
        } 

        //if (data == null) return null; //Error occured

        return this.getTrueObjects2<T>(data, objectType) as T;
    }

    public async postAsyncFile<T>(url: string, file: Blob, fileName: string): Promise<T> {
        let data = null;

        data = (await this.postAsyncBlob(url, file, fileName));

        return data as T;
    }

    public async postAsyncFileAndObject<T extends ISerializable<T> | Object >(url: string, file: Blob, postObject: ISerializable<T> | Object, objectType: { new(): T; }): Promise<T | Object> {
        let data = null;

        if (typeof (postObject as ISerializable<T>).toJson === 'function') {
            //this._error = new CustomError("This function is not yet enabled for ISerializable objects.",HttpStatus.httpUnknown,null,null,999,null);
            data = (await this.postAsyncBlobAndObject(url, file, (postObject as ISerializable<T>).toJson()));
        }
        else {
            data = (await this.postAsyncBlobAndObject(url, file, postObject));
        } 

        if (typeof (postObject as ISerializable<T>).toJson === 'function') {
            return this.getTrueObjects3<T>(data, objectType) as T;
        }
        else {
            return data as T;
        }
    }


    public async deleteAsyncPrimitive<T>(url: string): Promise<T> {
        const data = (await this.deleteAsync(url));
        //if (data == null) return null; //Error occured

        return data as T;
    }

    public async deleteAsyncObject<T extends ISerializable<T>>(url: string, objectType: { new(): T; }): Promise<T> {
        const data = (await this.deleteAsync(url));
        //if (data == null) return null; //Error occured

        return this.getTrueObjects2<T>(data, objectType) as T;
    }

    //=====================================================================================

    private async getAsync(url: string): Promise<any> {
        try {
            this._error = null;

            const headers = this.getHeadersJSON();

            const init: RequestInit =
            {
                method: "get",
                headers: headers
            };

            this._response = await fetch(url, init);

            if (!this._response.ok) {
                await this.setHttpError(this._response);
                return null;
            }

            return this.getResponseBody(this._response);
        }
        catch (error) {
            this.setError(error as Error, url);
            return null;
        }
    }

    private async getAsyncBlob(url: string): Promise<Blob> {
        try {
            this._error = null;

            const headers = this.getHeaders();

            const init: RequestInit =
            {
                method: "get",
                headers: headers
            };

            this._response = await fetch(url, init);

            if (!this._response.ok) {
                await this.setHttpError(this._response);
                return null;
            }

            return this.getResponseBody(this._response);
        }
        catch (error) {
            this.setError(error as Error, url);
            return null;
        }
    }

    private async postAsync(url: string, postObject: Object) : Promise<any> {
        try {

            //console.log("POST")
            //console.log(postObject)
            //console.log(JSON.stringify(postObject))

            this._error = null;

            const headers = this.getHeadersJSON();

            const init: RequestInit =
            {
                method: "post",
                headers: headers,
                body: JSON.stringify(postObject)
            };

            this._response = await fetch(url, init);

            if (!this._response.ok) {
                await this.setHttpError(this._response);
                return null;
            }

            return this.getResponseBody(this._response);

            //const data = await this._response.text(); //if empty response (returned null) cannot parse as js
            //if (data.length == 0) {
            //    return null;
            //}
            //else {
            //    return JSON.parse(data);
            //}
        }
        catch (error) {
                this.setError(error as Error, url);
                return null;
        }

    }

    private async postAsyncBlob(url: string, file: Blob, fileName: string): Promise<any> {
        try {

            this._error = null;

            // Strange headers behaviour for multipart form
            //https://stackoverflow.com/questions/39280438/fetch-missing-boundary-in-multipart-form-data-post
            const headers = new Headers();
            headers.append('Accept', 'application/json');
            //headers.append('Content-Type', contentType ? contentType : 'application/json'); DO NOT SET
            if (ApplicationState.get().sessionInfo.account) {
                headers.append('Authorization', 'Bearer ' + ApplicationState.get().sessionInfo.account.token);
            }
            // THis is ugly ^^            

            let form = new FormData();
            form.append('image', file);
            form.append('filename', fileName);

   
            const init: RequestInit =
            {
                method: "post",
                headers: headers,
                body: form //Do NOT stringify
            };

            this._response = await fetch(url, init);

            if (!this._response.ok) {
                await this.setHttpError(this._response);
                return null;
            }

            return this.getResponseBody(this._response);

        }
        catch (error) {
            this.setError(error as Error, url);
            return null;
        }

    }
 
    private async postAsyncBlobAndObject(url: string, file: Blob, postObject: Object): Promise<any> {
        try {

            this._error = null;

            // Strange headers behaviour for multipart form
            //https://stackoverflow.com/questions/39280438/fetch-missing-boundary-in-multipart-form-data-post
            const headers = new Headers();
            headers.append('Accept', 'application/json');
            //headers.append('Content-Type', contentType ? contentType : 'application/json'); DO NOT SET
            if (ApplicationState.get().sessionInfo.account) {
                headers.append('Authorization', 'Bearer ' + ApplicationState.get().sessionInfo.account.token);
            }
            // THis is ugly ^^

            //ensure the content-type is set for the json object. This works but wepAPI doesnt recognize it
            //So decode serverside needed
            //https://stackoverflow.com/questions/50774176/sending-file-and-json-in-post-multipart-form-data-request-with-axios
            //const json = JSON.stringify(postObject);
            //const tmpBlob = new Blob([json], {
            //    type: 'application/json'
            //});

            let form = new FormData();
            form.append('file', file);
            form.append('postObject', JSON.stringify(postObject));


            const init: RequestInit =
            {
                method: "post",
                headers: headers,
                body: form //Do NOT stringify
            };

            this._response = await fetch(url, init);

            if (!this._response.ok) {
                await this.setHttpError(this._response);
                return null;
            }

            return this.getResponseBody(this._response);

        }
        catch (error) {
            this.setError(error as Error, url);
            return null;
        }

    }

    private async deleteAsync(url: string): Promise<any> {
        try {
            this._error = null;

            const headers = this.getHeadersJSON();

            const init: RequestInit =
            {
                method: "delete",
                headers: headers
            };

            this._response = await fetch(url, init);

            if (!this._response.ok) {
                await this.setHttpError(this._response);
                return null;
            }

            return this.getResponseBody(this._response);

            //const data = await this._response.text(); //if empty response (returned null) cannot parse as js
            //if (data.length == 0) {
            //    return null;
            //}
            //else {
            //    return JSON.parse(data);
            //}
        }
        catch (error) {
            this.setError(error as Error, url);
            return null;
        }
    }

    //=====================================================================//

    private getTrueObjects2<T extends ISerializable<T>>(data: any, objectType: { new(): T; }): T | Array<T> {
        // How to create an instance of a generic type parameter? https://stackoverflow.com/questions/17382143/create-a-new-object-from-type-parameter-in-generic-class

        if (data !== Object(data)) {
            //primitive
            return data;
        }

        if (!Array.isArray(data)) {

            let creatorObject = new objectType();
            return creatorObject.fromJson(data);
        }

        let trueObjects = new Array<T>();
        for (let a of data) {
            let creatorObject = new objectType();
            let trueObject = creatorObject.fromJson(a);
            trueObjects.push(trueObject);
        }
        return trueObjects;
    }

    private getTrueObjects3<T>(data: any, objectType: { new(): T }): T | Array<T> {
        // How to create an instance of a generic type parameter? https://stackoverflow.com/questions/17382143/create-a-new-object-from-type-parameter-in-generic-class

        if (data !== Object(data)) {
            //primitive
            return data;
        }

        if (!Array.isArray(data)) {

            let creatorObject = new objectType();
            return (creatorObject as any as ISerializable<T>).fromJson(data);
        }

        let trueObjects = new Array<T>();
        for (let a of data) {
            let creatorObject = new objectType();
            let trueObject = (creatorObject as any as ISerializable<T>).fromJson(a);
            trueObjects.push(trueObject);
        }
        return trueObjects;
    }

    private getHeaders(contentType?: string): Headers {
        const headers = new Headers();

        if (ApplicationState.get().sessionInfo.account) {
            headers.append('Authorization', 'Bearer ' + ApplicationState.get().sessionInfo.account.token);
        }
        return headers;
    }

    private getHeadersJSON(contentType?: string): Headers {
        const headers = new Headers();

        headers.append('Accept', 'application/json');
        headers.append('Content-Type', contentType? contentType : 'application/json');

        if (ApplicationState.get().sessionInfo.account) {
            headers.append('Authorization', 'Bearer ' + ApplicationState.get().sessionInfo.account.token);
        }


        //interface RequestInit {
        //    body?: BodyInit | null;
        //    cache?: RequestCache;
        //    credentials?: RequestCredentials;
        //    headers?: HeadersInit;
        //    integrity?: string;
        //    keepalive?: boolean;
        //    method?: string;
        //    mode?: RequestMode;
        //    redirect?: RequestRedirect;
        //    referrer?: string;
        //    referrerPolicy?: ReferrerPolicy;
        //    signal?: AbortSignal | null;
        //    window?: any;
        //}

        return headers;
    }

    private getDownloadFilename(): string {

        const header = this._response.headers.get('Content-Disposition');
        if (!header) return null;

        const UTF8FileNameTag = "filename*=UTF-8\'\'";
        const UTF8FilenameTagIndex = header.indexOf(UTF8FileNameTag);
        if (UTF8FilenameTagIndex >= 0) {

            const UTF8FilenameStartIndex = UTF8FilenameTagIndex + UTF8FileNameTag.length;

            const endIndex = header.indexOf(";", UTF8FilenameStartIndex);
            if (endIndex < 0) {
                const encoded = header.substring(UTF8FilenameStartIndex)
                return decodeURI(encoded);
            }
            else {
                const encoded = header.substring(UTF8FilenameStartIndex, endIndex)
                return decodeURI(encoded);
            }
        }
        else {
            const FileNameTag = "filename=";
            const FileNameTagIndex = header.indexOf(FileNameTag);
            if (FileNameTagIndex >= 0) {

                const FilenameStartIndex = FileNameTagIndex + FileNameTag.length;

                const endIndex = header.indexOf(";", FilenameStartIndex);
                if (endIndex < 0) {
                    var fn = header.substring(FilenameStartIndex);
                    fn = (fn.split("\"")).join('')
                    return fn;
                }
                else {
                    var fn = header.substring(FilenameStartIndex, endIndex);
                    fn = (fn.split("\"")).join('')
                    return fn;
                }
            }
        }


        return null;
    }

    private async getResponseBody(response: Response) {

        if (response.headers.get('Content-Length') && response.headers.get('Content-Length') == "0") {
            return null;
        }

        if (response.headers.get('Content-Type') && response.headers.get('Content-Type').indexOf('application/json') > -1) {
            const data = await response.text();
            return JSON.parse(data);
        }

        if (response.headers.get('Content-Type') && response.headers.get('Content-Type').indexOf('text/plain') > -1) {
            const data = await response.text();
            return data;
        }
        if (response.headers.get('Content-Type') && response.headers.get('Content-Type').indexOf('application/x-www-form-urlencoded') > -1) {
            const data = await response.formData();
            return data;
        }

        return await response.blob();
    }

    private async setHttpError(response: Response) {
        const statusCode = response.status;
        const statusText = response.statusText;
        const url = response.url;
        const contentType = response.headers.get("content-type");
        const isJson: boolean = (contentType && contentType.indexOf("application/json") !== -1);
        const isHtml: boolean = (contentType && contentType.indexOf("text/html") !== -1);

        let bodyContent = "n/a";

        try {
            if (isJson) {
                bodyContent = await response.json();
                //bodyContent = JSON.stringify(bodyContent); NO IDEA WHY THIS LINE WAS IN....
            }
            else {
                bodyContent = await response.text();
                if (isHtml) {
                    bodyContent = bodyContent.replace(/<\/?[^>]+>/gi, '');
                }
            }
        }
        catch (e) { }//ignore any parsing errors

        let customErrorNr = null;
        let customErrorData = null;

        if (statusCode == HttpStatus.httpCustomError) {
            //There should be a json object in the body
            customErrorNr = (bodyContent as any).customErrorNr;
            customErrorData = (bodyContent as any).customErrorData;
        }

        this._error = new CustomError(
            "Server responded: " + statusCode + ", " + statusText,
            statusCode,
            url,
            bodyContent,
            customErrorNr,
            customErrorData
        )

    }

    private setError(error: Error, url: string) {

        if (error != undefined) {
            //console.log({ error });
            this._error = new CustomError(error.message, HttpStatus.httpUnknown, url, error.stack, null, null);
        }
        else {
            this._error = new CustomError("undefined error", HttpStatus.httpUnknown, url, null, null, null);
        }
    }

}