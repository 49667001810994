

import { IMember, Member } from "./Member";

import { MyDateTime } from "./../HelperClasses/MyDate";
import { ISerializable } from './ISerializable';
import { IValidation, IValidationState } from './IValidation'

//import moment = require('moment');


export interface IInstructorLog extends ISerializable<IInstructorLog>, IValidation {
    id: string;

    instructor: IMember;
    member: IMember;

    dateCreated: MyDateTime;
    dateModified: MyDateTime;
    dateDeleted: MyDateTime;
    msg: string;

    clone(): IInstructorLog;
}

export interface IInstructorLogValidationState extends IValidationState {       
    instructorIsValid: boolean;
    memberIsValid: boolean;
}

export class InstructorLog implements IInstructorLog {

    id: string = null;

    instructor: IMember = null;
    member: IMember = null;
    dateCreated: MyDateTime = null;
    dateModified: MyDateTime = null;
    dateDeleted: MyDateTime = null;
    msg: string = null;

    constructor() {
    }

    public getValidationState(): IInstructorLogValidationState {
        //console.log("Validation");
        //console.log(this.costPerMinute);

        let vs = {
            instructorIsValid: (this.instructor == null ? false : true),
            memberIsValid: (this.member == null ? false : true),

        } as IInstructorLogValidationState;

        vs.stateIsValid = (
            vs.instructorIsValid &&
            vs.memberIsValid 
        ); 

        return vs;
    }

    public fromJson(json: Object): InstructorLog {
        //DO NOT USE 'this.' RETURN A NEW OBJECT
        //console.log("Aircraft.fromJson");

        const trueObject = new InstructorLog();
        Object.assign<IInstructorLog, Object>(trueObject, json);

        //Overloads
        trueObject.dateCreated = MyDateTime.fromJson(trueObject.dateCreated);
        trueObject.dateModified = MyDateTime.fromJson(trueObject.dateModified);
        trueObject.dateDeleted = MyDateTime.fromJson(trueObject.dateDeleted);
        

        //Objects
        if (trueObject.instructor != null) trueObject.instructor = (new Member()).fromJson(trueObject.instructor);
        if (trueObject.member != null) trueObject.member = (new Member()).fromJson(trueObject.member);

        //console.log(trueObject);
        return trueObject;
    }

    public toJson(): Object {
        //console.log("Aircraft.toJson");

        let x = JSON.parse(JSON.stringify(this));

        //Overloads
        if (this.dateCreated) x.dateCreated = this.dateCreated.toJson();
        if (this.dateModified) x.dateModified = this.dateModified.toJson();
        if (this.dateDeleted) x.dateDeleted = this.dateDeleted.toJson();

        if (this.instructor) x.instructor = this.instructor.toJson();
        if (this.member) x.member = this.member.toJson();

        //console.log(x);
        return x;
    }

    public clone(): IInstructorLog {

        const log = Object.assign<InstructorLog, IInstructorLog>(new InstructorLog(), this);

        if (this.dateCreated) log.dateCreated = this.dateCreated.clone();
        if (this.dateModified) log.dateModified = this.dateModified.clone();
        if (this.dateDeleted) log.dateDeleted = this.dateDeleted.clone();

        log.id = this.id;
        log.msg = this.msg;

        //Do not clone, not needed as is read only
        log.member = this.member;
        log.instructor = this.instructor;

        return log;
    }
}