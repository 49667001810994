
import { MyDateTime } from "./../HelperClasses/MyDate";
import { ISerializable } from './ISerializable';
import { IValidation, IValidationState } from './IValidation'
//import moment = require('moment');

import { IEventDay, EventDay } from "./EventDay"
import { IReservable, Reservable } from "./Reservable"


export interface IEvent extends ISerializable<IEvent>, IValidation {
    
    id: string;

    name: string;
    details: string;

    startDate: MyDateTime;
    endDate: MyDateTime;
    deadline: MyDateTime;

    isCamp: boolean;
    isRegistrationOpen: boolean;

    eventDay: Array<IEventDay>;
    reservable: Array<IReservable>;

    clone(): IEvent;

}

export interface IEventValidationState extends IValidationState {
    nameIsValid: boolean;
    startDateIsValid: boolean;
    endDateIsValid: boolean;
    deadlineDateIsValid: boolean;
}

export class Event implements IEvent {

    public id: string = null;

    public name: string = null;
    public details: string = null;

    public startDate: MyDateTime = null;
    public endDate: MyDateTime = null;
    public deadline: MyDateTime = null;

    public isCamp: boolean = false;
    public isRegistrationOpen: boolean = false;

    public eventDay: Array<IEventDay> = new Array<IEventDay>();
    public reservable: Array<IReservable> = new Array<IReservable>();

    constructor() {
    }


    public getValidationState(): IEventValidationState {

        let vs = {
            nameIsValid: this.name && this.name.trim().length > 0,
            startDateIsValid: this.startDate ? this.startDate.isValid : false,
            endDateIsValid: this.endDate ? this.endDate.isValid : false,
            deadlineDateIsValid: this.deadline ? this.deadline.isValid : false,
            stateIsValid: false
        } as IEventValidationState;

        vs.stateIsValid = (vs.startDateIsValid && vs.endDateIsValid && vs.deadlineDateIsValid);

        return vs;
    }



    public fromJson(json: Object): Event {
        //DO NOT USE 'this.' RETURN A NEW OBJECT
        //console.log("Aircraft.fromJson");

        const trueObject = new Event();
        Object.assign<IEvent, Object>(trueObject, json);

        //trueObject.key = trueObject.id;

        //Overloads
        trueObject.startDate = MyDateTime.fromJson(trueObject.startDate);
        trueObject.endDate = MyDateTime.fromJson(trueObject.endDate);
        trueObject.deadline = MyDateTime.fromJson(trueObject.deadline);

        //Objects
        let objs = new Array<IEventDay>();
        trueObject.eventDay.forEach(
            (json) => {
                objs.push((new EventDay()).fromJson(json));
            }
        )
        trueObject.eventDay = objs;

        let objs2 = new Array<IReservable>();
        trueObject.reservable.forEach(
            (json) => {
                objs2.push((new Reservable()).fromJson(json));
            }
        )
        trueObject.reservable = objs2;

        //console.log(trueObject);
        return trueObject;
    }


    public toJson(): Object {
        //console.log("Aircraft.toJson");

        let x = JSON.parse(JSON.stringify(this));

        //Overloads

        if (this.startDate) x.startDate = this.startDate.toJson();
        if (this.endDate) x.endDate = this.endDate.toJson();
        if (this.deadline) x.deadline = this.deadline.toJson();

        //Objects
        let jsonObjects = new Array<object>();
        this.eventDay.forEach(
            (anEventDay) => {
                jsonObjects.push(anEventDay.toJson());
            })
        x.eventDay = jsonObjects;

        let jsonObjects2 = new Array<object>();
        this.reservable.forEach(
            (aReservable) => {
                jsonObjects2.push(aReservable.toJson());
            })
        x.reservable = jsonObjects2;

        //console.log(x);
        return x;
    }

    public clone() : IEvent {
        
        const event = Object.assign<Event, IEvent>(new Event(), this);

        if (this.startDate) event.startDate = this.startDate.clone();
        if (this.endDate) event.endDate = this.endDate.clone();

        for (var i = 0; i < event.eventDay.length; i++) {
            event.eventDay[i] = this.eventDay[i].clone();
        }

        for (var i = 0; i < event.reservable.length; i++) {
            event.reservable[i] = this.reservable[i].clone();
        }

        return event;
    }

}