import * as React from 'react';


import { Row, Col, Badge } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { Http } from "../HelperClasses/Http";
import { Formatters } from '../HelperClasses/Formatters';
import { MyDateTime } from '../HelperClasses/MyDate';
//import { Styles } from '../HelperClasses/Styles';
import { ApplicationState } from "../HelperClasses/ApplicationState";

import { LoadingSpinner } from '../UIComponents/MySpinners';
import { InfoOverlay } from "./../UIComponents/InfoOverlay";
import { IFlightDay, FlightDay } from "../DTO/FlightDay"
import { IMemberFlightDay, MemberFlightDay } from "../DTO/MemberFlightDay"
import { IFlightDayNumbers, FlightDayNumbers } from "../DTO/FlightDayNumbers"
import { IDuty, Duty } from "../DTO/Duty"



export interface IFlightDaysCalendarProps {

    year: number;
    month: number;

    onDaySelected?: (flightDay: IFlightDay) => void;
    
}

export interface IFlightDaysCalendarState {
    isLoading: boolean;

    year: number;
    month: number;
    selectedDay: MyDateTime;

    flightDays: Array<IFlightDay>;
    flightDayNumbers: Array<IFlightDayNumbers>;
    myFlightDays: Array<IMemberFlightDay>;
    myDuties: Array<IDuty>;
}


export class FlightDaysCalendar extends React.Component<IFlightDaysCalendarProps, IFlightDaysCalendarState> {

    private month: Array<Array<number>>

    constructor(props: any) {
        super(props);

        this.month = this.createMonthLayout(this.props.year, this.props.month);

        this.state = {
            isLoading: false, year: this.props.year, month: this.props.month, selectedDay: null,
            flightDays: null,
            myFlightDays: null,
            flightDayNumbers: null,
            myDuties: null
        };

        this.handlePrevMonth = this.handlePrevMonth.bind(this);
        this.handleNextMonth = this.handleNextMonth.bind(this);
        this.handleDaySelect = this.handleDaySelect.bind(this);
    }

    render() {

        const JSX =

            <>
                <LoadingSpinner active={this.state.isLoading} >
                <>
                    {this.getControls()}
                    {this.getInnerForm()}
                </>
                </LoadingSpinner>
            </>

        return JSX;
    }

    private getControls() {
        const jsx =
            <Row className=''>
                <Col xs={2} className='text-center'>
                    <span onClick={() => this.handlePrevMonth()} >
                        <FontAwesomeIcon icon="chevron-circle-left" size='1x' />
                    </span>
                </Col>
                <Col xs={6} className='text-center'>
                    <h5>{Formatters.getYearMonthString(this.state.year, this.state.month)}</h5>
                </Col>
                <Col xs={2} className='text-center'>
                    <span onClick={() => this.handleNextMonth()}  >
                        <FontAwesomeIcon icon="chevron-circle-right" />
                    </span>
                </Col>
                <Col xs={2} className='text-center'>
                    <InfoOverlay tooltip={this.getLegenda()} />
                </Col>
            </Row>

        return jsx;
    }

    private getInnerForm() {
        return this.getCalendarJSX();
    }

    private getCalendarJSX() {

        const weeksJSX = this.month.map(
            (week, index) => {
                return this.getDateRowJSX(week);
            }
        );

        const jsx =
            <div className='m-2'>
                {this.getHeaderRowJSX()}
                {weeksJSX}
            </div>
        return jsx;
    }

    private getHeaderRowJSX() {
        const weekDays = ["Ma", "Di", "Wo", "Do", "Vr", "Za", "Zo"];

        const jsxCols = weekDays.map(
            (weekDayName, index) => {
                const jsxCol =
                    <Col className='px-0 text-center' key={index}>
                        {weekDayName}
                    </Col>
                return jsxCol;
            }
        );

        const jsx =
            <Row key={0}>
                {jsxCols}
            </Row>

        return jsx;
    }

    private getDateRowJSX(weekDays: Array<number>) {

        const jsxCols = weekDays.map(
            (dayNumber, index) => {
                const jsxCol =
                    <Col className='p-0'
                        style={
                            {
                                ...this.getDayColor(this.getDayType(dayNumber)),
                                ...this.getDayBorder(this.getFlightDay(dayNumber))
                                //...{ border: 'solid', borderColor: '#e9ecef', borderWidth: '1px' }
                                //...{ border: 'solid', borderColor: 'red', borderWidth: '1px' }
                            }
                        }
                        key={index} onClick={() => { this.handleDaySelect(dayNumber) }}>
                        <div className='mx-auto text-center' style={{ background: '#343a40', color: 'white' }}>
                            {dayNumber != 0 ? dayNumber.toString() : null}
                        </div>
                        <div className=' mx-auto text-center' style={{ width: '40px', height: '50px' }}>
                            {this.amISignedUp(dayNumber) ? this.getSignedUpJSX() : null}
                            <br />
                            {this.getSignedUpNr(dayNumber)}
                        </div>
                    </Col>

                return jsxCol;
            }
        );

        const jsx =
            <Row key={(weekDays[0])}>
                {jsxCols}
            </Row>

        return jsx;
    }

    private amISignedUp(dayNumber: number): boolean {
        if (!this.state.myFlightDays) return false;

        const d = this.getFlightDay(dayNumber);
        if (!d) return false;

        const memberFlightDay = this.state.myFlightDays.find(x => MyDateTime.isSameDay(x.arrivalTime, d.day));

        if (memberFlightDay) return true;

        return false;
    }

    private getSignedUpNr(dayNumber: number) {
        if (!this.state.flightDayNumbers) return null;

        const d = this.getFlightDayNumbers(dayNumber) as IFlightDayNumbers;
        if (!d) return null;

        const nrMembers = d.nrMembers;
        const nrInis = d.nrInis;
        const nrExternalInis = d.nrExternalInis;

        if (nrMembers == 0 && nrInis == 0 && nrExternalInis==0 && this.getDayType(dayNumber) == 'nofly') {
            return null;
        }

        return this.getSignedUpNrJSX(nrMembers, nrInis, nrExternalInis);
    }

    private getSignedUpNrJSX(members: number, nrInis: number, nrExternalInis: number) {
        let totalInis = nrExternalInis;
        if (nrInis) {
            totalInis += nrInis
        }


        const jsx =
            <Badge variant='light'>{(members != 0 ? members.toString() : '-') + '/' + (totalInis!= 0 ? totalInis.toString() : '-') }</Badge>

        return jsx;
    }

    private getDayType(n: number) {
        
        const flightDay = this.getFlightDay(n);

        if (!flightDay) return 'nofly';

        
        if (this.state.myDuties) {
            
            const aDuty = this.state.myDuties.find(x => MyDateTime.isSameDay(x.day, flightDay.day));

            if (aDuty) return 'duty';
        }

        if (this.state.flightDayNumbers) {
            const fdn = this.getFlightDayNumbers(n);
            if (fdn && fdn.nrDuties > 0) return 'fly';
        }

        //if (!FlightDay.isEmpty(flightDay)) return 'fly';

        return 'nofly';
    }

    private getDayColor(dayType: 'nofly' | 'fly' | 'duty' | 'none') {
        if (dayType == 'duty') {
            //return { background: '#ffcccc' };
            return { background: '#ffcccc' };
        }
        else if (dayType == 'fly') {
            //return { background: '#e6ffe6' };
            return { background: '#e6faff' };
        }
        else if (dayType == 'nofly') {
            return { background: 'lightgrey' };
        }
        else if (dayType == 'none') {
            return { background: 'transparant' };
        }

        else {
            throw new Error('Unknown flightDay type: ' + dayType)
        }
    }

    private getDayBorder(flightDay: IFlightDay) {
        if (!flightDay) return { border: 'solid', borderColor: '#e9ecef', borderWidth: '1px' }

        if (MyDateTime.isSameDay(flightDay.day, MyDateTime.today)) {
            return { border: 'solid', borderColor: '#00b8e6', borderWidth: '3px' }
        }
        return { border: 'solid', borderColor: '#e9ecef', borderWidth: '1px' }
    }

    private getSignedUpJSX() {
        const jsx =
            <FontAwesomeIcon icon="check" color='green' />
        return jsx;
    }

    private getLegenda() {
        const arr = new Array<Array<JSX.Element>>();

        const nofly = new Array<JSX.Element>();
        nofly.push(
            this.getSquareJSX('nofly')
        );
        nofly.push(<span className='font-italic'>Geen instructeur/sleep piloot ingepland</span>)

        const fly = new Array<JSX.Element>();
        fly.push(
            this.getSquareJSX('fly')
        );
        fly.push(<span className='font-italic'>Instructeur/sleep piloot ingepland</span>)

        const duty = new Array<JSX.Element>();
        duty.push(
            this.getSquareJSX('duty')
        );
        duty.push(<span className='font-italic'>Opgelet! Je staat op de beurtrol</span>)

        const signedUp = new Array<JSX.Element>();
        signedUp.push(
            this.getSignedUpJSX()
        );
        signedUp.push(<span className='font-italic'>Je aanwezigheid is geregistreerd</span>)

        const signedUpNr = new Array<JSX.Element>();
        signedUpNr.push(
            this.getSignedUpNrJSX(17, 3, 1)
        );
        signedUpNr.push(<span className='font-italic'>Aantal leden en initiatie vluchten</span>)

        arr.push(nofly);
        arr.push(fly);
        arr.push(duty);
        arr.push(signedUp);
        arr.push(signedUpNr);

        const jsx = arr.map(
            (row, index) => {
                const jsxRow =
                    <Row className='align-items-center' key={index}>
                        <Col xs={2} className='mx-auto' >
                            {row[0]}
                        </Col>
                        <Col xs={10} className='text-left'>
                            {row[1]}
                        </Col>
                    </Row>
                return jsxRow;
            }
        );

        return <>{jsx}</>;
    }

    private getSquareJSX(dayType: 'nofly' | 'fly' | 'duty'): JSX.Element {
        const jsx =
            <div style={
                {
                    ...this.getDayColor(dayType),
                    ...{ width: '20px', height: '20px' }
                }
            } />

        return jsx;
    }

    //======================== Get Handlers =============================//

    private async handlePrevMonth() {
        let newYear = this.state.year;
        let newMonth = this.state.month;
        if (this.state.month == 1) {
            newYear--;
            newMonth = 12
        }
        else {
            newMonth--;
        }

        this.month = this.createMonthLayout(newYear, newMonth);
        await this.setState({ year: newYear, month: newMonth });
        await this.loadMonthTables();
    }

    private async handleNextMonth() {
        let newYear = this.state.year;
        let newMonth = this.state.month;
        if (this.state.month == 12) {
            newYear++;
            newMonth = 1
        }
        else {
            newMonth++;
        }

        this.month = this.createMonthLayout(newYear, newMonth);
        await this.setState({ year: newYear, month: newMonth });
        await this.loadMonthTables()
    }

    private handleDaySelect(dayNumber: number) {
        if (dayNumber && this.props.onDaySelected) {
            let flightDay = this.getFlightDay(dayNumber);
            if (flightDay) {
                this.props.onDaySelected(flightDay);    
            }
        }
    }

    private createMonthLayout(y: number, m: number) {
        const firstDay = MyDateTime.createDate(y, m, 1);
        const nrDays = firstDay.moment.daysInMonth();
        const dayOfWeek = firstDay.moment.isoWeekday();

        const month = new Array<Array<number>>();

        let currWeek = 0;

        for (let day = 1; day <= nrDays; day++) {
            if (day == 1) {

                const week = Array<number>();
                month.push(week);
                currWeek = 1;

                for (let x = 0; x < dayOfWeek - 1; x++) {
                    week.push(0);
                }
            }

            const prevDay = month[currWeek - 1].length;
            if (prevDay == 7) {
                const week = Array<number>();
                month.push(week);
                currWeek++;
            }

            (month[currWeek - 1]).push(day);

            if (day == nrDays) {
                while (month[currWeek - 1].length < 7) {
                    (month[currWeek - 1]).push(0);
                }
            }
        }

        return month;
    }

    private getFlightDay(nr: Number) {
        if (!this.state.flightDays) return null;

        const flightDay = this.state.flightDays.find(x => x.day.day == nr);

        return flightDay;
    }

    private getFlightDayNumbers(nr: Number) {
        if (!this.state.flightDayNumbers) return null;

        const flightDay = this.state.flightDayNumbers.find(x => x.day.day == nr);

        return flightDay;
    }

   //======================== Get Form Handlers =============================//


    //======================== Get Validation =============================//

    //======================== Data Layer =============================//

    private async loadMonthTables() {
        //only await the first load as a new month needs to be cretated first
        await this.loadFlightDaysAsync(this.state.year, this.state.month);

        this.loadMyMemberFlightDaysAsync(this.state.year, this.state.month);
        this.loadFlightDayNumbersAsync(this.state.year, this.state.month);
        this.loadMyDutiesAsync(this.state.year, this.state.month);
    }


    private async loadFlightDaysAsync(year: number, month: number): Promise<Array<IFlightDay>> {

        this.setState({ isLoading: true });

        const http = new Http();

        const res = await http.getAsyncObject<IFlightDay>('api/flightday/' + year.toString() + '/' + month.toString(), FlightDay) as Array<IFlightDay>;

        if (http.error == null) {
            this.setState({ isLoading: false, flightDays: res as Array<IFlightDay> });
            return (res as Array<IFlightDay>);
        }
        else {
            this.setState({ isLoading: false, flightDays: null });
            ApplicationState.get().setError(http.error);
            return null;
        }
    }

    private async loadFlightDayNumbersAsync(year: number, month: number): Promise<Array<IFlightDayNumbers>> {

        this.setState({ isLoading: true });

        const http = new Http();

        const res = await http.getAsyncObject<IFlightDayNumbers>('api/flightday/numbers/' + year.toString() + '/' + month.toString(), FlightDayNumbers) as Array<IFlightDayNumbers>;

        if (http.error == null) {
            this.setState({ isLoading: false, flightDayNumbers: res as Array<IFlightDayNumbers> });
            return (res as Array<IFlightDayNumbers>);
        }
        else {
            this.setState({ isLoading: false, flightDayNumbers: null });
            ApplicationState.get().setError(http.error);
            return null;
        }
    }

    private async loadMyMemberFlightDaysAsync(year: number, month: number): Promise<Array<IMemberFlightDay>> {

        this.setState({ isLoading: true });

        const http = new Http();

        const res = await http.getAsyncObject<IMemberFlightDay>('api/flightday/my/' + year.toString() + '/' + month.toString(), MemberFlightDay) as Array<IMemberFlightDay>;

        if (http.error == null) {
            this.setState({ isLoading: false, myFlightDays: res as Array<IMemberFlightDay> });
            return (res as Array<IMemberFlightDay>);
        }
        else {
            this.setState({ isLoading: false, myFlightDays: null });
            ApplicationState.get().setError(http.error);
            return null;
        }
    }

    private async loadMyDutiesAsync(year: number, month: number): Promise<Array<IDuty>> {
        this.setState({ isLoading: true });

        const http = new Http();

        const res = await http.getAsyncObject<IDuty>('api/duty/myduties/' + year.toString() + '/' + month.toString(), Duty) as Array<IDuty>;

        if (http.error == null) {
            this.setState({ isLoading: false, myDuties: res as Array<IDuty> });
            return (res as Array<IDuty>);
        }
        else {
            this.setState({ isLoading: false, myDuties: null });
            ApplicationState.get().setError(http.error);
            return null;
        }
    }

    //======================== Lifecycle =============================//
    async componentDidMount() {
        //console.log("mounting ConfirmFlightsDialog");
        this.loadMonthTables();
    }

    componentDidUpdate(prevProps: IFlightDaysCalendarProps) {

    }
}