import * as React from 'react';


//import { Button, ListGroup, ListGroupItem } from 'react-bootstrap'
//import { Alert } from 'react-bootstrap'

import { Jumbotron } from 'react-bootstrap'
import { Row, Col, Badge } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import 'react-bootstrap-typeahead/css/Typeahead-bs4.css';

//import { Tooltip, OverlayTrigger } from 'react-bootstrap'
//import { Styles } from '../HelperClasses/Styles';
//import { CardNavigator } from "./../UIComponents/CardNavigator";
import { InfoOverlay } from "./../UIComponents/InfoOverlay";
import { LoadingSpinner } from '../UIComponents/MySpinners';
import { TrafficLightIcon } from '../UIComponents/TrafficLightIcon';
import { Formatters } from '../HelperClasses/Formatters'
import { MyDateTime, MyTimeSpan } from '../HelperClasses/MyDate';
//import { ICustomError } from "../HelperClasses/CustomError";
import { Http } from "../HelperClasses/Http";
import { ApplicationState } from "../HelperClasses/ApplicationState";
import { Cache } from "../HelperClasses/Cache";

import { Roles } from "./../DTO/Role";
import { IEasaStatus, EasaStatus } from "./../DTO/EasaStatus";
import { IMember } from "./../DTO/Member";
//import { MyDateTime } from '../HelperClasses/MyDate';

import { InstructorLogs } from './../UIComponents/InstructorLogs';


interface IMyFlightStatusProps {
    selectedMember: IMember;
}


interface IMyFlightStatusState {
    currentMember: IMember;
    selectedMember: IMember;
    selectingMember: boolean;

    allMembers: Array<IMember>;

    easaStatusIsLoading: boolean;

    easaStatus: IEasaStatus;
}

export default class MyFlightStatus extends React.Component<IMyFlightStatusProps, IMyFlightStatusState> {


    constructor(props: any) {
        super(props);

        this.state = {
            currentMember: null, 
            selectedMember: null,
            selectingMember: false,
            allMembers: null,
            easaStatusIsLoading: false,
            easaStatus: null
        }

        this.handleMemberChange = this.handleMemberChange.bind(this);
        this.handleStartSelectingMember = this.handleStartSelectingMember.bind(this);
    }

    render() {

        const jsx =
            <LoadingSpinner active={this.state.easaStatusIsLoading} >
                <Jumbotron className='m-0' >
                    {this.getInnerJSX()}
                </Jumbotron>
            </LoadingSpinner>
        return jsx;
    }

    //======================== Get Form JSX elements =============================//

    private getInnerJSX() {
        const jsx = 
            <>
                {this.getTitelJSX()}
                {this.getHeaderJSX()}
                <hr />
                {this.getDashboardJSX()}
                <hr />
                {this.getInstructorLogJSX()}
            </>

        return jsx;
    }

    private getTitelJSX() {
        // className='align-bottom' style={{ fontSize: '1.75rem', fontWeight: 500 }
        const jsx =
            <>

                <Row >
                    <Col xs={3}>
                        <TrafficLightIcon color={this.getFlightStatusColor()} size={4} />
                    </Col>
                    <Col xs={9} className='text-right mt-auto pl-0'>
                        <Row>
                            <Col xs={12} className='pl-0'>
                                {this.maySeeMembersStatus() && this.state.selectingMember ? this.getMemberSelectorJSX() : this.getMemberNameJSX()}
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                {this.getEndValidityDateJSX()}
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <br />
                <span>Huidige status volgens onze gegevens</span>
                <br />
            </>
        return jsx;
    }

    private getHeaderJSX() {

        const jsx =
            <Row style={{ fontWeight: 'bold' }} >
                <Col xs={5} className='text-left'>
                    Easa regel
                    </Col>
                <Col xs={3} className='text-center'>
                    Actueel
                    </Col>
                <Col xs={3} className='text-right'>
                    Vervalt
                    </Col>
                <Col xs={1}>
                    {null}
                </Col>
            </Row>

        return jsx;
    }

    private getDashboardJSX() {
        const jsx = 
            <>
                <Row style={{background: ''}} >
                    <Col xs={1} className='p-2'>
                        <FontAwesomeIcon icon='notes-medical' color='#343a40' className='fa-fw' />
                    </Col>
                    <Col xs={4} className='p-2'>
                        Medical
                    </Col>
                    <Col xs={3} className='text-center p-2'>
                        { null}
                    </Col>
                    <Col xs={3} className='text-right p-2'>
                        {this.state.easaStatus && this.state.easaStatus.medicalExpires && this.state.easaStatus.medicalExpires.isValid ?
                            this.getFormattedDate(this.state.easaStatus.medicalExpires) :
                            this.getEmptyBadge()}
                    </Col>
                    <Col xs={1} className='p-2'>
                        <InfoOverlay tooltip={this.getMedicalRule()} />
                    </Col>
                </Row>

                <Row >
                    <Col xs={1} className='p-2'>
                        <span className="fa-layers fa-fw">
                            <FontAwesomeIcon icon='clock' color='#343a40' />
                            <span className="fa-layers-counter fa-inverse" style={{ backgroundColor: 'blue' }}>5</span>
                        </span>
                    </Col>
                    <Col xs={4} className='p-2'>
                        Min 5 uur PIC
                    </Col>
                    <Col xs={3} className='text-center p-2'>
                        {this.state.easaStatus && this.state.easaStatus.hasLicenseSpl ? Formatters.getShortTimeSpanString(new MyTimeSpan(0,0,this.state.easaStatus.flightMinutes24Months,0)) : '--' }
                    </Col>
                    <Col xs={3} className='text-right p-2'>
                        {this.state.easaStatus && this.state.easaStatus.hasLicenseSpl ? this.getFormattedDate(this.state.easaStatus.endDate5HoursPIC) : '--'}
                    </Col>
                    <Col xs={1} className='p-2'>
                        <InfoOverlay tooltip={this.get5HourRule()} />
                    </Col>
                </Row>

                <Row>
                    <Col xs={1} className='p-2'>
                        <span className="fa-layers fa-fw">
                            <FontAwesomeIcon icon='plane-departure' color='#343a40' />
                            <span className="fa-layers-counter fa-inverse" style={{ backgroundColor: 'blue' }} >15</span>
                        </span>
                    </Col>
                    <Col xs={4} className='p-2'>
                        Min 15 starts
                    </Col>
                    <Col xs={3} className='text-center p-2'>
                        {this.state.easaStatus && this.state.easaStatus.hasLicenseSpl ? this.state.easaStatus.nrStarts24Months.toString() : '--'}
                    </Col>
                    <Col xs={3} className='text-right p-2'>
                        {this.state.easaStatus && this.state.easaStatus.hasLicenseSpl ? this.getFormattedDate(this.state.easaStatus.endDate15Starts) : '--'}
                    </Col>
                    <Col xs={1} className='p-2'>
                        <InfoOverlay tooltip={this.get15StartsRule()} />
                    </Col>
                </Row>

                <Row>
                    <Col xs={1} className='p-2'>
                        <span className="fa-layers fa-fw">
                            <FontAwesomeIcon icon='chalkboard-teacher' color='#343a40' />
                            <span className="fa-layers-counter fa-inverse" style={{ backgroundColor: 'blue' }} >2</span>
                        </span>
                    </Col>
                    <Col xs={4} className='p-2'>
                        Min 2 checks
                    </Col>
                    <Col xs={3} className='text-center p-2'>
                        {this.state.easaStatus && this.state.easaStatus.hasLicenseSpl ? this.state.easaStatus.nrStartsTrainingFlights24Months.toString() : '--'}
                    </Col>
                    <Col xs={3} className='text-right p-2'>
                        {this.state.easaStatus && this.state.easaStatus.hasLicenseSpl ? this.getFormattedDate(this.state.easaStatus.endDateStartTrainingFlights) : '--'}
                    </Col>
                    <Col xs={1} className='p-2'>
                        <InfoOverlay tooltip={this.get2FlightsFIRule()} />
                    </Col>

                </Row>

                <Row>
                    <Col xs={1} className='p-2'>
                        <span className="fa-layers fa-fw">
                            <FontAwesomeIcon icon='calendar' color='#343a40' />
                            <span className="fa-layers-text fa-inverse" data-fa-transform="shrink-6 down-3" style={{ fontWeight: 600 }}>90</span>
                        </span>
                    </Col>
                    <Col xs={4} className='p-2'>
                        Passagiers
                    </Col>
                    <Col xs={3} className='text-center p-2'>
                        {this.state.easaStatus && this.state.easaStatus.hasLicenseSpl && this.state.easaStatus.mayFlyPassenger ? this.state.easaStatus.nrStartsForPassengers90Days.toString() : '--'}
                    </Col>
                    <Col xs={3} className='text-right p-2'>
                        {this.state.easaStatus && this.state.easaStatus.hasLicenseSpl && this.state.easaStatus.mayFlyPassenger ? this.getFormattedDate(this.state.easaStatus.endDate3StartForPassengers) : '--'}
                    </Col>
                    <Col xs={1} className='p-2'>
                            <InfoOverlay tooltip={this.get90DayRule()} />
                    </Col>

                </Row>
            </>

        return jsx;
    }

    private getEndValidityDateJSX() {
        if (!this.state) return '';
        if (!this.state.easaStatus) return 'Geen info';

        if (!this.state.easaStatus.hasLicenseSpl) return 'In opleiding';

        if (this.state.easaStatus.getFlightColor() === 'red') {
            return <span style={{}}>Niet current</span>
        }

        //fontSize: '1.5rem'
        return <span style={{  }}>Current tot {Formatters.getMediumDateString(this.state.easaStatus.firstEndDate)}</span>
    }

    private getFlightStatusColor() {
        if (!this.state) return 'none';
        if (!this.state.easaStatus) return 'red';

        return this.state.easaStatus.getFlightColor();
    }

    private getFormattedDate(aDate: MyDateTime) {
        if (!aDate || !aDate.isValid) return this.getEmptyBadge();

        let variant = 'info';
        let color = {};

        if (aDate.isBeforeDate(MyDateTime.today)) {
            variant = 'danger';
            color = { color: 'white' };
        }
        else if (aDate.isBeforeDate(MyDateTime.today.addDays(30))) {
            variant = 'warning'
            color = { color: 'black' };
        }
        else {
            variant = 'success'
            color = { color: 'black' };
        }

        const jsx =
            <Badge style={color} variant={variant as any}>{Formatters.getShortDateString(aDate)}</Badge>
        return jsx;
    }

    private getEmptyBadge() {
        const jsx =
            <Badge variant='danger'>&nbsp;&nbsp;Geen info&nbsp;&nbsp;</Badge>
        return jsx;
    }

    private getMemberSelectorJSX() {
        if (!this.maySeeMembersStatus()) return null;

        const jsx = 
                        <Typeahead id="lstMembers"
                            positionFixed={true}
                            flip={false}
                            defaultOpen={true}
                            labelKey={(x: IMember) => x ? x.getFullNameReverse() : ''}
                            allowNew={false}
                            emptyLabel="niet gevonden"
                            options={this.state.allMembers ? this.state.allMembers : []}
                            selected={this.state.selectedMember ? [this.state.selectedMember] : []}
                            multiple={false}
                            clearButton={true}
                            placeholder="selecteer..."
                            minLength={0}
                            ref="refMemberSelector"
                            onChange={this.handleMemberChange}
                            isInvalid={!true}
                            disabled={false}
                            inputProps={{ tabIndex: 5 }}
                                    />

        return jsx;
    }

    private getMemberNameJSX() {
        if (!this.state.selectedMember) return null;


        if (this.maySeeMembersStatus()) {
            const jsx =
            <span onClickCapture={this.handleStartSelectingMember}>
                <span style={{ fontSize: '1.5rem' }} >
                    {this.state.selectedMember.getFullName()}
                </span>
                &nbsp;&nbsp;
                <FontAwesomeIcon icon='caret-square-down' size='2x' color='#343a40' />
                </span>
            return jsx;
        }

        const jsx =  
            <span style={{ fontSize: '1.5rem' }} >
                {this.state.selectedMember.getFullName()}
            </span>

        return jsx;
    }

    private getLogHeaderJSX() {

        const jsx =
            <Row style={{ fontWeight: 'bold' }} >
                <Col xs={12} className='text-left'>
                    Instructeurs log
                </Col>
            </Row>

        return jsx;
    }

    private getInstructorLogJSX() {

        if (!this.maySeeMembersStatus()) return null;

        if (!this.state.selectedMember) return null;
        if (!this.state.currentMember) return null;
        if (this.state.selectedMember.id === this.state.currentMember.id) return null;

        const jsx = 
        <>
            {this.getLogHeaderJSX()}
            <InstructorLogs member={this.state.selectedMember} />
            </>

        return jsx;
        
    }

    //======================== App Logic =============================//

    private maySeeMembersStatus(): boolean {

        if (ApplicationState.get().sessionInfo.account.hasRole(Roles.Admin)) return true;
        if (ApplicationState.get().sessionInfo.account.hasRole(Roles.Mngt)) return true;
        
        if (!this.state.currentMember) return false;
        if (!this.state.currentMember.isCurrentlyMember()) return false;

        if (this.state.currentMember.isInstructor) return true;
        if (this.state.currentMember.isExaminator) return true;

        return false;
    }

    //======================== Handlers =============================//

    private async handleMemberChange(selectedOptions: Array<IMember>) {
        let sel = null;
        if (selectedOptions != null && selectedOptions.length > 0) {
            sel = selectedOptions[0];
            this.setState({ selectedMember: sel, selectingMember: false, easaStatus: await this.loadEasaStatus(sel.id) });
        }
        else {
            this.setState({ selectedMember: null, selectingMember: true, easaStatus: null })
        }
    }

    private async handleStartSelectingMember() {
        this.setState({selectedMember: null, selectingMember: true, easaStatus: null})
    }

    //================== Tooltips =======================================================//

    private getMedicalRule() {
        const jsx =
            <>
                Easa regel: <i>Een geldige medische keuring klasse 2/LAPL-S (of hoger) is verplicht</i>
            </>

        return jsx;
    }
    private get5HourRule() {
        const jsx =
            <>
                Easa regel: <i>'Minimum 5 vlieguren als PIC in de voorbije 24 maanden'</i>
            </>

        return jsx;
    }
    private get15StartsRule() {
        const jsx =
            <>
                Easa regel: <i>'Minimum 15 starts als PIC in de voorbije 24 maanden, waarvan minimaal 5 in de beoogde startwijze'</i>
            </>

        return jsx;
    }
    private get2FlightsFIRule() {
        const jsx =
            <>
                Easa regel: <i>'Minimum 2 starts met FI in de voorbije 24 maanden'</i>
            </>

        return jsx;
    }
    private get90DayRule() {
        const jsx =
            <>
                Easa regel: <i>'Om passagiers mee te mogen nemen moet je 3 starts als PIC gemaakt hebben in de afgelopen 90 dagen'</i>
            </>

        return jsx;
    }

    //==================== Data layer =======================================//

    private async loadBaseTables() {
        this.setState({ easaStatusIsLoading: true });

        const easaStatus = this.loadEasaStatus(this.state.currentMember.id);

        let allMembers = null;
        if (this.maySeeMembersStatus())
        {
            allMembers = Cache.loadActiveMembersAsync();
        }
        

        this.setState({         //Promise.all()
            easaStatus: await easaStatus,
            allMembers: await allMembers,
        });

        //console.log(easaStatus);
    }

    private async loadEasaStatus(id: string): Promise<IEasaStatus> {
        if (!id) return null;

        const http = new Http();
        this.setState({ easaStatusIsLoading: true });
        const res = await http.getAsyncObject<IEasaStatus>('api/member/easastatus/' + id, EasaStatus);
        console.log(res)
        if (http.error == null) {
            this.setState({ easaStatusIsLoading: false });
            return (res as EasaStatus);
        }
        else {
            this.setState({ easaStatusIsLoading: false });
            ApplicationState.get().setError(http.error);
            return null;
        }
    }

    async componentDidMount() {
        //ApplicationState.get().onErrorChange.registerHandler(this.handleStateError);

        //const memberId = '8246816A-FD90-401B-8883-A9BEB7923A3A';
        //const memberId = ApplicationState.get().sessionInfo.account.memberId;

        const currMember = ApplicationState.get().sessionInfo.account.member;
        const selMember = this.props.selectedMember ? this.props.selectedMember : currMember;
        await this.setState({ currentMember: currMember, selectedMember: selMember, selectingMember: false })
        await this.loadBaseTables();
    }

    componentWillUnmount() {
        //ApplicationState.get().onErrorChange.unregisterHandler(this.handleStateError);
    }
}