import * as React from 'react';

import { Jumbotron } from 'react-bootstrap'
//import { Card, Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Row, Col } from 'react-bootstrap'

import { LoadingSpinner } from '../UIComponents/MySpinners';
import { Cache } from './../HelperClasses/Cache';
import { ApplicationState } from "./../HelperClasses/ApplicationState";

import { ILibraryCategory } from '../DTO/LibraryCategory';
import { ILibraryDoc } from '../DTO/LibraryDoc';
//import { MyDateTime } from '../HelperClasses/MyDate';

//var NumericInput = require('react-numeric-input');

import { LibraryCard } from './../UIComponents/LibraryCard'


interface ILibraryProps {
}


interface ILibraryState {
    isLoading: boolean;

    isEditMode: boolean;

    allCategories: Array<ILibraryCategory>;
    allDocs: Array<ILibraryDoc>;
}

export default class Library extends React.Component<ILibraryProps, ILibraryState> {


    constructor(props: any) {
        super(props);

        this.state = {
            isLoading: false,
            isEditMode: false,
            allCategories: new Array<ILibraryCategory>(),
            allDocs: new Array<ILibraryDoc>(),
        };

        //this.getMngtMinutesJSX = this.getMngtMinutesJSX.bind(this);
        this.handleEditModeChange = this.handleEditModeChange.bind(this);
        this.handleLibraryChanged = this.handleLibraryChanged.bind(this);
    }

    render() {

        const jsx =
            <>
                <LoadingSpinner active={this.state.isLoading} >

                    <Jumbotron className='m-0'>
                        <Row hidden={!ApplicationState.get().sessionInfo.isAdminOrMngt}>
                            <Col className='col-auto ml-auto'>
                                {this.getEditModeButtonJSX()}
                            </Col>
                        </Row>
                    {this.getLibraryCardsJSX()}
                    </Jumbotron>
                </LoadingSpinner>
            </>
        return jsx;

    }

 //======================== Get Form JSX elements =============================//

    private getLibraryCardsJSX() {

        if (!this.state.allCategories) return null;

        const cards = this.state.allCategories.map( (obj, index) => { 
            const col =
                <Col className='p-2' key={index} >
                    {this.getLibraryCardJSX(obj)}
            </Col>

            return col;

        });

        const jsx = 
            <Row className=''>
                {cards}
            </Row>

        return jsx;
    }

    private getLibraryCardJSX(libraryCategory: ILibraryCategory) {

        const jsx =

            <LibraryCard
                adminMode={ApplicationState.get().sessionInfo.isAdminOrMngt && this.state.isEditMode}
                category={libraryCategory}
                docs={this.state.allDocs.filter(x => x.libraryCategory.id == libraryCategory.id)}
                onChanged={this.handleLibraryChanged}
            />
        return jsx;
    }

    private getEditModeButtonJSX() {
        return <span
            onClick={this.handleEditModeChange}
            style={{ cursor: 'pointer' }} >
            <FontAwesomeIcon icon={this.state.isEditMode ? 'window-close': 'pen' } />
        </span>
    }

//======================== Get Menu Handlers =============================//

    private handleEditModeChange() {
        this.setState({ isEditMode: !this.state.isEditMode });
    }

//======================== Get Form Handlers =============================//

    private handleLibraryChanged() {
        Cache.invalidateLibraryDocs();
        this.LoadBaseTables();
    }


//========================    Data Layer =============================/

    private async LoadBaseTables() {

        await this.setState({isLoading: true});

        this.setState({
            isLoading: false,
            allCategories: await this.loadAllCategories(),
            allDocs: await this.loadAllDocs(),
        });

    }

    private loadAllCategories(): Promise<Array<ILibraryCategory>> {
        return Cache.loadLibraryCategoriesAsync();
    }

    private loadAllDocs(): Promise<Array<ILibraryDoc>> {
        const allDocs = Cache.loadLibraryDocsAsync();

        return allDocs;
    }

//========================  Lifecycle =============================/
    //public handleStateError(error: ICustomError): void {
    //    if (error) {
    //        this.setState({  });
    //    }
    //}

    async componentDidMount() {
        this.LoadBaseTables();
    }

    componentWillUnmount() {
        //ApplicationState.get().onErrorChange.unregisterHandler(this.handleStateError);
    }
}