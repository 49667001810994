import * as React from 'react';
import { IMyTimeSpan, IMyDateTime, MyDateTime } from "./MyDate"



export class Formatters {

    public static toEuro(value: number | null): string {
        
        if (value == null || value == undefined || value == 0) return "0.00" + " €";

        //console.log("Formatting: " + value);

        const x = new Number(value);
        return x.toFixed(2) + " €";
    }

    public static toKM(value: number | null): string {
        //console.log("Formatting: " + value);
        if (value == null || value == undefined || value == 0) return "0" + " km";

        const x = new Number(value);
        return x.toFixed(0) + " Km";
    }

    public static toKg(value: number | null): string {
        //console.log("Formatting: " + value);
        if (value == null || value == undefined || value == 0) return "0" + " Kg";

        const x = new Number(value);
        return x.toFixed(0) + " Kg";
    }

    public static tom(value: number | null): string {
        //console.log("Formatting: " + value);
        if (value == null || value == undefined || value == 0) return "0" + " m";

        const x = new Number(value);
        return x.toFixed(0) + " m";
    }

    public static tocm(value: number | null): string {
        //console.log("Formatting: " + value);
        if (value == null || value == undefined || value == 0) return "0" + " m";

        const x = new Number(value);
        return x.toFixed(2) + " m";
    }

    public static toDegree(value: number | null): string {
        //console.log("Formatting: " + value);
        if (value == null || value == undefined || value == 0) return "0" + " °";

        const x = new Number(value);
        return x.toFixed(0) + " °";
    }

    public static toKmHr(value: number | null): string {
        //console.log("Formatting: " + value);
        if (value == null || value == undefined || value == 0) return "0" + " km/h";

        const x = new Number(value);
        return x.toFixed(0) + " km/h";
    }

    public static toms(value: number | null): string {
        //console.log("Formatting: " + value);
        if (value == null || value == undefined || value == 0) return "0" + " m/s";

        const x = new Number(value);
        return x.toFixed(1) + " m/s";
    }

    public static toPercentage(value: number | null): string {
        //console.log("Formatting: " + value);
        if (value == null || value == undefined || value == 0) return "0.00" + " %";

        const x = new Number(value);
        return x.toFixed(2) + " %";
    }

    public static getShortTimeSpanString(timespan: IMyTimeSpan): string {
        
        if (!timespan || !timespan.isValid) return "--:--";

        return (timespan.days*24 + timespan.hours).toString() + ":" + timespan.minutes.toString().padStart(2, '0');
    }

    //Formatters
    public static getMediumDateString(dateTime: IMyDateTime): string {
        return (dateTime && dateTime.isValid ? dateTime.moment.format("LL") : null);
    }
    public static getShortDateString(dateTime: IMyDateTime): string {
        return (dateTime && dateTime.isValid ? dateTime.moment.format("L") : null);
    }
    public static getShortMonthDayString(dateTime: IMyDateTime): string {
        return (dateTime && dateTime.isValid ? dateTime.moment.format("DD/MM") : null);
    }
    public static getMediumMonthDayString(dateTime: IMyDateTime): string {
        return (dateTime && dateTime.isValid ? dateTime.moment.format("DD MMM") : null);
    }
    public static getLongMonthDayString(dateTime: IMyDateTime): string {
        return (dateTime && dateTime.isValid ? dateTime.moment.format("DD MMMM") : null);
    }
    public static getDayOfWeekLong(dateTime: IMyDateTime): string {
        return (dateTime && dateTime.isValid ? dateTime.moment.format("dddd") : null);
    }
    public static getDayOfWeekShort(dateTime: IMyDateTime): string {
        return (dateTime && dateTime.isValid ? dateTime.moment.format("ddd") : null);
    }

    public static getYearMonthString(year: number, month: number) {
        const my = MyDateTime.createDate(year, month, 1);
        return my.moment.format("MMMM YYYY");
    }

    public static getShortTimeString(dateTime: IMyDateTime): string {
        return (dateTime && dateTime.isValid ? dateTime.moment.format("HH:mm") : "--:--");
    }

    public static getShortDateTimeString(dateTime: IMyDateTime): string {
        return (dateTime && dateTime.isValid ? dateTime.moment.format("DD/MM/YYYY HH:mm") : "--/--/---- --:--");
    }

    public static formatEmail(emailAddress: string) : JSX.Element {

        const adres = "mailto:" + emailAddress;
        const jsx = <a href={adres}> {emailAddress} </a>

        return jsx;
    }

}