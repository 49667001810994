
import { MyDateTime } from "./../HelperClasses/MyDate";
import { ISerializable } from './ISerializable';
import { IValidation, IValidationState } from './IValidation'
//import moment = require('moment');

//import { IMemberFlightDay, MemberFlightDay } from "./MemberFlightDay"

export interface IConditions {
    index: number;
    code: string;
}

export interface IFlightDay extends ISerializable<IFlightDay>, IValidation {
    
    id: string;
    day: MyDateTime;
    sunrise: MyDateTime;
    sunset: MyDateTime;
    startTime : MyDateTime;
    conditionsInstruction: number;
    conditionsXc: number;
    avgLift : number;
    xcSpeed : number;
    cloudbase : number;
    visibility : number;
    windDirection : number;
    wind: number;
    nrExternalInis: number;
    remarks: string;
    created: MyDateTime;
    lastModified: MyDateTime;
    lastModifiedBy: string;

    //memberFlightDay: Array<IMemberFlightDay>;

    //getNrPassengerFlights(): number;
}

export interface IFlightDayValidationState extends IValidationState {       
    dayIsValid: boolean;
}

export class FlightDay implements IFlightDay {

    public id: string = null;
    public day: MyDateTime = null;
    public sunrise: MyDateTime = null;
    public sunset: MyDateTime = null;
    public startTime: MyDateTime = null;
    public conditionsInstruction: number = 0;
    public conditionsXc: number = 0;
    public avgLift: number = null;
    public xcSpeed: number = null;
    public cloudbase: number = null;
    public visibility: number = null;
    public windDirection: number = null;
    public wind: number = null;
    public nrExternalInis: number = 0;
    public remarks: string = null;
    public created: MyDateTime = null;
    public lastModified: MyDateTime = null;
    public lastModifiedBy: string = null;

    //memberFlightDay: Array<IMemberFlightDay> = new Array<IMemberFlightDay>();

    static Conditions: Array<IConditions> =
        [
            { index: 0, code: "slecht" },
            { index: 1, code: "matig" },
            { index: 2, code: "goed" },
            { index: 3, code: "zeer goed" },
            { index: 4, code: "hammerwetter!" },
        ];

    //= new Array<IConditions>();

    constructor() {
    }

    //public getNrPassengerFlights() {
    //    let nrInis = 0;
    //    for (let mfd of this.memberFlightDay) {
    //        nrInis = nrInis + mfd.nrPassengerFlights;
    //    }
    //    return nrInis;
    //}

    public static isEmpty(flightDay: IFlightDay) : boolean {
        if (flightDay.startTime) return false;
        if (flightDay.conditionsInstruction ) return false;
        if (flightDay.conditionsXc) return false;
        if (flightDay.avgLift) return false;
        if (flightDay.xcSpeed) return false;
        if (flightDay.cloudbase) return false;
        if (flightDay.visibility) return false;
        if (flightDay.windDirection) return false;
        if (flightDay.wind) return false;
        if (flightDay.remarks) return false;

        return true;
    }

    public getValidationState(): IFlightDayValidationState {
        //console.log("Validation");
        //console.log(this.costPerMinute);

        let vs = {
            dayIsValid: this.day ? this.day.isValid : false,
            stateIsValid: false
        } as IFlightDayValidationState;

        vs.stateIsValid = (vs.dayIsValid); 

        return vs;
    }



    public fromJson(json: Object): FlightDay {
        //DO NOT USE 'this.' RETURN A NEW OBJECT
        //console.log("Aircraft.fromJson");

        const trueObject = new FlightDay();
        Object.assign<IFlightDay, Object>(trueObject, json);

        //trueObject.key = trueObject.id;

        //Overloads
        trueObject.day = MyDateTime.fromJson(trueObject.day);
        trueObject.sunrise = MyDateTime.fromJson(trueObject.sunrise);
        trueObject.sunset = MyDateTime.fromJson(trueObject.sunset);
        trueObject.startTime = MyDateTime.fromJson(trueObject.startTime);
        trueObject.created = MyDateTime.fromJson(trueObject.created);
        trueObject.lastModified = MyDateTime.fromJson(trueObject.lastModified);

        //Objects
        //let objs = new Array<IMemberFlightDay>();
        //trueObject.memberFlightDay.forEach(
        //    (json) => {
        //        objs.push((new MemberFlightDay()).fromJson(json));
        //    }
        //)
        //trueObject.memberFlightDay = objs;

        //console.log(trueObject);
        return trueObject;
    }


    public toJson(): Object {
        //console.log("Aircraft.toJson");

        let x = JSON.parse(JSON.stringify(this));

        //Overloads

        if (this.day) x.day = this.day.toJson();
        if (this.sunrise) x.sunrise = this.sunrise.toJson();
        if (this.sunset) x.sunset = this.sunset.toJson();
        if (this.startTime) x.startTime = this.startTime.toJson();
        if (this.created) x.created = this.created.toJson();
        if (this.lastModified) x.lastModified = this.lastModified.toJson();


        //Objects
        //let jsonObjects = new Array<object>();
        //this.memberFlightDay.forEach(
        //    (aMemberFlightDay) => {
        //        jsonObjects.push(aMemberFlightDay.toJson());
        //    })
        //x.memberFlightDay = jsonObjects;

        //console.log(x);
        return x;
    }
}