import { MyDateTime } from "./../HelperClasses/MyDate";
import { ISerializable } from './ISerializable';
import { IValidation, IValidationState } from './IValidation'
import { ISex } from "./Sex"
import { ICountry } from "./Country"
import { IMembership, Membership } from "./Membership"
import { EmailHelpers } from './../HelperClasses/EmailHelpers'
import { RRN } from './../HelperClasses/RRN'


export interface IMember extends ISerializable<IMember>, IValidation {
    id: string;
    oldId: string;
    membershipNr: number;
    firstName: string;
    surName: string;
    address: string;
    zip: string;
    city: string;
    tel: string;
    gsm: string;
    email: string;
    contactGsm: string;
    contactEmail: string;
    dateBirth: MyDateTime;
    placeBirth: string;
    nationalNr: string;
    licenseNr: string;
    medicalExpires: MyDateTime;
    

    isSolo: boolean;
    hasLicenseSpl: boolean;
    hasLicenseTmg: boolean;
    hasLicensePpl: boolean;
    mayFlyPassenger: boolean;
    isInstructor: boolean;
    isExaminator: boolean;
    isTowPilot: boolean;

    country: ICountry;
    nationality: ICountry;
    sex: ISex;

    membership: Array<IMembership>;

    profilePictureUrl: string;
    shareEmail: boolean;
    shareGsm: boolean;

    getFullName(): string;
    getFullNameReverse(): string;
    getMembershipNrFormatted(): string;

    isCurrentlyMember(): boolean;
}

export interface IMemberValidationState extends IValidationState {       
     
    membershipNrIsValid: boolean;
    firstNameIsValid: boolean;
    surNameIsValid: boolean;
    addressIsValid: boolean;
    zipIsValid: boolean;
    cityIsValid: boolean;
    telIsValid: boolean;
    gsmIsValid: boolean;
    emailIsValid: boolean;
    contactGsmIsValid: boolean;
    contactEmailIsValid: boolean;
    dateBirthIsValid: boolean;
    placeBirthIsValid: boolean;
    nationalNrIsValid: boolean;
    licenseNrIsValid: boolean;
    medicalExpiresIsValid: boolean;

    flightCostBalanceIsValid: boolean;

    countryIsValid: boolean;
    nationalityIsValid: boolean;
    sexIsValid: boolean;

}

export class Member implements IMember {
    readonly id: string = null;
    oldId: string = null;
    membershipNr: number = null;
    firstName: string = null;
    surName: string = null;
    address: string = null;
    zip: string = null;
    city: string = null;
    tel: string = null;
    gsm: string = null;
    contactGsm: string = null;
    contactEmail: string = null;
    email: string = null;
    dateBirth: MyDateTime = null;
    placeBirth: string = null;
    nationalNr: string = null;
    licenseNr: string = null;
    medicalExpires: MyDateTime = null;

    isSolo: boolean = false;
    hasLicenseSpl: boolean = false;
    hasLicenseTmg: boolean = false;
    hasLicensePpl: boolean = false;
    mayFlyPassenger: boolean = false;
    isInstructor: boolean = false;
    isExaminator: boolean = false;
    isTowPilot: boolean = false;

    country: ICountry = null;
    nationality: ICountry = null;
    sex: ISex = null;

    membership: Array<IMembership> = new Array<Membership>();

    private isCurrentMember: boolean = null;

    private _profilePictureUrl: string;
    public get profilePictureUrl(): string {
        if (this._profilePictureUrl) {
            return this._profilePictureUrl;
        }
        return '/profilepics/pp-empty.png'
    }
    public set profilePictureUrl(value: string) {
        this._profilePictureUrl = value;
    }

    public shareEmail: boolean;
    public shareGsm: boolean;

    constructor() {
    }

    public getValidationState(): IMemberValidationState {

        let vs = {

            //membershipNrIsValid: (this.membershipNr!=null && this.membershipNr>0),
            firstNameIsValid: (this.firstName != null && this.firstName.length > 0),
            surNameIsValid: (this.surName != null && this.surName.length > 0),
            addressIsValid: true,
            zipIsValid: true,
            cityIsValid: true,
            telIsValid: true,
            gsmIsValid: true,
            emailIsValid: EmailHelpers.isValidEmailAddress(this.email),
            contactGsmIsValid: true,
            contactEmailIsValid: (this.contactEmail ? EmailHelpers.isValidEmailAddress(this.contactEmail) : true),
            dateBirthIsValid: true,
            placeBirthIsValid: true,
            nationalNrIsValid: (this.nationalNr == null || RRN.isValid(this.nationalNr)),
            licenseNrIsValid: true,
            medicalExpiresIsValid: true,
            countryIsValid: true,
            nationalityIsValid: true,
            sexIsValid: (this.sex != null),

            stateIsValid: false
        } as IMemberValidationState;

        vs.stateIsValid = (
            //vs.membershipNrIsValid &&
            vs.firstNameIsValid &&
            vs.surNameIsValid &&
            vs.addressIsValid &&
            vs.zipIsValid &&
            vs.cityIsValid &&
            vs.telIsValid &&
            vs.gsmIsValid &&
            vs.emailIsValid &&
            vs.contactGsmIsValid &&
            vs.contactEmailIsValid &&
            vs.dateBirthIsValid &&
            vs.placeBirthIsValid &&
            vs.nationalNrIsValid &&
            vs.licenseNrIsValid &&
            vs.medicalExpiresIsValid &&
            vs.countryIsValid &&
            vs.nationalityIsValid &&
            vs.sexIsValid ); 

        return vs;
    }


    //Formatters
    public getFullName() {
        return this.replaceNull(this.firstName) + " " + this.replaceNull(this.surName);
    }
    public getFullNameReverse() {
        return this.replaceNull(this.surName) + ", " + this.replaceNull(this.firstName);
    }
    public getMembershipNrFormatted() {
        if (this.membershipNr == null) return "-----";
        
        return this.membershipNr.toString().padStart(4, '0');
    }
    private replaceNull(s: string) {
        if (s == null) return "-----";
        return s;
    }

    //features
    public isCurrentlyMember()
    {
        if (this.getCurrentMembership()) return true;

        //Mayby memberships weren't loaded yet. Trust the isCurrentMember
        return this.isCurrentMember;
    }
    private getCurrentMembership(): IMembership {
        if (this.membership == null) return null;
        if (this.membership.length == 0) return null;
        const currMembership = this.membership.find(x => x.isCurrent());
        return currMembership;
    }


    //Serialization
    public fromJson(json: Object): Member {
        //DO NOT USE 'this.' RETURN A NEW OBJECT
        //console.log("Member.fromJson");

        const trueObject = new Member();
        Object.assign<IMember, Object>(trueObject, json);

        //Overloads
        trueObject.dateBirth = MyDateTime.fromJson(trueObject.dateBirth);
        trueObject.medicalExpires = MyDateTime.fromJson(trueObject.medicalExpires);

        let trueMemberships = new Array<Membership>();
        if (trueObject.membership) {
            trueObject.membership.forEach(
                (json) => {
                    trueMemberships.push((new Membership()).fromJson(json));
                }
            )
        }
        trueObject.membership = trueMemberships;

        //console.log(json);
        //console.log(trueObject);
        return trueObject;
    }


    public toJson(): Object {
        //console.log("Aircraft.toJson");

        let json = JSON.parse(JSON.stringify(this));

        //Overloads
        if (this.dateBirth) {
            json.dateBirth = this.dateBirth.toJson();
        }
        if (this.medicalExpires) {
            json.medicalExpires = this.medicalExpires.toJson();
        }

        let jsonObjects = new Array<object>();
        if (this.membership) {
            this.membership.forEach(
                (aMembership) => {
                    jsonObjects.push(aMembership.toJson());
                })
        }
        json.membership = jsonObjects;

        //console.log(x);
        return json;
    }
}