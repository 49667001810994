import * as React from 'react';


//import { Redirect } from "react-router-dom";

//import Container from 'react-bootstrap/Container';

import { Row, Col } from 'react-bootstrap';

import { Form, Button } from 'react-bootstrap';
//import { InputGroup } from 'react-bootstrap';
import { Card, Accordion, Badge } from 'react-bootstrap';

import 'rc-datepicker/lib/style.css';

import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import 'react-bootstrap-typeahead/css/Typeahead-bs4.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

var NumericInput = require('react-numeric-input');

import { Styles } from './../HelperClasses/Styles';
import { InstructionIcon, XCIcon } from './../UIComponents/MyIcons';

import { ModalResult, FormModus } from './../HelperClasses/Enums';
import { Formatters } from './../HelperClasses/Formatters';

import { ApplicationState } from "./../HelperClasses/ApplicationState";
import { ISessionInfo } from "./../HelperClasses/SessionInfo";

import { MyDateTime } from "../HelperClasses/MyDate";
import { CustomError } from '../HelperClasses/CustomError';
import { TimeField } from './../UIComponents/TimeField';


import { IFlightDay, IFlightDayValidationState, FlightDay, IConditions } from '../DTO/FlightDay';


export interface IForecastFormProps {
    formMode: FormModus;

    entityIn?: IFlightDay;

    onClose: (result: ModalResult, entity: IFlightDay) => void;
}

interface IForecastFormState {
    
    entity: IFlightDay;

    isValidated: boolean;
    validationState: IFlightDayValidationState;
}

export class ForecastForm extends React.Component<IForecastFormProps, IForecastFormState> {

    private entity: IFlightDay;
    constructor(props: any) {
        super(props);

        
        //var initialEntity;
        if (this.props.formMode == FormModus.Edit) {

            if (this.props.entityIn == null) {
                ApplicationState.get().setError(new CustomError("No form entity provided.", null, null, null, null, null));
                return;
            }

            this.entity = Object.assign<FlightDay, IFlightDay>(new FlightDay(), this.props.entityIn);

            if (!this.entity.startTime) {
                this.entity.startTime = MyDateTime.createDateTime(this.entity.day.year, this.entity.day.month, this.entity.day.day, 10, 0, 0);
            }
        }
        else {
            this.entity = new FlightDay();
        }

        //Set initial state
        this.state = {
            entity: this.entity,
            isValidated: false,
            validationState: (this.entity.getValidationState() as IFlightDayValidationState)
        };

        //Form methods
        this.handleSessionChange = this.handleSessionChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleReset = this.handleReset.bind(this);
        this.validateForm = this.validateForm.bind(this);

        this.handleVisibilityChange = this.handleVisibilityChange.bind(this);
        this.handleWindDirectionChange = this.handleWindDirectionChange.bind(this);
        this.handleWindChange = this.handleWindChange.bind(this);
        this.handleCloudbaseChange = this.handleCloudbaseChange.bind(this);
        this.handleAvgLiftChange = this.handleAvgLiftChange.bind(this);
        this.handleStartTimeChange = this.handleStartTimeChange.bind(this);
        this.handleConditionsInstructionChange = this.handleConditionsInstructionChange.bind(this);
        this.handleConditionsXcChange = this.handleConditionsXcChange.bind(this);
        this.handleXcSpeedChange = this.handleXcSpeedChange.bind(this);
        this.handleRemarksChange = this.handleRemarksChange.bind(this);

        //Form Handlers
        //this.handleRegistrationChange = this.handleRegistrationChange.bind(this);
        //this.handleTypeChange = this.handleTypeChange.bind(this);


        //this.handleInsuranceCompanyChange = this.handleInsuranceCompanyChange.bind(this);
        //this.handleInsuranceContractIdChange = this.handleInsuranceContractIdChange.bind(this);
        //this.handleInsuranceValidUntilChange = this.handleInsuranceValidUntilChange.bind(this);

        //this.handleArcValidUntilChange = this.handleArcValidUntilChange.bind(this);

        //this.handleIsClubOwnedChange = this.handleIsClubOwnedChange.bind(this);
        //this.handleIsInUseChange = this.handleIsInUseChange.bind(this);
        //this.handleIsSingleSeateChange = this.handleIsSingleSeateChange.bind(this);


        //this.handleCostPerMinuteChange = this.handleCostPerMinuteChange.bind(this);
    }

    render() {

        let form = this.getForm();

        return (
            <>
                {form}
            </>   
        );
    }

    //======================== Get Form JSX elements =============================//

    private getForm() {
        const form =
            <Form noValidate validated={false} onSubmitCapture={this.handleSubmit} onResetCapture={this.handleReset} className='m-2'>

                {this.getInnerFormJSX()}

                <hr />
                <div style={{ textAlign: 'right' }}>
                    <Button type="reset" variant="secondary" tabIndex={998}>Annuleren</Button>
                    &nbsp;&nbsp;
                    <Button type="submit" tabIndex={999}>Opslaan</Button>
                </div>

            </Form>

        return form;
    }

    private getInnerFormJSX() {

        const jsx = 
            <>
                <h5>
                    <Badge variant="secondary">{this.state.entity? Formatters.getMediumDateString(this.state.entity.day) : null}</Badge>
                </h5>

                <Accordion defaultActiveKey="0" >
                    <Card style={this.accordeonValid()}>
                        <Accordion.Toggle as={Card.Header} eventKey="0">
                            <FontAwesomeIcon inverse={false} size="lg" icon="cloud-sun-rain" />&nbsp;&nbsp;Voorspellingen
                        </Accordion.Toggle>

                        <Accordion.Collapse eventKey="0">
                            <Card.Body>
                                {this.getForecastJSX()}
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>
            </>
        return jsx;
    }
        
    private getForecastJSX() : JSX.Element
    {
        const jsx =
            <>
                {this.getLine1()}
                <hr />
                {this.getLine2()}
            </>
        return jsx; 
    }

    private getLine1() {
        const jsx = 
            <>
            <Row>
                    <Col xs={6}>
                    <Form.Group controlId="frmVisibility" >
                        <Form.Text><FontAwesomeIcon inverse={false} icon="eye" />&nbsp;&nbsp;Zichtbaarheid</Form.Text>
                        <NumericInput min={0} step={1} precision={0} className="form-control"
                            snap
                            value={this.state.entity.visibility ? this.state.entity.visibility : null}
                            onChange={this.handleVisibilityChange}
                            ref='refVisibility'
                            format={Formatters.toKM }
                            tabIndex={1} />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col xs={6}>
                    <Form.Group controlId="frmWindDirection" >
                        <Form.Text><FontAwesomeIcon inverse={false} icon="compass" />&nbsp;&nbsp;Windrichting</Form.Text>
                        <NumericInput min={0} step={10} precision={0} className="form-control"
                            snap
                            value={this.state.entity.windDirection ? this.state.entity.windDirection : null}
                            onChange={this.handleWindDirectionChange}
                            format={Formatters.toDegree}
                            tabIndex={2} />
                        </Form.Group>
                </Col>
                <Col xs={6}>
                    <Form.Group controlId="frmWind" >
                        <Form.Text><FontAwesomeIcon inverse={false} icon="wind" />&nbsp;&nbsp;Windsterkte</Form.Text>
                        <NumericInput min={0} step={1} precision={0} className="form-control"
                            snap
                            value={this.state.entity.wind ? this.state.entity.wind : null}
                            onChange={this.handleWindChange}
                            format={Formatters.toKmHr}
                            tabIndex={3} />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                    <Col xs={6}>
                    <Form.Group controlId="frmCloudbase" >
                        <Form.Text><FontAwesomeIcon inverse={false} icon="cloud" />&nbsp;&nbsp;Wolkenbasis</Form.Text>
                        <NumericInput min={0} step={100} precision={0} className="form-control"
                            snap
                            value={this.state.entity.cloudbase ? this.state.entity.cloudbase : null}
                            onChange={this.handleCloudbaseChange}
                            format={Formatters.tom}
                            tabIndex={4} />
                    </Form.Group>
                </Col>
                    <Col xs={6}>
                    <Form.Group controlId="frmAvgLift" >
                        <Form.Text><FontAwesomeIcon inverse={false} icon="wind" transform='rotate-270' />&nbsp;&nbsp;Thermiek</Form.Text>
                        <NumericInput min={0} step={0.5} precision={1} className="form-control"
                            snap
                            value={this.state.entity.avgLift ? this.state.entity.avgLift : null}
                            onChange={this.handleAvgLiftChange}
                            format={Formatters.toms}
                            tabIndex={5} />
                    </Form.Group>
                </Col>
                </Row>
                </>
        return jsx;
    }

    private getLine2() {
        const jsx =
            <>
            <Row>
                    <Col xs={6}>
                        <Form.Group controlId="frmConditionsInstruction">
                            <Form.Text ><InstructionIcon />
                                <span className='d-none d-sm-inline'>&nbsp;&nbsp;Condities voor lesvluchten</span>
                                <span className='d-inline d-sm-none'>&nbsp;&nbsp;lesvluchten</span>
                            </Form.Text>
                            {this.getConditionsInstructionJSX()}
                        </Form.Group>
                    </Col>

                    <Col xs={6}>
                        <Form.Group controlId="frmStartTime">
                            <Form.Text><FontAwesomeIcon inverse={false} icon="clock" />&nbsp;&nbsp;Starttijd</Form.Text>
                            <TimeField date={this.state.entity.day} time={this.entity.startTime}
                                onChangeCapture={this.handleStartTimeChange}
                                isInvalid={!true && this.state.isValidated}
                                tabIndex={7} />
                        </Form.Group>
                    </Col>
            </Row>
            <Row>

                <Col xs={6}>
                    <Form.Group controlId="frmConditionsXc">
                            <Form.Text><XCIcon />
                                <span className='d-none d-sm-inline'>&nbsp;&nbsp;Condities voor XC vluchten</span>
                                <span className='d-inline d-sm-none'>&nbsp;&nbsp;XC vluchten</span>
                            </Form.Text>
                            {this.getConditionsXcJSX()}
                    </Form.Group>
                </Col>

                <Col xs={6}>
                    <Form.Group controlId="frmXcSpeed" >
                        <Form.Text><FontAwesomeIcon inverse={false} icon="tachometer-alt" />&nbsp;&nbsp;Reissnelheid</Form.Text>
                        <NumericInput min={0} step={1} precision={0} className="form-control"
                            snap
                            value={this.state.entity.xcSpeed ? this.state.entity.xcSpeed : null}
                            onChange={this.handleXcSpeedChange}
                            format={Formatters.toKmHr}
                            tabIndex={9} />
                </Form.Group>
                </Col>
                </Row>
                <Row>
                <Col xs={12}>
                    <Form.Group controlId="frmRemarks">
                        <Form.Text><FontAwesomeIcon inverse={false} icon="clipboard" />&nbsp;&nbsp;Opmerkingen</Form.Text>
                            <Form.Control type="input" rows={3} placeholder="..."
                            value={this.state.entity.remarks ? this.state.entity.remarks : ""}
                            onChangeCapture={this.handleRemarksChange}
                            isInvalid={!true && this.state.isValidated} tabIndex={10} onChange={() => { }} />
                    </Form.Group>
                </Col>

            </Row>
        </>
        return jsx;
    }

    private getConditionsInstructionJSX() {

        const x =
            <Typeahead id="lstConditionsInstruction"
                positionFixed={true}
                flip={true}
                labelKey={(x: IConditions) => x.code}
                allowNew={false}
                emptyLabel="niet gekend"
                options={FlightDay.Conditions}
                selected={this.state.entity.conditionsInstruction!=null ? [FlightDay.Conditions[this.state.entity.conditionsInstruction]] : []}
                multiple={false}
                clearButton={true}
                placeholder="selecteer..."
                minLength={0}
                selectHintOnEnter={true}
                onChange={this.handleConditionsInstructionChange}
                isInvalid={!true && this.state.isValidated}

                inputProps={{ tabIndex: 6 }}
            />

        return x;
    }

    private getConditionsXcJSX() {

        const x =
            <Typeahead id="lstConditionsXc"
                positionFixed={true}
                flip={true}
                labelKey={(x: IConditions) => x.code}
                allowNew={false}
                emptyLabel="niet gekend"
                options={FlightDay.Conditions}
                selected={this.state.entity.conditionsXc != null ? [FlightDay.Conditions[this.state.entity.conditionsXc]] : []}
                multiple={false}
                clearButton={true}
                placeholder="selecteer..."
                minLength={0}
                selectHintOnEnter={true}
                onChange={this.handleConditionsXcChange}
                isInvalid={!true && this.state.isValidated}

                inputProps={{ tabIndex: 8 }}
            />

        return x;
    }

    //======================== Form Validation =============================//

    private handleVisibilityChange(value: number | null, stringValue: string, input: HTMLInputElement) {
        this.entity.visibility = value;
        this.setState({ entity: this.entity });
    }
    
    private handleWindDirectionChange(value: number | null, stringValue: string, input: HTMLInputElement) {
        this.entity.windDirection = value;
        this.setState({ entity: this.entity });
    }

    private handleWindChange(value: number | null, stringValue: string, input: HTMLInputElement) {
        this.entity.wind = value;
        this.setState({ entity: this.entity });
    }

    
    private handleCloudbaseChange(value: number | null, stringValue: string, input: HTMLInputElement) {
        this.entity.cloudbase = value;
        this.setState({ entity: this.entity });
    }

    private handleAvgLiftChange(value: number | null, stringValue: string, input: HTMLInputElement) {
        this.entity.avgLift = value;
        this.setState({ entity: this.entity });
    }


    private handleStartTimeChange(time: MyDateTime) {
        this.entity.startTime = time;
        this.setState({ entity: this.entity })
    }

    private handleConditionsInstructionChange(selectedOptions: Array<IConditions>) {
        if (selectedOptions != null && selectedOptions.length > 0) {
            this.entity.conditionsInstruction = selectedOptions[0].index;
        }
        else {
            this.entity.conditionsInstruction = null;
        }
        this.setState({ entity: this.entity })
    }

    private handleConditionsXcChange(selectedOptions: Array<IConditions>) {
        if (selectedOptions != null && selectedOptions.length > 0) {
            this.entity.conditionsXc = selectedOptions[0].index;
        }
        else {
            this.entity.conditionsXc = null;
        }
        this.setState({ entity: this.entity })
    }

    private handleXcSpeedChange(value: number | null, stringValue: string, input: HTMLInputElement) {
        this.entity.xcSpeed = value;
        this.setState({ entity: this.entity });
    }

    private handleRemarksChange(event: React.ChangeEvent<HTMLInputElement>) {
        const x = event.currentTarget.value;
        this.state.entity.remarks = x;
        this.setState({ entity: this.entity });
    }
    //======================== Form Validation =============================//

    private accordeonValid() {
        let vs = this.entity.getValidationState() as IFlightDayValidationState;
        return !vs.stateIsValid && this.state.isValidated ?
            Styles.getDivErrorStyle() : {};
    }

    private validateForm() {

        let vs = this.entity.getValidationState() as IFlightDayValidationState;

        this.setState({validationState: vs});

        //console.log("Valid: " + vs.isValid.toString());
        return vs.stateIsValid;
    }

    private handleReset() {
        event.preventDefault();
        event.stopPropagation();
        this.setState({ isValidated: false });

        this.props.onClose(ModalResult.Cancel, null);
    }

    private async handleSubmit(event: React.FormEvent<HTMLFormElement>) {

        event.preventDefault();
        event.stopPropagation();

        this.setState({ isValidated: true });  //toggle the validation for all controls
        // this.validateForm() && event.currentTarget.checkValidity() //checkValidity checks the required fields but we need to set invalid manually so makes no sense to use
        
        if (this.validateForm()) {
            //console.log("Closing AircraftForm")
            this.props.onClose(ModalResult.OK, this.entity);
        }
    }

    //======================== Data Layer =============================//

    private async loadBaseTables() {

    }


    //======================== Lifecycle =============================//

    handleSessionChange = (sessionInfo: ISessionInfo) => {
    }

    async componentDidMount() {
        //console.log("mounting AircraftForm");
        ApplicationState.get().sessionInfo.onChange.registerHandler(this.handleSessionChange);

        this.loadBaseTables();

        ((this.refs.refVisibility as any).refsInput as HTMLInputElement).focus();
    }

    componentWillUnmount() {
        //console.log("unmounting AircraftForm");
        ApplicationState.get().sessionInfo.onChange.unregisterHandler(this.handleSessionChange);
    }
}
