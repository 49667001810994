import * as React from 'react';
import { Alert } from 'react-bootstrap';
import LoadingOverlay from 'react-loading-overlay';

import { PacmanLoader } from 'react-spinners';
import { PulseLoader } from 'react-spinners';


//=========================================================================================//

export interface IMySpinnerProps {
    active: boolean;
    children: JSX.Element;
}


export const DotsSpinner: React.SFC<any> = (props: IMySpinnerProps) => {

    const spinner = <PulseLoader size={15} color='#FFFFFF' />

    const jsx =
        <>

            <LoadingOverlay
                active={props.active ? props.active : false}
                spinner={spinner}
                styles={{
                    overlay: (base: any) => ({
                        ...base,
                        background: 'rgba(10,72, 116, 0.70)'
                    })
                }}
            >
                {props.children}
            </LoadingOverlay>

        </>

    return jsx;

}

export const LoadingSpinner: React.SFC<any> = (props: IMySpinnerProps) => {

    const loadingText =
        <div>
            <br />
            <br />
            <Alert variant='primary'>
                Moment geduld aub ...
            </Alert>
        </div>

    const spinner = <PacmanLoader color='#428bca' />

    const overlay = <LoadingOverlay
        active={props.active ? props.active : false}
        spinner={spinner}
        text={loadingText}

    >
        {props.children}
    </LoadingOverlay>

    return overlay;
}

