import * as React from 'react';

import { Redirect } from "react-router-dom";

//import Container from 'react-bootstrap/Container';

import { Jumbotron } from 'react-bootstrap';

import { Form, FormControl, Button } from "react-bootstrap";
import { Row } from "react-bootstrap";

import { ApplicationState } from "../HelperClasses/ApplicationState";
//import { ISessionInfo } from "../HelperClasses/SessionInfo";
import { IAccount } from "../DTO/Account";
import { Http } from '../HelperClasses/Http';
import { Errors } from '../HelperClasses/Errors';
import { EmailHelpers } from '../HelperClasses/EmailHelpers';

import { ModalResult, ModalButtons } from './../HelperClasses/Enums';
import { SimpleModal } from '../UIComponents/SimpleModal';

import { LoadingSpinner } from '../UIComponents/MySpinners';

//import { Styles } from "./../HelperClasses/Styles";

//import { CustomError } from "./../HelperClasses/CustomError";
 

interface ILoginProps {


}
interface ILoginState {
    isLoading: boolean;

    email: string;
    password: string;

    isValidated: boolean;
    logonFailed: boolean;
    logonFailureMsg: JSX.Element | string;

    account: IAccount;

    showPwdForgottenModal: boolean;
    pwdForgottenModalBodyText: JSX.Element;
    pwdResetSent: boolean;
}




export default class Login extends React.Component<ILoginProps, ILoginState> {

    constructor(props: any) {
        super(props);

        this.state = { isLoading: false, email: "", password: "", isValidated: false, account: ApplicationState.get().sessionInfo.account, logonFailed: false, logonFailureMsg:null, showPwdForgottenModal: false, pwdForgottenModalBodyText: null, pwdResetSent: false };

        //this.handleSessionChange = this.handleSessionChange.bind(this);

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        this.validateForm = this.validateForm.bind(this);

        this.handleCloseError = this.handleCloseError.bind(this);

        this.handlePwdForgotten = this.handlePwdForgotten.bind(this);
        this.handlePwdForgottenModalAsync = this.handlePwdForgottenModalAsync.bind(this);
    }

    render() {

        if (this.state.account) {
            return <Redirect to="/1/" />
        }

        return this.getForm();
    }


    private getForm() {
        const form =
            <Form noValidate validated={false} onSubmitCapture={this.handleSubmit} autoComplete='on' >
                {this.getInnerFormJSX()}
            </Form>

        let PwdForgottenModal = null;
        if (this.state.showPwdForgottenModal == true) {
            PwdForgottenModal = this.getPwdForgottenJSX();
        }


        return (
            <>
                {PwdForgottenModal}
                {form}
            </>

        );
    }

    private getInnerFormJSX() {
        const jsx =
            <>
                <LoadingSpinner active={this.state.isLoading } >
                <Jumbotron className='m-0'>

                    <Row className='justify-content-center align-items-center'>
                        <h4> Welkom bij <b>eSoar</b>!</h4>
                        <br /><br />
                    </Row>
                    <Row className='justify-content-center align-items-center'>

                            <Form.Group controlId="ctrlEmail" style={{ width: '15rem' }} >
                                <Form.Text >Email</Form.Text>
                                <FormControl required autoFocus
                                    type="email" placeholder="Email adres"
                                    value={this.state.email}
                                    isInvalid={!EmailHelpers.isValidEmailAddress(this.state.email) && this.state.isValidated}
                                    onChangeCapture={this.handleEmailChange}
                                    onChange={() => { }}
                                    tabIndex={1}/>
                                <Form.Control.Feedback type="invalid">Geen geldig email adres</Form.Control.Feedback>
                            </Form.Group>

                    </Row>

                    <Row className="justify-content-center align-items-center mb-2">
                            <Form.Group controlId="ctrlPassword" style={{ width: '15rem' }}>
                                <Form.Text>Wachtwoord</Form.Text>
                                <FormControl required type="password"
                                    placeholder="Wachtwoord"
                                    value={this.state.password}
                                    isInvalid={this.state.password.length == 0 && this.state.isValidated}
                                    onChangeCapture={this.handlePasswordChange}
                                    onChange={() => { }}
                                    tabIndex={2}
                                    />
                            </Form.Group>
                    </Row>

                    <Row className="justify-content-center align-items-center mb-4">
                        <Form.Group controlId="login" style={{ width: '15rem' }}>
                            <Button type="submit" tabIndex={3} style={{ width: '15rem' }} >Aanmelden</Button>
                            <Form.Text hidden={!this.state.logonFailed}>{this.state.logonFailureMsg ? this.state.logonFailureMsg:""}</Form.Text>
                        </Form.Group>
                    </Row>

                    <Row className="justify-content-center align-items-center">
                        <Form.Group controlId="resetPassword" style={{ width: '15rem' }}>
                            <Button variant="outline-primary" tabIndex={4} style={{ width: '15rem' }}
                                onClick={this.handlePwdForgotten}>Wachtwoord vergeten?</Button>
                            <Form.Text hidden={!this.state.pwdResetSent} >Uw aanvraag werd verstuurd.</Form.Text>
                        </Form.Group>
                    </Row >

                </Jumbotron>
                </LoadingSpinner>
            </>

        return jsx;
    }

    private getPwdForgottenJSX() {
        const jsx = 
            <SimpleModal
                headingText="Wachtwoord vergeten"
                bodyText={this.state.pwdForgottenModalBodyText}
                onClose={this.handlePwdForgottenModalAsync}
                buttons={ModalButtons.OKCancel} />

        return jsx;
    }

    //====================== Form Handlers ==================//
    private handleCloseError() {
        this.setState({ logonFailed: false });
    }

    private handlePwdForgotten() {


        if (!EmailHelpers.isValidEmailAddress(this.state.email)) {
            //this.setState({ isValidated: true });

            const jsx = <>Ongeldig email adres.<br /><br />Geef eerst het email adres in dat u gebruikt om in te loggen;</>
            this.setState({ pwdForgottenModalBodyText: jsx, pwdResetSent: false });
        }
        else {
            const jsx = <>Indien het email adres '{this.state.email}' bij ons bekend is zult u een email ontvangen met daarin de instructies om uw wachtwoord te resetten.</>
            this.setState({ pwdForgottenModalBodyText: jsx, pwdResetSent: false });
        }

        this.setState({ showPwdForgottenModal: true });
    }

    private async handlePwdForgottenModalAsync(result: ModalResult) {

        if (result == ModalResult.OK && EmailHelpers.isValidEmailAddress(this.state.email) ) {
            await this.requestPwdReset(this.state.email);

            this.setState({ pwdResetSent: true})
        }
        this.setState({ showPwdForgottenModal: false, email: "", password: "", isValidated: false });
    }

    private handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let x = event.currentTarget.value
        this.setState({ email: x });     
    }

    private handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let x = event.currentTarget.value;
        this.setState({ password: x });        
    }

    //====================== Form validation ==================//

    validateForm() {
        return EmailHelpers.isValidEmailAddress(this.state.email); //&& this.state.password.length>0;
    }

    private async handleSubmit(event: React.FormEvent<HTMLFormElement>){

        event.preventDefault();
        event.stopPropagation();

        this.setState({ isValidated: true, logonFailed: false, logonFailureMsg: null });

        if (this.validateForm()) {

            await this.setState({ isLoading: true })

            let error = await ApplicationState.get().sessionInfo.logOnAsync(this.state.email, this.state.password);
            //Add ERROR HANDLING IN CASE 555
            if (error) {
                if (error.isHttpCustomError()) {
                    //https://stackoverflow.com/questions/33688208/webapi2-return-simple-string-without-quotation-mark

                    const errString = Errors.fromCustomError(error.customErrorNr, error.customErrorData);

                    await this.setState({ isLoading: false, password: "", logonFailed: true, logonFailureMsg: errString });
                }
                else {
                    ApplicationState.get().setError(error);
                    await this.setState({ isLoading: false,logonFailed: true });
                }
            }
            else {
                await this.setState({ isLoading: false, account: ApplicationState.get().sessionInfo.account, logonFailed: false });
            }
        }
    }



    validateCredentials() {
        return true;
    }

    //===================== Data Layer ==============================

    private async requestPwdReset(emailAddress: string): Promise<boolean> {

        if (!EmailHelpers.isValidEmailAddress(emailAddress)) return false;

        //this.setState({ isLoading: true })

        const http = new Http();
        const res = await http.postAsyncPrimitive<boolean>("api/account/requestpwdreset", emailAddress);

        //this.setState({ isLoading: false })

        if (http.error == null) {
            return res;
        }
        else {
            ApplicationState.get().setError(http.error);
            return false;
        }
    }


    //===================== Life cycle ==============================

    //handleSessionChange(sessionInfo: ISessionInfo) {       
    //    //this.setState({ account: sessionInfo.account } );
    //}

    componentDidMount() {
        //console.log("mounting login");
        //ApplicationState.get().sessionInfo.onChange.registerHandler(this.handleSessionChange);
    }

    componentWillUnmount() {
        //console.log("unmounting login");
        //ApplicationState.get().sessionInfo.onChange.unregisterHandler(this.handleSessionChange);
    }
}