import * as React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Styles } from "./../HelperClasses/Styles";


//=========================================================================================//
//Try to mimic the font awsomeprops
export interface IMyIconProps {
    size?: number;
    color?: string;
}


//=========================================================================================//
export const XCIcon: React.SFC<any> = (props) => {
    const jsx =
        <span className="fa-layers fa-fw">
            <FontAwesomeIcon icon='plane' transform='shrink-4 left-5 down-4' />
            <FontAwesomeIcon icon='globe' transform='shrink-4 right-5 up-4' />
        </span>

    return jsx;

}



//=========================================================================================//
export interface IInstructionIconProps   {

    
}

export const InstructionIcon: React.SFC<IInstructionIconProps> = (props) => {
    return getInstructionIcon(props);
}

const getInstructionIcon = (props: IInstructionIconProps): JSX.Element => {
    const jsx =
        <span className="fa-layers fa-fw">
            <FontAwesomeIcon icon='plane' transform='shrink-4 left-5 down-4' />
            <FontAwesomeIcon icon='chalkboard-teacher' transform='shrink-4 right-5 up-4' />
        </span>

    return jsx;
}

//=========================================================================================//

export interface IPaidIconProps {

    isPaid: boolean;
}

export const PaidIcon: React.SFC<IPaidIconProps> = (props) => {
    return getPaidIcon(props.isPaid);
}

const getPaidIcon = (paid: boolean): JSX.Element => {
    if (paid) {
        return <FontAwesomeIcon icon="coins" inverse style={Styles.getOKColorStyle()} />
    }
    else {
        return <span className="fa-layers fa-fw">
            <FontAwesomeIcon icon="slash" style={Styles.getErrorColorStyle()} />
            <FontAwesomeIcon icon="coins" style={Styles.getErrorColorStyle()} />
        </span>
        //return <FontAwesomeIcon icon="coins" inverse style={Styles.getFontErrorStyle()} />
    }
}

//=========================================================================================//


export const EventIcon: React.SFC<IMyIconProps> = (props) => {
    return getEventIcon(props);
}

const getEventIcon = (props: IMyIconProps): JSX.Element => {
    let style = 'fa-layers fa-fw'
    if (props.size) {
        style = style + ' fa-' + props.size.toString() + 'x';
    }
    const jsx =
        <span className={style} >
        <FontAwesomeIcon icon='arrow-left' transform='rotate-45 shrink-10 right-5 down-5 ' color={props.color ? props.color:''} />
            <FontAwesomeIcon icon='arrow-left' transform='rotate-135 shrink-10 left-5 down-5 ' color={props.color ? props.color : ''} />
            <FontAwesomeIcon icon='arrow-left' transform='rotate-225 shrink-10 left-5 up-5' color={props.color ? props.color : ''} />
            <FontAwesomeIcon icon='arrow-left' transform='rotate-315 shrink-10 right-5 up-5 ' color={props.color ? props.color : ''} />
            <FontAwesomeIcon icon='users' transform='shrink-10 ' color={props.color ? props.color : ''} />
        </span>

    return jsx;
}