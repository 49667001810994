
import * as React from 'react';
import { RouteComponentProps, withRouter } from "react-router-dom";

import { CustomError } from "./CustomError"

export enum Sections { home = 0, myEsoar = 1, secretariat = 2, camp = 3, siteAdmin = 4, unknown = 99 }

export enum Modules {
    nomodule = "",

    login = "login",
    resetpwd = "resetpwd",
    redirect="redirect",

    myAccount = "myAccount",
    mySettings = "mySettings",

    myFlightStatus = "myFlightStatus",
    myCostsOverview = "myCostsOverview",
    myFlights = "myFlights",
    myPersonalData = "myPersonalData",
    flightDays = "flightDays",
    contacts = "contacts",
    myEvents = "myevents",
    library= "library",

    flights = "flights",
    members = "members",
    aircrafts = "aircrafts",
    cashbook = "cashbook",
    emails = "emails",
    reports = "reports",
    events = "events",

    accounts = "accounts",
    tools = "tools",
    about = "about",

    participants = "participants",
    meals = "meals"
}

export interface IRouting {


}

export class Routing implements IRouting {

    private static _routing: Routing = null;

    private routingProps: RouteComponentProps;

    constructor() {
        if (Routing._routing != null) {
            throw new CustomError("Cannot create instance of Routing. use Routing.get() instead", null, null, null, null, null);
        }

        Routing._routing = this;
    }

    public static createOnce(): void {
        new Routing();
    }

    public static setRoutingProps(props: RouteComponentProps) {

        if (Routing._routing == null) {
            Routing.createOnce();
        }
        Routing._routing.routingProps = props;
        //console.log("setProps")
        //console.log(Routing._routing.routingProps.location.pathname)
    }

    public static get(): Routing {
        return Routing._routing;
    };

    //==========================================================//

    public static getSection(): Sections {
        var parts = this.splitPathname(Routing._routing.routingProps.location.pathname);

        if (parts.length == 0) return Sections.home;

        const s = Number.parseInt(parts[0]);

        if (isNaN(s)) return Sections.home;

        switch (s) {
            case 0: return Sections.home;
            case 1: return Sections.myEsoar;
            case 2: return Sections.secretariat;
            case 3: return Sections.camp;
            case 4: return Sections.siteAdmin;

            default: return Sections.unknown;
        }
    }

    public static getModule(): Modules {

        var parts = this.splitPathname(Routing._routing.routingProps.location.pathname);
        if (parts.length <= 1) return Modules.nomodule;
        return (parts[1] as Modules);
    }

    public static getPath(section: Sections, module?: Modules) {
        if (section == Sections.home) {

            if (module === undefined || module == Modules.nomodule) {
                return "/";
            }

            return "/" + module.toString();
        }


        if (module === undefined || module == Modules.nomodule) {
            return "/" + section.toString() + "/";
        }

        return "/" + section.toString() + "/" + module.toString();
    }

    public static navigateTo(section: Sections, module: Modules) {

       if (section == null) {
            Routing._routing.routingProps.history.push('/');
        }
        else {
            Routing._routing.routingProps.history.push(this.getPath(section, module));
        }

    }

    public static getSectionName(section: Sections): string {
        switch (section) {
            case Sections.myEsoar: return "My eSoar";
            case Sections.secretariat: return "Secretariaat";
            case Sections.camp: return "Zweefkamp";
            case Sections.siteAdmin: return "Beheer eSoar";


            case Sections.home: return "Zou nooit mogen gebeuren";
            default: throw new CustomError("Unknown module: " + module.toString(), null, null, null, null, null);
        }
    }

    public static getModuleName(module: Modules): string {
        switch (module) {
            case Modules.nomodule: return "";

            case Modules.login: return "Aanmelden";
            case Modules.resetpwd: return "Wachtwoord reset";
            case Modules.redirect: return "Redirecting";

            case Modules.myAccount: return "Mijn account";
            case Modules.mySettings: return "Instellingen";
            case Modules.myFlightStatus: return "Mijn vlieg status";
            case Modules.myCostsOverview: return "Mijn vlieg kosten";
            case Modules.myFlights: return "Mijn vluchten";
            case Modules.myPersonalData: return "Mijn gegevens";
            case Modules.flightDays: return "Vliegdagen";
            case Modules.contacts: return "Contacteer";
            case Modules.myEvents: return "Mijn events";
            case Modules.library: return "Documenten";

            case Modules.flights: return "Vluchten";
            case Modules.members: return "Leden";
            case Modules.aircrafts: return "Vloot";
            case Modules.cashbook: return "Kasboek";
            case Modules.emails: return "Emails";
            case Modules.reports: return "Rapporten";
            case Modules.events: return "Evenementen";

            case Modules.accounts: return "Accounts beheren";
            case Modules.tools: return "Tools";
            case Modules.about: return "About";

            case Modules.participants: return "Deelnemers";
            case Modules.meals: return "Maaltijden";

            default: throw new CustomError("Unknown module: " + module, null, null, null, null, null);
        }
    }

    public static getCurrentSectionName(): string {
        const section = Routing.getSection();
        return Routing.getSectionName(section);
    }

    public static getQueryString() {
        const x: string = Routing._routing.routingProps.location.search;
        if (x.length > 1) {
            return x.substr(1);
        }
        return null;
    }

    private static splitPathname(pathname: string) {
        const parts = new Array<string>();
        let part: string = null;

        for (let i: number = 0; i < pathname.length; i++) {

            if (pathname[i] == '/') {
                if (part != null) {
                    parts.push(part);
                }
                part = null;
            }
            else if (pathname[i] == '?') {
                //Query string. Exit
                if (part != null) {
                    parts.push(part);
                }
                return parts;
            }
            else {
                if (part == null) {
                    part = pathname[i];
                }
                else {
                    part = part + pathname[i];
                }
            }
        }

        if (part != null) {
            parts.push(part);
        }

        return parts;
    }
}


export interface IDummyProps extends RouteComponentProps {
}

export interface IDummyState {

}

class DummyCatchRoutingProps extends React.Component<IDummyProps, IDummyState> {

    constructor(props: any) {
        super(props);
    }

    render() : JSX.Element {
        Routing.setRoutingProps(this.props);
        return null
    }
}


//https://stackoverflow.com/questions/63774425/react-router-pass-this-props-location-to-navbar-component
export default withRouter(DummyCatchRoutingProps)



