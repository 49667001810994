import * as React from 'react';

import { Jumbotron } from 'react-bootstrap'
import { Row, Col } from 'react-bootstrap'

//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { CardNavigator } from "./../UIComponents/CardNavigator";
import { Sections, Modules } from "./../HelperClasses/Routing";
//import { Routing, Sections} from "./../HelperClasses/Routing";

interface IHomeSecretariatProps {
}


interface IHomeSecretariatState {

}

export default class HomeSecretariat extends React.Component<IHomeSecretariatProps, IHomeSecretariatState> {


    constructor(props: any) {
        super(props);

        this.state = {};
    }

    render() {

        const jsx =
            <>
                <Jumbotron className='m-0'>
                    {this.getModulesJSX()}
                </Jumbotron>
            </>
        return jsx;

    }

 //======================== Get Form JSX elements =============================//

    private getModulesJSX() {
        const jsx =
            <>
            <Row>
                <Col className="p-2" >
                        <CardNavigator section={ Sections.secretariat} module={Modules.flights} headerText="Vluchten" iconName="plane-departure" />
                </Col>
                <Col className="p-2" >
                        <CardNavigator section={Sections.secretariat} module={Modules.members} headerText="Leden" iconName="user-friends" />
                </Col>
                <Col className="p-2" >
                        <CardNavigator section={Sections.secretariat} module={Modules.aircrafts} headerText="Vloot" iconName="plane" />
                </Col>

                <Col className="p-2" >
                        <CardNavigator section={Sections.secretariat} module={Modules.cashbook} headerText="Kasboek" iconName="coins" />
                </Col>
                <Col className="p-2" >
                    <CardNavigator section={Sections.secretariat} module={Modules.reports} headerText="Rapporten" iconName="file-export" />
                </Col>
                <Col className="p-2" >
                    <CardNavigator section={Sections.secretariat} module={Modules.emails} headerText="Emails" iconName="mail-bulk" />
                </Col>
                <Col className="p-2" >
                    <CardNavigator section={Sections.secretariat} module={Modules.events} headerText="Events" iconName="eventIcon" />
                </Col>

            </Row>
            </>
        return jsx;
    }


    async componentDidMount() {

    }

    componentWillUnmount() {

    }
}