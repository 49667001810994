import * as React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
//import { Styles } from "./../HelperClasses/Styles";


export interface ITrafficLightIconProps {
    size: number;
    color: 'red' | 'green' | 'orange' | 'all' | 'none';
    disabled?: boolean;
}

export const TrafficLightIcon: React.SFC<ITrafficLightIconProps> = (props) => {
    return getTrafficLightJSX(props.size, props.color, props.disabled);
}

const getTrafficLightJSX = (size: number, color : 'red' | 'green' | 'orange' | 'all' | 'none', disabled?: boolean) => {

    const className = "fa-layers fa-" + size.toString() + "x";
    const jsx =
        <span className={className}>
            
            {color == 'red' || color == 'all' ? getRedLightJSX() : null}
            {color == 'orange' || color == 'all'  ? getOrangeLightJSX() : null}
            {color == 'green' || color == 'all' ? getGreenLightJSX() : null}
            <FontAwesomeIcon icon='traffic-light' color={disabled ? 'lightgrey' : '#343a40'} />
        </span>

    return jsx;
}

const getRedLightJSX = () => {
    return <FontAwesomeIcon icon='circle' transform="shrink-12.5 up-4.5 left-0" color={'red'} />
}

const getOrangeLightJSX = () => {
    return <FontAwesomeIcon icon='circle' transform="shrink-12.5 up-0.5 left-0" color={'orange'} />
}

const getGreenLightJSX = () => {
    return <FontAwesomeIcon icon='circle' transform="shrink-12.5 down-3.5 left-0" color={'green'} />
}