import * as React from 'react';


//import { Button, ListGroup, ListGroupItem } from 'react-bootstrap'
//import { Alert } from 'react-bootstrap'

import { Jumbotron, Badge, Button } from 'react-bootstrap'
import { Row, Col, Form } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

var NumericInput = require('react-numeric-input');

//import { Tooltip, OverlayTrigger } from 'react-bootstrap'
//import { Styles } from '../HelperClasses/Styles';
//import { FiletypePicker, fileTypes } from "./../UIComponents/FiletypePicker";
import { InfoOverlay } from "./../UIComponents/InfoOverlay";
import { LoadingSpinner } from '../UIComponents/MySpinners';
import { Formatters } from '../HelperClasses/Formatters'
//import { MyDateTime, MyTimeSpan } from '../HelperClasses/MyDate';
//import { ICustomError } from "../HelperClasses/CustomError";
import { Http } from "../HelperClasses/Http";
import { ApplicationState } from "../HelperClasses/ApplicationState";

import { IMember } from "./../DTO/Member";
import { IFlight, Flight } from "./../DTO/Flight";
import { MyDateTime } from '../HelperClasses/MyDate';
//import { ModalResult } from '../HelperClasses/Enums';
//import { MyDateTime } from '../HelperClasses/MyDate';

interface IMyFlightsviewProps {

}


interface IMyFlightsState {
    member: IMember;
    selectedYear: number;
    isLoading: boolean;

    lastUpdateDate: MyDateTime;

    flightsIsLoading: boolean;
    flights: Array<IFlight>;
}

export default class MyFlights extends React.Component<IMyFlightsviewProps, IMyFlightsState> {


    constructor(props: any) {
        super(props);

        this.state = { member: null, selectedYear: MyDateTime.today.year, isLoading: false, lastUpdateDate: null, flightsIsLoading: false, flights: null }

        this.handleSelectedYearChange = this.handleSelectedYearChange.bind(this);
        this.handleDownload = this.handleDownload.bind(this);
        //this.handleCloseFiletypeSelector = this.handleCloseFiletypeSelector.bind(this);
    }

    render() {
        
        const jsx =
            <LoadingSpinner active={this.state.isLoading} >
                <Jumbotron className='m-0 ' >
                    {this.getInnerJSX()}
                </Jumbotron>
            </LoadingSpinner>
        return jsx;
    }

    //======================== Get Form JSX elements =============================//

    private getInnerJSX() {
        const jsx = 
        <>
            {this.getTitelJSX()}
            {this.getHeaderJSX()}
            <hr />
            {this.getDashboardJSX()}
            <hr />
            {this.getLastUpdateDate()}
        </>

        return jsx;
    }

    private getTitelJSX() {
        const jsx =
            <>
                <Row>
                    <Col className='col-auto mr-auto'>
                        <h3 >
                            <FontAwesomeIcon icon="plane-departure" />&nbsp;
                            Mijn Vluchten 
                        </h3>
                    </Col>
                    <Col className='col-auto'>
                        { this.getDownload()}
                    </Col>
                </Row>
                

                {this.getYearSelector()}

            </>
        return jsx;
    }

    private getYearSelector() {

        const jsx =

            <Form.Group controlId="frmYear" style={{ width: '12rem' }} >
                <Form.Text>Vluchten overzicht</Form.Text>
                <NumericInput min={0} step={1} precision={0} className="form-control"
                    snap
                    value={this.state.selectedYear ? this.state.selectedYear.toString() : null}
                    onChange={this.handleSelectedYearChange}
                    tabIndex={0} />
            </Form.Group>

        return jsx;
    }

    private getHeaderJSX() {
        const jsx = 
            <Row key={-1} style={{ fontWeight: 'bold' }}>
                <Col xs={3} sm={1} md={1} lg={1} className='text-left '>
                    Datum
                </Col>

                <Col xs={3} sm={2} md={2} lg={1} className='text-left '>
                    Toestel
                </Col>

                <Col md={1} lg={1} className='text-right d-none d-lg-block'>
                    Start
                </Col>

                <Col md={1} lg={1} className='text-right  d-none  d-lg-block'>
                    Landing
                </Col>

                <Col xs={3} sm={1} md={1} lg={1} className='text-right '>
                    Duur
                </Col>

                <Col sm={3} md={3} lg={2} className='text-left d-none d-sm-block'>
                    Front
                </Col>

                <Col sm={3} md={3} lg={2} className='text-left d-none d-sm-block'>
                    Rear
                </Col>

                <Col xs={1} sm={1} md={1} lg={2} className='text-left '>
                    Type
                </Col>

                <Col lg={1} className='text-right d-none d-lg-block'>
                    Kosten
                </Col>
                <Col xs={1} className='text-right d-lg-none'>
                    &nbsp;
                </Col>
                <Col xs={1} className='text-right d-lg-none'>
                    
                </Col>
            </Row>
        
        return jsx;

    }

    private getDashboardJSX() {
        if (!this.state.flights) return null;

        const jsx =
            this.state.flights.map(
                (flight, index) => {
                    //console.log(flight);

                    const style = (index % 2 == 0 ? { background: '#ebf5fb' } : { background: 'white' });
                    const jsxRow =

                        <Row key={index} style={style}>
                            <Col xs={3} sm={1} md={1} lg={1} className='text-left'>
                                {Formatters.getShortMonthDayString(flight.day)}
                            </Col>

                            <Col xs={3} sm={2} md={2} lg={1} className='text-left d-sm-block  pr-2'>
                                {flight.aircraft.registration}
                            </Col>

                            <Col lg={1} className='text-right d-none d-lg-block'>
                                {Formatters.getShortTimeString(flight.takeOff)}
                            </Col>

                            <Col lg={1} className='text-right d-none d-lg-block'>
                                {Formatters.getShortTimeString(flight.landing)}
                            </Col>

                            <Col xs={3} sm={1} md={1} lg={1} className='text-right d-sm-block'>
                                {Formatters.getShortTimeSpanString(MyDateTime.getTimeSpanBetween(flight.takeOff, flight.landing))}
                            </Col>

                            <Col sm={3} md={3} lg={2} className='text-left  d-none d-sm-block'>
                                {flight.frontSeat ? flight.frontSeat.getFullName() : "--"}
                            </Col>

                            <Col sm={3} md={3} lg={2} className='text-left  d-none d-sm-block'>
                                {flight.rearSeat ? flight.rearSeat.getFullName(): "--"}
                            </Col>

                            <Col lg={2} className='text-left d-none d-lg-block'>
                                {flight.flightType.name}
                            </Col>
                            <Col xs={1} sm={1} md={1} className='text-center d-lg-none'>
                                {flight.flightType.name[0]}
                            </Col>

                            <Col lg={1} className='text-right d-none d-lg-block'>
                                {flight.frontSeat ? this.displayEuro(
                                    (flight.frontSeat.id == this.state.member.id) ?
                                        flight.flightCostFrontSeat + flight.launchCostFrontSeat :
                                        flight.flightCostRearSeat + flight.launchCostRearSeat
                                ) : null
                                }
                            </Col>

                            <Col xs={1} className='text-center d-lg-none'>
                                <InfoOverlay tooltip={this.getFlightDetails(flight)} />
                            </Col>
                            <Col xs={1} className='text-center d-lg-none'>
                               
                            </Col>

                            </Row>


                    return jsxRow;
                }
            );
        //    

        return jsx;
    }

    private getLastUpdateDate() {
        const jsx =
            <span>
                Vluchten bijgewerkt tot&nbsp;
                <Badge variant='dark'>
                    {this.state.lastUpdateDate && this.state.lastUpdateDate.isValid ? Formatters.getMediumDateString(this.state.lastUpdateDate) : '--'}
                </Badge>
            </span>

        return jsx;
    }

    private getDownload() {

        const jsx1 = 
            <Button variant="primary" onClickCapture={this.handleDownload}>
                <FontAwesomeIcon icon="cloud-download-alt" />
                <span className='d-none d-sm-inline'>
                        &nbsp;&nbsp;
                        Download
                </span>
            </Button>

        return jsx1;
    }

    private displayEuro(amount: number) {
        if (amount == 0) return null;

        const jsx =
        <>
            { Formatters.toEuro(amount) }
        </>
        return jsx;
    }

    private getFlightDetails(flight: IFlight) {
        const jsx =
            <div className='text-left'>
 
                <i>
                {Formatters.getMediumDateString(flight.day)}
                <br />
                {flight.aircraft.registration}
                <br />
                {Formatters.getShortTimeString(flight.takeOff)}&nbsp;-&nbsp;
                {Formatters.getShortTimeString(flight.landing)}&nbsp;=&nbsp;
                {Formatters.getShortTimeSpanString(MyDateTime.getTimeSpanBetween(flight.takeOff, flight.landing))}
                <br />
                {flight.frontSeat ? flight.frontSeat.getFullName() : "--"}
                <br />
                {flight.rearSeat ? flight.rearSeat.getFullName() : "--"}
                <br />
                {flight.flightType.name}
                <br />
                    {flight.frontSeat ?
                        this.displayEuro(
                            (flight.frontSeat.id == this.state.member.id) ?
                                flight.flightCostFrontSeat + flight.launchCostFrontSeat
                                : flight.flightCostRearSeat + flight.launchCostRearSeat
                        )
                        : null
                }
                </i>
        </div>

        return jsx;
    }

    //==================== Handlers =======================================//
    private async handleSelectedYearChange(value: number | null, stringValue: string, input: HTMLInputElement) {
        await this.setState({ selectedYear: value })

        this.loadFlightsAsync(this.state.selectedYear)
    }

    private async handleDownload(): Promise<void> {
        this.downLoadReportAsync()
    }

    //==================== Data layer =======================================//

    private async loadBaseTables(year: number) {
        //don't await
        this.loadFlightsAsync(year);
        this.loadLastUpdateAsync();
    }

    private async loadFlightsAsync(year: number): Promise<Array<IFlight>> {
        if (!this.state.member) return null;

        this.setState({ isLoading: true, flightsIsLoading: true });

        const http = new Http();

        const res = await http.getAsyncObject<IFlight>('api/flight/member/' + this.state.member.id + '/' + year.toString(), Flight) as Array<IFlight>;

        if (http.error == null) {
            this.setState({ isLoading: false, flightsIsLoading: false, flights: res as Array<IFlight> });
            return (res as Array<IFlight>);
        }
        else {
            this.setState({ isLoading: false, flightsIsLoading: false, flights: null });
            ApplicationState.get().setError(http.error);
            return null;
        }
    }


    private async loadLastUpdateAsync() : Promise<MyDateTime> {

        const http = new Http();

        //this.setState({ isLoading: true });

        let res = null;
        res = await http.getAsyncPrimitive<string>("api/flight/lastupdate/") as string;

        if (http.error == null) {
            const lastUpdateDate = MyDateTime.fromJson(res);
            this.setState({ lastUpdateDate: lastUpdateDate  });
            return lastUpdateDate;
        }
        else {
            this.setState({ lastUpdateDate: null });
            ApplicationState.get().setError(http.error);
            return null;
        }
    }

    private async downLoadReportAsync() : Promise<void> {
        
        if (!this.state.member) return null;

        const http = new Http();
        await http.getAsyncFile("api/report/flightspermember/" + this.state.member.id + "/" + this.state.selectedYear.toString());

        if (http.error == null) {
        }
        else {
            ApplicationState.get().setError(http.error);
        }
    }

    //==================== Lifecycle =================================//

    async componentDidMount() {
        //ApplicationState.get().onErrorChange.registerHandler(this.handleStateError);
        await this.setState({ member: ApplicationState.get().sessionInfo.account.member })

        await this.loadBaseTables(this.state.selectedYear);
    }

    componentWillUnmount() {
        //ApplicationState.get().onErrorChange.unregisterHandler(this.handleStateError);
    }
}