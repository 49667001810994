import * as React from 'react';

//import Container from 'react-bootstrap/Container';
import { Accordion, Card, } from 'react-bootstrap';
import { Jumbotron } from 'react-bootstrap';
import {  Form, Row, Col } from "react-bootstrap";
//import { Form, Button, Row, Column } from "react-bootstrap";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { ApplicationState } from "../HelperClasses/ApplicationState";
import { IAccount } from "../DTO/Account";
import { Http } from "./../HelperClasses/Http";
import { Cache } from "./../HelperClasses/Cache";
import { InfoOverlay } from "./../UIComponents/InfoOverlay";
//import { HttpStatus } from '../HelperClasses/CustomError';
//import { Errors } from '../HelperClasses/Errors';


interface IMySettingsProps {


}
interface IMySettingsState {
    account: IAccount;

    activeAccordeon: string;
}




export default class MySettings extends React.Component<IMySettingsProps, IMySettingsState> {

    constructor(props: any) {
        super(props);

        
        this.state = {
            account: ApplicationState.get().sessionInfo.account,
            activeAccordeon: '0'
        };

        this.handleAccordeonSelect = this.handleAccordeonSelect.bind(this);
        this.handleChangeShareEmail = this.handleChangeShareEmail.bind(this);
        this.handleChangeShareGsm = this.handleChangeShareGsm.bind(this);
    }

    render() {
        if (ApplicationState.get().sessionInfo.account == null) return null;

        const y =
            <Jumbotron className='m-0 '>
                {this.getAccordeonJSX()}
            </Jumbotron>

        return y;

    }


    private getAccordeonJSX() {

        var JSX =
            <>
                <Accordion activeKey={this.state.activeAccordeon} onSelect={this.handleAccordeonSelect} >
                    <Card >
                        <Accordion.Toggle as={Card.Header} eventKey="0" style={{ cursor: 'pointer' }} >
                            <FontAwesomeIcon inverse={false} size="lg" icon="handshake" />&nbsp;&nbsp;Toestemmingen

                            
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="0">
                            <Card.Body>
                                {this.getAuthorizationsJSX() }
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>

                </Accordion>

            </>
        return JSX;
    }

    private getAuthorizationsJSX() {
        const jsx =
            <>
                <Row className='my-1'>
                    <Col className='text-right'>
                        <InfoOverlay tooltip={this.getShareStatusJSX()} />
                    </Col>
                </Row>
                <Row className='my-2'>
                    <Col>
                        <Form.Switch

                            id="switchGsm"
                            label="GSM nummer zichtbaar voor leden"
                            checked={this.state.account.shareGsm}
                            onChangeCapture={this.handleChangeShareGsm}
                            onChange={() => { }}
                        />
                    </Col>

            </Row>
                <Row className='my-2'>
                    <Col>
                        <Form.Switch

                            id="switchEmail"
                            label="Email adres zichtbaar voor leden"
                            checked={this.state.account.shareEmail}
                            onChangeCapture={this.handleChangeShareEmail}
                            onChange={() => { }}
                        />
                    </Col>

            </Row>
            </>
        return jsx;
    }

    private getShareStatusJSX() {
        const jsx =
                <div className='text-left'>

                <u><i>Toestemmingen</i></u>
                <br />
                <br />
                Om te kunnen bellen, WhatsAppen, e-mailen of SMS'en met een ander lid moeten jullie hier beiden toestemming voor geven.
                <br />
                <br />
                Geef hier toestemming om je telefoon nummer en/of email zichtbaar te maken voor andere leden.
                <br />
                <br />
                Teneinde een correcte werking van de club te verzekeren heeft het bestuur altijd toegang tot je contact gegevens.
                </div>

        return jsx;
    }

    //======================== Handlers =============================//
    private handleAccordeonSelect(eventKey: string) {
        this.setState({ activeAccordeon: eventKey })
    }

    private async handleChangeShareEmail(e: any) {
        let acc = ApplicationState.get().sessionInfo.account;
        acc.shareEmail = !acc.shareEmail;
        //console.log(acc);

        let newacc = await this.upsertAccountAsync(acc);
        if (newacc) {
            this.setState({ account: newacc });
        }
        Cache.invalidateActiveMembers(); //else the email will (not) be filtered out
    }

    private async handleChangeShareGsm(e: any) {
        let acc = ApplicationState.get().sessionInfo.account;
        acc.shareGsm = !acc.shareGsm;
        //console.log(acc);

        let newacc = await this.upsertAccountAsync(acc);
        if (newacc) {
            this.setState({ account: newacc });
        }
        Cache.invalidateActiveMembers(); //else the GSM will (not) be filtered out
    }

    //======================== Data Layer =============================//

    private async upsertAccountAsync(acc: IAccount): Promise<IAccount> {

        const http = new Http();
        const res = await http.postAsyncPrimitive<IAccount>("api/account", acc);
        
        if (http.error == null) {
            //this.setState({  })
            return res as IAccount;
        }
        else {
            ApplicationState.get().setError(http.error);
            return null;
        }
    }

    //======================== Lifecycle =============================//


    componentDidMount() {
        //console.log("mounting MyProfile");
        //ApplicationState.get().sessionInfo.onChange.registerHandler(this.handleSessionChange);
    }

    componentWillUnmount() {
        //console.log("unmounting MyProfile");
        //ApplicationState.get().sessionInfo.onChange.unregisterHandler(this.handleSessionChange);
    }
}