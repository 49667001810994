
//import { FlightDay, IFlightDay } from "./FlightDay";
import { Member, IMember } from "./Member";
import { MyDateTime } from "./../HelperClasses/MyDate";
import { ISerializable } from './ISerializable';
import { IValidation, IValidationState } from './IValidation'
import { EasaStatus, IEasaStatus } from "./EasaStatus";
//import moment = require('moment');


export interface IMemberFlightDay extends ISerializable<IMemberFlightDay>, IValidation {
    id: string;
    arrivalTime: MyDateTime;
    departureTime: MyDateTime;
    nrPassengerFlights: number;

    //flightDay: IFlightDay;
    member: IMember;
    easaStatus: IEasaStatus;
}

export interface IMemberFlightDayValidationState extends IValidationState {

    arrivalTimeIsValid: boolean;
    departureTimeIsValid: boolean;
}

export class MemberFlightDay implements IMemberFlightDay {

    public readonly id: string = null;
    public arrivalTime: MyDateTime = null;
    public departureTime: MyDateTime = null;
    public nrPassengerFlights: number = null;

    //flightDay: IFlightDay;
    member: IMember;
    easaStatus: IEasaStatus;

    constructor() {
    }

    public getValidationState(): IMemberFlightDayValidationState {

        let vs = {
            arrivalTimeIsValid: (this.arrivalTime != null && this.arrivalTime.isValid),
            departureTimeIsValid: (this.departureTime == null || this.departureTime.isAfter(this.arrivalTime)),
            stateIsValid: false
        } as IMemberFlightDayValidationState;

        vs.stateIsValid = (
            vs.arrivalTimeIsValid &&
            vs.departureTimeIsValid
        );

        return vs;
    }

    public fromJson(json: Object): MemberFlightDay {
        //DO NOT USE 'this.' RETURN A NEW OBJECT
        //console.log("Aircraft.fromJson");

        const trueObject = new MemberFlightDay();
        Object.assign<IMemberFlightDay, Object>(trueObject, json);

        //trueObject.key = trueObject.id;

        //Overloads
        trueObject.arrivalTime = MyDateTime.fromJson(trueObject.arrivalTime);
        trueObject.departureTime = MyDateTime.fromJson(trueObject.departureTime);

        //Objects
        //if (trueObject.flightDay != null) trueObject.flightDay = (new FlightDay()).fromJson(trueObject.flightDay);
        if (trueObject.member != null) trueObject.member = (new Member()).fromJson(trueObject.member);
        if (trueObject.easaStatus != null) trueObject.easaStatus = (new EasaStatus()).fromJson(trueObject.easaStatus);
        //console.log(trueObject);
        return trueObject;
    }


    public toJson(): Object {
        //console.log("MemberFlightDay.toJson");

        let x = JSON.parse(JSON.stringify(this));

        //Overloads
        if (this.arrivalTime) {
            x.arrivalTime = this.arrivalTime.toJson();
        }
        if (this.departureTime) {
            x.departureTime = this.departureTime.toJson();
        }

        //if (this.flightDay) {
        //    x.flightDay = this.flightDay.toJson();
        //}

        if (this.member) {
            x.member = this.member.toJson();
        }
        if (this.easaStatus) {
            x.easaStatus = this.easaStatus.toJson();
        }


        //console.log(x);
        return x;
    }
}