
import * as React from 'react';
import { Dropdown, Row, Col } from 'react-bootstrap'
//import { Button } from 'react-bootstrap'
import { Button, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { ModalResult} from './../HelperClasses/Enums';
import { Filetypes, Filetype } from './../HelperClasses/Filetypes';




export interface IFiletypePickerProps{
    title?: string;
    word?: boolean;
    excel?: boolean;
    pdf?: boolean;

    mode: 'dropdown' | 'modal'
    showButton?: boolean;

    onClose?: (modalResult: ModalResult, fileType: Filetypes) => void;
}

interface IFiletypePickerState {
    show: boolean;
}

type RefType = number;

export class FiletypePicker extends React.Component<IFiletypePickerProps, IFiletypePickerState> {

    constructor(props: any) {
        super(props);

        this.state = { show: false };

        this.handleClose = this.handleClose.bind(this);
        this.isFiletypeEnabled = this.isFiletypeEnabled.bind(this);
    };

    render() {
        if (this.props.mode == 'dropdown') return this.getDropdownJSX(this.props);

        if (this.props.mode == 'modal') {
            const jsx =
                <>
                    {this.getModalButtonJSX()}
                    {this.getModalJSX(this.props)}
                </>
            return jsx;
        }

        
        return null;
    }

        
    
    private IconToggle = React.forwardRef<RefType,any>(({ children, onClick } , ref) => (
        <span
            ref={(ref as any)} 
            onClick={(e) => {
                e.preventDefault();
                onClick(e);
            }}
        >
        {children}
        </span>
    )
    );

    private getDropdownJSX = (props: IFiletypePickerProps) => {
        if (!this.props.word && !this.props.excel && !this.props.pdf) {
            return <FontAwesomeIcon icon='cloud-download-alt' color='grey' />
        }

        const jsx =

            <Dropdown>
                <Dropdown.Toggle id="dropdown-custom-1" as={this.IconToggle}>
                    <FontAwesomeIcon icon='cloud-download-alt' />
                </Dropdown.Toggle>

                <Dropdown.Menu >
                    {this.getDropDownItemJSX(Filetypes.Word)}
                    {this.getDropDownItemJSX(Filetypes.Excel)}
                    {this.getDropDownItemJSX(Filetypes.Pdf)}
                </Dropdown.Menu>
            </Dropdown>

        return jsx;
    }

    private getModalButtonJSX() {
        if (!this.props.word && !this.props.excel && !this.props.pdf) {
            return <FontAwesomeIcon icon='cloud-download-alt' color='grey' />
        }

        let jsx =
            <>
                <span onClick={() => { this.setState({ show: true }) }}>
                    <FontAwesomeIcon icon='cloud-download-alt' />
                </span>
            </>

        if (this.props.showButton) {
            jsx =
                <Button variant="primary" onClick={() => { this.setState({ show: true }) }} >
                    {jsx}
                    <span className='d-none d-sm-inline'>
                        &nbsp;&nbsp;
                        Download
                    </span>
                </Button>
        }

        return jsx;
    }

    private getModalJSX = (props: IFiletypePickerProps) => {

        let jsx =
            <>
                <Modal show={this.state.show} onHide={() => { this.handleClose(ModalResult.Cancel, null) }} >
                <Modal.Header closeButton={true}>
                        <Modal.Title>{this.props.title ? this.props.title : 'Download als' }</Modal.Title>
                </Modal.Header>
                    <Modal.Body>
                        
                        <Row className='mx-auto text-center justify-content-center my-2'><Col>
                            {this.getButtonItemJSX(Filetypes.Word)}
                            </Col></Row>
                        <Row className='mx-auto text-center my-2'><Col>
                            {this.getButtonItemJSX(Filetypes.Excel)}
                            </Col></Row>
                        <Row className='mx-auto text-center my-2'><Col>
                            {this.getButtonItemJSX(Filetypes.Pdf)}
                            </Col></Row>
                        
                </Modal.Body>
                <Modal.Footer>
                        <Button variant="secondary" onClick={() => { this.handleClose(ModalResult.Cancel, null) }} >Cancel</Button>
                </Modal.Footer>
            </Modal>
            </>

        return jsx;
    }

    private getDropDownItemJSX (fileType: Filetypes) {
        if (!this.isFiletypeEnabled(fileType)) return null;
        const jsx =
            <Dropdown.Item eventKey={fileType} onClick={() => { this.handleClose(ModalResult.OK, fileType) }} >
                <FontAwesomeIcon icon={Filetype.getFiletypeIcon(fileType) as any} color={Filetype.getFiletypeColor(fileType)} />
            &nbsp;&nbsp;{fileType}
        </Dropdown.Item>

        return jsx
    }

    private getButtonItemJSX(fileType: Filetypes) {
        if (!this.isFiletypeEnabled(fileType)) return null;
        const jsx =
            <Button onClick={() => { this.handleClose(ModalResult.OK, fileType) }} className='text-left' variant="outline-primary" style={{ color: 'black', width: '10rem' }}>
                <FontAwesomeIcon icon={Filetype.getFiletypeIcon(fileType) as any} color={Filetype.getFiletypeColor(fileType)} />
                &nbsp;&nbsp;{fileType}
            </Button>

        return jsx
    }


    private isFiletypeEnabled(fileType: Filetypes) : boolean {
        switch (fileType) {

            case Filetypes.Word: return this.props.word;
            case Filetypes.Excel: return this.props.excel;
            case Filetypes.Pdf: return this.props.pdf;

            default: return false;
        }
    }



  


    //----------------------------------------  Handlers ------------------------//


    private handleClose = (result: ModalResult, fileType: Filetypes) => {
    
        this.setState({ show: false });

        if (this.props.onClose) {
            this.props.onClose(result, fileType);
        }
    }
    //----------------------------------------  impl ------------------------//



}


