
//import { FlightDay, IFlightDay } from "./FlightDay";
//import { Member, IMember } from "./Member";
import { MyDateTime } from "./../HelperClasses/MyDate";
import { ISerializable } from './ISerializable';
//import { IValidation, IValidationState } from './IValidation'
//import moment = require('moment');


export interface IFlightDayNumbers extends ISerializable<IFlightDayNumbers> {

    day: MyDateTime;
    nrMembers: number;
    nrInis: number;
    nrExternalInis: number;
    nrDuties: number;
}


export class FlightDayNumbers implements IFlightDayNumbers {

    public day: MyDateTime=null;
    public nrMembers: number=0;
    public nrInis: number = 0;
    public nrExternalInis: number = 0;
    public nrDuties: number=0;

    constructor() {
    }

    public fromJson(json: Object): FlightDayNumbers {
        //DO NOT USE 'this.' RETURN A NEW OBJECT
        //console.log("Aircraft.fromJson");

        const trueObject = new FlightDayNumbers();
        Object.assign<IFlightDayNumbers, Object>(trueObject, json);

        //trueObject.key = trueObject.id;

        //Overloads
        trueObject.day = MyDateTime.fromJson(trueObject.day);

        //Objects

        //console.log(trueObject);
        return trueObject;
    }


    public toJson(): Object {
        //console.log("FlightDayNumbers.toJson");

        let x = JSON.parse(JSON.stringify(this));

        //Overloads
        if (this.day) {
            x.day = this.day.toJson();
        }
;
        return x;
    }
}