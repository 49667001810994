import * as React from 'react';


//import { Button, ListGroup, ListGroupItem } from 'react-bootstrap'
//import { Alert } from 'react-bootstrap'

import { Jumbotron, Accordion, Card, Badge } from 'react-bootstrap'
//import { Badge, Button } from 'react-bootstrap'
import { Row, Col } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

//import { Tooltip, OverlayTrigger } from 'react-bootstrap'
//import { Styles } from '../HelperClasses/Styles';
//import { CardNavigator } from "./../UIComponents/CardNavigator";
//import { InfoOverlay } from "./../UIComponents/InfoOverlay";
import { LoadingSpinner } from '../UIComponents/MySpinners';
import { Formatters } from '../HelperClasses/Formatters'
//import { ICustomError } from "../HelperClasses/CustomError";
//import { Http } from "../HelperClasses/Http";
import { ApplicationState } from "../HelperClasses/ApplicationState";
import { RRN } from '../HelperClasses/RRN';

import { IMember } from '../DTO/Member';

interface IMyPersonalDataProps {

}


interface IMyPersonalDataState {
    member: IMember;

    isLoading: boolean;
}

export default class MyPersonalData extends React.Component<IMyPersonalDataProps, IMyPersonalDataState> {


    constructor(props: any) {
        super(props);

        this.state = { member: null, isLoading: false }

    }

    render() {
        
        const jsx =
            <LoadingSpinner active={this.state.isLoading} >
                <Jumbotron className='m-0 ' >
                    {this.getInnerJSX()}
                </Jumbotron>
            </LoadingSpinner>
        return jsx;
    }

    //======================== Get Form JSX elements =============================//

    private getInnerJSX() {
        const jsx = 
        <>
                {this.getTitelJSX()}
                {this.getAccordeonJSX()}

        </>

        return jsx;
    }

    private getTitelJSX() {
        const jsx =
            <>
                <Row>
                    <Col className='col-auto mr-auto'>
                        <h3 >
                            <FontAwesomeIcon icon="address-card" />&nbsp;
                            Mijn gegevens
                        </h3>
                    </Col>
                    <Col className='col-auto'>
                        
                    </Col>
                </Row>

                <br />
                    {this.getSubTitleJSX()}
                <br />
                <br />

            </>
        return jsx;
    }

    private getAccordeonJSX() {


        var JSX =
            <>
                <Accordion defaultActiveKey="0" >
                    <Card >
                        <Accordion.Toggle as={Card.Header} eventKey="0" style={{ cursor: 'pointer' }} >
                            <FontAwesomeIcon inverse={false} size="lg" icon="passport" />&nbsp;&nbsp;Identiteit
                        </Accordion.Toggle>

                        <Accordion.Collapse eventKey="0">
                            <Card.Body>
                                { this.getIdentityJSX()}
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>

                    <Card >
                        <Accordion.Toggle as={Card.Header} eventKey="1" style={{ cursor: 'pointer' }}>
                            <FontAwesomeIcon inverse={false} size="lg" icon="address-book" />&nbsp;&nbsp;Contact gegevens
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="1">
                            <Card.Body>
                                {this.getContactInfoJSX()}
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>

                    <Card >
                        <Accordion.Toggle as={Card.Header} eventKey="2" style={{ cursor: 'pointer' }}>
                            <FontAwesomeIcon inverse={false} size="lg" icon="plane" />&nbsp;&nbsp;Vlieg informatie
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="2">
                            <Card.Body>
                                {this.getFlightInfoJSX()}
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>

                </Accordion>

            </>
        return JSX;
    }


    private getIdentityJSX() {
        if (!this.state.member) return null;

        const rrn = new RRN(this.state.member.nationalNr);

        const table = new Array<any>();

        table.push({ name: 'Lidnummer', value: this.state.member.membershipNr });
        table.push({ name: 'Naam', value: this.state.member.getFullName() });
        table.push({ name: 'Rijksregisternr', value: rrn.toString() });
        table.push({ name: 'Geslacht', value: this.state.member.sex.description });
        table.push({ name: 'Geboren', value: Formatters.getMediumDateString(this.state.member.dateBirth) });
        table.push({ name: 'Geboren te', value: this.state.member.placeBirth });
        table.push({ name: 'Nationaliteit', value: this.state.member.nationality? this.state.member.nationality.name : '' });

        return this.getTableJSX(table);
    }

    private getContactInfoJSX() {
        if (!this.state.member) return null;

        const table = new Array<any>();

        //console.log(this.state.member.address)
        //console.log(this.state.member.zip)
        //console.log(this.state.member.city)
        table.push({ name: 'Adres', value: this.state.member.address });
        table.push({ name: '', value: (this.state.member.zip ? this.state.member.zip : '') + ' ' + (this.state.member.city ? this.state.member.city: '') });
        table.push({ name: '', value: this.state.member.country ? this.state.member.country.name : '' });
        table.push({ name: 'Tel', value: this.state.member.tel });
        table.push({ name: 'Gsm', value: this.state.member.gsm });
        table.push({ name: 'Email', value: this.state.member.email });

        return this.getTableJSX(table);
    }

    private getFlightInfoJSX() {
        if (!this.state.member) return null;

        const table = new Array<any>();

        table.push({ name: 'Solo', value: this.getIcon(this.state.member.isSolo) });
        table.push({ name: 'Vergunning', value: <>{this.getIcon(this.state.member.hasLicenseSpl)}&nbsp;{this.getLicenseNr()}</>});
        table.push({ name: 'Passagiers', value: this.getIcon(this.state.member.mayFlyPassenger) });
        table.push({ name: 'Instructeur', value: this.getIcon(this.state.member.isInstructor) });
        table.push({ name: 'Examinator', value: this.getIcon(this.state.member.isExaminator) });
        table.push({ name: 'Sleeppiloot', value: this.getIcon(this.state.member.isTowPilot) });

        return this.getTableJSX(table);
    }

    private getIcon(value: boolean) {
        if (value) {
            return <FontAwesomeIcon icon="check" color='green' />
        }
        return <FontAwesomeIcon icon="times" color='red' />
    }

    private getTableJSX(data: Array<any>) {
        const tableJSX = data.map(
            (tblRow, index) => {
                const jsx =
                    <Row key={index}>
                        <Col xs={5}>
                            <>
                                {tblRow.name}
                            </>
                        </Col>
                        <Col xs={7}>
                            <b>
                                {tblRow.value}
                            </b>
                        </Col>
                    </Row>

                return jsx;
            });

        return tableJSX;

    }

    private getLicenseNr() {
        const jsx = 
            <span>{this.state.member.hasLicenseSpl ? this.state.member.licenseNr : ''}</span>
        return jsx;
    }

    private getSubTitleJSX() {
        const jsx =
            <span>
                Wijzigingen?&nbsp;
                <span className='d-none d-sm-inline' > Contacteer &nbsp; </span>
                <Badge variant='dark'>
                    <a style={{ color: 'white' }} href='mailto:secretariaat@kazm.be?subject=Aanvraag wijziging persoonsgegevens'>
                        
                        secretariaat@kazm.be &nbsp;&nbsp; 
                        <FontAwesomeIcon icon="envelope" />
                        </a> 
                </Badge>
            </span>

        return jsx;

    }
    //==================== Handlers =======================================//



    //==================== Data layer =======================================//

    private async loadBaseTables() {

    }

    //==================== Lifecycle =================================//

    async componentDidMount() {
        //ApplicationState.get().onErrorChange.registerHandler(this.handleStateError);
        await this.setState({ member: ApplicationState.get().sessionInfo.account.member })

        await this.loadBaseTables();
    }

    componentWillUnmount() {
        //ApplicationState.get().onErrorChange.unregisterHandler(this.handleStateError);
    }
}