import * as React from 'react';

import { Jumbotron } from 'react-bootstrap'
import { Row, Col } from 'react-bootstrap'

//import { Tooltip, OverlayTrigger } from 'react-bootstrap'

import { CardNavigator } from "./../UIComponents/CardNavigator";
//import { InfoOverlay } from "./../UIComponents/InfoOverlay";
import { Sections, Modules } from "./../HelperClasses/Routing";
//import { MyDateTime } from '../HelperClasses/MyDate';
import { Http } from '../HelperClasses/Http';
import { Formatters } from '../HelperClasses/Formatters'
import { ApplicationState } from '../HelperClasses/ApplicationState';
import { Styles } from '../HelperClasses/Styles';

import { EasaStatus, IEasaStatus } from '../DTO/EasaStatus';
import { FlightCostsBalance, IFlightCostsBalance } from '../DTO/FlightCostsBalance';

interface IHomeMyeSoarState{

    isLoading: boolean;
    easaStatusIsLoading: boolean;    
    costBalanceIsLoading: boolean;

    easaStatus: IEasaStatus;
    costsBalance: IFlightCostsBalance;
}

export default class HomeMyeSoar extends React.Component<any, IHomeMyeSoarState> {

    constructor(props: any) {
        super(props);

        this.state = {isLoading: false, easaStatus: null, costsBalance: null, easaStatusIsLoading: false, costBalanceIsLoading: false };

        this.loadBaseTables = this.loadBaseTables.bind(this);
        this.loadEasaStatus = this.loadEasaStatus.bind(this);

        this.getFlightStatusText = this.getFlightStatusText.bind(this);
        this.getFlightStatusColor = this.getFlightStatusColor.bind(this);
        this.getCostsBalance = this.getCostsBalance.bind(this);
        this.getCostsBalanceColor = this.getCostsBalanceColor.bind(this);
    }

    render() {

        return this.getPage();
    }

    private getPage() {
        //{this.getDashboardJSX()}
        const jsx =
            <Jumbotron className='m-0  py-2' >
                
                {this.getModulesJSX()}
            </Jumbotron>

        return jsx;
    }

     //======================== Get Form JSX elements =============================//

    private getModulesJSX() {

        const jsx =
            <>
                <Row>
                    <Col className="p-2" >
                        <CardNavigator section={Sections.myEsoar} module={Modules.myFlightStatus} headerText="Vlieg status" iconName="TrafficLightIcon"
                            iconColor={this.getFlightStatusColor()}
                            footerText={this.getFlightStatusText()}
                            isLoading={this.state.easaStatusIsLoading}
                            disabled={!this.getMemberForCurrentUser()}
                        />
                    </Col>
                    <Col className="p-2" >
                        <CardNavigator section={Sections.myEsoar} module={Modules.myCostsOverview} headerText="Saldo" iconName="euro-sign"
                            iconColor={this.getCostsBalanceColor()}
                            footerText={this.getCostsBalance()}
                            isLoading={this.state.costBalanceIsLoading}
                            disabled={!this.getMemberForCurrentUser()}
                        />
                    </Col>

                    <Col className="p-2" >
                        <CardNavigator section={Sections.myEsoar} module={Modules.myFlights} headerText="Mijn vluchten" iconName="plane-departure"
                            disabled={!this.getMemberForCurrentUser()}
                        />
                    </Col>

                    <Col className="p-2" >
                        <CardNavigator section={Sections.myEsoar} module={Modules.flightDays} headerText="Vliegdagen" iconName="calendar-alt"
                            disabled={!this.getMemberForCurrentUser()}
                        />
                    </Col>

                    <Col className="p-2" >
                        <CardNavigator section={Sections.myEsoar} module={Modules.contacts} headerText="Contacteer" iconName="address-book"
                            disabled={!this.getMemberForCurrentUser()}
                        />
                    </Col>

                    <Col className="p-2" >
                        <CardNavigator section={Sections.myEsoar} module={Modules.myPersonalData} headerText="Mijn gegevens" iconName="address-card"
                            disabled={!this.getMemberForCurrentUser()}
                        />
                    </Col>

                    <Col className="p-2" hidden={false} >
                        <CardNavigator section={Sections.myEsoar} module={Modules.myEvents} headerText="Events" iconName="eventIcon"
                            disabled={!this.getMemberForCurrentUser()}
                        />
                    </Col>

                    <Col className="p-2" hidden={false} >
                        <CardNavigator section={Sections.myEsoar} module={Modules.library} headerText="Documenten" iconName="file-download"
                            footerText={''}
                            disabled={false}
                        />
                    </Col>

                </Row>
                
            </>
        return jsx;
    }

    //private getWIPJSX() {
    //    const jsx =
    //        <div style={{ fontSize: 18, fontWeight: 'bold', color: 'lightgray' }}>WIP</div>

    //    return jsx;
    //}

    private getFlightStatusText() {
        if (!this.state) return '';
        if (!this.state.easaStatus) return 'Geen info';
        
        if (!this.state.easaStatus.hasLicenseSpl) return 'In opleiding';

        if (this.state.easaStatus.getFlightColor() === 'red') {
            return 'Niet current'
        }

        return Formatters.getShortDateString(this.state.easaStatus.firstEndDate);
     
    }

    private getFlightStatusColor() {
        if (!this.state) return Styles.getDarkBackColorNr();
        if (!this.state.easaStatus) return 'red';
        
        return this.state.easaStatus.getFlightColor();
    }

    private getCostsBalance() {
        if (!this.state) return '';
        if (!this.state.costsBalance) return '';
        return Formatters.toEuro(this.state.costsBalance.balancePerDate);
    }

    private getCostsBalanceColor() {
        if (!this.state) return Styles.getDarkBackColorNr();
        if (!this.state.costsBalance) return Styles.getDarkBackColorNr();

        if (this.state.costsBalance.balancePerDate < 0) return 'red';
        if (this.state.costsBalance.balancePerDate < 50) return 'orange';
        return 'green';
    }

    //========================== handlers ============//

    private getMemberForCurrentUser() {
        const account = ApplicationState.get().sessionInfo.account;
        if (!account) return null;
        if (!account.member) return null;
        return account.member;
    }


    //========================== Data layer ============//

    private async loadBaseTables() {
        if (this.getMemberForCurrentUser()) {
            this.loadEasaStatus();
            this.loadCostsBalance();
        }
    }

    private async loadEasaStatus(): Promise<IEasaStatus> {
        const member = this.getMemberForCurrentUser();
        if (!member) return null;

        this.setState({ easaStatusIsLoading: true });

        const http = new Http();

        const res = await http.getAsyncObject<IEasaStatus>('api/member/easastatus/' + member.id, EasaStatus);

        if (http.error == null) {
            this.setState({ easaStatus: res as EasaStatus, easaStatusIsLoading: false });
            return (res as EasaStatus);
        }
        else {
            this.setState({ easaStatus: null, easaStatusIsLoading: false });
            ApplicationState.get().setError(http.error);
            return null;
        }
    }

    private async loadCostsBalance(): Promise<IFlightCostsBalance> {
        const member = this.getMemberForCurrentUser();
        if (!member) return null;

        this.setState({ costBalanceIsLoading: true });

        const http = new Http();

        const res = await http.getAsyncObject<IFlightCostsBalance>('api/member/balance/' + member.id, FlightCostsBalance);

        if (http.error == null) {
            this.setState({ costsBalance: res as FlightCostsBalance, costBalanceIsLoading: false });
            return (res as IFlightCostsBalance);
        }
        else {
            this.setState({ costsBalance: null, costBalanceIsLoading: false });
            ApplicationState.get().setError(http.error);
            return null;
        }
    }

    //========================== Life Cycle ============//

    async componentDidMount() {
        //await this.setState({ member: ApplicationState.get().sessionInfo.account.member })
        await this.loadBaseTables();
    }

    componentWillUnmount() {
        //console.log("Home will be unmounted");
    }
}




