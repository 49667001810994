import * as React from 'react';
import { RouteComponentProps, withRouter } from "react-router-dom";

import { Navbar, Nav, Image } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


import { Styles } from "./../HelperClasses/Styles";
import { ApplicationState } from "./../HelperClasses/ApplicationState";
import { Routing, Sections, Modules } from "./../HelperClasses/Routing";
import { CustomError } from "./../HelperClasses/CustomError";
import { EventIcon } from "./../UIComponents/MyIcons";
//import { Roles } from "./../DTO/Role";
import { IAccount } from "./../DTO/Account";

import  ProfileMenu  from "./ProfileMenu";
import  SectionsMenu   from "./SectionsMenu";



interface IMainMenuMobileProps extends RouteComponentProps {
    account: IAccount;
}

interface IMainMenuMobileState {
    selectedKey: string;
}



export class MainMenuMobile extends React.Component<IMainMenuMobileProps, IMainMenuMobileState> {

    constructor(props: IMainMenuMobileProps ) {
        super(props);

        this.handleBack = this.handleBack.bind(this);

        this.state = { selectedKey: null}
        
    }

    render() {

        if (ApplicationState.get().sessionInfo.account == null) {
            return this.getNoUserNavbar();
        }

        const jsx =
            <>
                {this.getSMNavbar()}
                {this.getLGNavbar()}
            </>

        return jsx;
    }

    private getSMNavbar() {

        const jsx = 
            <div className='d-block d-md-none'>

                <Navbar bg="dark" variant="dark" >
                    <Nav className="mr-auto" >
                        {this.getNavigationJSX()}
                    </Nav>
                    <Nav>
                        <Nav.Link disabled hidden={true}>
                            <FontAwesomeIcon icon="bell" inverse style={Styles.getAlertColorStyle()} />
                        </Nav.Link>

                        <ProfileMenu account={this.props.account} />
                    </Nav>
                </Navbar>
            </div>

        return jsx;
    }


    private getLGNavbar() {
        const jsx = 
            <div className='d-none d-md-block'>
                <Navbar bg="dark" variant="dark" >
                    <Nav >

                        <Navbar.Brand>                        
                            <Image src="imgs/kazmlogot.png" rounded fluid />
                        </Navbar.Brand>                        

                    </Nav>

                    <Nav className="mr-auto">
                        {this.getNavigationJSX()}
                    </Nav>
                   
                    <Nav>
                        <Nav.Link disabled hidden={true}>
                            <span className="fa-layers fa-fw">
                                <FontAwesomeIcon icon="bell" data-countx="2" inverse style={Styles.getAlertColorStyle()} />

                            </span>
                        </Nav.Link>

                        <ProfileMenu account={this.props.account}/>
                    </Nav>



                </Navbar>
            </div>

        return jsx;
    }

    private getNavigationJSX() {

        let backAndLocationJSX: JSX.Element = null;
        let sectionMenuJSX: JSX.Element = null;

        const module = Routing.getModule();
        
        if (module != Modules.nomodule) {//Module loaded
            backAndLocationJSX = this.getBackAndLocationJSX(module);
            
        }
        else {//No module loaded
            sectionMenuJSX = <SectionsMenu />
        }

        const jsx =
            <>
                {backAndLocationJSX}
                {sectionMenuJSX}
            </>;

        return jsx;
    }

    private getBackAndLocationJSX(module: Modules) {
        const jsx=
                <Nav.Link active eventKey='backButton' onSelect={this.handleBack}>
                <FontAwesomeIcon icon="arrow-circle-left" inverse transform='grow-6' />&nbsp;&nbsp;&nbsp;

                {this.getModuleLocationJSX(module)}


                </Nav.Link>

        return jsx;
    }


    private getModuleLocationJSX(module: Modules) {
        let jsx: JSX.Element = null;

        switch (module) {

            case Modules.myAccount:
                jsx =
                    <>
                    <FontAwesomeIcon icon="key" inverse />
                    <span style={{ color: 'white' }}> &nbsp;{Routing.getModuleName(Modules.myAccount)}</span>
                    </>
                break;

            case Modules.mySettings:
                jsx =
                    <>
                        <FontAwesomeIcon icon="cog" inverse />
                        <span style={{ color: 'white' }}> &nbsp;{Routing.getModuleName(Modules.mySettings)}</span>
                    </>
                break;

            case Modules.myFlightStatus:
                jsx =
                    <>
                        <FontAwesomeIcon icon="traffic-light" inverse />
                    <span > {Routing.getModuleName(Modules.myFlightStatus)}</span>
                    </>
                break;

            case Modules.myCostsOverview:
                jsx =
                    <>
                    <FontAwesomeIcon icon="euro-sign" inverse />
                    <span > {Routing.getModuleName(Modules.myCostsOverview)}</span>
                    </>
                break;

            case Modules.myFlights:
                jsx =
                    <>
                        <FontAwesomeIcon icon="plane-departure" inverse />
                        <span > {Routing.getModuleName(Modules.myFlights)}</span>
                    </>
                break;

            case Modules.myPersonalData:
                jsx =
                    <>
                    <FontAwesomeIcon icon="address-card" inverse />
                    <span > {Routing.getModuleName(Modules.myPersonalData)}</span>
                    </>
                break;

            case Modules.flightDays:
                jsx =
                    <>
                        <FontAwesomeIcon icon="calendar-alt" inverse />
                        <span > {Routing.getModuleName(Modules.flightDays)}</span>
                    </>
                break;

            case Modules.contacts:
                jsx =
                    <>
                        <FontAwesomeIcon icon="address-book" inverse />
                        <span > {Routing.getModuleName(Modules.contacts)}</span>
                    </>
                break;

            case Modules.myEvents:
                jsx =
                    <>
                    <EventIcon  />
                        <span > {Routing.getModuleName(Modules.myEvents)}</span>
                    </>
                break;

            case Modules.library:
                jsx =
                    <>
                    <FontAwesomeIcon icon="file-download" inverse />
                        <span > {Routing.getModuleName(Modules.library)}</span>
                    </>
                break;

            case Modules.flights:
                jsx =
                    <>
                    <FontAwesomeIcon icon="plane-departure" inverse />
                    <span> {Routing.getModuleName(Modules.flights)}</span>
                    </>
                break;

            case Modules.members:
                jsx =
                    <>
                    <FontAwesomeIcon icon="user-friends" inverse />
                    <span> {Routing.getModuleName(Modules.members)}</span>
                    </>
                break;

            case Modules.aircrafts:
                jsx =
                    <>
                    <FontAwesomeIcon icon="plane" inverse />
                    <span> {Routing.getModuleName(Modules.aircrafts)}</span>
                    </>
                break;

            case Modules.cashbook:
                jsx =
                    <>
                    <FontAwesomeIcon icon="coins" inverse />
                    <span > {Routing.getModuleName(Modules.cashbook)}</span>
                    </>
                break;

            case Modules.emails:
                jsx =
                    <>
                    <FontAwesomeIcon icon="mail-bulk" inverse />
                    <span> {Routing.getModuleName(Modules.emails)}</span>
                    </>
                break;

            case Modules.events:
                jsx =
                    <>
                        <EventIcon  />
                        <span> {Routing.getModuleName(Modules.events)}</span>
                    </>
                break;

            case Modules.reports:
                jsx =
                    <>
                    <FontAwesomeIcon icon="file-export" inverse />
                    <span> {Routing.getModuleName(Modules.reports)}</span>
                    </>
                break;

            case Modules.accounts:
                jsx =
                    <>
                    <FontAwesomeIcon icon="users-cog" inverse />
                    <span> {Routing.getModuleName(Modules.accounts)}</span>
                    </>
                break;

            case Modules.tools:
                jsx =
                    <>
                    <FontAwesomeIcon icon="tools" inverse />
                    <span > {Routing.getModuleName(Modules.tools)}</span>
                    </>
                break;

            case Modules.about:
                jsx =
                    <>
                    <FontAwesomeIcon icon="info" inverse />
                    <span > {Routing.getModuleName(Modules.about)}</span>
                    </>
                break;

            case Modules.participants:
                jsx =
                    <>
                        <FontAwesomeIcon icon="users" inverse />
                        <span > {Routing.getModuleName(Modules.participants)}</span>
                    </>
                break;

            case Modules.meals:
                jsx =
                    <>
                        <FontAwesomeIcon icon="utensils" inverse />
                        <span > {Routing.getModuleName(Modules.meals)}</span>
                    </>
                break;

            //default : return null;
            default: throw new CustomError("Unknow module: " + module.toString(), null, null, null, null, null);
        }

        return jsx;
    }


    private getNoUserNavbar() {

        const jsx =
            <div className='text-center' style={Styles.getDarkBackColorStyle()}>
                <Image src="imgs/kazmlogot.png" rounded fluid />
            </div>

        return jsx;
    }

    //======================= Handlers ==============================================//
    private handleBack(eventKey: any, event: any) {
        this.setState({ selectedKey: eventKey });

        if (eventKey === "backButton") {

            const section : Sections = Routing.getSection();
            const module = Routing.getModule();

            if (module == Modules.nomodule) {

                Routing.navigateTo(Sections.myEsoar, Modules.nomodule);
            }
            else {
                Routing.navigateTo(section, Modules.nomodule);
                
            }

        }
    }

}

export default withRouter(MainMenuMobile)
