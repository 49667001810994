import * as React from 'react';

import { Row, Col } from 'react-bootstrap'
import { OverlayTrigger } from 'react-bootstrap'
//import { Tooltip } from 'react-bootstrap'
import { Popover } from 'react-bootstrap'
import {ApplicationState} from './../HelperClasses/ApplicationState'



//import { Styles } from "./../HelperClasses/Styles"; 

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ProfilePicture } from "./ProfilePicture"
import { ContactOptions } from './ContactOptions'

import { Roles } from './../DTO/Role'
import { IMember } from "./../DTO/Member"
import { OverlayTriggerType } from 'react-bootstrap/esm/OverlayTrigger';

export interface IContactInfoProps {
    member: IMember

    size: 'sm' | 'md' | 'lg';
    
    showAuthorization: boolean;
}

export interface IContactInfoState {
    trigger: OverlayTriggerType | OverlayTriggerType[];
    showPopover: boolean;
}

export class ContactInfo extends React.Component<IContactInfoProps, IContactInfoState> {

    constructor(props: any) {
        super(props);

        this.state = { showPopover: false, trigger: ['focus', 'click'] } //'hover',

        this.getPopoverJSX = this.getPopoverJSX.bind(this);
        this.handleShow = this.handleShow.bind(this);
        this.handleContacted = this.handleContacted.bind(this);


    }

    render() {
        const jsx=
        <>
            {this.getOverlayJSX()}
            </>

        return jsx;
    }

    private getOverlayJSX() {

        const jsx =
            <OverlayTrigger
                overlay={this.getPopoverJSX}
                trigger={this.state.trigger}
                placement='auto'
                delay={{ show: 250, hide: 250 }}
                
                rootClose={true}
                show={this.state.showPopover}
                onToggle={this.handleShow}
               
            >
                {/*https://lifesaver.codes/answer/tooltip-doesn-t-show-when-overlaytrigger-child-is-a-custom-react-component*/}
                <span className='p-0 m-0' style={{ cursor: 'pointer' }}> 

                    {this.props.children}

                </span>
            </OverlayTrigger>

        return jsx;
    }


    private getPopoverJSX(props: any) {
        const correctedProps = { ...props };
        correctedProps.show = (correctedProps.show ? 'true' : 'false'); //bug van react.

        const jsx =
            <Popover
                id="popover-basic"
                {...correctedProps}
            >
                <Popover.Title as="h3">

                    <Row>
                        <Col className='text-center'>
                            <span>
                                {this.props.member ? this.props.member.getFullName() : ''}

                                <span style={{ position: 'absolute', right: 20 }} onClickCapture={() => { this.setState({ showPopover: false }) }}>
                                    <FontAwesomeIcon icon='times-circle' size='1x' color='black'  />
                                </span>
                            </span>
                            <hr />
                        </Col>

                    </Row>
                    <Row>
                        <Col className='text-center'>
                            <ProfilePicture size='lg' srcURL={this.props.member ? this.props.member.profilePictureUrl : ''} fluid={true} />
                        </Col>
                    </Row>


                </Popover.Title>
                <Popover.Content>

                    <Row>
                        <Col>
                            <span style={{  }}>Contacteer { this.props.member? this.props.member.firstName :  ''} via:</span>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <ContactOptions mode='list'
                                gsm={this.gsmShareable() ? this.props.member.gsm : null}
                                email={this.emailShareable()? this.props.member.email : null}
                                onContacted={this.handleContacted} />
                        </Col>
                    </Row>

                    {this.getShareStatusJSX()}

                </Popover.Content>
            </Popover>
        return jsx;
    }


    private getShareStatusJSX() {

        //console.log(this.props.member)
        //console.log(ApplicationState.get().sessionInfo.account)

        if (!this.props.member) return null;
        if (!this.props.showAuthorization) return null;

        const currentAccount = ApplicationState.get().sessionInfo.account;

        //Below is a feature.... not sure if we want to hide in this case
        //if (this.props.member.shareGsm && this.props.member.shareEmail
        //    && currentAccount.shareGsm && currentAccount.shareEmail) return null;

        let msg1 = this.props.member.firstName + ' heeft ';

        if (!this.props.member.shareGsm && !this.props.member.shareEmail) {
            msg1 += 'telefoon en email niet zichtbaar'
        }
        else if (!this.props.member.shareGsm) {
            msg1 += 'telefoon niet zichtbaar'
        }
        else if (!this.props.member.shareEmail) {
            msg1 += 'email niet zichtbaar'
        }
        else {
            msg1 += 'telefoon en email zichtbaar'
        }

        let msg2 = 'Jij hebt ';

        if (!currentAccount.shareGsm && !currentAccount.shareEmail) {
            msg2 += 'telefoon en email niet zichtbaar'
        }
        else if (!currentAccount.shareGsm) {
            msg2 += 'telefoon niet zichtbaar'
        }
        else if (!currentAccount.shareEmail) {
            msg2 += 'email niet zichtbaar'
        }
        else {
            msg2 += 'telefoon en email zichtbaar'
        }

        let msg3 = null;
        if (currentAccount.hasRole(Roles.Admin) || currentAccount.hasRole(Roles.Mngt)) {
            msg3 = 'Jij bent bestuurslid of eSoar beheerder'
        }


        const jsx =
            <>
                <hr />
                <FontAwesomeIcon inverse={false} size="lg" icon="handshake" />&nbsp;&nbsp;Toestemmingen
            <Row>
                <Col>
                    <span style={{ fontSize: '0.7rem' }}>
                        {msg1}
                    </span>
                    <br />
                    <span style={{ fontSize: '0.7rem' }}>
                        {msg2}
                    </span>

                    {msg3 ?
                        <>
                            <br />
                            <span style={{ fontSize: '0.7rem' }}>
                                {msg3}
                            </span>
                        </>
                        : null
                    }

                </Col>
            </Row>
            </>

        return jsx;
    }

    //============================= Handlers ===================================================================================//
    private handleShow(desiredShowState: boolean) {
        this.setState({showPopover: desiredShowState})
    }

    private handleContacted(via: 'gsm' | 'whatsapp' | 'email' | 'sms') {
        this.setState({ showPopover: false })
    }

    //=========================== Helpers ======================================================================================//

    private gsmShareable(): boolean {
        const currentAccount = ApplicationState.get().sessionInfo.account;

        if (currentAccount.hasRole(Roles.Admin) || currentAccount.hasRole(Roles.Mngt)) return true;

        const member = this.props.member;

        if (currentAccount.shareGsm && member.shareGsm) return true;
        return false;
    }

    private emailShareable(): boolean {
        const currentAccount = ApplicationState.get().sessionInfo.account;

        if (currentAccount.hasRole(Roles.Admin) || currentAccount.hasRole(Roles.Mngt)) return true;

        const member = this.props.member;

        if (currentAccount.shareEmail && member.shareEmail) return true;
        return false;
    }

    //private handleClick(e: any) {
    //    //console.log(e)
    //    //e.stopPropagation();
    //    //e.preventDefault();
    //    //e.nativeEvent.stopImmediatePropagation();
    //}
}