import { ISerializable } from './ISerializable';
import { IValidation, IValidationState } from './IValidation'
import { ILibraryCategory } from './LibraryCategory';

export interface ILibraryDoc extends ISerializable<ILibraryDoc>, IValidation {
    id: string;
    origFileName: string;
    displayName: string;
    filetype: string;
    sortNr: number;
    libraryCategory: ILibraryCategory

    clone(): ILibraryDoc;
}

export interface ILibraryDocValidationState extends IValidationState {
    origFileNameIsValid: boolean;
    displayNameIsValid: boolean;
    filetypeIsValid: boolean;
    categoryIsValid: boolean;
    sortNrIsValid: boolean;
    //
}

export class LibraryDoc implements ILibraryDoc {

    public readonly id: string = null;

    origFileName: string = null;;
    displayName: string = null;
    filetype: string = null;
    sortNr: number = null;

    libraryCategory: ILibraryCategory = null;
    

    constructor() {  }


    public getValidationState(): ILibraryDocValidationState {
        //console.log("Validation");
        //console.log(this.costPerMinute);

        let vs = {
            origFileNameIsValid: true,
            displayNameIsValid: (this.displayName && this.displayName.trim().length>0),
            filetypeIsValid: true,
            categoryIsValid: (this.libraryCategory? true : false),
            sortNrIsValid: true,

            stateIsValid: false
        } as ILibraryDocValidationState;

        vs.stateIsValid = (
            vs.origFileNameIsValid &&
            vs.displayNameIsValid &&
            vs.filetypeIsValid &&
            vs.categoryIsValid &&
            vs.sortNrIsValid
            );

        return vs;
    }

    public clone(): ILibraryDoc {
        const doc = Object.assign<LibraryDoc, ILibraryDoc>(new LibraryDoc(), this);

        return doc;
    }


    public fromJson(json: Object): LibraryDoc {
        //DO NOT USE 'this.' RETURN A NEW OBJECT
        //console.log("Aircraft.fromJson");

        const trueObject = new LibraryDoc();
        Object.assign<ILibraryDoc, Object>(trueObject, json);

        //Overloads
        //eg

        //Objects
        //if (trueObject.libraryCategory != null) trueObject.libraryCategory = (new LibraryCategory()).fromJson(trueObject.libraryCategory);

        //console.log(trueObject);
        return trueObject;
    }


    public toJson(): Object {
        //console.log("Aircraft.toJson");

        let x = JSON.parse(JSON.stringify(this));

        //Overloads
        //if (this.libraryCategory) x.libraryCategory = this.libraryCategory.toJson();

        return x;
    }
}


