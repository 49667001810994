import { IAircraft, Aircraft } from "./Aircraft";
import { IMember, Member } from "./Member";

import { IFlightType } from "./FlightType";
import { ILaunchType } from "./LaunchType";
import { ILaunchPaymentMethod } from "./LaunchPaymentMethod";


import { MyDateTime } from "./../HelperClasses/MyDate";
import { ISerializable } from './ISerializable';
import { IValidation, IValidationState } from './IValidation'
//import moment = require('moment');


export interface IFlight extends ISerializable<IFlight>, IValidation {
    id: string;

    day: MyDateTime;
    aircraft : IAircraft;
    takeOff : MyDateTime;
    landing : MyDateTime;
    frontSeat : IMember;
    rearSeat : IMember;
    flightType : IFlightType;
    launchType : ILaunchType;
    launchCost : number;
    launchPaymentMethod : ILaunchPaymentMethod;
    flightCostFrontSeat : number;
    flightCostRearSeat: number;
    launchCostFrontSeat: number;
    launchCostRearSeat: number;
    towPlane : IAircraft;
    towPilot : IMember;
    confirmed: boolean;
    noCosts: boolean;

}

export interface IFlightValidationState extends IValidationState {       
    dayIsValid: boolean;
    aircraftIsValid: boolean;
    takeOffIsValid: boolean;
    landingIsValid: boolean;
    frontSeatIsValid: boolean;
    rearSeatIsValid: boolean;
    flightTypeIsValid: boolean;
    launchTypeIsValid: boolean;
    launchCostIsValid: boolean;
    launchPaymentMethodIsValid: boolean;
    flightCostFrontSeatIsValid: boolean;
    flightCostRearSeatIsValid: boolean;
    launchCostFrontSeatIsValid: boolean;
    launchCostRearSeatIsValid: boolean;
    towPlaneIsValid: boolean;
    towPilotIsValid: boolean;
 
}

export class Flight implements IFlight {

    public readonly id: string = null;

    public day: MyDateTime = null;
    public aircraft: IAircraft = null;
    public takeOff: MyDateTime = null;
    public landing: MyDateTime = null;
    public frontSeat: IMember = null;
    public rearSeat: IMember = null;
    public flightType: IFlightType = null;
    public launchType: ILaunchType = null;
    public launchCost: number = 0;
    public launchPaymentMethod: ILaunchPaymentMethod = null;
    public flightCostFrontSeat: number = 0;
    public flightCostRearSeat: number = 0;
    public launchCostFrontSeat: number = 0;
    public launchCostRearSeat: number = 0;
    public towPlane: IAircraft = null;
    public towPilot: IMember = null;
    public confirmed: boolean = false;
    public noCosts: boolean = false;

    constructor() {
    }

    public getValidationState(): IFlightValidationState {
        //console.log("Validation");
        //console.log(this.costPerMinute);

        let vs = {
            dayIsValid: this.day ? this.day.isValid : false,
            aircraftIsValid: this.aircraftValidation(),
            takeOffIsValid: this.takeOff ? this.takeOff.isValid && (this.landing && this.landing.isValid ? this.takeOff.isBefore(this.landing) : true) : false,
            landingIsValid: this.landing ? this.landing.isValid && (this.takeOff && this.takeOff.isValid ? this.takeOff.isBefore(this.landing) : false) : true,
            frontSeatIsValid: this.seatValidation(),
            rearSeatIsValid: this.seatValidation(),
            flightTypeIsValid: this.flightType? true: false,
            launchTypeIsValid: this.launchType? true: false,
            launchCostIsValid: true,
            launchPaymentMethodIsValid: this.launchPaymentMethod? true: false,
            flightCostFrontSeatIsValid: true,
            flightCostRearSeatIsValid: true,
            launchCostFrontSeatIsValid: true,
            launchCostRearSeatIsValid: true,
            towPlaneIsValid: this.towPlaneValidation(),
            towPilotIsValid: this.towPilotValidation(),

            stateIsValid: false
        } as IFlightValidationState;

        vs.stateIsValid = (vs.dayIsValid && vs.aircraftIsValid && vs.takeOffIsValid && vs.landingIsValid && vs.frontSeatIsValid
            && vs.rearSeatIsValid && vs.flightTypeIsValid && vs.launchCostIsValid && vs.launchCostIsValid
            && vs.launchPaymentMethodIsValid && vs.flightCostFrontSeatIsValid && vs.flightCostRearSeatIsValid
            && vs.launchCostFrontSeatIsValid && vs.launchCostRearSeatIsValid
            && vs.towPlaneIsValid && vs.towPilotIsValid ); 

        return vs;
    }

    private towPlaneValidation(): boolean {
        if (this.noCosts) return true;
        if (!this.towPlane) return false;
        return true;
    }

    private towPilotValidation(): boolean {
        if (this.noCosts) return true;
        if (!this.towPilot) return false;
        if (this.frontSeat != null && this.towPilot != null && this.frontSeat.id == this.towPilot.id) return false;
        if (this.rearSeat != null && this.towPilot != null && this.rearSeat.id == this.towPilot.id) return false;
        return true;
    }

    private seatValidation(): boolean {
        if (this.flightType && this.flightType.code == "EXTERNAL" && this.frontSeat == null && this.rearSeat == null) return true;

        if (this.frontSeat == null && this.rearSeat == null) return false;

        if (this.frontSeat != null && this.rearSeat != null && this.frontSeat.id == this.rearSeat.id) return false;
        if (this.frontSeat != null && this.towPilot != null && this.frontSeat.id == this.towPilot.id) return false;
        if (this.rearSeat != null && this.towPilot != null && this.rearSeat.id == this.towPilot.id) return false;

        if (this.aircraft && this.aircraft.isSingleSeat && this.frontSeat == null) return false;
        if (this.aircraft && this.aircraft.isSingleSeat && this.rearSeat != null) return false;

        if (this.flightType.code == "SOLO" && (this.aircraft && this.aircraft.isSingleSeat)) return false;
        if (this.flightType.code == "SOLO" && this.frontSeat == null) return false;
        if (this.flightType.code == "SOLO" && this.rearSeat != null) return false;

        if (this.flightType.code == "TRAINING" && this.aircraft.isSingleSeat) return false;
        if (this.flightType.code == "TRAINING" && (this.frontSeat == null || this.rearSeat == null)) return false;
        if (this.flightType.code == "TRAINING" && !(this.frontSeat.isInstructor || this.frontSeat.isExaminator || this.rearSeat.isInstructor || this.rearSeat.isExaminator)) return false;

        //1 lege plaats bij een doop
        if ((this.flightType.code == "PASSENGER_ACQUAINTED" || this.flightType.code == "PASSENGER_VISITOR")
            && !(this.frontSeat == null || this.rearSeat == null)) return false;

        //1 lege plaats bij een examen
        if ((this.flightType.code == "EXAM")
            && !(this.frontSeat == null || this.rearSeat == null)) return false;

        //1 lege plaats bij een SOLO
        if ((this.flightType.code == "SOLO")
            && !(this.frontSeat == null || this.rearSeat == null)) return false;

        return true;
    }

    private aircraftValidation() {
        if (this.flightType && this.flightType.code == "EXTERNAL") return true;

        return this.aircraft;
    }



    public fromJson(json: Object): Flight {
        //DO NOT USE 'this.' RETURN A NEW OBJECT
        //console.log("Aircraft.fromJson");

        const trueObject = new Flight();
        Object.assign<IFlight, Object>(trueObject, json);

        //trueObject.key = trueObject.id;

        //Overloads
        trueObject.day = MyDateTime.fromJson(trueObject.day);
        trueObject.takeOff = MyDateTime.fromJson(trueObject.takeOff);
        trueObject.landing = MyDateTime.fromJson(trueObject.landing);


        //Objects
        if (trueObject.aircraft != null) trueObject.aircraft = (new Aircraft()).fromJson(trueObject.aircraft);
        if (trueObject.frontSeat != null) trueObject.frontSeat = (new Member()).fromJson(trueObject.frontSeat);
        if (trueObject.rearSeat != null) trueObject.rearSeat = (new Member()).fromJson(trueObject.rearSeat);
        if (trueObject.towPlane != null) trueObject.towPlane = (new Aircraft()).fromJson(trueObject.towPlane);
        if (trueObject.towPilot != null) trueObject.towPilot = (new Member()).fromJson(trueObject.towPilot);

        //console.log(trueObject);
        return trueObject;
    }


    public toJson(): Object {
        //console.log("Aircraft.toJson");

        let x = JSON.parse(JSON.stringify(this));

        //Overloads

        if (this.day) {
            x.day = this.day.toJson();
        }
        if (this.aircraft) {
            x.aircraft = this.aircraft.toJson();
        }
        if (this.takeOff) {
            x.takeOff = this.takeOff.toJson();
        }
        if (this.landing) x.landing = this.landing.toJson();
        
        if (this.frontSeat) x.frontSeat = this.frontSeat.toJson();
        if (this.rearSeat) x.rearSeat = this.rearSeat.toJson();

        if (this.towPilot) x.towPilot = this.towPilot.toJson();
        if (this.towPlane) x.towPlane = this.towPlane.toJson();

        //console.log(x);
        return x;
    }
}