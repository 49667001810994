
import * as React from 'react';
import { Dropdown, Row, Col } from 'react-bootstrap'
//import { Button } from 'react-bootstrap'
import { Button, Modal } from 'react-bootstrap';
import { ModalResult } from './../HelperClasses/Enums';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Styles } from "./../HelperClasses/Styles";

export interface IContactOptionsProps{
    title?: string;
    gsm?: string;
    email?: string;

    mode: 'dropdown' | 'modal' | 'list'

    onClose?: (modalResult: ModalResult) => void;
    onContacted?: (via: 'gsm' | 'whatsapp' | 'email' | 'sms') => void;
}

interface IContactOptionsState {
    show: boolean;
}

type RefType = number;

export class ContactOptions extends React.Component<IContactOptionsProps, IContactOptionsState> {

    constructor(props: any) {
        super(props);

        this.state = { show: false };

        this.handleClose = this.handleClose.bind(this);
    };

    render() {
        if (this.props.mode == 'dropdown') return this.getDropdownJSX(this.props);
        if (this.props.mode == 'modal') return this.getModalJSX(this.props);
        if (this.props.mode == 'list') return this.getListJSX(this.props);
        return null;
    }

    //https://stackoverflow.com/questions/65341399/property-onclick-does-not-exist-on-type-children-reactnode
    
    private IconToggle = React.forwardRef<RefType,any>(({ children, onClick } , ref) => (
        <span
            ref={(ref as any)} 
            onClick={(e) => {
                e.preventDefault();
                onClick(e);
            }}
        >
        {children}
        </span>
    )
    );

    private getDropdownJSX = (props: IContactOptionsProps) => {
        if (!this.props.gsm && !this.props.email) {
            return null;
            //<FontAwesomeIcon icon='phone' size='1x' color='grey' />
        }

        const jsx =

            <Dropdown>
                <Dropdown.Toggle id="dropdown-custom-1" as={this.IconToggle}>
                    <FontAwesomeIcon icon='phone' size='1x' color='black' />
                </Dropdown.Toggle>

                <Dropdown.Menu >
                    {this.getPhoneJSX()}
                    {this.getWhatsAppJSX()}
                    {this.getEmailJSX()}
                    {this.getSMSJSX()}
                </Dropdown.Menu>
            </Dropdown>

        return jsx;
    }

    private getModalJSX = (props: IContactOptionsProps) => {
        if (!this.props.gsm && !this.props.email) {
            return null;
            //return <FontAwesomeIcon icon='phone' size='1x' color='grey' />;
        }

        const jsx =
            <>
                <span onClick={() => {this.setState({ show: true })}}>
                    <FontAwesomeIcon icon='phone' size='1x' color='black' />
                </span>

                <Modal show={this.state.show} onHide={() => { this.handleClose(ModalResult.Cancel) }} >
                <Modal.Header closeButton={true}>
                        <Modal.Title>{this.props.title ? this.props.title : 'Contacteer via'}</Modal.Title>
                </Modal.Header>
                    <Modal.Body>
                        
                        <Row className='mx-auto text-center justify-content-center my-2'><Col>
                                {this.getPhoneModalJSX()}
                            </Col></Row>
                        <Row className='mx-auto text-center my-2'><Col>
                                {this.getWhatsAppModalJSX()}
                            </Col></Row>
                        <Row className='mx-auto text-center my-2'><Col>
                                {this.getEmailModalJSX()}
                            </Col></Row>
                        <Row className='mx-auto text-center my-2'><Col>
                                {this.getSMSModalJSX()}
                            </Col></Row>
                        
                </Modal.Body>
                <Modal.Footer>
                        <Button variant="secondary" onClick={() => { this.handleClose(ModalResult.Cancel) }} >Cancel</Button>
                </Modal.Footer>
            </Modal>
            </>
        return jsx;
    }

    private getListJSX = (props: IContactOptionsProps) => {

        const jsx =
            <>
                <Row className='mx-auto text-center justify-content-center my-2'>
                    <Col>
                        {this.getPhoneSmallJSX()}
                    </Col>

                    <Col>
                        {this.getWhatsAppSmallJSX()}
                    </Col>

                    <Col>
                        {this.getSMSSmallJSX()}
                    </Col>

                    <Col>
                        {this.getEmailSmallJSX()}
                    </Col>
                </Row>
            </>
        return jsx;
    }

    //------------------- individual lines per comm type available -----------------//

    private getPhoneJSX () {
        if (!this.props.gsm) return null;
        const jsx =
            <Dropdown.Item eventKey="1" href={"tel:" + this.props.gsm} onClick={() => { this.handleClose(ModalResult.OK) }} >
                <FontAwesomeIcon icon='phone' size='1x' color='#030ffc' />
            &nbsp;&nbsp;Bellen
        </Dropdown.Item>

        return jsx
    }

    private getPhoneModalJSX() {
        if (!this.props.gsm) return null;

        const jsx =
            <Button href={"tel:" + this.props.gsm} onClick={() => { this.handleClose(ModalResult.OK) }} className='text-left' variant="outline-primary" style={{ color: 'black', width: '10rem' }} >
                <FontAwesomeIcon icon='phone' size='1x' color='#030ffc' />
            &nbsp;&nbsp;Bellen
        </Button>

        return jsx
    }

    private getPhoneSmallJSX() {
        //console.log(this.props.gsm)
        if (!this.props.gsm)
            return <FontAwesomeIcon icon='phone' size='2x' color='grey' />;

        const jsx =
            <a href={"tel:" + this.props.gsm} onClickCapture={() => { this.handleContacted('gsm') }}>
                <FontAwesomeIcon icon='phone' size='2x' color='#030ffc'  />
            </a>
        return jsx
    }

    private getWhatsAppJSX() {
        if (!this. props.gsm) return null;

        const jsx =
            <>
                <Dropdown.Item eventKey="2" href={"whatsapp://send?phone=" + this.getWhatsAppNr(this.props.gsm)} className='d-block d-md-none' onClick={() => { this.handleClose(ModalResult.OK) }} >
                    <FontAwesomeIcon icon={['fab', 'whatsapp']} size='1x' color='#00b300' />
                    &nbsp;&nbsp;WhatsApp
                </Dropdown.Item>

                <Dropdown.Item eventKey="2" href={"https://api.whatsapp.com/send?phone=" + this.getWhatsAppNr(this.props.gsm)} target='_blank' className='d-none d-md-block' onClick={() => { this.handleClose(ModalResult.OK) }} >
                    <FontAwesomeIcon icon={['fab', 'whatsapp']} size='1x' color={Styles.getWhatsAppColor()} />
                    &nbsp;&nbsp;WhatsApp
                </Dropdown.Item>
            </>
       return jsx

    }

    private getWhatsAppModalJSX() {
        if (!this.props.gsm) return null;

        const jsx =
                <>
                <Button href={"whatsapp://send?phone=" + this.getWhatsAppNr(this.props.gsm)} className='d-inline-block d-md-none text-left' onClick={() => { this.handleClose(ModalResult.OK) }} variant="outline-primary" style={{ color: 'black', width: '10rem' }}>
                    <FontAwesomeIcon icon={['fab', 'whatsapp']} size='1x' color='#00b300' />
                    &nbsp;&nbsp;WhatsApp
                </Button>

                <Button href={"https://api.whatsapp.com/send?phone=" + this.getWhatsAppNr(this.props.gsm)} target='_blank' className='d-none d-md-inline-block text-left' onClick={() => { this.handleClose(ModalResult.OK) }} variant="outline-primary" style={{ color: 'black', width: '10rem' }}>
                    <FontAwesomeIcon icon={['fab', 'whatsapp']} size='1x' color={Styles.getWhatsAppColor()} />
                    &nbsp;&nbsp;WhatsApp
                </Button>
                </>

        return jsx
    }

    private getWhatsAppSmallJSX() {
        if (!this.props.gsm) return <FontAwesomeIcon icon={['fab', 'whatsapp']} size='2x' color='grey' />;
        const jsx =
            <>
                <a href={"whatsapp://send?phone=" + this.getWhatsAppNr(this.props.gsm)} className='d-block d-md-none' onClickCapture={() => { this.handleContacted('whatsapp') }}>
                    <FontAwesomeIcon icon={['fab', 'whatsapp']} size='2x' color='#00b300' />
                </a>

                <a href={"https://api.whatsapp.com/send?phone=" + this.getWhatsAppNr(this.props.gsm)} target='_blank' className='d-none d-md-block' onClickCapture={() => { this.handleContacted('whatsapp') }}>
                    <FontAwesomeIcon icon={['fab', 'whatsapp']} size='2x' color='#00b300' />
                </a>
            </>
        return jsx
    }

    private getEmailJSX(){
        if (!this.props.email) return null;
        const jsx =
            <Dropdown.Item eventKey="3" href={"mailto:" + this.props.email} onClick={() => { this.handleClose(ModalResult.OK) }} >
                <FontAwesomeIcon icon={'envelope'} size='1x' color='#cc0000' />
                &nbsp;&nbsp;Email
            </Dropdown.Item>

        return jsx
    }

    private getEmailModalJSX() {
        if (!this.props.email) return null;
        const jsx =
            <Button href={"mailto:" + this.props.email} onClick={() => { this.handleClose(ModalResult.OK) }} className='text-left' variant="outline-primary" style={{ color: 'black', width: '10rem' }} >
                <FontAwesomeIcon icon={'envelope'} size='1x' color='#cc0000' />
                &nbsp;&nbsp;Email
            </Button>

        return jsx
    }

    private getEmailSmallJSX() {
        if (!this.props.email) return <FontAwesomeIcon icon={'envelope'} size='2x' color='grey' />;
        const jsx =
            <a href={"mailto:" + this.props.email} onClickCapture={() => { this.handleContacted('email') }}>
                <FontAwesomeIcon icon={'envelope'} size='2x' color='#cc0000' />
            </a>
        return jsx
    }

    private getSMSJSX() {
        if (!this.props.gsm) return null;
        const jsx =
            <Dropdown.Item eventKey="4" href={"sms:" + this.props.gsm} className='d-block d-md-none' onClick={() => { this.handleClose(ModalResult.OK) }} >
                <FontAwesomeIcon icon={'sms'} size='1x' color='blue' />
                &nbsp;&nbsp;SMS
            </Dropdown.Item>

        return jsx
    }

    private getSMSModalJSX(){
        if (!this.props.gsm) return null;
        const jsx =
            <Button href={"sms:" + this.props.gsm} className='d-inline-block d-md-none text-left' onClick={() => { this.handleClose(ModalResult.OK) }} variant="outline-primary" style={{ color: 'black', width: '10rem' }}  >
                <FontAwesomeIcon icon={'sms'} size='1x' color='blue' />
                &nbsp;&nbsp;SMS
            </Button>

        return jsx
    }

    private getSMSSmallJSX() {
        if (!this.props.gsm) return <FontAwesomeIcon icon={'sms'} size='2x' color='grey' />;
        const jsx =
            <a href={"sms:" + this.props.gsm} onClickCapture={() => { this.handleContacted('sms') }} >
                <FontAwesomeIcon icon={'sms'} size='2x' color='blue' />
            </a>
        return jsx
    }

    //----------------------------------------  Handlers ------------------------//


    private handleClose = (result: ModalResult) => {
    
        this.setState({ show: false });

        if (this.props.onClose) {
            this.props.onClose(result);
        }
    }


    private handleContacted = (via: 'gsm' | 'whatsapp' | 'email' | 'sms') => {

        if (this.props.onContacted) {
            this.props.onContacted(via);
        }
    }
    //----------------------------------------  impl ------------------------//

    private getWhatsAppNr(gsm: string) {
        if (!gsm) return null;
        let res = gsm.replace(' ', '');
        if (res[0] == '+') {
            res = res.substr(1, res.length - 1)
        }
        return res;
    }

}


