import * as React from 'react';


//import Container from 'react-bootstrap/Container';
import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';

import { Form, Button, Badge, Alert } from 'react-bootstrap';
//import { Jumbotron } from 'react-bootstrap';

import { InputGroup } from 'react-bootstrap';
//import { Card, Accordion, Badge } from 'react-bootstrap';

import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import 'react-bootstrap-typeahead/css/Typeahead-bs4.css';

import { DatePickerInput } from 'rc-datepicker';
import 'rc-datepicker/lib/style.css';

import { Styles } from './../HelperClasses/Styles';
import { Formatters } from './../HelperClasses/Formatters';

import { ModalResult, FormModus } from './../HelperClasses/Enums';
import { LoadingSpinner } from '../UIComponents/MySpinners';
import { NumericField } from '../UIComponents/NumericField'
import { WizardProgressBar, IWizardStep } from '../UIComponents/WizardProgressBar'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { ApplicationState } from "./../HelperClasses/ApplicationState";
//import { ISessionInfo } from "./../HelperClasses/SessionInfo";
//import { SimpleModal } from "../UIComponents/SimpleModal";
//import { Http } from "../HelperClasses/Http";
import { Cache } from "../HelperClasses/Cache";
import { CustomError } from '../HelperClasses/CustomError';
import { ICountry } from "./../DTO/Country";
import { IEvent, Event } from "./../DTO/Event";
import { ISex } from "./../DTO/Sex";

import { ICampRegistration, CampRegistration, ICampRegistrationValidationState } from './../DTO/CampRegistration'
import { MyDateTime } from "./../HelperClasses/MyDate";
import { RRN } from "./../HelperClasses/RRN";
import { RRNInputField } from "./../UIComponents/RRNInputField";



export interface ICampRegistrationFormProps {
    formMode: FormModus;

    adminModus?: boolean;

    entityIn?: ICampRegistration;
    event: IEvent;

    onClose: (result: ModalResult, entity: ICampRegistration) => void;
}

interface ICampRegistrationFormState {
    isLoading: boolean;
    isValidated: boolean;

    //showConfirmModal: boolean;

    entity: ICampRegistration;

    selectedWizardStep: number;
    wizardSteps: Array<IWizardStep>;

    allCountries: Array<ICountry>;
    allSexes: Array<ISex>;

    validationState: ICampRegistrationValidationState;
}

//ToDo: Summary page, and email aknowledgment with code and documents (overview with costs in mail)
// existing record :  mwyno

export class CampRegistrationForm extends React.Component<ICampRegistrationFormProps, ICampRegistrationFormState> {

    private entity: ICampRegistration;

    private baseColor: string = Styles.getMeeuwColor();

    private confirmationDate: string = "5 Juni 2023";

    constructor(props: any) {
        super(props);


        //var initialEntity;
        if (this.props.formMode == FormModus.Edit) {

            if (this.props.entityIn == null) {
                ApplicationState.get().setError(new CustomError("No form entity provided.", null, null, null, null, null));
                return;
            }
            this.entity = Object.assign<CampRegistration, ICampRegistration>(new CampRegistration(), this.props.entityIn);

        }
        else {
            this.entity = new CampRegistration();
        }

        if (!this.entity.event) {
            this.entity.event = Object.assign<Event, IEvent>(new Event(), this.props.event);
        }

        //console.log(this.entity)

        //Set initial state
        this.state = {
            isLoading: false, isValidated: false,
            entity: this.entity,

            selectedWizardStep: (this.props.adminModus ? 0 : null),
            wizardSteps:
                        [
                            { subTitle: 'Stap 1. Persoonsgegevens', color: this.baseColor },
                            { subTitle: 'Stap 2. Contactgegevens', color: this.baseColor },
                            { subTitle: 'Stap 3. Vlieggegevens', color: this.baseColor },
                            { subTitle: 'Stap 4. Inschrijving', color: this.baseColor },
                            { subTitle: 'Stap 5. Bevestigen', color: this.baseColor },
                        ],

            allCountries: new Array<ICountry>(),
            allSexes: new Array<ISex>(),

            validationState: (this.entity.getValidationState() as ICampRegistrationValidationState)
        };

        //Form methods
        //this.handleSessionChange = this.handleSessionChange.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handlePrevious = this.handlePrevious.bind(this);
        this.handleNext = this.handleNext.bind(this);
        this.handleStepChange = this.handleStepChange.bind(this);
        this.validateForm = this.validateForm.bind(this);
        this.setStateAndValidation = this.setStateAndValidation.bind(this);

        //Form Handlers
        this.handleFirstNameChange = this.handleFirstNameChange.bind(this);
        this.handleSurNameChange = this.handleSurNameChange.bind(this);
        this.handleSexChange = this.handleSexChange.bind(this);
        this.handleNationalityChange = this.handleNationalityChange.bind(this);
        this.handleDateBirthChange = this.handleDateBirthChange.bind(this);
        this.handlePlaceBirthChange = this.handlePlaceBirthChange.bind(this);
        this.handleAddressChange = this.handleAddressChange.bind(this);
        this.handleZipChange = this.handleZipChange.bind(this);
        this.handleCityChange = this.handleCityChange.bind(this);
        this.handleRRNChange = this.handleRRNChange.bind(this);


        this.handleGsmChange = this.handleGsmChange.bind(this);
        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.handleContactGsmChange = this.handleContactGsmChange.bind(this);
        this.handleContactEmailChange = this.handleContactEmailChange.bind(this);

        this.handleWeightChange = this.handleWeightChange.bind(this);
        this.handleHeightChange = this.handleHeightChange.bind(this);
        this.handleWeek1Change = this.handleWeek1Change.bind(this);
        this.handleWeek2Change = this.handleWeek2Change.bind(this);

        this.handleExperienceChange = this.handleExperienceChange.bind(this);
        this.handleRemarksChange = this.handleRemarksChange.bind(this);

        this.handleCountryChange = this.handleCountryChange.bind(this);

        this.handleMedicalExpiresChange = this.handleMedicalExpiresChange.bind(this);

        this.handleIsSoloChange = this.handleIsSoloChange.bind(this);
        this.handlePreviousKAZMMemberChange = this.handlePreviousKAZMMemberChange.bind(this);
        this.handleCurrentLVZCMemberChange = this.handleCurrentLVZCMemberChange.bind(this);

        this.handleLVZCSignatureChange = this.handleLVZCSignatureChange.bind(this);
        this.handleKAZMSignatureChange = this.handleKAZMSignatureChange.bind(this);

       
    }

    render() {

        //<Jumbotron className='m-0'>
        //    {form}
        //</Jumbotron>

        let form = this.getForm();

        return (
            <>
                    {form}
            </>
        );
    }

    //======================== Get Form JSX elements =============================//

    private getForm() {
        let form =
            <LoadingSpinner active={this.state.isLoading} >

                <Form noValidate validated={false} className='m-2'>

                    {this.getInnerFormJSX()}

                    <hr />
                    <div style={{ textAlign: 'right' }}>

                        <Button className='mx-2' variant="secondary" onClick={() => { this.handleClose(ModalResult.Cancel) }} >Annuleren</Button>
                        <Button className='mx-2' variant="primary" onClick={() => { this.handlePrevious() }} hidden={(this.state.selectedWizardStep <= 0)} ><FontAwesomeIcon icon='arrow-alt-circle-left' /><span className='d-none d-sm-inline'>&nbsp; Vorige</span></Button>
                        <Button className='mx-2' variant="primary" onClick={() => { this.handleNext() }} hidden={(this.state.selectedWizardStep >= this.state.wizardSteps.length - 1)} > <span className='d-none d-sm-inline'>{Number.isInteger(this.state.selectedWizardStep) ? 'Volgende ' : 'Start '}</span><FontAwesomeIcon icon='arrow-alt-circle-right' /></Button>
                        <Button className='mx-2' variant="primary"
                            onClick={() => { this.handleClose(ModalResult.OK) }}
                            hidden={this.state.selectedWizardStep < this.state.wizardSteps.length - 1} >

                            {this.getOKButtonText()}

                        </Button>
                        <Form.Text
                            style={Styles.getErrorColorStyle()}
                            hidden={!
                                (
                                    this.state.isValidated &&
                                    this.state.wizardSteps[this.state.selectedWizardStep].color == Styles.getErrorColor()
                                )
                            }
                        >
                            Niet alle velden zijn correct ingevuld. Controleer de rode velden en probeer opnieuw
                        </Form.Text>
                        <Form.Text
                            style={Styles.getErrorColorStyle()}
                            hidden={!
                                (
                                this.state.isValidated && !this.entity.getValidationState().stateIsValid && this.state.selectedWizardStep==4
                                )
                            }
                        >
                            Niet alle velden zijn correct ingevuld. Controleer de rode velden en probeer opnieuw
                        </Form.Text>
                    </div>

                </Form>

            </LoadingSpinner>

        return form;
    }

    private getOKButtonText() {
        if (this.props.adminModus) {
            return <span>OK</span>
        }

        return(
            <>
                <FontAwesomeIcon icon='envelope' />
                <span className='d-none d-sm-inline'>&nbsp; Aanvraag versturen</span>
            </>
        )

    }

    private getInnerFormJSX() {
        //disabled={!Number.isInteger(this.state.selectedWizardStep)}
        var JSX =
            <>
                <div className='text-center'>
                    <h3>
                    <Badge variant='secondary' >
                    {this.state.entity ? (this.state.entity.firstName ? this.state.entity.firstName : '') + ' ' + (this.state.entity.surName ? this.state.entity.surName : '') : ''}
                        </Badge>
                    </h3>
                </div>
                <hr />

                <div className=''>
                    <WizardProgressBar
                        steps={this.state.wizardSteps}
                        size={3}
                        selectedStep={this.state.selectedWizardStep}
                        stepSelection='free'
                        onStepSelected={(stepNr) => { this.handleStepChange(stepNr) }}
                        disabled={false}
                    />
                </div>
                <hr />
                <div className='mx-auto'>
                    {!Number.isInteger(this.state.selectedWizardStep) ? this.getIntroPageJSX() : null} 
                    {this.state.selectedWizardStep == 0 ? this.getMemberIdentityJSX() : null}
                    {this.state.selectedWizardStep == 1 ? this.getMemberContactJSX() : null}
                    {this.state.selectedWizardStep == 2 ? this.getFlightSkillsJSX() : null}
                    {this.state.selectedWizardStep == 3 ? this.getRegistrationJSX() : null}
                    {this.state.selectedWizardStep == 4 ? this.getSummaryJSX() : null}
                </div>
            </>
        return JSX;
    }

    private getIntroPageJSX() {
        if (this.entity.id) {
            return this.getIntroPageExistingJSX();
        }
        else {
            return this.getIntroPageNewJSX();
        }

    }

    private getIntroPageNewJSX() {
        const doc = "FolderZweefvliegstage" + (this.props.event ? this.props.event.startDate.year : "") + ".pdf";

        const jsx =
            <>
                {this.getIntroPageHeaderJSX()}
                <br />
                Hoe werkt het:
                <br />
                <br />
                <ul>
                    <li>
                        Ben je reeds lid, schrijf je dan in via <a href="/">eSoar!.</a>
                    </li>
                    <li>
                        Lees eerst aandachtig onze &nbsp;
                        <Badge variant='secondary' >
                            <a href={"camp/" + doc}
                            target="_blank"
                                type="application/octet-stream" download={doc}
                                style={{ color: 'white' }}
                        >
                            info brochure &nbsp;
                            <FontAwesomeIcon icon="cloud-download-alt" />
                        </a>
                        </Badge>
                        &nbsp;, dit zal je helpen bij de inschrijving

                    </li>
                    <li>
                        De aanvraag tot inschrijving verloopt in vijf stappen.
                    </li>
                    <li>
                        Velden met een * zijn verplicht.
                    </li>
                    <li>
                        Indien je voor een tweede keer naar het kamp komt is een medisch vergunning verplicht
                    </li>
                    <li>
                        Controleer alle gegevens in stap 5 alvorens de aanvraag te verzenden
                    </li>
                    <li>
                        Na verzending ontvang je een email ter bevestiging van uw aanvraag en een code waarmee je later de aanvraag nog kunt wijzigen.
                        <br />
                        Deze email bevat ook een aantal documenten ter ondertekening.
                    </li>
                    <li>
                        Om de aanvraag compleet te maken dien je de ondertekende documenten te sturen naar &nbsp;
                        <Badge variant='secondary' >
                        <a style={{ color: 'white' }} href='mailto:info@zweefkamp.be'>
                            info@zweefkamp.be &nbsp;
                            <FontAwesomeIcon icon="envelope" />
                        </a>
                        </Badge>
                        
                        <br />
                        <strong>Opgelet</strong>: indien de inschrijving over een minderjarige gaat dient tevens ondertekend te worden door een ouder/voogd.
                    </li>
                    <li>
                        De inschrijving sluit op <strong>{Formatters.getMediumDateString(this.props.event.deadline)}</strong>.
                        <br />
                        Bevestiging van deelname ontvang je dan ten laatste op <strong>{this.confirmationDate}</strong>.
                    </li>
                    <li>
                        Vragen of problemen? Contacteer ons op : &nbsp;
                        <Badge variant='secondary' >
                            <a style={{ color: 'white' }} href='mailto:info@zweefkamp.be'>
                                info@zweefkamp.be &nbsp;
                                <FontAwesomeIcon icon="envelope" />
                            </a>
                        </Badge>
                    </li>

                </ul>

                <br />
            </>
        return jsx;

    }

    private getIntroPageExistingJSX() {
        const doc = "FolderZweefvliegstage" + (this.props.event ? this.props.event.startDate.year : "") + ".pdf";
        const jsx =
            <>
                {this.getIntroPageHeaderJSX() }
                <br />
                Je wilt je inschrijving aanpassen:
                <br />
                <br />
                <ul>
                    <li>
                        Navigeer door de verschillende schermen met de pijltjes onderaan of door op de stappen bovenaan te klikken
                    </li>
                    <li>
                        Velden met een * zijn verplicht.
                    </li>
                    <li>
                        Indien je voor een tweede keer naar het kamp komt is een medisch keuring verplicht.
                    </li>
                    <li>
                        Controleer alle gegevens in stap 5 alvorens de aanvraag te verzenden
                    </li>
                    <li>
                        De inschrijving sluit op <strong>{Formatters.getMediumDateString(this.props.event.deadline)}</strong>.
                        <br />
                        Bevestiging van deelname ontvang je dan ten laatste op <strong>{this.confirmationDate}</strong>.
                    </li>
                    <li>
                        Lees onze &nbsp;
                        <Badge variant='secondary' >
                            <a href={"camp/" + doc}
                            target="_blank"
                                type="application/octet-stream" download={doc}
                            style={{ color: 'white' }}
                        >
                            info brochure &nbsp;
                            <FontAwesomeIcon icon="cloud-download-alt" />
                            </a>
                            </Badge>
                        &nbsp;, dit zal je helpen bij de inschrijving

                    </li>
                    <li>
                        Vragen of problemen? Contacteer ons op : &nbsp;
                        <Badge variant='secondary' >
                        <a style={{ color: 'white' }} href='mailto:info@zweefkamp.be'>
                            info@zweefkamp.be &nbsp;
                            <FontAwesomeIcon icon="envelope" />
                        </a>
                        </Badge>
                    </li>

                </ul>

                <br />
            </>
        return jsx;
    }

    private getIntroPageHeaderJSX() {
        const jsx =
            <>
                <h3 className='d-block d-sm-none'>Inschrijven zweefkamp</h3>
                <h3 className='d-none d-sm-block d-lg-none'>{'Inschrijven zweefkamp ' + this.props.event.startDate.year} </h3>
                <h3 className='d-none d-lg-block'>{'Welkom op de inschrijvings pagina van het zweefkamp ' + this.props.event.startDate.year}</h3>
            </>
        return jsx;
    }

    private getMemberIdentityJSX() {

        const jsx =
            <>
                {this.getLine1JSX(10)}
                {this.getLine2JSX(20)}
                {this.getLine3JSX(30)}

            </>
        //{ this.getLine3JSX() }
        return jsx;
    }

    private getMemberContactJSX() {

        const jsx =
            <>
                {this.getLine4JSX(40)}
                {this.getLine5JSX(50)}
                {this.getLine6JSX(60)}
                {this.getLine7JSX(70)}
            </>
        return jsx;
    }

    private getFlightSkillsJSX() {

        const jsx =
            <>
                {this.getLine8JSX(80)}
                {this.getLine9JSX(90)}
                {this.getLine10JSX(100)}
                {this.getLine11JSX(110)}
            </>
        return jsx;
    }

    private getRegistrationJSX() {
        const jsx =
            <>
                {this.getLine12JSX(120)}
                {this.getLine13JSX(130)}
                {this.getLine14JSX(140)}
            </>
        return jsx;
    }

    private getSummaryJSX() {

        const w1 = 'van 11 t/m 15 juli';
        const w2 = 'van 18 t/m 22 juli';

        let presence = '';
        if (this.entity.week1) {
            presence += w1;
        }
        if (this.entity.week1 && this.entity.week2) {
            presence += ' en '
        }
        if (this.entity.week2) {
            presence += w2;
        }
        presence += '.';

        const jsx =
            <>
                <h3>Overzicht</h3>
                <br />
                <Alert variant='info'>
                    {this.entity.firstName} zou willen komen zweefvliegen in de week {presence}
                </Alert>
                <br />
                <strong>Details</strong>

                <hr />
                {this.getSummaryTableJSX()}

                <hr />

                {this.getSignaturesJSX() }

            </>

        return jsx;
    }

    private getSummaryTableJSX() {
        if (!this.state.entity) return null;

        const rrn = new RRN(this.state.entity.nationalNr);

        const table = new Array<any>();

        table.push({ name: 'Naam', value: this.state.entity.firstName ? this.state.entity.firstName : "" + ' ' + this.state.entity.surName ? this.state.entity.surName:"" });
        table.push({ name: 'Rijksregister', value: rrn.toString() });
        table.push({ name: 'Geslacht', value: this.state.entity.sex ? this.state.entity.sex.description : null });
        table.push({ name: 'Geboren', value: Formatters.getMediumDateString(this.state.entity.dateBirth) });
        table.push({ name: 'Geboren te', value: this.state.entity.placeBirth });
        table.push({ name: 'Nationaliteit', value: this.state.entity.nationality ? this.state.entity.nationality.name : '' });

        table.push({ name: 'Adres', value: this.state.entity.address });
        table.push({ name: '', value: (this.state.entity.zip ? this.state.entity.zip : '') + ' ' + (this.state.entity.city ? this.state.entity.city : '') });
        table.push({ name: '', value: this.state.entity.country ? this.state.entity.country.name : '' });
        table.push({ name: 'Gsm', value: this.state.entity.gsm });
        table.push({ name: 'Email', value: this.state.entity.email });

        table.push({ name: 'Contactpersoon', value: this.state.entity.contactGsm });
        //table.push({ name: '', value: this.state.entity.contactEmail });

        //table.push({ name: 'Vliegervaring', value: this.state.entity.experience});
        //table.push({ name: 'Medical', value: Formatters.getMediumDateString(this.state.entity.medicalExpires) });
        //table.push({ name: 'Reeds solo geweest', value: this.state.entity.isSolo ? 'Ja' : 'Nee' });
        //table.push({ name: 'Lengte', value: Formatters.tocm(this.state.entity.height/100.00) });
        //table.push({ name: 'Gewicht', value: Formatters.toKg(this.state.entity.weight) });

        return this.createSummaryTableJSX(table);
    }

    private createSummaryTableJSX(data: Array<any>) {
        const tableJSX = data.map(
            (tblRow, index) => {
                const jsx =
                    <Row key={index}>
                        <Col xs={5}>
                            <>
                                {tblRow.name}
                            </>
                        </Col>
                        <Col xs={7}>
                            <b>
                                {tblRow.value}
                            </b>
                        </Col>
                    </Row>

                return jsx;
            });

        return tableJSX;

    }

    private getSignaturesJSX() {
        let jsx = <></>

        if (this.props.adminModus) {
            jsx =
                <>
                <strong>Ondertekende documenten (via email)</strong>
                <br />
                <Row>
                    <Col xs={12} lg={8}>
                        <Form.Group controlId="frmLVZC"
                            style={(!this.state.entity.docsLVZC) ? Styles.getDivErrorStyle() : {}}
                        >
                            <Form.Text>LVZC</Form.Text>
                            <InputGroup>
                                <InputGroup.Prepend>
                                    <InputGroup.Checkbox id='cbxLVZC' type="checkbox" checked={this.state.entity.docsLVZC}
                                        onChangeCapture={this.handleLVZCSignatureChange}
                                        tabIndex={1}
                                        onChange={() => { }}
                                    />
                                </InputGroup.Prepend>
                                <Form.Control readOnly={true} value="Akkoord aanvraag LVZC ontvangen"
                                    onClickCapture={this.handleLVZCSignatureChange}
                                />
                            </InputGroup>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} lg={8}>
                        <Form.Group controlId="frmKAZM"
                            style={(!this.state.entity.docsKAZM) ? Styles.getDivErrorStyle() : {}}
                        >
                            <Form.Text>KAZM</Form.Text>
                            <InputGroup>
                                <InputGroup.Prepend>
                                    <InputGroup.Checkbox id='cbxKAZM' type="checkbox" checked={this.state.entity.docsKAZM}
                                        onChangeCapture={this.handleKAZMSignatureChange}
                                        tabIndex={2}
                                        onChange={() => { }}
                                    />
                                </InputGroup.Prepend>
                                <Form.Control readOnly={true} value="Akkoord aanvraag KAZM ontvangen"
                                    onClickCapture={this.handleKAZMSignatureChange}
                                />
                            </InputGroup>
                        </Form.Group>
                    </Col>
                </Row>
                </>
        }
        else {
            jsx =
                <>
                    <strong>Ondertekende documenten (via email)</strong>
                    <br />
                    {this.entity.docsLVZC ?
                        <FontAwesomeIcon icon='check' color='green' fixedWidth={true} />
                        :
                        <FontAwesomeIcon icon='times' color='red' fixedWidth={true} />
                    }
                    &nbsp; Akkoord aanvraag LVZC ontvangen
                    <br />
                    {this.entity.docsKAZM ?
                        < FontAwesomeIcon icon='check' color='green' fixedWidth={true} />
                        :
                        <FontAwesomeIcon icon='times' color='red' fixedWidth={true} />
                    }
                    &nbsp; Akkoord aanvraag KAZM ontvangen
                    <br />
                </>
        }

        return jsx;
    }

    private getLine1JSX(tIndexBase: number) {
        
        const jsx =
            <Row className='mx-auto'>
                <Col sm={6} md={6} lg={4}>
                    <Form.Group controlId="frmfirstName">

                        <Form.Text>Voornaam *</Form.Text>
                        <Form.Control autoFocus type="input" placeholder="..."
                            value={this.state.entity.firstName ? this.state.entity.firstName : ""}
                            onChangeCapture={this.handleFirstNameChange}
                            isInvalid={!this.state.validationState.firstNameIsValid && this.state.isValidated} ref="refFirstFocus"
                            tabIndex={tIndexBase + 1} onChange={() => { }} />

                    </Form.Group>
                </Col>

                <Col sm={6} md={6} lg={4}>
                    <Form.Group controlId="frmSurName">

                        <Form.Text>Achternaam *</Form.Text>
                        <Form.Control autoFocus type="input" placeholder="..."
                            value={this.state.entity.surName ? this.state.entity.surName : ""}
                            onChangeCapture={this.handleSurNameChange}
                            isInvalid={!this.state.validationState.surNameIsValid && this.state.isValidated} ref="refSurName"
                            tabIndex={tIndexBase + 2} onChange={() => { }} />

                    </Form.Group>
                </Col>

            </Row>

        return jsx;
    }

    private getLine2JSX(tIndexBase: number) {

        const jsx =
            <Row className='mx-auto'>
                <Col sm={6} md={6} lg={4}>
                    <Form.Group controlId="RRN">
                        <Form.Text >Rijksregister nummer *</Form.Text>
                        <RRNInputField
                            RRN={this.state.entity.nationalNr}
                            onChangeCapture={this.handleRRNChange}
                            isInvalid={!this.state.validationState.nationalNrIsValid && this.state.isValidated}
                            tabIndex={tIndexBase + 1} />
                    </Form.Group>
                </Col>

                <Col xs={6} sm={3} md={3} lg={2}>
                    <Form.Group controlId="frmDateBirth" tabIndex={tIndexBase + 2}>
                        <Form.Text >Geboortedatum *</Form.Text>
                        <DatePickerInput
                            value={this.state.entity.dateBirth && this.state.entity.dateBirth.isValid ? this.state.entity.dateBirth.moment : null}
                            onChange={this.handleDateBirthChange}
                            showOnInputClick={true}
                            small={false}
                            placeholder="31/12/1999"
                            ref="refArcValidUntil"
                            position="bottom"
                            style={(!this.state.validationState.dateBirthIsValid && this.state.isValidated) ? Styles.getDivErrorStyle() : {}}

                        />
                    </Form.Group>
                </Col>

                <Col xs={6} sm={3} md={3} lg={2}>
                    <Form.Group controlId="frmSexName" >
                        <Form.Text>Geslacht *</Form.Text>
                        {this.getSexJSX(tIndexBase + 3)}
                    </Form.Group>
                </Col>

            </Row>

        return jsx;
    }

    private getLine3JSX(tIndexBase: number) {
        const jsx =
            <Row className='mx-auto'>


                <Col sm={6} md={6} lg={4}>
                    <Form.Group controlId="frmPlaceBirth">
                        <Form.Text >Geboorteplaats *</Form.Text>
                        <Form.Control autoFocus type="input" placeholder="..."
                            value={this.state.entity.placeBirth ? this.state.entity.placeBirth : ""}
                            onChangeCapture={this.handlePlaceBirthChange}
                            isInvalid={!this.state.validationState.placeBirthIsValid && this.state.isValidated} ref="refPlaceBirth"
                            tabIndex={tIndexBase + 1} onChange={() => { }} />
                    </Form.Group>
                </Col>

                <Col sm={6} md={6} lg={4}>
                    <Form.Group controlId="frmNationality" >
                        <Form.Text >Nationaliteit *</Form.Text>
                        {this.getNationalityJSX(tIndexBase + 2)}
                    </Form.Group>
                </Col>
            </Row>

        return jsx;
    }

    private getLine4JSX(tIndexBase: number) {
        const jsx =
            <Row>
                <Col lg={8}>
                    <Form.Group controlId="frmAddress">
                        <Form.Text>Adres *</Form.Text>
                        <Form.Control autoFocus type="input" placeholder="straat en (bus)nummer..."
                            value={this.state.entity.address ? this.state.entity.address : ""}
                            onChangeCapture={this.handleAddressChange} isInvalid={!this.state.validationState.addressIsValid && this.state.isValidated}
                            ref="refFirstFocus"
                            tabIndex={1}
                            onChange={() => { }} />
                    </Form.Group>
                </Col>
            </Row>

        return jsx;
    }

    private getLine5JSX(tIndexBase: number) {
        const jsx =
            <Row>
                <Col xs={4} sm={2} md={2} lg={2} >
                    <Form.Group controlId="frmZipCode">
                        <Form.Text>Postcode *</Form.Text>
                        <Form.Control autoFocus type="input" placeholder="1234..."
                            value={this.state.entity.zip ? this.state.entity.zip : ""}
                            onChangeCapture={this.handleZipChange} isInvalid={!this.state.validationState.zipIsValid && this.state.isValidated}
                            ref="refZip"
                            tabIndex={tIndexBase + 1}
                            onChange={() => { }} />
                    </Form.Group>
                </Col>

                <Col xs={8} sm={4} md={4} lg={3}>
                    <Form.Group controlId="frmCityName">
                        <Form.Text>Plaats *</Form.Text>
                        <Form.Control autoFocus type="input" placeholder="..."
                            value={this.state.entity.city ? this.state.entity.city : ""}
                            onChangeCapture={this.handleCityChange} isInvalid={!this.state.validationState.cityIsValid && this.state.isValidated}
                            ref="refPlaats"
                            tabIndex={tIndexBase + 2}
                            onChange={() => { }} />
                    </Form.Group>
                </Col>

                <Col sm={6} md={6} lg={3}>
                    <Form.Group controlId="frmCountry">
                        <Form.Text>Land *</Form.Text>
                        {this.getCountryJSX(tIndexBase + 3)}
                    </Form.Group>
                </Col>

            </Row>
        return jsx;
    }

    private getLine6JSX(tIndexBase: number) {
        const jsx =
            <Row>


                <Col sm={6} lg={4}>
                    <Form.Group controlId="frmGsm">
                        <Form.Text>GSM *</Form.Text>
                        <Form.Control autoFocus type="input" placeholder="+32 456 12 34 56..."
                            value={this.state.entity.gsm ? this.state.entity.gsm : ""}
                            onChangeCapture={this.handleGsmChange} isInvalid={!this.state.validationState.gsmIsValid && this.state.isValidated}
                            ref="refGsm"
                            tabIndex={tIndexBase + 1}
                            onChange={() => { }} />
                    </Form.Group>
                </Col>


                <Col sm={6} lg={4}>
                    <Form.Group controlId="frmEmail">
                        <Form.Text>Email *</Form.Text>
                        <Form.Control autoFocus type="email" placeholder="iemand@ergens.be ..."
                            value={this.state.entity.email ? this.state.entity.email : ""}
                            onChangeCapture={this.handleEmailChange} isInvalid={!this.state.validationState.emailIsValid && this.state.isValidated}
                            ref="refEmail"
                            tabIndex={tIndexBase + 2}
                            onChange={() => { }} />
                    </Form.Group>
                </Col>


            </Row>

        return jsx;
    }

    private getLine7JSX(tIndexBase: number) {
        const jsx =
            <Row>

                <Col sm={6} lg={4}>
                    <Form.Group controlId="frmGsm">
                        <Form.Text>GSM, extra contact persoon *</Form.Text>
                        <Form.Control autoFocus type="input" placeholder="+32 456 12 34 56..."
                            value={this.state.entity.contactGsm ? this.state.entity.contactGsm : ""}
                            onChangeCapture={this.handleContactGsmChange} isInvalid={!this.state.validationState.contactGsmIsValid && this.state.isValidated}
                            ref="refGsmContact"
                            tabIndex={tIndexBase + 1}
                            onChange={() => { }} />
                    </Form.Group>
                </Col>


                <Col sm={6} lg={4}>
                    <Form.Group controlId="frmEmail">
                        <Form.Text>Email, extra contact persoon *</Form.Text>
                        <Form.Control autoFocus type="email" placeholder="iemand@ergens.be ..."
                            value={this.state.entity.contactEmail ? this.state.entity.contactEmail : ""}
                            onChangeCapture={this.handleContactEmailChange} isInvalid={!this.state.validationState.contactEmailIsValid && this.state.isValidated}
                            ref="refEmailContact"
                            tabIndex={tIndexBase + 2}
                            onChange={() => { }} />
                    </Form.Group>
                </Col>


            </Row>

        return jsx;
    }

    private getLine8JSX(tIndexBase: number) {

        const jsx =
            <Row>
                <Col xs={12} lg={8}>
                    <Form.Group controlId="frmExperience">
                        <Form.Text>Eerdere vliegervaring</Form.Text>
                        <Form.Control as="textarea" type='text' rows={3} placeholder="..." style={{ whiteSpace: 'pre-line' }}
                            value={this.state.entity.experience ? this.state.entity.experience : ""} ref="refFirstFocus"
                            onChangeCapture={this.handleExperienceChange}
                            isInvalid={false}
                            //disabled={this.props.formMode != FormModus.New}
                            tabIndex={tIndexBase + 1}
                            onChange={() => { }} />
                    </Form.Group>
                </Col>
            </Row>

        return jsx;
    }

    private getLine9JSX(tIndexBase: number) {
        const jsx =
            <Row>

                <Col xs={6} lg={4}>
                    <Form.Group controlId="frmPrevKAZM" tabIndex={tIndexBase + 1}>
                        <Form.Text>Al eens lid geweest of kamp gedaan bij KAZM</Form.Text>
                        <InputGroup >
                            <InputGroup.Prepend>
                                <InputGroup.Checkbox id='cbxPrevKAZM' type="checkbox"
                                    checked={this.state.entity.previousKAZMMember} onChangeCapture={this.handlePreviousKAZMMemberChange}
                                    tabIndex={tIndexBase + 1} onChange={() => { }} />
                            </InputGroup.Prepend>
                            <Form.Control readOnly={true} value="KAZM" onClickCapture={this.handlePreviousKAZMMemberChange} />
                        </InputGroup>
                    </Form.Group>
                </Col>

                <Col xs={6} lg={4}>
                    <Form.Group controlId="frmCurrentLVZC">
                        <Form.Text>Momenteel LVZC lid</Form.Text>
                        <InputGroup >
                            <InputGroup.Prepend>
                                <InputGroup.Checkbox id='cbxIsSolo' type="checkbox"
                                    checked={this.state.entity.currentLVZCMember} onChangeCapture={this.handleCurrentLVZCMemberChange}
                                    tabIndex={tIndexBase + 2} onChange={() => { }} />
                            </InputGroup.Prepend>
                            <Form.Control readOnly={true} value="Vlaamse Liga Zweefclubs" onClickCapture={this.handleCurrentLVZCMemberChange} />
                        </InputGroup>
                    </Form.Group>
                </Col>

            </Row>

        return jsx;
    }


    private getLine10JSX(tIndexBase: number) {
        const jsx =
            <Row>

                <Col xs={6} lg={4}>
                    <Form.Group controlId="frmMedical" tabIndex={tIndexBase + 1}>
                        <Form.Text >Vervaldatum Medical</Form.Text>
                        <DatePickerInput
                            value={this.state.entity.medicalExpires ? this.state.entity.medicalExpires.moment : null}
                            onChange={this.handleMedicalExpiresChange}
                            showOnInputClick={true}
                            small={false}
                            placeholder="31/12/1999"
                            ref="refMedicalValidUntil"
                            position="bottom"
                            style={(!this.state.validationState.medicalExpiresIsValid && this.state.isValidated) ? Styles.getDivErrorStyle() : {}}
                        />
                    </Form.Group>
                </Col>

                <Col xs={6} lg={4}>
                    <Form.Group controlId="frmIsSolo">
                        <Form.Text>Reeds solo geweest</Form.Text>
                        <InputGroup >
                            <InputGroup.Prepend>
                                <InputGroup.Checkbox id='cbxIsSolo' type="checkbox"
                                    checked={this.state.entity.isSolo} onChangeCapture={this.handleIsSoloChange}
                                    tabIndex={tIndexBase + 2} onChange={() => { }} />
                            </InputGroup.Prepend>
                            <Form.Control readOnly={true} value="Solo" onClickCapture={this.handleIsSoloChange} />
                        </InputGroup>
                    </Form.Group>
                </Col>

            </Row>

        return jsx;
    }

    private getLine11JSX(tIndexBase: number) {
        const jsx =
            <Row>
                <Col xs={6} lg={4}>
                    <Form.Group controlId="frmWeight">
                        <Form.Text>Gewicht *</Form.Text>
                        <NumericField
                            value={this.state.entity.weight}
                            precision={0}
                            unit='kg'
                            allowNegativeValue={false}
                            isInvalid={this.state.isValidated && !this.state.validationState.weightIsValid}
                            tabIndex={tIndexBase + 1}
                            onChangeCapture={this.handleWeightChange}
                            
                        />
                    </Form.Group>
                </Col>


                <Col xs={6} lg={4}>
                    <Form.Group controlId="frmHeight">
                        <Form.Text>Lengte *</Form.Text>
                        <NumericField
                            value={this.state.entity.height / 100.00}
                            precision={2}
                            unit='m'
                            allowNegativeValue={false}
                            isInvalid={this.state.isValidated && !this.state.validationState.heightIsValid}
                            tabIndex={tIndexBase + 2}
                            onChangeCapture={this.handleHeightChange}
                        />
                    </Form.Group>
                </Col>


            </Row>

        return jsx;
    }

    private getLine12JSX(tIndexBase: number) {
        const jsx =
            <Row>
                <Col xs={12} lg={8}>
                    <Form.Group controlId="frmWeek1"
                        style={(!this.state.validationState.weeksAreValid && this.state.isValidated) ? Styles.getDivErrorStyle() : {}}
                    >
                        <Form.Text>Ja, ik ben aanwezig gedurende week 1</Form.Text>
                        <InputGroup>
                            <InputGroup.Prepend>
                                <InputGroup.Checkbox id='cbxWeek1' type="checkbox" checked={this.state.entity.week1}
                                    onChangeCapture={this.handleWeek1Change}
                                    tabIndex={tIndexBase + 1}
                                    onChange={() => { }}
                                />
                            </InputGroup.Prepend>
                            <Form.Control readOnly={true} value={Formatters.getLongMonthDayString(this.props.event.startDate) + " t/m" + Formatters.getLongMonthDayString(this.props.event.startDate.addDays(4))}
                                onClickCapture={this.handleWeek1Change}
                                ref='refFirstFocus'
                            />
                        </InputGroup>
                    </Form.Group>
                </Col>
            </Row>
        return jsx;
    }

    private getLine13JSX(tIndexBase: number) {
        const jsx =
            <Row>
                <Col xs={12} lg={8}>
                    <Form.Group controlId="frmWeek2"
                        style={(!this.state.validationState.weeksAreValid && this.state.isValidated) ? Styles.getDivErrorStyle() : {}}>
                        <Form.Text>Ja, ik ben aanwezig gedurende week 2</Form.Text>
                        <InputGroup >
                            <InputGroup.Prepend>
                                <InputGroup.Checkbox id='cbxWeek2' type="checkbox"
                                    checked={this.state.entity.week2} onChangeCapture={this.handleWeek2Change}
                                    tabIndex={tIndexBase + 1} onChange={() => { }}
                                />
                            </InputGroup.Prepend>
                            <Form.Control readOnly={true}
                                value={Formatters.getLongMonthDayString(this.props.event.startDate.addDays(7)) + " t/m" + Formatters.getLongMonthDayString(this.props.event.endDate)}
                                onClickCapture={this.handleWeek2Change}

                            />
                        </InputGroup>
                    </Form.Group>
                </Col>

            </Row>

        return jsx;
    }

    private getLine14JSX(tIndexBase: number) {
        const jsx =
            <Row>
                <Col xs={12} lg={8}>
                    <Form.Group controlId="frmRemarks">
                        <Form.Text>Verdere opmerkingen (allergiën, vegetarisch, aandoeningen, ....)</Form.Text>

                        <Form.Control as="textarea" type='text' rows={3} placeholder="..." style={{ whiteSpace: 'pre-line' }}
                            value={this.state.entity.remarks ? this.state.entity.remarks : ""} ref="remarks"
                            onChangeCapture={this.handleRemarksChange}
                            isInvalid={false}
                            //disabled={this.props.formMode != FormModus.New}
                            tabIndex={tIndexBase + 1}
                            onChange={() => { }} />

                    </Form.Group>
                </Col>


            </Row>

        return jsx;
    }





    private getSexJSX(tIndex: number) {

        const jsx =
            <Typeahead id="lstSex"
                positionFixed={true}
                flip={true}
                labelKey={(x: ISex) => x.description}
                allowNew={false}
                emptyLabel="niet gevonden"
                options={this.state.allSexes}
                selected={this.state.entity.sex ? [this.state.entity.sex] : []}
                multiple={false}
                clearButton={true}
                placeholder="selecteer..."
                minLength={0}
                selectHintOnEnter={true}
                ref="refSex"
                onChange={this.handleSexChange}
                isInvalid={!this.state.validationState.sexIsValid && this.state.isValidated}

                inputProps={{ tabIndex: tIndex }}
            />

        return jsx;
    }

    private getNationalityJSX(tIndex: number) {

        const jsx =
            <Typeahead id="lstNationality"
                positionFixed={true}
                flip={true}
                labelKey={(x: ICountry) => x.name}
                allowNew={false}
                emptyLabel="niet gevonden"
                options={this.state.allCountries}
                selected={this.state.entity.nationality ? [this.state.entity.nationality] : []}
                multiple={false}
                clearButton={true}
                placeholder="selecteer..."
                minLength={0}
                selectHintOnEnter={true}
                ref="refNationality"
                onChange={this.handleNationalityChange}
                isInvalid={!this.state.validationState.nationalityIsValid && this.state.isValidated}

                inputProps={{ tabIndex: tIndex }}
            />

        return jsx;
    }

    private getCountryJSX(tabIndex: number) {

        const jsx =
            <Typeahead id="lstCountry"
                positionFixed={true}
                flip={true}
                labelKey={(x: ICountry) => x.name}
                allowNew={false}
                emptyLabel="niet gevonden"
                options={this.state.allCountries}
                selected={this.state.entity.country ? [this.state.entity.country] : []}
                multiple={false}
                clearButton={true}
                placeholder="selecteer..."
                minLength={0}
                selectHintOnEnter={true}
                ref="refCountry"
                onChange={this.handleCountryChange}
                isInvalid={!this.state.validationState.countryIsValid && this.state.isValidated}

                inputProps={{ tabIndex: tabIndex }}
            />

        return jsx;
    }


    //======================== Get Form Handlers =============================//

    private handleFirstNameChange(event: React.ChangeEvent<HTMLInputElement>) {
        let x = event.currentTarget.value;

        this.entity.firstName = x;
        this.setStateAndValidation(this.entity);
    }

    private handleSurNameChange(event: React.ChangeEvent<HTMLInputElement>) {
        let x = event.currentTarget.value;

        this.entity.surName = x;
        this.setStateAndValidation(this.entity);
    }

    private handlePlaceBirthChange(event: React.ChangeEvent<HTMLInputElement>) {
        let x = event.currentTarget.value;
        this.entity.placeBirth = x;
        this.setStateAndValidation(this.entity);
    }

    private async handleDateBirthChange(date: any) {
        let newDate = new MyDateTime(date);
        if (!newDate.isValid) return;

        this.entity.dateBirth = newDate.clone();
        await this.setStateAndValidation(this.entity);
    }

    private handleSexChange(selectedOptions: Array<ISex>) {

        if (selectedOptions != null && selectedOptions.length > 0) {
            this.entity.sex = selectedOptions[0];
        }
        else {
            this.entity.sex = null;
        }
        this.setStateAndValidation(this.entity);
    }

    private handleNationalityChange(selectedOptions: Array<ICountry>) {

        if (selectedOptions != null && selectedOptions.length > 0) {
            this.entity.nationality = selectedOptions[0];
        }
        else {
            this.entity.nationality = null;
        }
        this.setStateAndValidation(this.entity);
    }

    private handleAddressChange(event: React.ChangeEvent<HTMLInputElement>) {
        let x = event.currentTarget.value;

        this.entity.address = x;
        this.setStateAndValidation(this.entity);
    }

    private handleZipChange(event: React.ChangeEvent<HTMLInputElement>) {
        let x = event.currentTarget.value;

        this.entity.zip = x;
        this.setStateAndValidation(this.entity);
    }

    private handleCityChange(event: React.ChangeEvent<HTMLInputElement>) {
        let x = event.currentTarget.value;

        this.entity.city = x;
        this.setStateAndValidation(this.entity);
    }

    private handleRRNChange(value: string) {
        this.entity.nationalNr = value;

        if (RRN.isValid(this.entity.nationalNr)) {
            const rrn = new RRN(this.entity.nationalNr);

            const newDateBirth = MyDateTime.createDate(rrn.year, rrn.month, rrn.day);
            this.entity.dateBirth = newDateBirth;

            if (rrn.isMale) {
                this.entity.sex = this.state.allSexes.find(x => x.code == 'M');
            }
            else {
                this.entity.sex = this.state.allSexes.find(x => x.code == 'V');
            }
        }

        this.setStateAndValidation(this.entity);
    }

    private handleGsmChange(event: React.ChangeEvent<HTMLInputElement>) {
        let x = event.currentTarget.value;

        this.entity.gsm = x;
        this.setStateAndValidation(this.entity);
    }

    private handleEmailChange(event: React.ChangeEvent<HTMLInputElement>) {
        let x = event.currentTarget.value;

        this.entity.email = x;
        this.setStateAndValidation(this.entity);
    }

    private handleContactGsmChange(event: React.ChangeEvent<HTMLInputElement>) {
        let x = event.currentTarget.value;

        this.entity.contactGsm = x;
        this.setStateAndValidation(this.entity);
    }

    private handleContactEmailChange(event: React.ChangeEvent<HTMLInputElement>) {
        let x = event.currentTarget.value;

        this.entity.contactEmail = x;
        this.setStateAndValidation(this.entity);
    }

    private handleCountryChange(selectedOptions: Array<ICountry>) {

        if (selectedOptions != null && selectedOptions.length > 0) {
            this.entity.country = selectedOptions[0];
        }
        else {
            this.entity.country = null;
        }
        this.setStateAndValidation(this.entity);
    }

    private handleMedicalExpiresChange(date: any) {
        let newDate = new MyDateTime(date);
        if (!newDate.isValid) return;

        this.entity.medicalExpires = MyDateTime.createDate(newDate.year, newDate.month, newDate.day);
        this.setStateAndValidation(this.entity);
    }

    private handleIsSoloChange(event: any) {
        this.entity.isSolo = !this.entity.isSolo;
        this.setStateAndValidation(this.entity);
    }

    private handlePreviousKAZMMemberChange(event: any) {
        this.entity.previousKAZMMember = !this.entity.previousKAZMMember;
        this.setStateAndValidation(this.entity);
    }

    private handleCurrentLVZCMemberChange(event: any) {
        this.entity.currentLVZCMember = !this.entity.currentLVZCMember;
        this.setStateAndValidation(this.entity);
    }

    private handleWeightChange(weight: number) {
        this.entity.weight = weight;
        this.setStateAndValidation(this.entity);
    }

    private handleHeightChange(height: number) {
        this.entity.height = height * 100;
        this.setStateAndValidation(this.entity);
    }

    private handleWeek1Change(event: any) {
        this.entity.week1 = !this.entity.week1;
        this.setStateAndValidation(this.entity);
    }

    private handleWeek2Change(event: any) {
        this.entity.week2 = !this.entity.week2;
        this.setStateAndValidation(this.entity);
    }

    private handleExperienceChange(event: React.ChangeEvent<HTMLInputElement>) {
        let x = event.currentTarget.value;

        this.entity.experience = x; //Don't trim as it eats the line breaks
        this.setStateAndValidation(this.entity);
    }

    private handleRemarksChange(event: React.ChangeEvent<HTMLInputElement>) {
        let x = event.currentTarget.value;

        this.entity.remarks = x; //Don't trim as it eats the line breaks
        this.setStateAndValidation(this.entity);
    }

    private handleLVZCSignatureChange(event: any) {
        this.entity.docsLVZC = !this.entity.docsLVZC;
        this.setStateAndValidation(this.entity);
    }

    private handleKAZMSignatureChange(event: any) {
        this.entity.docsKAZM = !this.entity.docsKAZM;
        this.setStateAndValidation(this.entity);
    }

    private async handleNext() {
        if (this.state.selectedWizardStep == this.state.wizardSteps.length - 1) {
            return;
        }
        this.handleStepChange(Number.isInteger(this.state.selectedWizardStep) ? this.state.selectedWizardStep + 1 : 0);
    }

    private async handlePrevious() {
        if (this.state.selectedWizardStep == 0) {
            return;
        }

        this.handleStepChange(this.state.selectedWizardStep - 1);
    }

    private async handleStepChange(stepNr: number) {

        await this.setState({ selectedWizardStep: stepNr });

        if (this.refs.refFirstFocus) (this.refs.refFirstFocus as any).focus();
    }

    private async handleClose(result: ModalResult) {

        if (result == ModalResult.OK) {
            await this.setState({ isValidated: true });
            if (!this.validateForm()) return;
        }

        if (this.props.onClose) {
            this.props.onClose(result, result == ModalResult.OK ? this.entity : null);
        }
    }

    //======================== Form Validation =============================//

    private isMemberIdentityValid(vs: ICampRegistrationValidationState) {
        return (vs.firstNameIsValid && vs.surNameIsValid && vs.nationalNrIsValid && vs.dateBirthIsValid && vs.sexIsValid && vs.placeBirthIsValid && vs.nationalityIsValid);
    }

    private isMemberContactValid(vs: ICampRegistrationValidationState) {
        return (vs.addressIsValid && vs.zipIsValid && vs.cityIsValid && vs.countryIsValid && vs.gsmIsValid && vs.emailIsValid && vs.contactGsmIsValid && vs.contactEmailIsValid);
    }

    private isFlightExperienceValid(vs: ICampRegistrationValidationState) {
        return (vs.medicalExpiresIsValid && vs.weightIsValid && vs.heightIsValid );
    }

    private isRegistrationValid(vs: ICampRegistrationValidationState) {
        return (vs.weeksAreValid);
    }

    private setValidationstateOnWizard(vs: ICampRegistrationValidationState, steps: IWizardStep[]) {

        if (!this.isMemberIdentityValid(vs) && this.state.isValidated) {
            steps[0].color = Styles.getErrorColor();
        }
        else {
            steps[0].color = this.baseColor;
        }

        if (!this.isMemberContactValid(vs) && this.state.isValidated) {
            steps[1].color = Styles.getErrorColor();
        }
        else {
            steps[1].color = this.baseColor;
        }

        if (!this.isFlightExperienceValid(vs) && this.state.isValidated) {
            steps[2].color = Styles.getErrorColor();
        }
        else {
            steps[2].color = this.baseColor;
        }

        if (!this.isRegistrationValid(vs) && this.state.isValidated) {
            steps[3].color = Styles.getErrorColor();
        }
        else {
            steps[3].color = this.baseColor;
        }
    }

    private setStateAndValidation(entity: ICampRegistration) {
        const vs = entity.getValidationState() as ICampRegistrationValidationState;

        this.setValidationstateOnWizard(vs, this.state.wizardSteps);

        this.setState({
            entity: entity,
            validationState: vs,
            wizardSteps : this.state.wizardSteps,
        });
        

        return vs;
    }

    private validateForm() {
        const vs = this.setStateAndValidation(this.entity);

        //let vs = this.entity.getValidationState() as ICampRegistrationValidationState;
        //this.setState({ validationState: vs });

        //this.setValidationstateOnWizard(vs)

        //console.log("Valid: " + vs.stateIsValid.toString());
        //console.log(vs);
        //console.log(this.entity.nationalNr);
        //console.log(this.entity)
        //console.log(this.entity.membership.length > 0);

        return vs.stateIsValid;
        //return vs.stateIsValid;
    }


    //======================== Data Layer =============================//

    private async loadBaseTables() {

        this.setState({ isLoading: true });

        const countries = Cache.loadCountriesAsync();
        const sexes = Cache.loadSexesAsync();
        this.setState({ isLoading: false, allCountries: await countries, allSexes: await sexes });
    }



    //======================== Lifecycle =============================//

    //handleSessionChange = (sessionInfo: ISessionInfo) => {
    //}


    async componentDidMount() {
        //console.log("mounting MemberForm");
        //ApplicationState.get().sessionInfo.onChange.registerHandler(this.handleSessionChange);

        this.loadBaseTables();

        if (this.refs.refFirstFocus) (this.refs.refFirstFocus as HTMLInputElement).focus();
    }

    componentWillUnmount() {
        //console.log("unmounting MemberForm");
        //ApplicationState.get().sessionInfo.onChange.unregisterHandler(this.handleSessionChange);
    }
}
