
// How to use CustomEvent
// put a CustomEvent on your class
// let all clients register their handler in de mount
// en unregister in the unmout. Clients should NEVER call trigger()
// Sample: 
//class ApplicationState {
//    public readonly errorEvent: CustomEvent<void> = new CustomEvent<void>();
//}
//
//class Client {
//
//    componentDidMount() {
//        ApplicationState.get().errorEvent.registerHandler(this.handleX);
//    }
//
//    componentWillUnmount() {
//        ApplicationState.get().errorEvent.unregisterHandler(this.handleX);
//    }
//}

export interface ICustomEvent<T> {

    registerHandler(handler: (z? : T) => void): void;
    unregisterHandler(handler: (z?: T) => void): void;

    trigger(z?: T): void;
}

export class CustomEvent<T> implements ICustomEvent<T> {

    private _handlers: Array<(z? : T) => void>;

    constructor() {
        this._handlers = new Array<(z? : T) => void>();
    }

    public registerHandler(handler: (z? : T) => void): void {
        
        if (this._handlers.filter(x => x === handler).length == 0) {
            this._handlers.push(handler);
        }

        //console.log("Handlers : " + this._handlers.toString());
    }

    public unregisterHandler(handler: (z? : T) => void) {
        this._handlers = this._handlers.filter(x => x !== handler);

        //console.log("Handlers : " + this._handlers.toString());
    }

    public trigger(z? : T): void{
        this._handlers.forEach(x => { if (x) x(z); } );
    }
  
}

