import { ISerializable } from './ISerializable';
//import { IValidation, IValidationState } from './IValidation'

export interface ILibraryCategory extends ISerializable<ILibraryCategory> {
    id: string;
    name: string;
    mngtOnly: boolean;
}

export class LibraryCategory {
    id: string;
    name: string;
    mngtOnly: boolean;

    constructor() { }

    public fromJson(json: Object): LibraryCategory {
        //DO NOT USE 'this.' RETURN A NEW OBJECT
        //console.log("Aircraft.fromJson");

        const trueObject = new LibraryCategory();
        Object.assign<ILibraryCategory, Object>(trueObject, json);

        //trueObject.key = trueObject.id;

        //Overloads

        //Objects

        //console.log(trueObject);
        return trueObject;
    }


    public toJson(): Object {
        //console.log("Aircraft.toJson");

        let x = JSON.parse(JSON.stringify(this));

        //Overloads

        //Objects

        //console.log(x);
        return x;
    }
}


