import { MyDateTime } from "./../HelperClasses/MyDate";
import { ISerializable } from './ISerializable';
import { IValidation, IValidationState } from './IValidation'
import { ICashBookEntry, CashBookEntry } from './CashBookEntry';


export interface IMembership extends ISerializable<IMembership>, IValidation {
    id: string;
    memberId: string;
    cashBookEntry: ICashBookEntry;
    year: number;
    startDate: MyDateTime;
    endDate: MyDateTime;
    remarks: string;
    isSupportingMember: boolean;

    isCurrent(): boolean
}

export interface IMembershipValidationState extends IValidationState {       
     
    memberIdIsValid: boolean;
    yearIsValid: boolean;
    startDateIsValid: boolean;
    endDateIsValid: boolean;

}

export class Membership implements IMembership {

    readonly id: string = null;
    memberId: string = null;
    cashBookEntry: ICashBookEntry = null;
    year: number = 0;
    startDate: MyDateTime = null;
    endDate: MyDateTime = null;
    remarks: string = null;
    isSupportingMember: boolean = false;
    constructor() {
    }

    public static getTodaysMembershipYear(): number {
        const myDate = MyDateTime.now;
        if (myDate.moment.month() > 1) {
            return myDate.moment.year();
        }
        else {
            return myDate.moment.year() - 1;
        }
    }
    public isCurrent(): boolean {
        if (!this.startDate.isValid || !this.endDate.isValid) return false;
        const nu = MyDateTime.now;
        return (this.startDate.isBeforeOrOnDate(nu) && this.endDate.isAfterOrOnDate(nu));
    }

    public getValidationState(): IMembershipValidationState {

        let vs = {

            memberIdIsValid: (this.memberId != null),
            
            yearIsValid: (this.year > 0),
            startDateIsValid: (this.startDate != null && this.startDate.isValid && (this.endDate? this.startDate.isBeforeDate(this.endDate): true) && this.startDate.isAfterDate(MyDateTime.createDate(this.year, 1, 31)) && this.startDate.isBeforeDate(MyDateTime.createDate(this.year+1, 2, 1))),
            endDateIsValid: (this.endDate != null && this.endDate.isValid && (this.startDate? this.startDate.isBeforeDate(this.endDate): true) && this.endDate.isAfterDate(MyDateTime.createDate(this.year, 1, 31)) && this.endDate.isBeforeDate(MyDateTime.createDate(this.year+1, 2, 1))),


            stateIsValid: false
        } as IMembershipValidationState;

        vs.stateIsValid = (
            vs.yearIsValid &&
            vs.startDateIsValid &&
            vs.endDateIsValid
        ); 

        return vs;
    }

    public fromJson(json: Object): Membership {
        //DO NOT USE 'this.' RETURN A NEW OBJECT
        //console.log("Member.fromJson");

        const trueObject = new Membership();
        Object.assign<IMembership, Object>(trueObject, json);

        //Overloads
        trueObject.startDate = MyDateTime.fromJson(trueObject.startDate);
        trueObject.endDate = MyDateTime.fromJson(trueObject.endDate);

        //Objects
        if (trueObject.cashBookEntry != null) trueObject.cashBookEntry = (new CashBookEntry()).fromJson(trueObject.cashBookEntry);

        //console.log(json);
        //console.log(trueObject);
        return trueObject;
    }


    public toJson(): Object {
        //console.log("Aircraft.toJson");

        let x = JSON.parse(JSON.stringify(this));

        //Overloads
        if (this.startDate) {
            x.startDate = this.startDate.toJson();
        }
        if (this.endDate) {
            x.endDate = this.endDate.toJson();
        }

        if (this.cashBookEntry) x.cashBookEntry = this.cashBookEntry.toJson();

        //console.log(x);
        return x;
    }
}