import * as React from 'react';

//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import {  Form, Button, Modal, Row, FormControl,Col } from 'react-bootstrap';
//import { DatePickerInput } from 'rc-datepicker';
import 'rc-datepicker/lib/style.css';


import { ModalResult, FormModus } from './../HelperClasses/Enums';
//import { NumericField } from "../UIComponents/NumericField";
//import { Styles } from './../HelperClasses/Styles';
import { Cache } from './../HelperClasses/Cache';
import { Http } from './../HelperClasses/Http';
import { ApplicationState } from './../HelperClasses/ApplicationState';
import { Filetype, Filetypes } from './../HelperClasses/Filetypes';
import { DotsSpinner } from '../UIComponents/MySpinners';

import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import 'react-bootstrap-typeahead/css/Typeahead-bs4.css';

import { ILibraryDoc, LibraryDoc, ILibraryDocValidationState } from "../DTO/LibraryDoc"
import { ILibraryCategory } from '../DTO/LibraryCategory';
//import { MyDateTime } from '../HelperClasses/MyDate';

interface ILibraryDocModalProps {

    formMode: FormModus;

    entityIn: ILibraryDoc;

    defaultCategory?: ILibraryCategory;
    
    nextSortNr?: number;

    onClose: (result: ModalResult, updatedDoc: ILibraryDoc) => void;
}

interface ILibraryDocModalState {
    show: boolean;
    entity: ILibraryDoc;
    fileBlob: Blob;
    isInvalidFiletype: boolean,
    isValidated: boolean;
    isUploading: boolean;
    validationState: ILibraryDocValidationState;

    allCategories: Array<ILibraryCategory>;
}


export class LibraryDocModal extends React.Component<ILibraryDocModalProps, ILibraryDocModalState> {

    private entity: ILibraryDoc;
    private allowedFileFormats: string[];

    constructor(props: ILibraryDocModalProps, context: ILibraryDocModalState ) {
        super(props, context);

        this.allowedFileFormats = [
            Filetype.getMimetypeFromFiletype(Filetypes.Pdf),
            Filetype.getMimetypeFromFiletype(Filetypes.Word),
            Filetype.getMimetypeFromFiletype(Filetypes.Excel),
            Filetype.getMimetypeFromFiletype(Filetypes.Zip)
        ];

        if (this.props.formMode == FormModus.New) {
            this.entity = new LibraryDoc();
            if (this.props.defaultCategory) {
                this.entity.libraryCategory = this.props.defaultCategory;
            }
            if (this.props.nextSortNr) {
                this.entity.sortNr = this.props.nextSortNr
            }
            else {
                this.entity.sortNr = 0;
            }
        }
        else {
            this.entity = this.props.entityIn.clone();
        }

        this.state = {
            entity: this.entity,
            fileBlob: null,
            show: true,
            isInvalidFiletype: false,
            isValidated: false,
            isUploading: false,
            validationState: (this.entity.getValidationState() as ILibraryDocValidationState),

            allCategories: null,
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleReset = this.handleReset.bind(this);

        this.handleDisplayNameChange = this.handleDisplayNameChange.bind(this);
        this.handleCategoryChange = this.handleCategoryChange.bind(this);

        this.handleUpload = this.handleUpload.bind(this);
        this.handleSelectedFile = this.handleSelectedFile.bind(this);
        this.onFileLoaded = this.onFileLoaded.bind(this);
    }


    render() {

        let dlg =
            <Modal show={this.state.show} onHide={(event: React.FormEvent<HTMLFormElement>) => { this.handleReset(event) }} >
            <Modal.Header closeButton >
                    <Modal.Title>{this.state.entity.displayName ? this.state.entity.displayName : 'Nieuw document' }</Modal.Title>
            </Modal.Header>


                <Modal.Body>
                    <DotsSpinner active={this.state.isUploading} >
                        {this.state.isInvalidFiletype ? alert('invalid filetype'):null }
                        {this.getHiddenFileSelectorJSX()}
                        {this.getFormJSX()}
                    </DotsSpinner>
                </Modal.Body>
            

            <Modal.Footer>
                <Button variant="secondary" onClick={this.handleReset} >Annuleren</Button>
                <Button variant="primary" onClick={this.handleSubmit} >OK</Button>
            </Modal.Footer>
        </Modal>

        return (
            <>
                {dlg}
            </>
        );
    }

    private getFormJSX() {
         //de form incl uploadbutton
        const jsx =
            <div>
                <Row hidden={this.props.formMode != FormModus.New}>
                    <Col>
                        <Button
                            variant='outline-primary'
                            
                            onClick={this.handleUpload}
                        >
                            Selecteer een bestand...
                        </Button>
                        &nbsp;&nbsp;
                        <Form.Label hidden={!this.state.entity.origFileName} >{this.state.entity.origFileName}</Form.Label>
                        <Form.Label hidden={this.state.entity.origFileName} style={{ color: 'gray', fontStyle: 'italic' }}>Bestandsnaam</Form.Label>
                        <hr />
                    </Col>
                </Row>
                <Row hidden={!this.state.entity.origFileName}>
                    <Col>
                        <Form.Group controlId="frmCategory">
                            <Form.Text>Categorie</Form.Text>
                            {this.getCategoriesJSX()}
                        </Form.Group>
                    </Col>
                </Row>
                <Row hidden={!this.state.entity.origFileName}>
                    <Col>
                        <Form.Group controlId="ctrlDisplayName">
                            <Form.Text >Weergave naam</Form.Text>
                            <FormControl type="input" placeholder="..."
                                value={this.state.entity.displayName ? this.entity.displayName : ''}
                                onChangeCapture={this.handleDisplayNameChange}
                                isInvalid={!this.state.validationState.displayNameIsValid && this.state.isValidated}
                                tabIndex={2} onChange={() => { }} />
                        </Form.Group>
                    </Col>
                </Row>

            </div>


        return jsx;
    }

    private getHiddenFileSelectorJSX() {
        const jsx =
            <Form.File
                ref={'fileSelector'}
                className='d-none'
                custom={true}
                label='Selecteer een document'
                onChangeCapture={this.handleSelectedFile}
                size='sm'
                accept={this.allowedFileFormats}
                data-browse='Zoeken'
            />
        return jsx;
    }

    private getCategoriesJSX() {

        const x =
            <Typeahead id="lstPowerOptions"
                positionFixed={false}
                flip={false}
                labelKey={(x: ILibraryCategory) => x.name}
                allowNew={false}
                emptyLabel="niet gevonden"
                options={this.state.allCategories ? this.state.allCategories : []}
                selected={this.state.entity.libraryCategory ? [this.state.entity.libraryCategory] : []}
                multiple={false}
                clearButton={true}
                placeholder="selecteer..."
                minLength={0}
                selectHintOnEnter={true}
                ref="refCategories"
                onChange={this.handleCategoryChange}

                isInvalid={!this.state.validationState.categoryIsValid && this.state.isValidated}

                inputProps={{ tabIndex: 3 }}
            />

        return x;
    }


    //=========================  Handlers ===========================================//

    private handleDisplayNameChange(event: React.ChangeEvent<HTMLInputElement>) {
        const x = event.currentTarget.value;
        this.entity.displayName = x;
        this.setState({ entity: this.entity });
    }

    private handleUpload(e: any) {

        if (this.refs.fileSelector) {
            (this.refs.fileSelector as HTMLInputElement).click();
        }

        return false;
    }

    private handleSelectedFile(e: any) {
        const files = e.target.files;

        if (files && files.length > 0) {
            //console.log(e.target.files)
            this.loadFile(e.target.files[0])
        }
    };


    private async loadFile(fileBlob: Blob) {

        if (fileBlob) {

            if (this.allowedFileFormats.indexOf(fileBlob.type) === -1) {
                this.setState({ isInvalidFiletype: true });
                return;
            }
            this.setState({ isInvalidFiletype: false});

            const reader = new FileReader();
            reader.addEventListener("load", () => { this.onFileLoaded(fileBlob, reader) });
            reader.readAsDataURL(fileBlob);
        }
    }

   private async onFileLoaded(fileBlob: Blob, reader: FileReader) {

       this.entity.origFileName = (fileBlob as any).name;
       this.entity.displayName = this.entity.origFileName;
       this.entity.filetype = (fileBlob as any).type;
       this.entity.sortNr = 0;

       this.setState({ entity: this.entity, fileBlob: fileBlob});
    }


    private handleCategoryChange(selectedOptions: Array<ILibraryCategory>) {
        if (selectedOptions != null && selectedOptions.length > 0) {
            this.entity.libraryCategory = selectedOptions[0];
        }
        else {
            this.entity.libraryCategory = null;
        }
        this.setState({ entity: this.entity })
    }



    //=========================== logic ==================================//



    //======================== Form Validation =============================//

    private validateForm() {

        let vs = this.entity.getValidationState() as ILibraryDocValidationState;

        this.setState({validationState: vs});

        return vs.stateIsValid;
    }

    private handleReset(event: React.FormEvent<HTMLFormElement>) {
        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }
        this.setState({ isValidated: false, show:false });

        this.props.onClose(ModalResult.Cancel, null);
    }

    private async handleSubmit(event: React.FormEvent<HTMLFormElement>) {

        event.preventDefault();
        event.stopPropagation();

        this.setState({ isValidated: true });  //toggle the validation for all controls
        // this.validateForm() && event.currentTarget.checkValidity() //checkValidity checks the required fields but we need to set invalid manually so makes no sense to use

        if (this.validateForm()) {

            const updatedDoc = await this.upsertDoc(this.state.fileBlob, this.entity);
            if (updatedDoc) {
                this.entity = updatedDoc;
                this.setState({ show: false, isUploading: false });
                this.props.onClose(ModalResult.OK, this.entity);
            }
            else {
                this.setState({ show: false, isUploading: false });
                this.props.onClose(ModalResult.Cancel, null);
            }
        }
    }

   //======================== Data layer =============================//

    private async LoadBaseTables() {
        this.setState({ allCategories: await this.loadAllCategories() });
    }
    
    private loadAllCategories(): Promise<Array<ILibraryCategory>> {
        return Cache.loadLibraryCategoriesAsync();
    }

    private async upsertDoc(blob: Blob, doc: ILibraryDoc): Promise<ILibraryDoc> {

        this.setState({ isUploading: true })

        const http = new Http();
        const updatedDoc = await http.postAsyncFileAndObject<ILibraryDoc>("api/library", blob, doc, LibraryDoc);

        Cache.invalidateLibraryDocs();

        this.setState({ isUploading: false })

        if (http.error == null) {
            return updatedDoc as ILibraryDoc;
        }
        else {

            ApplicationState.get().setError(http.error);
            return null;
        }

    }

    //======================== Lifecycle =============================//

    componentDidMount() {
        //console.log("mounting SimpleDialog");
        this.LoadBaseTables();

    }

    componentWillUnmount() {
        //console.log("unmounting SimpleDoialog");
    }
}
