
import { ICashBookCategory } from "./CashBookCategory";
import { IMember, Member } from "./Member";

import { MyDateTime } from "./../HelperClasses/MyDate";
import { ISerializable } from './ISerializable';
import { IValidation, IValidationState } from './IValidation'

//import moment = require('moment');


export interface ICashBookEntry extends ISerializable<ICashBookEntry>, IValidation {
    id: string;

    cashBookCategory: ICashBookCategory
    member: IMember;
    bookingYear: number;
    bookingNr: number;
    bookingDate: MyDateTime;
    amount: number;
    isCredit: boolean;
    datePaid: MyDateTime;
    bankRef: string;
    description: string;
    party: string;
    isFrozen: boolean;
}

export interface ICashBookEntryValidationState extends IValidationState {       
    cashBookCategoryIsValid: boolean;
    memberIsValid: boolean;
    bookingYearIsValid: boolean;
    bookingNrIsValid: boolean;
    bookingDateIsValid: boolean;
    amountIsValid: boolean;
    isCreditIsValid: boolean;
    datePaidIsValid: boolean;
    bankRefIsValid: boolean;
    descriptionIsValid: boolean;
    partyIsValid: boolean;
    isFrozenIsValid: boolean;
}

export class CashBookEntry implements ICashBookEntry {

    public readonly id: string = null;

    cashBookCategory: ICashBookCategory = null;
    member: IMember = null;
    bookingYear: number = 0;
    bookingNr: number = 0;
    bookingDate: MyDateTime = null;
    amount: number = 0;
    isCredit: boolean = false;
    datePaid: MyDateTime = null;
    bankRef: string = null;
    description: string = null;
    party: string = null;
    isFrozen: boolean = false;

    constructor() {
    }

    public getValidationState(): ICashBookEntryValidationState {
        //console.log("Validation");
        //console.log(this.costPerMinute);

        let vs = {
            cashBookCategoryIsValid: (this.cashBookCategory==null? false: true),
            memberIsValid: true,
            bookingYearIsValid: true,
            bookingNrIsValid: true,
            bookingDateIsValid: (this.bookingDate && this.bookingDate.isValid),
            amountIsValid: true,//(this.amount>0),
            isCreditIsValid: true,
            datePaidIsValid: ((this.datePaid == null) || (this.datePaid && this.datePaid.isValid)),
            bankRefIsValid: true,
            descriptionIsValid: true,
            partyIsValid: true,
            isFrozenIsValid: true,
            stateIsValid: false
        } as ICashBookEntryValidationState;

        vs.stateIsValid = (
            vs.cashBookCategoryIsValid &&
            vs.memberIsValid &&
            vs.bookingYearIsValid &&
            vs.bookingNrIsValid &&
            vs.bookingDateIsValid &&
            vs.amountIsValid &&
            vs.isCreditIsValid &&
            vs.datePaidIsValid &&
            vs.bankRefIsValid &&
            vs.descriptionIsValid &&
            vs.partyIsValid &&
            vs.isFrozenIsValid); 

        return vs;
    }

    public fromJson(json: Object): CashBookEntry {
        //DO NOT USE 'this.' RETURN A NEW OBJECT
        //console.log("Aircraft.fromJson");

        const trueObject = new CashBookEntry();
        Object.assign<ICashBookEntry, Object>(trueObject, json);

        //Overloads
        trueObject.bookingDate = MyDateTime.fromJson(trueObject.bookingDate);
        trueObject.datePaid = MyDateTime.fromJson(trueObject.datePaid);

        //Objects
        if (trueObject.member != null) trueObject.member = (new Member()).fromJson(trueObject.member);

        //console.log(trueObject);
        return trueObject;
    }

    public toJson(): Object {
        //console.log("Aircraft.toJson");

        let x = JSON.parse(JSON.stringify(this));

        //Overloads
        if (this.bookingDate) x.bookingDate = this.bookingDate.toJson();
        if (this.datePaid) x.datePaid = this.datePaid.toJson();
        if (this.member) x.member = this.member.toJson();

        //console.log(x);
        return x;
    }
}