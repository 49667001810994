
//import { IMember, Member } from "./Member";

//import { MyDateTime } from "./../HelperClasses/MyDate";
import { ISerializable } from './ISerializable';
import { IValidation, IValidationState } from './IValidation'
import { EmailHelpers } from './../HelperClasses/EmailHelpers'
//import moment = require('moment');


export interface IExternal extends ISerializable<IExternal>, IValidation {
    id: string;

    firstName: string;
    surName: string;
    email: string;
    gsm: string;

    getFullName(): string;
    getFullNameReverse(): string;
    clone(): IExternal;
}

export interface IExternalValidationState extends IValidationState {       
    firstNameIsValid: boolean;
    surNameIsValid: boolean;
    emailIsValid: boolean;
    gsmIsValid: boolean;
}

export class External implements IExternal {

    id: string;

    firstName: string;
    surName: string;
    email: string;
    gsm: string;

    constructor() {
    }

    //Formatters
    public getFullName() {
        return this.replaceNull(this.firstName) + " " + this.replaceNull(this.surName);
    }
    public getFullNameReverse() {
        return this.replaceNull(this.surName) + ", " + this.replaceNull(this.firstName);
    }
    private replaceNull(s: string) {
        if (s == null) return "-----";
        return s;
    }

    public getValidationState(): IExternalValidationState {
        //console.log("Validation");
        //console.log(this.costPerMinute);

        let vs = {
            firstNameIsValid: this.isNotEmptyOrNull(this.firstName),
            surNameIsValid: this.isNotEmptyOrNull(this.surName),
            emailIsValid: (!this.isNotEmptyOrNull(this.email) || EmailHelpers.isValidEmailAddress(this.email)),
            gsmIsValid: true,
            stateIsValid: false
        } as IExternalValidationState;

        vs.stateIsValid = (vs.firstNameIsValid && vs.surNameIsValid && vs.emailIsValid && vs.gsmIsValid)

        return vs;
    }

    private isNotEmptyOrNull(s : string): boolean {
        return (s && s.trim().length > 0);
    }

    public clone(): IExternal {

        const newExternal = Object.assign<External, IExternal>(new External(), this);

        //Clone any non primitive classes
        return newExternal;
    }

    public fromJson(json: Object): IExternal {
        //DO NOT USE 'this.' RETURN A NEW OBJECT
        //console.log("Aircraft.fromJson");

        const trueObject = new External();
        Object.assign<IExternal, Object>(trueObject, json);

        //Overloads


        //Objects
        //if (trueObject.member != null) trueObject.member = (new Member()).fromJson(trueObject.member);
        //console.log(trueObject);
        return trueObject;
    }


    public toJson(): Object {
        //console.log("EmailSent.toJson");

        let x = JSON.parse(JSON.stringify(this));

        //Overloads

   
        
        //console.log(x);
        return x;
    }

}