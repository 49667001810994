

import { MyDateTime } from "./../HelperClasses/MyDate";
import { ISerializable } from './ISerializable';
//import { IValidation, IValidationState } from './IValidation'

//import moment = require('moment');


export interface IFlightCostsBalance extends ISerializable<IFlightCostsBalance>{

    memberId: string;
    startBalance: number;
    paymentsPerDate: number;
    flightCostsPerDate: number;
    launchCostsPerDate: number;
    balancePerDate: number;
    MostRecentNegative: MyDateTime;
    endPeriod: MyDateTime;
    lastEmailSentDate: MyDateTime;
}

export class FlightCostsBalance implements IFlightCostsBalance {

    memberId: string;
    startBalance: number;
    paymentsPerDate: number;
    flightCostsPerDate: number;
    launchCostsPerDate: number;
    balancePerDate: number;
    MostRecentNegative: MyDateTime;
    endPeriod: MyDateTime;
    lastEmailSentDate: MyDateTime;

    constructor() {
    }



    public fromJson(json: Object): FlightCostsBalance {
        //DO NOT USE 'this.' RETURN A NEW OBJECT
        //console.log("Aircraft.fromJson");

        const trueObject = new FlightCostsBalance();
        Object.assign<IFlightCostsBalance, Object>(trueObject, json);

        //Overloads
        trueObject.MostRecentNegative = MyDateTime.fromJson(trueObject.MostRecentNegative);
        trueObject.endPeriod = MyDateTime.fromJson(trueObject.endPeriod);
        trueObject.lastEmailSentDate = MyDateTime.fromJson(trueObject.lastEmailSentDate);

        return trueObject;
    }


    public toJson(): Object {
        //console.log("CostsBalance.toJson");

        let x = JSON.parse(JSON.stringify(this));

        //Overloads

        if (this.MostRecentNegative) x.negativeSince = this.MostRecentNegative.toJson();
        if (this.endPeriod) x.endPeriod = this.endPeriod.toJson();
        if (this.lastEmailSentDate) x.lastEmailSentDate = this.lastEmailSentDate.toJson();

        
        //console.log(x);
        return x;
    }

}