import * as React from 'react';

import { Accordion, Card, Row, Col, Badge, Button } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { Http } from "../HelperClasses/Http";
import { Formatters } from '../HelperClasses/Formatters';
import { InstructionIcon, XCIcon } from '../UIComponents/MyIcons';
import { MyDateTime } from "../HelperClasses/MyDate";
import { FormModus, ModalResult, ModalButtons } from "./../HelperClasses/Enums";
import { ApplicationState } from "../HelperClasses/ApplicationState";
//import { Styles } from "../HelperClasses/Styles";
import { InfoOverlay } from "./../UIComponents/InfoOverlay";
import { DotsSpinner } from "./../UIComponents/MySpinners";
import { SimpleModal } from "../UIComponents/SimpleModal";
import { NumericModal } from "../UIComponents/NumericModal";

//import { Contact } from "../UIComponents/Contact";
import { FiletypePicker } from "../UIComponents/FiletypePicker";
import { Filetypes } from "../HelperClasses/Filetypes";
import { ContactInfo } from "../UIComponents/ContactInfo";
import { ProfilePicture } from "../UIComponents/ProfilePicture";

import { IFlightDay, FlightDay } from "../DTO/FlightDay"
import { IMember } from "../DTO/Member"

import { ForecastForm } from "./../AppForms/ForecastForm";
import { MemberFlightDayForm } from '../AppForms/MemberFlightDayForm';
import { DutyForm } from "./../AppForms/DutyForm";
import { IMemberFlightDay, MemberFlightDay } from '../DTO/MemberFlightDay';
import { IDuty, Duty } from "../DTO/Duty"
import { Roles } from "./../DTO/Role";
//import { TrafficLightIcon } from './TrafficLightIcon';

import { FlightStatusForm } from "./../AppForms/FlightStatusForm";




export interface IFlightDayDetailsProps {
    member: IMember;
    flightDayInput: IFlightDay;
    onFlightDayClosed?: () => void;
    onFlightDayChanged?: (flightDay: IFlightDay) => void;
}

export interface IFlightDayDetailsState {
    isLoadingForecast: boolean;
    isLoadingMemberFlightDays: boolean;
    isLoadingDuties: boolean;

    flightDay: IFlightDay;

    //arrDuties: Array<IDuty>;
    arrMemberFlightDays: Array<IMemberFlightDay>;
    arrInstructors : Array<IDuty>;
    arrTowPilots : Array<IDuty>;

    selectedDuty: IDuty;
    selectedMember: IMember;

    dutyChangeMode: 'Edit' | 'Replace' | 'NewInstructor' | 'NewTowPilot';

    activeAccordeon: string;

    showEditForecastDlg: boolean;
    showEditExternalInisDlg: boolean;
    showEditMemberFlightDayDlg: boolean;
    showEditDutiesDlg: boolean;
    showConfirmSignOffDlg: boolean;
    showFlightStatusPage: boolean;
    showDeleteDutyDlg: boolean;
}


export class FlightDayDetails extends React.Component<IFlightDayDetailsProps, IFlightDayDetailsState> {

    constructor(props: any) {
        super(props);

        this.state = {
            isLoadingForecast: false,
            isLoadingMemberFlightDays: false,
            isLoadingDuties: false,

            flightDay: this.props.flightDayInput,
            
            arrMemberFlightDays : null,
            arrInstructors : Array<IDuty>(),
            arrTowPilots: Array<IDuty>(),
            selectedDuty: null,
            selectedMember: null,
            dutyChangeMode: 'Replace',
            activeAccordeon: '0',
            showEditForecastDlg: false, showEditExternalInisDlg: false, showEditMemberFlightDayDlg: false, showEditDutiesDlg: false, showConfirmSignOffDlg: false, showFlightStatusPage: false, showDeleteDutyDlg: false,
        };

        this.handleBackToCalendar = this.handleBackToCalendar.bind(this);

        this.handleDownload = this.handleDownload.bind(this);

        this.handleEditForecast = this.handleEditForecast.bind(this);
        this.handleReplaceDuty = this.handleReplaceDuty.bind(this);
        this.handleNewDuty = this.handleNewDuty.bind(this);
        this.handleEditDuty = this.handleEditDuty.bind(this);
        this.handleDeleteDuty = this.handleDeleteDuty.bind(this);
        this.handleEditExternalInis = this.handleEditExternalInis.bind(this);

        this.handleCloseEditForecastDgl = this.handleCloseEditForecastDgl.bind(this);
        this.handleCloseEditExternalInis = this.handleCloseEditExternalInis.bind(this);
        this.handleCloseEditMemberFlightDayDgl = this.handleCloseEditMemberFlightDayDgl.bind(this);
        this.handleCloseDutyDgl = this.handleCloseDutyDgl.bind(this);
        this.handleDeleteDutyDlg = this.handleDeleteDutyDlg.bind(this);

        this.handleSignUp = this.handleSignUp.bind(this);
        this.handleSignOff = this.handleSignOff.bind(this);

        this.handleAccordeonSelect = this.handleAccordeonSelect.bind(this);

        this.handleConfirmSignOff = this.handleConfirmSignOff.bind(this);

        this.setDutiesPerType = this.setDutiesPerType.bind(this);

        this.handleShowEasaStatus = this.handleShowEasaStatus.bind(this);
    }

    render() {

        const JSX =
            <>
                {this.state.showEditForecastDlg ?
                    <ForecastForm entityIn={this.state.flightDay}
                        formMode={FormModus.Edit}
                        onClose={this.handleCloseEditForecastDgl}
                    />
                    : null
                }

                {this.state.showEditExternalInisDlg ?
                    <NumericModal value={this.state.flightDay.nrExternalInis}
                        headingText='Externe initiatie vluchten'
                        bodyText='Aantal'
                        onClose={this.handleCloseEditExternalInis}
                    />
                    : null
                }

                {this.state.showEditMemberFlightDayDlg ?
                    <MemberFlightDayForm entityIn={this.getMemberFlightDay(this.props.member) ? this.getMemberFlightDay(this.props.member) : this.getNewMemberFlightDay(this.props.member)}
                        date={this.state.flightDay.day}
                        formMode={this.getMemberFlightDay(this.props.member) ? FormModus.Edit : FormModus.New}
                        onClose={this.handleCloseEditMemberFlightDayDgl}
                    />
                    : null
                }

                {this.state.showEditDutiesDlg ?
                    <DutyForm
                        duty={this.state.selectedDuty}
                        date={this.state.flightDay.day}
                        dutyChangeMode={this.state.dutyChangeMode}
                        onClose={this.handleCloseDutyDgl}
                    />
                    : null
                }

                {this.state.showDeleteDutyDlg ?
                    this.getDeleteDutyDlgJSX()
                    : null}

                {this.state.showConfirmSignOffDlg ?
                    this.getConfirmSignOffJSX()
                    : null}

                {this.state.showFlightStatusPage ?
                        this.getEasaStatusPageJSX()
                        : null}

                {!this.state.showEditForecastDlg && !this.state.showEditMemberFlightDayDlg && !this.state.showEditDutiesDlg && !this.state.showFlightStatusPage ? this.getInnerForm() : null}
            </>

        return JSX;
    }

    private getInnerForm() {
        const jsx =
            <>
                {this.getControlsJSX()}
                {this.getAccordeonJSX()}
            </>
        return jsx;
    }

    private getControlsJSX() {
        const jsx =
            <>
                <Row>
                    <Col className='col-auto mr-auto' onClick={this.handleBackToCalendar} >
                        <FontAwesomeIcon icon="chevron-circle-left" size='1x' />
                    </Col>
                    <Col className='text-right'>
                        {this.DownloadButtonJSX()}
                    </Col>

                </Row>
                <br />
            </>
        return jsx;

    }

    private DownloadButtonJSX() {
        if (!this.mayDelegateDuties()) return null;
        const jsx =
            <FiletypePicker word={true} pdf={true} mode='modal' showButton={true} onClose={this.handleDownload} />
        //const jsx =
        //    <Button variant='primary' onClickCapture={this.handleDownload}>
        //        Briefingdocument&nbsp;&nbsp;
        //        <FontAwesomeIcon icon="cloud-download-alt" size='1x' />
        //    </Button>

        return jsx;
    }

    private getAccordeonJSX() {


        var JSX =
            <>
                <Accordion activeKey={this.state.activeAccordeon} onSelect={this.handleAccordeonSelect} >
                    <Card >
                        <Accordion.Toggle as={Card.Header} eventKey="0" style={{ cursor: 'pointer' }} >
                            <FontAwesomeIcon inverse={false} size="lg" icon="cloud-sun-rain" />&nbsp;&nbsp;Voorspellingen


                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="0">
                            <Card.Body>
                                <DotsSpinner active={this.state.isLoadingForecast}>
                                    {this.getForecastJSX()}
                                </DotsSpinner>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>

                    <Card >
                        <Accordion.Toggle as={Card.Header} eventKey="1" style={{ cursor: 'pointer' }}>
                            <FontAwesomeIcon inverse={false} size="lg" icon="users" />&nbsp;&nbsp;Aanwezigen&nbsp;&nbsp;
                                {this.getMemberFlightDay(this.props.member) ?
                                    <FontAwesomeIcon icon="check" color='green' />
                                : null}
                            <span className='float-right'>
                                <Badge variant='info'>
                                    {this.state.arrMemberFlightDays && this.state.flightDay ? this.state.arrMemberFlightDays.length.toString() + " / " + (this.getNrPassengerFlights() + this.state.flightDay.nrExternalInis).toString() : null}
                                </Badge>
                            </span>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="1">
                            <Card.Body>
                                <DotsSpinner active={this.state.isLoadingMemberFlightDays}>
                                    {this.getSignedUpJSX()}
                                </DotsSpinner >
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>

                    <Card >
                        <Accordion.Toggle as={Card.Header} eventKey="2" style={{ cursor: 'pointer' }}>
                            <FontAwesomeIcon inverse={false} size="lg" icon="scroll" />&nbsp;&nbsp;Beurtrol
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="2">
                            <Card.Body>
                                <DotsSpinner active={this.state.isLoadingDuties}>
                                    {this.getDutiesJSX()}
                                </DotsSpinner>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>

                </Accordion>

            </>
        return JSX;
    }

    //--------------------------------

    private getForecastJSX() {

        const arr = this.getForecastItems();
        if (!arr) return null;

        const jsx =
            <>
                <div className="position-relative">

                        {arr.map(
                            (rowData, index) => {
                                if (!rowData[0]) return <hr key={index} />
                                const jsx =
                                    <Row key={index} >
                                        <Col xs={2} sm={1}>
                                            {rowData[0]}
                                        </Col>
                                        <Col className='d-none d-sm-block' sm={4}>
                                            {rowData[1]}
                                        </Col>

                                        <Col xs={10} sm={7}>
                                            <b>
                                                {rowData[2]}
                                            </b>
                                        </Col>
                                    </Row>
                                return jsx;
                            }
                        )
                        }


                    <div className='position-absolute' style={{ top: 0, right: 0 }}>
                        {!this.mayEditForeCast() ?
                            <span className="d-block d-sm-none">
                                <InfoOverlay tooltip={this.getForecastLegendaJSX()} />
                            </span>
                            : null}

                        {this.mayEditForeCast() ?
                            <span className='mx-2' onClick={this.handleEditForecast} >
                                <FontAwesomeIcon icon="pen" size='1x' />
                            </span>
                            : null}
                    </div>

                </div>



                <span style={{ fontSize: '0.7rem' }} className='float-right'>
                    Laatst gewijzigd op
                        {this.state.flightDay.lastModified ? Formatters.getMediumDateString(this.state.flightDay.lastModified) : '--'}
                </span>
            </>
        return jsx;
    }

    private getForecastLegendaJSX() {

        const arr = this.getForecastItems();
        if (!arr) return null;

        const jsx = arr.map(
            (row, index) => {
                const jsxRow =
                    <Row className='align-items-center' key={index}>
                        <Col xs={2} className='mx-auto' >
                            {row[0]}
                        </Col>
                        <Col xs={10} className='text-left font-italic'>
                            {row[1]}
                        </Col>
                    </Row>
                return jsxRow;
            }
        );

        return <>{jsx}</>
    }

    private getForecastItems() {
        const d = this.state.flightDay;
        if (!d) return null;

        const arr =
            [
                [<FontAwesomeIcon inverse={false} icon="sun" />, 'Sunrise - Sunset', Formatters.getShortTimeString(d.sunrise) + " - " + Formatters.getShortTimeString(d.sunset) + ' hr'],
                [<FontAwesomeIcon inverse={false} icon="eye" />, 'Horizontaal zicht', (d.visibility ? d.visibility + " km" : "--")],
                [<FontAwesomeIcon inverse={false} icon="wind" />, 'Wind', (d.windDirection ? d.windDirection + "°" : "--") + " - " + (d.wind ? d.wind + " km/hr" : "--")],

                [<FontAwesomeIcon inverse={false} icon="cloud" />, 'Wolkenbasis', (d.cloudbase ? d.cloudbase + " m" : "--")],
                [<FontAwesomeIcon inverse={false} icon="wind" transform='rotate-270' />, 'Thermiek', (d.avgLift ? d.avgLift + " m/s" : "--")],
                [null],

                [<FontAwesomeIcon inverse={false} icon="clock" />, 'Verwachte starttijd', Formatters.getShortTimeString(d.startTime) + ' hr'],
                [<InstructionIcon />, 'Condities voor lessen', (d.conditionsInstruction!=null ? FlightDay.Conditions[d.conditionsInstruction].code : "--")],
                [<XCIcon />, 'Condities voor XC', (d.conditionsXc != null ? FlightDay.Conditions[d.conditionsXc].code : "--")],
                [<FontAwesomeIcon inverse={false} icon="tachometer-alt" />, 'Reissnelheid', (d.xcSpeed ? d.xcSpeed + " km/hr" : "--")],
                [<FontAwesomeIcon inverse={false} icon="clipboard" />, 'Opmerkingen', (d.remarks ? d.remarks : "--")],
            ]
        return arr;
    }

    //---------------------------------
    private getSignedUpJSX() {
        const jsx =
            <>
                {this.getSignedUpHeaderJSX()}
                <hr />
                {this.getMySignedUpJSX()}
                <hr />
                {this.getSignedUpMembersJSX()}
            </>

        return jsx;
    }

    private getSignedUpHeaderJSX() {

        const headerJSX =
            <>
                <Row key={-1} >
                    <Col xs={4} >
                        <b>
                            {"Leden: " + (this.state.arrMemberFlightDays ? this.state.arrMemberFlightDays.length : 0)}
                        </b>
                    </Col>

                    <Col xs={2} className=''>
                    </Col>

                    <Col xs={6} className='text-right'>
                        <b>
                            {"Interne Ini's: " + this.getNrPassengerFlights()}
                        </b>
                    </Col>

                </Row>

                <Row>
                    <Col xs={4} >
                        <b>
                           
                        </b>
                    </Col>


                    <Col xs={8} className='text-right'>
                        {this.mayEditExternalInis() ?
                            <>
                                <span className='mx-2' onClick={this.handleEditExternalInis} >
                                    <FontAwesomeIcon icon="pen" size='1x' />
                                </span>
                                &nbsp;&nbsp;
                            </>
                            : null}

                        <b>
                            {"Externe ini's: " + (this.state.flightDay ? this.state.flightDay.nrExternalInis.toString() : 0)}
                        </b>
                    </Col>


                </Row>
            </>

        return headerJSX;
    }

    private getMySignedUpJSX() {
        if (!this.state.arrMemberFlightDays) return null;

        const memberFlightDay = this.getMemberFlightDay(this.props.member);

        if (!memberFlightDay) {
            const notSignedUpJSX =
                <div className='text-center' >
                    <Button size='sm' variant='primary' onClickCapture={this.handleSignUp} >
                        <FontAwesomeIcon inverse={false} icon="check" />
                        &nbsp;&nbsp;Maak mij aanwezig...
                    </Button>
                </div>
            return notSignedUpJSX;

        }
        else {
            const jsx =
                <>
                    <Row key={-1} style={{ background: '#ebf5fb' }} className='mx-0' >
                        <Col xs={1} className='p-0 my-auto text-center'>
                            {this.getProfilePictureInfoJSX(memberFlightDay.member)}
                        </Col>
                        <Col xs={7} >
                            <b>
                                {memberFlightDay.member.getFullName()}
                            </b>
                        
                            <div className='mb-1' style={{ fontSize: '0.7rem' }}>

                                {memberFlightDay.arrivalTime && memberFlightDay.arrivalTime.isValid ? Formatters.getShortTimeString(memberFlightDay.arrivalTime) : '--'}
                                &nbsp;-&nbsp;
                                {memberFlightDay.departureTime && memberFlightDay.departureTime.isValid ? Formatters.getShortTimeString(memberFlightDay.departureTime) : 'einde van de dag'}
                                <br />
                                Initiatie vluchten: {memberFlightDay.nrPassengerFlights.toString()}
                            </div>

                        </Col>

                        <Col xs={2} className='text-center my-auto'>
                            <Button size='sm' variant='primary' onClickCapture={this.handleSignUp}>
                                <FontAwesomeIcon icon="pen" size='1x' />
                            </Button>
                            </Col>
                            <Col xs={2} className='text-center my-auto'>
                            <Button size='sm' variant='primary' onClickCapture={this.handleSignOff}>
                                <FontAwesomeIcon icon="trash" size='1x' />
                            </Button>
                        </Col>

                    </Row>
                </>
            return jsx;
        }
    }

    private getSignedUpMembersJSX() {

        if (!this.state.arrMemberFlightDays || this.state.arrMemberFlightDays.length == 0) {
            return <span style={{ fontSize: '0.7rem' }}>Nog geen aanwezigen</span>
        }

        let sortedArr = new Array<IMemberFlightDay>();
        for (let x of this.state.arrMemberFlightDays) {
            //Exclude current member
            if (x.member.id != this.props.member.id) {
                sortedArr.push(x);
            }
        }

        //Sort
        sortedArr = sortedArr.sort((o1, o2) => {
            if (o1.member.firstName[0] > o2.member.firstName[0]) return 1;
            if (o1.member.firstName[0] < o2.member.firstName[0]) return -1;
            return 0;
        })

        //<div className="position-relative">
        const tableJSX =
                <>    

                {sortedArr.map(
                    (memberFlightDay, index) => {

                        const style = (index % 2 == 0 ? { background: '#ebf5fb' } : { background: 'white' });

                        const jsx =
                            <Row key={index} style={style} className={'ml-0 mr-0'}>
                                <Col xs={2} className='p-0 my-auto text-center'>
                                    {this.getProfilePictureInfoJSX(memberFlightDay.member)}
                                </Col>

                                <Col xs={9} >
                                    <div className='p-0 m-0'
                                        onClickCapture={() => { this.handleShowEasaStatus(memberFlightDay.member); }}
                                        style={{ cursor: 'pointer' }}>                                    

                                        {this.getTrafficlightJSX(memberFlightDay)}

                                    <b>
                                        {memberFlightDay.member.getFullName()}
                                    </b>



                                    <div className='mb-1' style={{ fontSize: '0.7rem' }}>

                                        {memberFlightDay.arrivalTime && memberFlightDay.arrivalTime.isValid ? Formatters.getShortTimeString(memberFlightDay.arrivalTime) : '--'}
                                        &nbsp;-&nbsp;
                                        {memberFlightDay.departureTime && memberFlightDay.departureTime.isValid ? Formatters.getShortTimeString(memberFlightDay.departureTime) : 'einde van de dag'}

                                    </div>
                                    </div>
                                </Col>

                                <Col xs={1} className='px-1 text-right'>
                                    <b>
                                        {memberFlightDay.nrPassengerFlights.toString()}
                                    </b>
                                </Col>
                            </Row>
                        return jsx;
                    }
                )
                }

            </>

        return tableJSX;
    }

    private getProfilePictureInfoJSX(member: IMember) {

        const jsx =
            //<span className='p-0 m-0' style={{ cursor: 'pointer' }} onClick={() => this.handleEdit(a.original as IMember)}>
            //<span className='p-0 m-0'>
            <ContactInfo size='sm' member={member} showAuthorization={true} >
                <ProfilePicture size='sm' srcURL={member.profilePictureUrl} alt={member.getFullName()} fluid={false} />
            </ContactInfo>
            //</span>

        return jsx;
    }

    private getTrafficlightJSX(memberFlightDay: IMemberFlightDay) {
        if (!memberFlightDay.easaStatus) return null;
        if (!this.maySeeMembersStatus()) return null;

        //<TrafficLightIcon color={memberFlightDay.easaStatus.getFlightColor()} size={1} />

        const jsx =
            <>
                <span hidden={!(memberFlightDay.easaStatus && this.maySeeMembersStatus())}>

                    <span className="fa-layers fa-fw">
                        <FontAwesomeIcon icon='circle' color={memberFlightDay.easaStatus.getFlightColor()} />
                        <span hidden={memberFlightDay.easaStatus.nrLogEntries<=0 } className="fa-layers-text fa-inverse" data-fa-transform="shrink-8 down-3 up-3" style={{ fontWeight: 'bold' }} >
                            {memberFlightDay.easaStatus.nrLogEntries.toString()}
                        </span>
                    </span>
                    &nbsp;
                </span>
            </>

        return jsx;
    }

    //--------------------------------

    private getDutiesJSX() {


        const jsx =
        <>
                <div className="position-relative">
                    <span>Instructeur(s)</span>
                    <>
                        {this.mayEditDuties() ?
                            <span className='px-1' onClick={(e: any) => { this.handleNewDuty(e, 'NewInstructor') }} >
                                <FontAwesomeIcon icon="plus-circle" size='1x' />
                            </span>
                            : null
                        }
                    </>
                    <hr />
                    {this.getDutiesTableJSX(this.state.arrInstructors)}

                </div>

                

                <div className="position-relative mt-4">
                    <span>Sleeppilo(o)t(en)</span>
                    <>
                        {this.mayEditDuties() ?
                            <span className='px-1 text-right' onClick={(e: any) => { this.handleNewDuty(e, 'NewTowPilot') }} >
                                <FontAwesomeIcon icon="plus-circle" size='1x' />
                            </span>
                            : null
                        }
                    </>
                    <hr />
                    {this.getDutiesTableJSX(this.state.arrTowPilots)}
                </div>
        </>

        return jsx;
    }

    private getDutiesTableJSX(arr: Array<IDuty>): any {

        if (!arr || arr.length==0) return <span style={{ fontSize: '0.7rem' }}>Nog geen beurtrol</span>

        const jsx =
            <>
                {
                    arr.map(
                    (duty, index) => {
                        const style = (index % 2 == 0 ? { background: '#ebf5fb' } : { background: 'white' });
                        const jsx =
                            <Row key={index} style={style} className={'ml-0 mr-0'} >
                                
                                <Col xs={2} className='p-0 my-auto text-center'>
                                    {this.getProfilePictureInfoJSX(this.getCorrectMember(duty))}
                                </Col>

                                <Col xs={7} className='px-0' >
                                    <b>
                                        { this.getCorrectMemberJSX(duty)}
                                    </b>
                                    <div className='mb-1' style={{ fontSize: '0.7rem' }}>
                                        <span style={{ fontSize: '0.7rem' }}>
                                            {this.getDutyTimeJSX(duty)}
                                        </span>
                                        <br/>
                                        {duty.replacementMember ? "Vervanging van : " + duty.member.getFullName() : null}
                                    </div>
                                </Col>
                                <Col xs={1} className='text-center px-0' style={style} >
                                    <>
                                        {this.mayEditDuties() ?
                                            <span className='px-1' onClick={(e: any) => { this.handleEditDuty(e, duty) }} >
                                                <FontAwesomeIcon icon="pen" size='1x' />
                                            </span>
                                            : null
                                        }
                                    </>
                                </Col>
                                <Col xs={1} className='text-center px-0' style={style} >
                                    <>
                                        {this.mayEditDuties() ?
                                            <span className='px-1' onClick={(e: any) => { this.handleDeleteDuty(e, duty) }} >
                                                <FontAwesomeIcon icon="trash" size='1x' />
                                            </span>
                                            : null
                                        }
                                    </>
                                </Col>
                                <Col xs={1} className='text-center px-0' style={style} >
                                    <>
                                        {this.mayDelegateDuties() ?
                                            <span className='px-1' onClick={(e: any) => { this.handleReplaceDuty(e, duty) }} >
                                                <FontAwesomeIcon icon="exchange-alt" size='1x' />
                                            </span>
                                            : null
                                        }
                                    </>
                                </Col>

                            </Row>
                        return jsx;
                    }
                    )
                }

            </>
        return jsx;
    }

    private getDutyTimeJSX(duty: IDuty) {
        if (!duty) return null;

        if (duty.am && duty.pm) return "VM, NM";
        if (duty.am) return "VM";
        if (duty.pm) return "NM";

        return null;;
    }

    private getCorrectMember(duty: IDuty) : IMember {
        if (!duty || !duty.member) return null;
        const member = duty.replacementMember ? duty.replacementMember : duty.member;

        return member;
    }

    private getCorrectMemberJSX(duty: IDuty) {

       
        const member = this.getCorrectMember(duty);

        const jsx=
            <>
                {
                    //this.getProfilePictureJSX(member)
                }
                
                {member.getFullName()}
            </>
        return jsx;
    }

    private getDeleteDutyDlgJSX() {
        const bodyText = <>
            Bent u zeker dat u deze beurtrol wilt verwijderen?
            <br />
            <br />
            {this.state.selectedDuty ? this.state.selectedDuty.member.getFullName() : null}
        </>

        const frm = <SimpleModal headingText="Beurtrol verwijderen" bodyText={bodyText}
            buttons={ModalButtons.OKCancel} onClose={this.handleDeleteDutyDlg}
        />
        return frm;
    }

    private getConfirmSignOffJSX() {
        const bodyText = <>
            Bent u zeker dat u deze aanwezigheid wilt verwijderen?
        </>

        const frm = <SimpleModal headingText="Aanwezigheid verwijderen" bodyText={bodyText}
            buttons={ModalButtons.OKCancel} onClose={this.handleConfirmSignOff}
        />
        return frm;
    }

    private getEasaStatusPageJSX() {
        if (!this.state.selectedMember) return null;

        const jsx =
            <FlightStatusForm formMode={FormModus.ReadOnly} member={this.state.selectedMember} onClose={() => { this.setState({showFlightStatusPage: false, selectedMember: null}) } } />

        return jsx;
    }
    //======================== Logic handlers =============================//

    public setDutiesPerType(arrDuties: Array<IDuty>) {
        let arrInstructors = new Array<IDuty>();
        let arrTowPilots = new Array<IDuty>();

        if (arrDuties) {
            for (let d of arrDuties) {
                if (d.dutyType == Duty.DutyTypeInstructor) {
                    arrInstructors.push(d);
                }
                else if (d.dutyType == Duty.DutyTypeTowPilot) {
                    arrTowPilots.push(d);
                }
                else {
                    throw new Error("Unknown duty type: " + d.dutyType)
                }
            }

            //Sort
            arrInstructors = arrInstructors.sort((o1, o2) => {
                if (o1.am == o2.am && o1.pm == o2.pm) return 0;
                if (o1.am && !o1.pm) return -1;
                if (!o1.am && o1.pm) return 1;
                if (o1.am && o1.pm && o2.am) return 1;
                if (o1.am && o1.pm && !o2.am) return -1;
                return 0;
            })

            arrTowPilots = arrTowPilots.sort((o1, o2) => {
                if (o1.am == o2.am && o1.pm == o2.pm) return 0;
                if (o1.am && !o1.pm) return -1;
                if (!o1.am && o1.pm) return 1;
                if (o1.am && o1.pm && o2.am) return 1;
                if (o1.am && o1.pm && !o2.am) return -1;

                return 0;
            })
        }

        this.setState({arrInstructors: arrInstructors, arrTowPilots: arrTowPilots})
    }

    public getMemberFlightDay(member: IMember): IMemberFlightDay {
        if (!this.state.arrMemberFlightDays) return null;

        return this.state.arrMemberFlightDays.find(x => x.member.id == member.id);
    }

    public getNewMemberFlightDay(member: IMember): IMemberFlightDay {
        const mfd = new MemberFlightDay();
        mfd.arrivalTime = MyDateTime.createDateTime(this.state.flightDay.day.year, this.state.flightDay.day.month, this.state.flightDay.day.day, 10, 0, 0);
        mfd.departureTime = null;
        mfd.nrPassengerFlights = 0
        mfd.member = member;

        return mfd;
    }

    public getNrPassengerFlights() : number {
        if (!this.state.arrMemberFlightDays) return 0;

        let x = 0;
        for (let mfd of this.state.arrMemberFlightDays) {
            x = x + mfd.nrPassengerFlights;
        }
        return x;
    }

    private maySeeMembersStatus(): boolean {

        if (ApplicationState.get().sessionInfo.account.hasRole(Roles.Admin)) return true;
        if (ApplicationState.get().sessionInfo.account.hasRole(Roles.Mngt)) return true;

        if (!ApplicationState.get().sessionInfo.account.member) return false;
        if (!ApplicationState.get().sessionInfo.account.member.isCurrentlyMember()) return false;

        if (ApplicationState.get().sessionInfo.account.member.isInstructor) return true;
        if (ApplicationState.get().sessionInfo.account.member.isExaminator) return true;

        return false;
    }

    //======================== Get Handlers =============================//

    private handleAccordeonSelect(eventKey: string) {
        this.setState({ activeAccordeon: eventKey })
    }

    private handleBackToCalendar() {
        if (this.props.onFlightDayClosed) {
            this.props.onFlightDayClosed();
        }
    }

    private handleDownload(result: ModalResult, filetype: Filetypes) {
        if (result != ModalResult.OK) return;

        this.loadBriefingDocAsync(this.state.flightDay.day.year, this.state.flightDay.day.month, this.state.flightDay.day.day, filetype);
    }

    private handleEditForecast(e: any) {
        e.stopPropagation()
        this.setState({ showEditForecastDlg: true })
    }

    private handleEditExternalInis(e: any) {
        e.stopPropagation()
        this.setState({ showEditExternalInisDlg: true })
    }

    private handleSignUp(e: any) {
        e.stopPropagation();
        this.setState({ showEditMemberFlightDayDlg: true })
    }

    private async handleSignOff() {
        this.setState({ showConfirmSignOffDlg: true });
    }

    private handleReplaceDuty(e: any, duty: IDuty) {
        
        e.stopPropagation()
        this.setState({ showEditDutiesDlg: true, selectedDuty : duty, dutyChangeMode : 'Replace' })
    }

    private handleNewDuty(e: any, newJob: 'NewInstructor' | 'NewTowPilot') {

        e.stopPropagation()
        this.setState({ showEditDutiesDlg: true, selectedDuty: null, dutyChangeMode: newJob })
    }

    private handleEditDuty(e: any, duty: IDuty) {

        e.stopPropagation()
        this.setState({ showEditDutiesDlg: true, selectedDuty: duty, dutyChangeMode : 'Edit' })
    }

    private handleDeleteDuty(e: any, duty: IDuty) {

        e.stopPropagation()
        this.setState({ showDeleteDutyDlg: true, selectedDuty: duty })
    }

    private async handleCloseEditForecastDgl(result: ModalResult, entity: IFlightDay) {

        this.setState({ showEditForecastDlg: false, activeAccordeon: '0' })

        if (result == ModalResult.OK) {
            const updatedFlightDay = await this.upsertFlightDay(entity);

            if (updatedFlightDay) {
                if (this.props.onFlightDayChanged) {
                    this.props.onFlightDayChanged(updatedFlightDay);
                }
            }
        }
        
    }

    private async handleCloseEditExternalInis(result: ModalResult, newValue: number) {

        this.setState({showEditExternalInisDlg: false, activeAccordeon: '1'});

        if (result == ModalResult.OK) {
            if (newValue) {
                this.state.flightDay.nrExternalInis = newValue;
            }
            else {
                this.state.flightDay.nrExternalInis = 0;
            }

            const updatedFlightDay = await this.upsertFlightDay(this.state.flightDay);

            if (updatedFlightDay) {
                if (this.props.onFlightDayChanged) {
                    this.props.onFlightDayChanged(updatedFlightDay);
                }
            }
        }

    }

    private async handleCloseEditMemberFlightDayDgl(result: ModalResult, entity: IMemberFlightDay) {

        this.setState({ showEditMemberFlightDayDlg: false, activeAccordeon: '1' })

        if (result == ModalResult.OK) {
            await this.upsertMemberFlightDay(entity);
            await this.loadMembersPresentFlightDaysAsync(this.state.flightDay.id);
        }

    }

    private async handleConfirmSignOff(res: ModalResult) {

        this.setState({ showConfirmSignOffDlg: false, activeAccordeon: '1' });

        if (res == ModalResult.OK) {

            const mfd = this.getMemberFlightDay(this.props.member);

            await this.removeMemberFlightDay(mfd.id);
            await this.loadMembersPresentFlightDaysAsync(this.state.flightDay.id);

        }
    }

    private async handleCloseDutyDgl(result: ModalResult, updatedDuty: IDuty) {

        this.setState({ showEditDutiesDlg: false, selectedDuty: null, activeAccordeon: '2' });

        if (result == ModalResult.OK) {
            updatedDuty = await this.upsertDutyFlightDay(updatedDuty)
            await this.loadDutiesAsync(this.props.flightDayInput.day.year, this.props.flightDayInput.day.month, this.props.flightDayInput.day.day,);
        }
    }

    private async handleDeleteDutyDlg(result: ModalResult) {

        this.setState({ showDeleteDutyDlg: false, activeAccordeon: '2' });

        if (result == ModalResult.OK) {
            await this.deleteDutyFlightDay(this.state.selectedDuty);
            await this.loadDutiesAsync(this.props.flightDayInput.day.year, this.props.flightDayInput.day.month, this.props.flightDayInput.day.day,);
        }
    }

    private handleShowEasaStatus(member: IMember) {
        if (!this.maySeeMembersStatus()) return;
        this.setState({ selectedMember: member, showFlightStatusPage:true })
    }

    private mayEditForeCast(): boolean {
        if (ApplicationState.get().sessionInfo.account.hasRole(Roles.Admin)) return true;
        if (ApplicationState.get().sessionInfo.account.hasRole(Roles.Mngt)) return true;

        if (!this.props.member) return false;
        if (this.props.member.isInstructor) return true;
        if (this.props.member.isExaminator) return true;
        if (this.props.member.isTowPilot) return true;

        return false;
    }

    private mayDelegateDuties(): boolean {
        
        if (ApplicationState.get().sessionInfo.account.hasRole(Roles.Admin)) return true;
        if (ApplicationState.get().sessionInfo.account.hasRole(Roles.Mngt)) return true;

        if (!this.props.member) return false;
        if (this.props.member.isInstructor) return true;
        if (this.props.member.isExaminator) return true;
        if (this.props.member.isTowPilot) return true;

        return false;
    }

    private mayEditExternalInis(): boolean {

        if (ApplicationState.get().sessionInfo.account.hasRole(Roles.Admin)) return true;
        if (ApplicationState.get().sessionInfo.account.hasRole(Roles.Mngt)) return true;

        return false;
    }

    private mayEditDuties(): boolean {

        if (ApplicationState.get().sessionInfo.account.hasRole(Roles.Admin)) return true;

        return false;
    }

   //======================== Get Form Handlers =============================//


    //======================== Get Validation =============================//

    //======================== Data Layer =============================//
    private async loadBaseTables() {

        this.loadDutiesAsync(this.props.flightDayInput.day.year, this.props.flightDayInput.day.month, this.props.flightDayInput.day.day,);
        this.loadMembersPresentFlightDaysAsync(this.state.flightDay.id);
    }

    private async upsertFlightDay(flightDay: IFlightDay) : Promise<IFlightDay> {

        const http = new Http();
        this.setState({ isLoadingForecast: true });

        let res = await http.postAsyncObject<IFlightDay>("api/flightday/", flightDay, FlightDay);

        if (http.error == null) {
            this.setState({ isLoadingForecast: false, flightDay : res as IFlightDay });
            return res;
        }
        else {
            ApplicationState.get().setError(http.error);
            this.setState({ isLoadingForecast: false });
            return null;
        }
    }

    private async upsertMemberFlightDay(memberFlightDay: IMemberFlightDay): Promise<IMemberFlightDay> {

        const http = new Http();
        this.setState({ isLoadingMemberFlightDays: true });

        let res = await http.postAsyncObject<IMemberFlightDay>("api/flightday/member/" + this.state.flightDay.id, memberFlightDay, MemberFlightDay);
        
        if (http.error == null) {
            this.setState({ isLoadingMemberFlightDays: false });
            return res as IMemberFlightDay;
        }
        else {
            this.setState({ isLoadingMemberFlightDays: false });
            ApplicationState.get().setError(http.error);
            return null;
        }
    }

    private async removeMemberFlightDay(memberFlightDayId: string): Promise<boolean> {

        const http = new Http();
        this.setState({ isLoadingMemberFlightDays: true });

        let res = await http.deleteAsyncPrimitive<boolean>("api/flightday/member/" + memberFlightDayId);

        if (http.error == null) {
            this.setState({ isLoadingMemberFlightDays: false });
            return res as boolean;
        }
        else {
            ApplicationState.get().setError(http.error);
            this.setState({ isLoadingMemberFlightDays: false });
            return null;
        }
    }

    private async loadMembersPresentFlightDaysAsync(flightDayId: string): Promise<Array<IMemberFlightDay>> {

        const http = new Http();
        this.setState({ isLoadingMemberFlightDays: true });

        const res = await http.getAsyncObject<IMemberFlightDay>('api/flightday/memberspresent/' + flightDayId, MemberFlightDay) as Array<IMemberFlightDay>;

        if (http.error == null) {
            this.setState({ isLoadingMemberFlightDays: false, arrMemberFlightDays: res as Array<IMemberFlightDay> });
            return (res as Array<IMemberFlightDay>);
        }
        else {
            this.setState({ isLoadingMemberFlightDays: false, arrMemberFlightDays: null });
            ApplicationState.get().setError(http.error);
            return null;
        }
    }

    private async loadDutiesAsync(year: number, month: number, day: number): Promise<Array<IDuty>> {

        const http = new Http();
        this.setState({ isLoadingDuties: true });

        const res = await http.getAsyncObject<IDuty>('api/duty/' + year.toString() + '/' + month.toString() + '/' + day.toString(),  Duty) as Array<IDuty>;

        if (http.error == null) {
            this.setDutiesPerType(res as Array<IDuty>);
            this.setState({ isLoadingDuties: false });
            return (res as Array<IDuty>);
        }
        else {
            this.setState({ isLoadingDuties:false, arrInstructors : null, arrTowPilots : null });
            ApplicationState.get().setError(http.error);
            return null;
        }
    }

    private async upsertDutyFlightDay(duty: IDuty): Promise<IDuty> {

        const http = new Http();
        this.setState({ isLoadingDuties: true });

        let res = await http.postAsyncObject<IDuty>("api/duty/", duty, Duty);
        
        if (http.error == null) {
            this.setState({ isLoadingDuties: false });
            return res as IDuty;
        }
        else {
            this.setState({ isLoadingDuties: false });
            ApplicationState.get().setError(http.error);
            return null;
        }
    }

    private async deleteDutyFlightDay(duty: IDuty): Promise<boolean> {
        if (!duty) return false;

        const http = new Http();
        this.setState({ isLoadingDuties: true });

        await http.deleteAsyncPrimitive<boolean>("api/duty/" + duty.id);

        if (http.error == null) {
            this.setState({ isLoadingDuties: false });
            return true;
        }
        else {
            this.setState({ isLoadingDuties: false });
            ApplicationState.get().setError(http.error);
            return false;
        }
    }

    private async loadBriefingDocAsync(year: number, month: number, day: number, filetype: Filetypes) {

        const http = new Http();
        await http.getAsyncFile("api/report/briefing/" + year.toString() + "/" + month.toString() + "/" + day.toString() + "/" + filetype);

        if (http.error == null) {
        }
        else {
            ApplicationState.get().setError(http.error);
        }
    }

    //======================== Lifecycle =============================//
    async componentDidMount() {
        //console.log("mounting ConfirmFlightsDialog");
        this.loadBaseTables();
    }

    componentDidUpdate(prevProps: IFlightDayDetailsProps) {

    }
}