import * as React from 'react';

//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'



import {  Button, Modal, Row, Col } from 'react-bootstrap';
import { ModalResult } from './../HelperClasses/Enums';
import { NumericField } from "../UIComponents/NumericField";

interface INumericModalProps {
    headingText?: JSX.Element | string;
    bodyText?: JSX.Element | string;

    value: number;

    onClose: (result: ModalResult, newValue: number) => void;
}

interface INumericModalState {
    show: boolean;
    innerValue: number;
}


export class NumericModal extends React.Component<INumericModalProps, INumericModalState> {



    constructor(props: INumericModalProps, context: INumericModalState ) {
        super(props, context);

        this.handleClose = this.handleClose.bind(this);

        this.state = {
            innerValue: this.props.value,
            show: true,
        };

        this.handleChangeNumeric = this.handleChangeNumeric.bind(this);
    }


    render() {

        let dlg =
        <Modal show={this.state.show} onHide={() => { this.handleClose(ModalResult.Cancel) }} >
            <Modal.Header closeButton>
                <Modal.Title>{this.props.headingText}</Modal.Title>
            </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col xs={3} className="my-auto" >
                            {this.props.bodyText}
                        </Col>
                        <Col xs={9} >
                            {this.getNumericJSX()}
                        </Col>
                    </Row>
                </Modal.Body>
            <Modal.Footer>

                    <Button variant="secondary" onClick={() => { this.handleClose(ModalResult.Cancel) }} >Annuleren</Button>
                    <Button variant="primary" onClick={() => { this.handleClose(ModalResult.OK) }} >OK</Button>

            </Modal.Footer>
        </Modal>

        return (
            <>
                {dlg}
            </>
        );
    }

    private getNumericJSX() {
        const jsx =
            <>
                <NumericField
                    value={this.state.innerValue}
                    precision={0}
                    step={1}
                    allowNegativeValue={false}
                    onChangeCapture={this.handleChangeNumeric}
                    style={{maxWidth:'100px'}}
                />
            </>

        return jsx;
    }


    private handleChangeNumeric(newValue: number) {
        if (newValue) {
            this.setState({ innerValue: newValue })
        }
        else {
            this.setState({ innerValue: 0 })
        }
    }

    handleClose(result: ModalResult) {
        //console.log("HandleClose: " + result)

        this.setState({ show: false });

        if (this.props.onClose) {
            if (result == ModalResult.OK) {
                this.props.onClose(result, this.state.innerValue);
            }
            else {
                this.props.onClose(result, null);
            }
        }
    }

    componentDidMount() {
        //console.log("mounting SimpleDialog");
    }

    componentWillUnmount() {
        //console.log("unmounting SimpleDoialog");
    }
}
