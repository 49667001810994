import * as React from 'react';

import { Image } from "react-bootstrap";



//=========================================================================================//
export interface IProfilePictureProps   {
    size: 'sm' | 'md' | 'lg';
    srcURL: string;
    alt?: string;
    fluid?: boolean;
}

export const ProfilePicture: React.SFC<IProfilePictureProps> = (props) => {
    if (!props.srcURL || props.srcURL.length==0) return null;
    const jsx=
    <>
            {getMobileJSX(props)}
            {getPCJSX(props)}
    </>

    return jsx;
}

const getMobileJSX = (props: IProfilePictureProps): JSX.Element => {

    let size = 32;
    if (props.size === 'sm') size = 32;
    if (props.size === 'md') size = 128;
    if (props.size === 'lg') size = 200;
    //console.log(props.srcURL)

    const jsx =
        <>
            <Image
                className='d-md-none'
                alt={props.alt ? props.alt : "ProfilePicture"}
                title={props.alt ? props.alt : ''}
                src={props.srcURL}
                roundedCircle
                width={size}
                height={size}
                fluid={props.fluid ? props.fluid : false}
                key={Date.now()}
            />
        </>
    return jsx;
}


const getPCJSX = (props: IProfilePictureProps): JSX.Element => {

    let size = 40;
    if (props.size === 'sm') size = 40;
    if (props.size === 'md') size = 168;
    if (props.size === 'lg') size = 320;

    const jsx =
        <>
            <Image className='d-none d-md-inline'
                alt={props.alt ? props.alt : "ProfilePicture"}
                title={props.alt ? props.alt : ''}
                src={props.srcURL}
                roundedCircle
                width={size}
                height={size}
                fluid={props.fluid ? props.fluid : false}
                key={Date.now()}
            />
        </>
    return jsx;
}

//function getViewport() {
//    // https://stackoverflow.com/a/8876069
//    const width = Math.max(
//        document.documentElement.clientWidth,
//        window.innerWidth || 0
//    )
//    if (width <= 576) return 'xs'
//    if (width <= 768) return 'sm'
//    if (width <= 992) return 'md'
//    if (width <= 1200) return 'lg'
//    return 'xl'
//}