
export enum HttpStatus { httpUnknown = 0, http200 = 200, http400 = 400, http401 = 401, http403 = 403, http404 = 404, http500 = 500, httpCustomError = 555 };

export interface ICustomError {

    errorMessage: string | JSX.Element;
    httpStatus: HttpStatus;
    url: string;
    details: string;
    customErrorNr: number;
    customErrorData: string;

    isHttpCustomError(): boolean;
}

export class CustomError implements ICustomError {

    errorMessage: string | JSX.Element = null;
    httpStatus = HttpStatus.httpUnknown;
    url: string = null;
    details: string = null;
    customErrorNr: number = null;
    customErrorData: string = null;

    constructor(errorMessage: string | JSX.Element, httpStatus: HttpStatus, url: string, details: string, customErrorNr: number, customErrorData: string) {
        this.errorMessage = errorMessage;
        this.httpStatus = httpStatus;
        this.url = url;
        this.details = details;
        this.customErrorNr = customErrorNr;
        this.customErrorData = customErrorData;
    };

    public isHttpCustomError(): boolean { return this.httpStatus == HttpStatus.httpCustomError; }
}




    