

import { MyDateTime } from "./../HelperClasses/MyDate";
import { ISerializable } from './ISerializable';
//import { IValidation, IValidationState } from './IValidation'

//import moment = require('moment');


export interface IFlightCostsTransaction extends ISerializable<IFlightCostsTransaction> {

    memberId: string;
    date: MyDateTime;
    startBalance: number;
    payments: number;
    flightCosts: number;
    launchCosts: number;
    balance: number;
    
}


export class FlightCostsTransaction implements IFlightCostsTransaction {

    memberId: string;
    date: MyDateTime;
    startBalance: number;
    payments: number;
    flightCosts: number;
    launchCosts: number;
    balance: number;

    constructor() {
    }


    public fromJson(json: Object): FlightCostsTransaction {
        //DO NOT USE 'this.' RETURN A NEW OBJECT
        //console.log("Aircraft.fromJson");

        const trueObject = new FlightCostsTransaction();
        Object.assign<IFlightCostsTransaction, Object>(trueObject, json);

        //Overloads
        trueObject.date = MyDateTime.fromJson(trueObject.date);

        return trueObject;
    }


    public toJson(): Object {
        //console.log("CostsBalance.toJson");

        let x = JSON.parse(JSON.stringify(this));

        //Overloads

        if (this.date) x.date = this.date.toJson();
        
        //console.log(x);
        return x;
    }

}