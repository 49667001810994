import * as React from 'react';

import { Form, Row, Col, Button } from "react-bootstrap";

//import { CustomError, HttpStatus } from '../HelperClasses/CustomError';

import ReactCrop from 'react-image-crop';
import { Crop } from 'react-image-crop';
import "react-image-crop/dist/ReactCrop.css";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { Styles } from './../HelperClasses/Styles';
import { Http } from './../HelperClasses/Http';
import { ApplicationState } from './../HelperClasses/ApplicationState';

import { ProfilePicture } from './ProfilePicture';
import { IAccount } from './../DTO/Account';


export interface IProfilePictureEditorProps {

    account: IAccount;
    onChange? : (URL: string) => void;
}

export interface IProfilePictureEditorState {
    srcFile: string;
    srcImage: string;
    crop: Crop;

    profilePictureBlob: Blob;
    profilePictureUrl: string;

    isDragging: boolean;
    isInvalidFiletype: boolean;
}


export class ProfilePictureEditor extends React.Component<IProfilePictureEditorProps, IProfilePictureEditorState> {

    private imageRef: HTMLImageElement;
    private fileUrl: string;

    private allowedFileFormats: string[];

    constructor(props: any) {
        super(props);

        this.allowedFileFormats = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif', 'image/x-icon', 'image/bmp', 'image/svg+xml', 'image/tiff', 'image/webp'];

        this.state = {
            srcFile: null,
            srcImage: null,
            crop: {
                unit: "px",
                width: 100,
                height: 100,
                aspect: 1,
                x: 0,
                y: 0
            },
            profilePictureBlob: null,
            profilePictureUrl: null,
            isDragging: false,
            isInvalidFiletype: false,
        };

        this.handleUpload = this.handleUpload.bind(this);
        this.handleSrcImageLoaded = this.handleSrcImageLoaded.bind(this);
        
        this.handleCropComplete = this.handleCropComplete.bind(this);
        this.handleCropChange = this.handleCropChange.bind(this);

        this.handleSelectedFile = this.handleSelectedFile.bind(this);
        this.handleDroppedFile = this.handleDroppedFile.bind(this);

        this.handleDrag = this.handleDrag.bind(this);

        this.handleResetImg = this.handleResetImg.bind(this);
        this.handleSaveImg = this.handleSaveImg.bind(this);
        this.fireOnChange = this.fireOnChange.bind(this);
    }

    render() {
        const jsx =
            <>
                {this.getHiddenFileSelectorJSX()}


                {this.getDropZoneJSX()}

                {this.getPreviewZoneJSX() }

            </>

        return jsx;
    }

    private getDropZoneJSX() {

        let styleObj = { maxWidth: '1024px', borderStyle: 'dashed', borderColor: '#0a4874' };
        let styleObjButtons = { maxWidth: '1024px' }

        if (!this.state.srcImage) {
            styleObj = {
                ...styleObj, ...{ minHeight: '300px', minWidth: '300px', borderWidth: 'thin' }
            }
            styleObjButtons = {
                ...styleObjButtons, ...{ minWidth: '300px' }
            }

        }

        if (!this.state.isDragging) {
            styleObj = {
                ...styleObj, ...{ borderWidth: 'thin' }
            }
        }
        else {
            styleObj = {
                ...styleObj, ...{ borderWidth: 'thick' }
            }
        }

        


        const jsx =
            <>

            <div className='py-0 my-0 mx-auto d-flex justify-content-center'
                style={styleObj}
                onDragStart={this.handleDrag}
                onDragEnter={this.handleDrag}
                onDragOver={this.handleDrag}
                onDragLeave={(e) => { e.preventDefault(); this.setState({ isDragging: false }); }}
                onDropCapture={this.handleDroppedFile} >

                {this.state.srcImage ?
                    null :
                    <>
                        <div className='my-auto mx-auto'>
                            <Row className='justify-content-center'>
                                <FontAwesomeIcon icon="cloud-upload-alt" color='#0a4874' size='7x' />
                            </Row>
                            <Row className='text-center justify-content-center'>
                                Sleep hier een foto
                                <br />
                                - OF -
                            </Row>
                            <Row>
                                <Button variant='outline-primary' onClickCapture={this.handleUpload}>
                                    Selecteer een foto
                                </Button>
                            </Row>
                        </div>
                        <br />
                        
                    </>
                }


                {this.state.srcImage ? this.getCropperJSX() : null}

            </div>

            <div className='mx-auto p-2' style={styleObjButtons} >{this.getButtonsRowJSX()} </div>

        </>
        return jsx;
    }

    private getCropperJSX() {
        if (!this.state.srcImage) return null;
        const jsx =
            <ReactCrop
                imageAlt='Dropzone'
                src={this.state.srcImage}
                crop={this.state.crop}
                ruleOfThirds={false}
                circularCrop={true}
                scale={1}
                rotate={0}
                style={{}}
                keepSelection={true}
                onImageLoaded={this.handleSrcImageLoaded}
                onComplete={this.handleCropComplete}
                onChange={this.handleCropChange}
            />

        return jsx;
    }

    private getHiddenFileSelectorJSX() {
        const jsx =
            <Form.File
                ref={'fileSelector'}
                className='d-none'
                custom={true}
                label='Selecteer een foto'
                onChangeCapture={this.handleSelectedFile}
                size='sm'
                accept={this.allowedFileFormats}
                data-browse='Zoeken'
            />
        return jsx;
    }

    private getButtonsRowJSX() {

        const jsx =
            <Row className=''>
                <Col className='col-auto'>
                    <span hidden={!this.state.isInvalidFiletype} style={Styles.getErrorColorStyle()}>Ongeldig formaat</span>
                </Col>
                <Col className='col-auto ml-auto'>
                    <Button hidden={!this.state.srcImage} variant='outline-secondary' size='sm' onClickCapture={this.handleResetImg}>Reset</Button>
                    &nbsp;&nbsp;
                    <Button hidden={!this.state.srcImage} variant='outline-primary' size='sm' onClickCapture={this.handleSaveImg}>Opslaan</Button>
                </Col>
            </Row>

        return jsx;
    }

    private getPreviewZoneJSX() {
        return null;
        if (!this.state.srcImage) return null;
        const jsx =
            <>
                <hr />
                <Row className='mx-auto my-auto p-0 m-0 justify-content-center text-center'>
                    <Col className='p-0 m-0 col-auto'>
                        <ProfilePicture size='lg' srcURL={this.state.profilePictureUrl} />
                    </Col>
                    <Col className='p-0 m-0 col-auto '>
                        <ProfilePicture size='md' srcURL={this.state.profilePictureUrl} />
                        <br /><br />
                        <ProfilePicture size='sm' srcURL={this.state.profilePictureUrl} />
                    </Col>
                </Row>

                <hr />
            </>
        return jsx;
    }

    //========================== Handlers ==============================================

    private handleUpload(e: any) {

        if (this.refs.fileSelector) {
            (this.refs.fileSelector as HTMLInputElement).click();
        }

        return false;
    }

    private handleDrag(e: any) {
        e.preventDefault();
        this.setState({ isDragging: true });
    }

    private handleSrcImageLoaded = (image: HTMLImageElement) => {
        this.imageRef = image;

        const crop = {
            unit: 'px',
            width: 100,
            height: 100,
            aspect: 1,
            x: image.width / 2 - 50,
            y: image.height / 2 - 50
        }

        this.makeClientCrop(crop as any);

        this.setState({ crop: crop as any });

        // If you setState the crop in here you should return false.
        return false;

    };

    private handleCropComplete = (crop: Crop) => {

        this.makeClientCrop(crop);
    };

    private handleCropChange = (crop: Crop, percentCrop: Crop) => {
        // You could also use percentCrop: this makes the crop move with a resize
        this.setState({ crop: percentCrop });
        //this.setState({ crop });

        //below is too slow
        //this.makeClientCrop(crop);
    };

    private handleSelectedFile(e: any) {
        const files = e.target.files;

        if (files && files.length > 0) {
            this.loadFile(e.target.files[0])
        }
    };

    private handleDroppedFile(e: any) {
        e.preventDefault();
        this.setState({ isDragging: false });
        
        const files = e.dataTransfer.files;
        //console.log(files[0].name)

        if (files && files.length > 0) {
            this.loadFile(files[0]);
        }

    }

    private loadFile(fileBlob: Blob) {

        if (fileBlob) {

            if (this.allowedFileFormats.indexOf(fileBlob.type) === -1) {
                this.setState({ isInvalidFiletype: true });
                return;
            }
            this.setState({ isInvalidFiletype: false });

            const reader = new FileReader();
            reader.addEventListener("load", () =>
                this.setState({ srcFile: (fileBlob as any).name, srcImage: (reader.result as string) })
            );
            reader.readAsDataURL(fileBlob);
        }
    }

    private async fireOnChange(URL: string) {
        if (this.props.onChange) {
            this.props.onChange(URL);
        }
    }

    private handleResetImg() {
        

        const crop = {
            unit: "px",
            width: 100,
            height: 100,
            aspect: 1,
            x: 0,
            y: 0
        };

        this.setState({ crop: crop as any, srcFile: null, srcImage: null, profilePictureUrl: null, isInvalidFiletype: false })

        window.URL.revokeObjectURL(this.fileUrl);
    }

    private async handleSaveImg() {
        //Save
        const url = await this.uploadImageAsync(this.state.profilePictureBlob, this.state.srcFile);

        //and clear
        this.handleResetImg();

        this.fireOnChange(url);
    }

    //ToDo
    //toepassen in profile menu: send event when pic changed in account? WERKT NIET OP MOBIEL?? check op acc
    

    //======================== Imaging logic =====================================

    private async makeClientCrop(crop: Crop) {
        if (this.imageRef && crop.width && crop.height) {
            const croppedImageUrl = await this.getCroppedImg(
                this.imageRef,
                crop,
                "newFile.jpeg"
            );
            this.setState({ profilePictureUrl: (croppedImageUrl as string) });
        }
    }

    private getCroppedImg(image: HTMLImageElement, crop: Crop, fileName: string) {
        // Set display size (css pixels).

        const srcCanvas = document.createElement("canvas");
        var size = 320;
        srcCanvas.width = size;
        srcCanvas.height = size;

        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        const ctx = srcCanvas.getContext("2d");

        const pixelRatio = window.devicePixelRatio;
        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx.imageSmoothingQuality = "high";

        ctx.drawImage(
            image,
            crop.x * scaleX, //src x
            crop.y * scaleY, //src y
            crop.width * scaleX * pixelRatio, //src Width
            crop.height * scaleY * pixelRatio, //src Height
            0, //dest x
            0, //dest y
            size, //dest Width
            size //des Height
        );

        return new Promise((resolve, reject) => {
            srcCanvas.toBlob(
                (blob) => {
                    if (!blob) {
                        //reject(new Error('Canvas is empty'));
                        return;
                    }

                    //blob.name = fileName;
                    this.setState({ profilePictureBlob: blob });
                    window.URL.revokeObjectURL(this.fileUrl);
                    this.fileUrl = window.URL.createObjectURL(blob);
                    resolve(this.fileUrl);
                },
                "image/jpeg", //type
                0.92 //quality: https://developer.mozilla.org/en-US/docs/Web/API/HTMLCanvasElement/toBlob
            );
        });

    }


    //============================== Data layer =============================================
    private async uploadImageAsync(blob: Blob, fileName: string) : Promise<string>{

        const http = new Http();
        const url = await http.postAsyncFile<string>("api/profilepicture", blob, fileName);
        
        if (http.error == null) {
            return url as string;
        }
        else {

            ApplicationState.get().setError(http.error);
            return null;
        }
    }


}