import * as React from "react";
import * as ReactDOM from "react-dom";
//import { BrowserRouter } from "react-router-dom";
import { HashRouter } from "react-router-dom";
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

//ReactDOM.render(<App />, document.getElementById('root'));
//ReactDOM.render(<BrowserRouter> <App /> </BrowserRouter>, document.getElementById('root'));
ReactDOM.render(<HashRouter > <App /> </HashRouter>, document.getElementById('root'));


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
