import * as React from 'react';


import { Row, Col } from 'react-bootstrap'
import { Jumbotron } from 'react-bootstrap'

import { Roles } from './../DTO/Role';
import { CardNavigator } from "./../UIComponents/CardNavigator";
import { Sections, Modules } from "./../HelperClasses/Routing";
import { Cache } from "./../HelperClasses/Cache";
import { ApplicationState } from '../HelperClasses/ApplicationState';


interface IHomeCampProps {
}


interface IHomeCampState {
    amICampOrg: boolean;
}

export default class HomeCamp extends React.Component<IHomeCampProps, IHomeCampState> {

    
    constructor(props: any) {
        super(props);

        this.state = {
            amICampOrg: false,
        };
    }

    render() {

        const jsx =
            <>
                <Jumbotron className='m-0'>
                    { this.getModulesJSX()}
                </Jumbotron>
            </>
        return jsx;

    }

 //======================== Get Form JSX elements =============================//

    private getModulesJSX() {
        const jsx =
            <>
                <Row>
                    <Col className="p-2" >
                        <CardNavigator section={Sections.camp} module={Modules.participants} headerText="Deelnemers" iconName="users"
                            disabled={
                                !(this.state.amICampOrg || ApplicationState.get().sessionInfo.isAdminOrMngt)
                            }
                        />
                    </Col>

                    <Col className="p-2" >
                        <CardNavigator section={Sections.camp} module={Modules.meals} headerText="Maaltijden" iconName="utensils"
                            disabled={
                                !(this.state.amICampOrg || ApplicationState.get().sessionInfo.account.hasRole(Roles.Incasso) || ApplicationState.get().sessionInfo.isAdminOrMngt)
                            }
                        />
                    </Col>

                </Row>

                <Row>
                    <Col className="p-2" >
                        <CardNavigator disabled section={Sections.camp} module={Modules.nomodule} headerText="Financiën" iconName="hard-hat" />
                    </Col>

                    <Col className="p-2" >
                        <CardNavigator disabled section={Sections.camp} module={Modules.nomodule} headerText="Corvée" iconName="hard-hat" />
                    </Col>

                </Row>

                <Row>
                    <Col className="p-2" >
                        <CardNavigator disabled section={Sections.camp} module={Modules.nomodule} headerText="Teams" iconName="hard-hat" />
                    </Col>


                    <Col className="p-2" >
                        <CardNavigator disabled section={Sections.camp} module={Modules.nomodule} headerText="Briefings" iconName="hard-hat" />
                    </Col>

                </Row>

            </>
        return jsx;
    }

//======================== Get Menu Handlers =============================//



//======================== Get Form Handlers =============================//




//========================    Data Layer =============================/



//========================  Lifecycle =============================/
    //public handleStateError(error: ICustomError): void {
    //    if (error) {
    //        this.setState({  });
    //    }
    //}

    async componentDidMount() {
        //ApplicationState.get().onErrorChange.registerHandler(this.handleStateError);
        //console.log("Mounting Reports");

        this.setState({ amICampOrg: await Cache.amICurrentCampOrg() })
    }

    componentWillUnmount() {
        //ApplicationState.get().onErrorChange.unregisterHandler(this.handleStateError);
    }
}