import * as React from 'react';

//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


import {  Button, Modal } from 'react-bootstrap';
import { ModalButtons, ModalResult } from './../HelperClasses/Enums';

interface ISimpleModalProps {
    headingText?: JSX.Element | string;
    bodyText?: JSX.Element | string;
    buttons?: ModalButtons;
    onClose: (result: ModalResult) => void;
}

interface ISimpleModalState {
    show: boolean;
}


export class SimpleModal extends React.Component<ISimpleModalProps, ISimpleModalState> {



    constructor(props: ISimpleModalProps, context: ISimpleModalState ) {
        super(props, context);

        this.handleClose = this.handleClose.bind(this);

        this.state = {
            show: true,
        };
    }

    handleClose(result: ModalResult) {
        //console.log("HandleClose: " + result)

        this.setState({ show: false });

        if (this.props.onClose) {
            this.props.onClose(result);
        }
    }



    render() {

        let dlg =
        <Modal show={this.state.show} onHide={() => { this.handleClose(ModalResult.Cancel) }} >
            <Modal.Header closeButton>
                <Modal.Title>{this.props.headingText}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{this.props.bodyText}</Modal.Body>
            <Modal.Footer>

                    {this.props.buttons == ModalButtons.OK ? <Button variant="primary" onClick={() => { this.handleClose(ModalResult.OK) }} >OK</Button> : <></>}

                    {this.props.buttons == ModalButtons.OKCancel ? <Button variant="secondary" onClick={() => { this.handleClose(ModalResult.Cancel) }} >Annuleren</Button> : <></>}
                    {this.props.buttons == ModalButtons.OKCancel ? <Button variant="primary" onClick={() => { this.handleClose(ModalResult.OK) }} >OK</Button> : <></>}


                    {this.props.buttons == ModalButtons.YesNo ? <Button variant="secondary" onClick={() => { this.handleClose(ModalResult.Cancel) }} >Nee</Button> : <></>}
                    {this.props.buttons == ModalButtons.YesNo ? <Button variant="primary" onClick={() => { this.handleClose(ModalResult.OK) }} >Ja</Button> : <></>}

            </Modal.Footer>
        </Modal>

        return (
            <>
                {dlg}
            </>
        );
    }

    componentDidMount() {
        //console.log("mounting SimpleDialog");
    }

    componentWillUnmount() {
        //console.log("unmounting SimpleDoialog");
    }
}
