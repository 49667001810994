import * as React from 'react';

//import Container from 'react-bootstrap/Container';
import { Form, Modal, FormControl, Button } from "react-bootstrap";
import { Badge } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { ModalResult } from '../HelperClasses/Enums';
import { ApplicationState } from "../HelperClasses/ApplicationState";

export interface IResetPasswordModalProps {
    userName?: string;
    onClose: (result: ModalResult, newPwd : string) => void;
}

interface IResetPasswordModalState {
    isValidated: boolean;
    pwd1: string;
    pwd2: string;
    pwdsEqual: boolean;
    pwdCompliant: boolean;
}

export class ResetPasswordModal extends React.Component<IResetPasswordModalProps, IResetPasswordModalState> {

    constructor(props: any) {
        super(props);

        this.state = {
            isValidated: false,
            pwd1: "",
            pwd2: "",
            pwdsEqual: false,
            pwdCompliant: false
        };

        //Form methods
        this.handlePwd1Change = this.handlePwd1Change.bind(this);
        this.handlePwd2Change = this.handlePwd2Change.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.validateFormAsync = this.validateFormAsync.bind(this);
    }

    render() {
        //onSubmitCapture={() => { return this.handleClose(ModalResult.OK) }} 
        //doesn't work

        let dlg =
            <Modal show={true} onHide={() => { this.handleClose(ModalResult.Cancel) } } >
                <Modal.Header closeButton>
                    <Modal.Title>Wachtwoord reset</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {this.getForm()}
                </Modal.Body>
                <Modal.Footer>

                    <Button variant="secondary" onClick={() => { this.handleClose(ModalResult.Cancel) }} >Annuleren</Button>
                    <Button type="submit" variant="primary" onClick={() => { this.handleClose(ModalResult.OK) }} >Ok</Button>

                </Modal.Footer>
            </Modal>

        return dlg;
    }

    
    //======================== Get Form JSX elements =============================//

    private getForm() {

        // the isValid and isInvalid props are bugged: https://github.com/react-bootstrap/react-bootstrap/issues/3505

        const frm =
            <Form noValidate={true} validated={false} >
                <div style={{ fontSize: 24 }}>
                    <FontAwesomeIcon inverse={false} size="lg" icon="key" />
                &nbsp;&nbsp;

                <Badge variant={"secondary"}> {this.props.userName ? this.props.userName : "---"} </Badge>
                </div>
                <br />
                {this.getPasswordCombiJSX()}
            </Form>

        return frm;
    }

    private getPasswordCombiJSX() {
        //Added a dummy pwd box to prevent autocomplete by firefox
        const pwdCombi =
            <>
            <FormControl hidden={true} type="password" onChange={() => { }} />

            <Form.Group controlId="ctrlPassword1">
                <Form.Text>Nieuw wachtwoord</Form.Text>

                <FormControl required 
                    isInvalid={this.state.isValidated && !(this.state.pwdCompliant)}
                    type="password"
                    value={this.state.pwd1 ? this.state.pwd1 : ""}
                    placeholder="Wachtwoord"
                    tabIndex={1}
                    onChangeCapture={this.handlePwd1Change}
                    onChange={() => { }}
                />
                <Form.Control.Feedback type="invalid">Wachtwoord moet minimaal 6 karakters bevatten met minstens één kleine letter, één hoofdletter en één cijfer</Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="ctrlPassword2">
                <Form.Text>Nieuwe wachtwoord bevestigen</Form.Text>
                <FormControl required
                    isInvalid={this.state.isValidated && !(this.state.pwdsEqual)}
                    type="password"
                        value={this.state.pwd2 ? this.state.pwd2 : ""}
                    placeholder="Wachtwoord"
                    tabIndex={2}
                    onChangeCapture={this.handlePwd2Change}
                    onChange={() => { }}
                />
                <Form.Control.Feedback type="invalid">De twee wachtwoorden zijn niet hetzelfde</Form.Control.Feedback>
            </Form.Group>
        </>

        return pwdCombi;
    }

    //======================== Get Form Handlers =============================//


    private async handlePwd1Change(event: React.ChangeEvent<HTMLInputElement>) {

        const pwd = event.currentTarget.value.trim();

        await this.setState({ pwd1: pwd });
    }

    private async handlePwd2Change(event: React.ChangeEvent<HTMLInputElement>) {

        const pwd = event.currentTarget.value.trim();

        await this.setState({ pwd2: pwd });
    }

    private async handleClose(result: ModalResult) {
        event.preventDefault();
        event.stopPropagation();
        
        if (result == ModalResult.OK) {
            if (!(await (this.validateFormAsync()))) return;
        }

        if (this.props.onClose) {
            this.props.onClose(result, (this.state.pwdCompliant&&this.state.pwdsEqual) ? this.state.pwd1 : null);
        }
    }
    //======================== Form Validation =============================//

    private async validateFormAsync() {
        let pwdCompliant = this.state.pwd1 ? await this.passwordIsCompliantAsync(this.state.pwd1) : false;
        const pwdsEqual = this.state.pwd2 ? (this.state.pwd1 === this.state.pwd2) : false;

        this.setState({ isValidated: true, pwdsEqual: pwdsEqual, pwdCompliant: pwdCompliant });
        return this.state.pwdCompliant && this.state.pwdsEqual;
    }

    private async passwordIsCompliantAsync(password: string): Promise<boolean> {
        const pwdok = await ApplicationState.get().sessionInfo.isPasswordCompliantAsync(password);
        return pwdok;
    }

    //======================== Lifecycle =============================//

    componentDidMount() {
        //console.log("mounting ResetPwdForm");
    }

    componentWillUnmount() {
        //console.log("unmounting ResetPwdForm");
    }
}
