import * as React from 'react';
import { Form } from "react-bootstrap";

import { RRN } from "./../HelperClasses/RRN";

export interface IRRNInputFieldProps {

    RRN?: string;

    isInvalid?: boolean;
    tabIndex?: number;
    disabled?: boolean;

    onChangeCapture? : (RRN: string) => void;
}

export interface IRRNInputFieldState {
    maskedRRN: string;
    RRN: string;
}

export class RRNInputField extends React.Component<IRRNInputFieldProps, IRRNInputFieldState> {

    private readonly mask : string = "__.__.__-___.__";

    constructor(props: any) {
        super(props);

        if (RRN.isValid(this.props.RRN)) {
            this.state = { maskedRRN: this.createMaskedRRN(this.props.RRN), RRN: this.props.RRN };
        }
        else {
            this.state = { maskedRRN: this.createMaskedRRN(this.props.RRN), RRN: null };
        }

        this.handleOnChangeCapture = this.handleOnChangeCapture.bind(this);

    }

    render() {
        const jsx =
            <Form.Control
                autoFocus
                type="input"
                placeholder={this.mask}
                value={this.state.maskedRRN ? this.state.maskedRRN : ""}
                onChangeCapture={this.handleOnChangeCapture}
                onChange={() => { }}
                isInvalid={this.props.isInvalid}
                tabIndex={this.props.tabIndex}
                disabled={this.props.disabled ? this.props.disabled : false}
                as='input'
                ref="RRNinputbox"
            />

        return  jsx;
    }


    //--------------- Handlers ---------------------------------------------------------------
    private async handleOnChangeCapture(event: React.ChangeEvent<HTMLInputElement>) {

        const stringValue = event.currentTarget.value;

        let newRRN = this.extractPossibleRRN(stringValue);
        const newMaskedRRN = this.createMaskedRRN(newRRN);


        //if (!RRN.isValidRRN(newRRN)) newRRN = null;
        if (newRRN != this.state.RRN) {
            if (this.props.onChangeCapture) {
                this.props.onChangeCapture(newRRN == "" ? null : newRRN);
            }
        }

        //await because Caret will be reset when async state will be updated
        await this.setState({ maskedRRN: newMaskedRRN, RRN: newRRN });

        const indexCaret = this.lastIndexNumeric(newMaskedRRN) != null ? this.lastIndexNumeric(newMaskedRRN) + 1 : 0;
        //Code below sets caret to next input field but blocks a backspace
        //if (indexCaret == 2 || indexCaret == 5 || indexCaret == 8 || indexCaret == 12) {
        //    indexCaret++;
        //}

        (this.refs.RRNinputbox as HTMLInputElement).selectionStart = indexCaret;
        (this.refs.RRNinputbox as HTMLInputElement).selectionEnd = indexCaret;

    }

    //--------------- Logic ---------------------------------------------------------------
    private extractPossibleRRN(stringValue: string): string {
        let newRRN = "";

        if (!stringValue) return newRRN;

        //Only Numeric chars, also filter out the mask chars
        for (let i = 0; i < stringValue.length; i++) {
            if (this.isDigitCode(stringValue[i])) {
                newRRN = newRRN + stringValue[i];
            }
        }

        //No more than 11 chars
        if (newRRN.length > 11) newRRN = newRRN.substr(0, 11);

        return newRRN;
    }

    private createMaskedRRN(newRRN: string): string {
        //const newRRN = this.extractPossibleRRN(stringValue);

        if (!newRRN) return "";

        //Add mask back in       
        let newMaskedRRN = "";
        let indexMask = 0;
        let indexNewRNN = 0;

        //Create the masked version
        for (let c of this.mask) {
            if (indexMask == 2 || indexMask == 5 || indexMask == 8 || indexMask == 12) {
                newMaskedRRN = newMaskedRRN + c;
            }
            else if (indexNewRNN < newRRN.length) {
                newMaskedRRN = newMaskedRRN + newRRN.charAt(indexNewRNN);
                indexNewRNN++;
            }
            else {
                newMaskedRRN = newMaskedRRN + c;
            }

            indexMask++;
        }

        return newMaskedRRN;
    }


    
    //--------------- Helpers ---------------------------------------------------------------
    private lastIndexNumeric(s: string) {
        for (let i = s.length - 1; i >= 0; i--) {
            if (this.isDigitCode(s.charAt(i))) return i;
        }
        return null;
    }

    private isDigitCode(n: string) {
        return (n.charCodeAt(0) >= "0".charCodeAt(0) && n.charCodeAt(0) <= "9".charCodeAt(0));
    }

    

}