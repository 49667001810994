import { IRole } from "./Role";
import { ISerializable } from './ISerializable';
import { IValidation, IValidationState } from './IValidation';
import { EmailHelpers } from './../HelperClasses/EmailHelpers';
import { ICustomEvent, CustomEvent } from './../HelperClasses/CustomEvent';

import { IMember, Member } from './Member';

export interface IAccount extends ISerializable<IAccount>, IValidation {
    id: string;
    userName: string;

    firstName: string;
    surName: string;

    isLocked: boolean;

    member: IMember;
    memberId: string;

    //password: string;
    token: string;

    roles: Array<IRole>;
    
    hasRole(code: string): boolean; 
    addRole(role: IRole): void;
    removeRole(role: IRole): void;

    getRolesString(): string;

    profilePictureUrl: string;

    onProfilePictureChanged: ICustomEvent<string>;
    //onProfilePictureChanged: (profilePictureUrl: string) => void;

    shareEmail: boolean;
    shareGsm: boolean;
}

export interface IAccountValidationState extends IValidationState {
    userNameIsValid: boolean;
    firstNameIsValid: boolean;
    surNameIsValid: boolean;

    isLockedValid: boolean;

    //passwordIsValid: boolean;

    rolesIsValid: boolean;
}

export class Account implements IAccount {


    public id: string;
    public userName: string;

    public firstName: string;
    public surName: string;

    public isLocked: boolean;

    public member: IMember;
    public memberId: string;

    //public password: string;
    public token: string;

    public readonly roles: Array<IRole>;

    private _profilePictureUrl: string;
    public get profilePictureUrl(): string {

        if (this._profilePictureUrl) {
            return this._profilePictureUrl;
        }
        return '/profilepics/pp-empty.png'
    }

    public set profilePictureUrl(value: string) {
        
        if (value != this._profilePictureUrl) {
            this._profilePictureUrl = value;
            if (this.onProfilePictureChanged) {
                this.onProfilePictureChanged.trigger(this._profilePictureUrl);
            }
        }
    }

    public shareEmail: boolean;
    public shareGsm: boolean;


    //public onProfilePictureChanged: (profilePictureUrl: string) => void;
    onProfilePictureChanged: ICustomEvent<string> = new CustomEvent<string>();


    //public get fullName(): string { return this.firstName + " " + this.lastName;}

    constructor() {
        this.id = null;
        this.userName = null;

        this.firstName = null;
        this.surName = null;

        this.isLocked = false;

        this.member = null;
        this.memberId = null;

        this.token = null;
        //this.password = null;

        this.roles = new Array<IRole>();

        this.shareEmail = false;
        this.shareGsm = false;
    }



    public static _hasRole(account: IAccount, role: IRole): boolean {
        return (account.roles.findIndex(r => r.code == role.code) > -1);
    }

    public hasRole(code: string): boolean {
        return (this.roles.findIndex(r => r.code == code) > -1);
    }

    public addRole(role: IRole): void {
        if (this.hasRole(role.code)) return;
        this.roles.push(role);
    }

    public removeRole(role: IRole): void {
        const index = this.roles.findIndex(r => r.code == role.code);
        if (index < 0) return;

        this.roles.splice(index, 1);
    }

    public getRolesString(): string {
        let rs = "";

        for (let r of this.roles) {
            if (rs.length != 0) rs = rs + ", ";
            rs = rs + r.name;
        }
        return rs;
    }

    public getValidationState(): IAccountValidationState {

        let vs = {
            userNameIsValid: (this.userName != null && this.userName.length > 0) && EmailHelpers.isValidEmailAddress(this.userName),
            firstNameIsValid: (this.firstName != null && this.firstName.length > 0),
            surNameIsValid: (this.surName != null && this.surName.length > 0),
            isLockedValid: true,
            //passwordIsValid: true,
            rolesIsValid: true,

            stateIsValid: false
        } as IAccountValidationState;

        vs.stateIsValid = (
            vs.userNameIsValid &&
            vs.firstNameIsValid &&
            vs.surNameIsValid &&
            vs.isLockedValid &&
            //vs.passwordIsValid &&
            vs.rolesIsValid);

        return vs;
    }




    public fromJson(json: Object): Account {
        //DO NOT USE 'this.' RETURN A NEW OBJECT
        const trueObject = new Account();
        Object.assign<IAccount, Object>(trueObject, json);

        //Overloads
        if (trueObject.member != null) trueObject.member = (new Member()).fromJson(trueObject.member);

        return trueObject;
    }


    public toJson(): Object {

        let x = JSON.parse(JSON.stringify(this));

        //Overloads
        if (this.member) {
            x.member = this.member.toJson();
        }

        return x;
    }
}

