import * as React from 'react';
import { Row, Col } from "react-bootstrap";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Styles } from "./../HelperClasses/Styles";


//=========================================================================================//

export interface IWizardStep {
    subTitle: string;
    color: string;
}

export interface IWizardProgressBarProps {
    //nrSteps: number;
    steps: Array<IWizardStep>;

    selectedStep: number;
    stepSelection?: 'free' | 'incremental' | 'locked'; //default is locked


    size?: number;
    color?: string;
    selectColor?: string;

    disabled?: boolean;

    onStepSelected? : (stepNr: number) => void
}

interface IWizardProgressBarState {
    
}

//=========================================================================================//

export class WizardProgressBar extends React.Component<IWizardProgressBarProps, IWizardProgressBarState> {

    
    constructor(props: any) {
        super(props);

        this.state = {
            
        }
        
    }

    render() {

        const jsx = this.getRowJSX();
        return (
            <>
                {jsx}
            </>
        )
    }

    private getRowJSX() {
        let jsx: Array<JSX.Element> = new Array<JSX.Element>();
        for (var stepNr: number = 0; stepNr < this.props.steps.length; stepNr++) {
            jsx.push(this.getColJSX(stepNr))
        }

        return (
            <>
                <Row className='m-auto'>
                    {jsx}
                </Row>
                <Row className='m-auto d-inline d-md-none'>
                    <Col className='text-center'>
                        {this.getSubTitleJSX(this.props.selectedStep)}
                    </Col>
                </Row>
            </>
        )

    }

    private getColJSX(stepNr: number) {

        const jsx =
            <Col className='m-0 p-0' key={'col' + stepNr}>
                <Row className='m-auto'>
                    <Col className='m-0 p-0' key={'colLineLeft' + stepNr}>
                        {stepNr == 0 ? null : this.getBarJSX(stepNr)}
                    </Col>
                    <Col className='text-center m-0 p-0 col-auto' key={'colCircle' + stepNr}>
                        {this.getStepJSX(stepNr)}
                    </Col>
                    <Col className='m-0 p-0' key={'colLineRight' + stepNr}>
                        {stepNr == this.props.steps.length - 1 ? null : this.getBarJSX(stepNr)}
                    </Col>
                </Row>
                <Row className='m-auto  d-none d-md-inline'>
                    <Col className='text-center'>
                        {this.getSubTitleJSX(stepNr)}
                    </Col>
                </Row>
            </Col>


        return jsx;
    }



    private getSubTitleJSX(stepNr: number) {
                
        if (!Number.isInteger(stepNr)) return null;
        //let fontSize: number = 1;
        //if (this.props.size < 3) fontSize = 0.7;

        const jsx =
            <>

            <span className='' style={{
                color: this.props.steps[stepNr].color,
                fontSize: '1rem',
                fontWeight: (stepNr == this.props.selectedStep ? 'bold' : 'normal'),
                }}
            >
                {this.props.steps[stepNr].subTitle}
            </span>
            </>

        return jsx;
    }

    private getStepJSX = (stepNr: number): JSX.Element => {
        if (!Number.isInteger(stepNr)) return null;

        let classes = 'fa-layers ' //fa-fw
        if (this.props.size) {
            classes = classes + ' fa-' + this.props.size.toString() + 'x';
        }

        let jsx: JSX.Element = null;

        //https://github.com/FortAwesome/Font-Awesome/issues/14768 solution was to change the key value of the span. Adding a random crashes the DOM

        if (this.props.selectedStep === stepNr) {

            jsx =
                <span className={classes} key={'superselected' + stepNr} >
                <FontAwesomeIcon icon={["fas", "circle"]} color={this.props.steps[stepNr].color} />

                <span
                    key={"selected " + stepNr}
                    className="fa-layers-text  fa-inverse"
                    data-fa-transform="shrink-10"
                    style={{ fontWeight: 600 }}>
                        {stepNr + 1}
                </span>
            </span>
        }
        else {

            jsx =
            <span
                className={classes}
                onClickCapture={() => { this.handleStepClicked(stepNr) }}
                style={this.setPointer(stepNr)}
                key={"superunselected " + stepNr}
            >
                <FontAwesomeIcon icon={["far", "circle"]} color={this.props.steps[stepNr].color} />

                <span
                    key={"unselected " + stepNr}
                    className="fa-layers-text"
                    data-fa-transform="shrink-10"
                    style={{ fontWeight: 600, color: this.props.steps[stepNr].color }}>
                        {stepNr + 1}
                </span>
            </span>
        }

        return jsx;
    }

    private getBarJSX = (stepNr: number): JSX.Element => {
        if (!Number.isInteger(stepNr)) return null;

        const jsx =
            <div className='w-100  h-50' style={{ borderBottomStyle: 'solid', borderBottomColor: Styles.getMeeuwColor() }} key={'bar' + stepNr}   >
               
            </div>

        return jsx;
    }

    private setPointer(stepNr: number) {
        if (this.props.disabled) return null;

        if (!Number.isInteger(stepNr)) return null;
        if (!this.props.stepSelection) return null;
        if (this.props.stepSelection == 'locked') return null;
        if (this.props.selectedStep == stepNr) return null;

        if (this.props.stepSelection == 'incremental') {
            if (this.props.selectedStep - stepNr < -1) return null;
            if (this.props.selectedStep - stepNr > 1) return null;
        }

        return { cursor: 'pointer' };
    }

    private async handleStepClicked(stepNr: number) {
        if (this.props.disabled) return;

        if (!Number.isInteger(stepNr)) return;

        if (!this.props.stepSelection) return;
        if (this.props.stepSelection == 'locked') return;
        if (this.props.selectedStep == stepNr) return;

        if (this.props.stepSelection == 'incremental') {
            if (this.props.selectedStep - stepNr < -1) return;
            if (this.props.selectedStep - stepNr > 1) return;
        }

        await this.setState({ selectedStep: stepNr });

        if (this.props.onStepSelected) {
            this.props.onStepSelected(stepNr);
        }
    }

}



