import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import 'rc-datepicker/lib/style.css';
import * as React from 'react';
//import { Redirect } from "react-router-dom";
//import Container from 'react-bootstrap/Container';
import { Accordion, Button, Card, Form } from 'react-bootstrap';

import 'react-bootstrap-typeahead/css/Typeahead-bs4.css';
import 'react-bootstrap-typeahead/css/Typeahead.css';

import { IMember } from '../DTO/Member';
import { CustomError } from '../HelperClasses/CustomError';

//import { Styles } from './../HelperClasses/Styles';
import { ApplicationState } from "./../HelperClasses/ApplicationState";

import { FormModus, ModalResult } from './../HelperClasses/Enums';
import { ISessionInfo } from "./../HelperClasses/SessionInfo";

import FlightStatus from "./../UIComponents/FlightStatus";









export interface IFlightStatusFormProps {
    formMode: FormModus;

    member: IMember;

    onClose: (result: ModalResult) => void;
}

interface IFlightStatusFormState {

    isValidated: boolean;
}

export class FlightStatusForm extends React.Component<IFlightStatusFormProps, IFlightStatusFormState> {

    
    constructor(props: any) {
        super(props);

        if (this.props.formMode == FormModus.ReadOnly) {

            if (!this.props.member) {
                ApplicationState.get().setError(new CustomError("No duty provided.", null, null, null, null, null));
                return;
            }
        }
        else {
            ApplicationState.get().setError(new CustomError("Only Readonly mode is supported on FlightStatusForm", null, null, null, null, null));
            return;
        }

        //Set initial state
        this.state = {
            isValidated: false,
        };

        //Form methods
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleReset = this.handleReset.bind(this);
        this.validateForm = this.validateForm.bind(this);

    }

    render() {

        let form = this.getForm();

        return (
            <>
                {form}

            </>   
        );
    }

    //======================== Get Form JSX elements =============================//

    private getForm() {
        const form =
            <Form noValidate validated={false} onSubmitCapture={this.handleSubmit} onResetCapture={this.handleReset} className='m-0'>

                {this.getInnerFormJSX()}

                <hr />
                <div style={{ textAlign: 'right' }}>
                    <Button type="submit" tabIndex={999}>Sluiten</Button>
                </div>

            </Form>

        return form;
    }

    private getInnerFormJSX() {

        const jsx = 
            <>
                <Accordion defaultActiveKey="0" >
                    <Card style={this.accordeonValid()}>
                        <Accordion.Toggle as={Card.Header} eventKey="0">
                            <FontAwesomeIcon inverse={false} size="lg" icon='scroll' />
                            &nbsp;&nbsp;Vliegstatus &nbsp;{ this.props.member.getFullName()}
                        </Accordion.Toggle>

                        <Accordion.Collapse eventKey="0">
                            <Card.Body className=''>
                                {this.getBodyJSX(this.props.member)}
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>
            </>
        return jsx;
    }
        
    private getBodyJSX(member: IMember): JSX.Element {
        if(!member) return null;
        const jsx =
            <>
                <FlightStatus member={this.props.member} />
            </>
        return jsx;
    }

 
    //======================== Handlers =============================//


    //======================== Form Validation =============================//

    private accordeonValid() {
        return {};
    //    let vs = this.entity.getValidationState() as IFlightDayValidationState;
    //    return !vs.stateIsValid && this.state.isValidated ?
    //        Styles.getDivErrorStyle() : {};
    }

    private validateForm() {
        return true
        //let vs = this.entity.getValidationState() as IMemberFlightDayValidationState;

        //this.setState({validationState: vs});

        //////console.log("Valid: " + vs.isValid.toString());
        //return vs.stateIsValid;
    }

    private handleReset() {
        event.preventDefault();
        event.stopPropagation();
        this.setState({ isValidated: false });

        this.props.onClose(ModalResult.Cancel);
    }

    private async handleSubmit(event: React.FormEvent<HTMLFormElement>) {

        event.preventDefault();
        event.stopPropagation();

        this.setState({ isValidated: true });  //toggle the validation for all controls
        // this.validateForm() && event.currentTarget.checkValidity() //checkValidity checks the required fields but we need to set invalid manually so makes no sense to use
        
        if (this.validateForm()) {
            //console.log("Closing AircraftForm")
            
            this.props.onClose(ModalResult.OK);
        }
    }

    //======================== Data Layer =============================//

    private async loadBaseTables() {

        
    }


    //======================== Lifecycle =============================//

    handleSessionChange = (sessionInfo: ISessionInfo) => {
    }

    async componentDidMount() {
        //console.log("mounting DutyForm");
        //ApplicationState.get().sessionInfo.onChange.registerHandler(this.handleSessionChange);

        this.loadBaseTables();

        //((this.refs.refArrivalTime as any).refs.refInput as HTMLInputElement).focus();
    }

    componentWillUnmount() {
        //console.log("unmounting AircraftForm");
        ApplicationState.get().sessionInfo.onChange.unregisterHandler(this.handleSessionChange);
    }
}
