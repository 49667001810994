import * as React from 'react';

import { Form } from "react-bootstrap";


import { IMyDateTime, MyDateTime } from "./../HelperClasses/MyDate"
import { Formatters } from "./../HelperClasses/Formatters"

//import { CustomError, HttpStatus } from '../HelperClasses/CustomError';


export interface ITimeFieldProps {

    isInvalid: boolean;
    tabIndex?: number;
    disabled?: boolean;

    date: IMyDateTime;
    time: IMyDateTime;

    onChangeCapture? : (time: MyDateTime) => void;
}

export interface ITimeFieldState {
    timeString: string;
}




export class TimeField extends React.Component<ITimeFieldProps, ITimeFieldState> {

    private readonly date: MyDateTime; //Date is immutable for this component. Any valid time will always be returned with this date for sake of daylight saving times
    private time: MyDateTime;

    constructor(props: any) {
        super(props);

        if (this.props.date && this.props.date.isValid) {
            this.date = new MyDateTime(this.props.date);
        }
        else {
            console.log(this.props.date);
            throw new Error("Invalid DateTime on property date");
            //this.date = null;
        }

        if (this.props.time && this.props.time.isValid) {
            this.time = new MyDateTime(this.props.time);
        }
        else {
            this.time = null;
        }

        this.state = { timeString: this.time && this.time.isValid ? Formatters.getShortTimeString(this.time) : "" };

        this.handleOnChangeCapture = this.handleOnChangeCapture.bind(this);

    }

    render() {
        const jsx =
            <Form.Control
                autoFocus
                ref='refInput'
                type="input"
                placeholder="--:--"
                value={this.state.timeString ? this.state.timeString : ""}
                onChangeCapture={this.handleOnChangeCapture}
                onChange={() => { }}
                isInvalid={this.props.isInvalid}
                tabIndex={this.props.tabIndex ? this.props.tabIndex : 999}
                disabled={this.props.disabled?this.props.disabled:false}
            />

        return  jsx;
    }

    private async handleOnChangeCapture(event: React.ChangeEvent<HTMLInputElement>) {
        const stringValue = event.currentTarget.value;
        let newString = "";

        //Only Numeric chars, also filter out the ":"
        for (let i = 0; i < stringValue.length ; i++) {
            if (this.isDigitCode(stringValue[i])) {
                newString = newString + stringValue[i];
            }
        }

        //No more than 4 chars
        if (newString.length > 4) newString = newString.substr(0, 4);

        //Add ":" back in       
        if (newString.length <= 2) {
            //do nothing
        }
        else {
            newString = newString.substring(0, 2) + ":" + newString.substring(2)
        }
        
        this.time = this.toMyDate(this.date, newString);


        await this.setState({ timeString: newString });

        if (this.props.onChangeCapture) {
            this.props.onChangeCapture(this.time);
        }
    }

    private isDigitCode(n: string) {
        return (n.charCodeAt(0) >= "0".charCodeAt(0) && n.charCodeAt(0) <= "9".charCodeAt(0));
    }

    private toMyDate(aDate: MyDateTime, valueString: string): MyDateTime {

        //Allow null values
        if (valueString == null || valueString.length == 0) return null;

        let s = valueString;
        s = s.replace(":", "");

        if (s.length != 4) return MyDateTime.invalidDateTime;

        const hours = parseInt(s.substring(0, 2));
        const minutes = parseInt(s.substring(2));

        if (hours < 0 || hours > 23) return MyDateTime.invalidDateTime;
        if (minutes < 0 || minutes > 59) return MyDateTime.invalidDateTime;

        return MyDateTime.createDateTime(aDate.year, aDate.month, aDate.day, hours, minutes, 0);
    }
}