import * as React from 'react';
import { Styles } from '../HelperClasses/Styles';

export enum CustomErrorNrs {
    INVALID_USERNAME = 1101,
    INVALID_EMAIL = 1102,
    INVALID_PASSWORD = 1103,
    INVALID_USERNAME_PWD_COMBINATION = 1104,
    ACCOUNT_LOCKED = 1105,
    INVALID_RESETCODE = 1106,
    RESETCODE_USED = 1107,
    RESETCODE_EXPIRED = 1108,

    ACCOUNT_WITH_USERNAME_ALREADY_EXISTS = 1110,
    MEMBER_WITH_EMAIL_ALREADY_EXISTS = 1111,
    MEMBER_WITH_FIRSTNAME_LASTNAME_ALREADY_EXISTS = 1112,
    MEMBER_SAME_NAME_DIFFERENT_EMAIL_EXISTS = 1113,
    ACCOUNT_SAME_NAME_DIFFERENT_EMAIL_EXISTS = 1114,
    ACCOUNT_SAME_NAME_EXISTS = 1115,

    INVALID_RRN = 1120,

    LIBRARYDOC_NOT_FOUND = 1200,
    LIBRARYDOC_ALREADY_EXISTS = 1201,
}

export class Errors {

    constructor() {
    }
  
    public static fromCustomError(nr: CustomErrorNrs, customErrorData: string) : JSX.Element | string {

        let x = null;

        switch (nr) {
            case CustomErrorNrs.INVALID_USERNAME:
                x =
                    <span style={Styles.getErrorColorStyle()} >
                        Ongeldige gebruikersnaam: <i>{customErrorData}</i>
                    </span>
                return x;

            case CustomErrorNrs.INVALID_EMAIL:
                x =
                    <span style={Styles.getErrorColorStyle()} >
                    Ongeldig email adres: <i>{customErrorData}</i>
                    </span>
                return x;

            case CustomErrorNrs.INVALID_PASSWORD:
                x =
                    <span style={Styles.getErrorColorStyle() } >
                        Ongeldig wachtwoord.
                    </span>
                return x;

            case CustomErrorNrs.INVALID_USERNAME_PWD_COMBINATION:
                x =
                    <span style={Styles.getErrorColorStyle()} >
                        De combinatie gebruikersnaam en wachtwoord is niet correct.
                </span>
                return x;

            case CustomErrorNrs.ACCOUNT_LOCKED:
                x =
                    <span style={Styles.getErrorColorStyle()} >
                    Uw account is geblokkeerd. <br /> Contacteer het secretariaat op <a href='mailto://secretariaat@kazm.be'>secretariaat@kazm.be</a>
                    </span>
                return x;

            case CustomErrorNrs.INVALID_RESETCODE:
                x =
                    <span style={Styles.getErrorColorStyle()} >
                        Wachtwoord reset code is niet geldig.
                    </span>
                return x;

            case CustomErrorNrs.RESETCODE_USED:
                x =
                    <span style={Styles.getErrorColorStyle()} >
                        reset code is reeds eerder gebruikt.
                    </span>
                return x;

            case CustomErrorNrs.RESETCODE_EXPIRED:
                x =
                    <span style={Styles.getErrorColorStyle()} >
                        reset code is verlopen.
                    </span>
                return x;



            case CustomErrorNrs.ACCOUNT_WITH_USERNAME_ALREADY_EXISTS:
                x =
                    <span style={Styles.getErrorColorStyle()} >
                        Er bestaat reeds een account met de gebruikersnaam: <i>{customErrorData}</i>
                    </span>
                return x;

            case CustomErrorNrs.MEMBER_WITH_EMAIL_ALREADY_EXISTS:
                x =
                    <span style={Styles.getErrorColorStyle()} >
                        Er bestaat reeds een lid met dit email adres: <i>{customErrorData}</i>
                    </span>
                return x;

            case CustomErrorNrs.MEMBER_WITH_FIRSTNAME_LASTNAME_ALREADY_EXISTS:
                x =
                    <span style={Styles.getErrorColorStyle()} >
                        Er bestaat reeds een lid met deze voornaam achternaam combinatie: <i>{customErrorData}</i>
                    </span>
                return x;

            case CustomErrorNrs.MEMBER_SAME_NAME_DIFFERENT_EMAIL_EXISTS:
                x =
                    <span style={Styles.getErrorColorStyle()} >
                        Er bestaat reeds een lid met deze voornaam achternaam combinatie maar met een ander email adres: <i>{customErrorData}</i>
                    </span>
                return x;

            case CustomErrorNrs.ACCOUNT_SAME_NAME_DIFFERENT_EMAIL_EXISTS:
                x =
                    <span style={Styles.getErrorColorStyle()} >
                        Er bestaat reeds een account met deze voornaam achternaam combinatie maar met een ander email adres: <i>{customErrorData}</i>
                    </span>
                return x;

            case CustomErrorNrs.ACCOUNT_SAME_NAME_EXISTS:
                x =
                    <span style={Styles.getErrorColorStyle()} >
                        Er bestaat reeds een account met deze voornaam achternaam combinatie: <i>{customErrorData}</i>
                    </span>
                return x;


            case CustomErrorNrs.INVALID_RRN:
                x =
                    <span style={Styles.getErrorColorStyle()} >
                        Ongeldig Rijksregisternummer: <i>{customErrorData}</i>
                    </span>
                return x;

            case CustomErrorNrs.LIBRARYDOC_NOT_FOUND:
                x =
                    <span style={Styles.getErrorColorStyle()} >
                        Document is niet gevonden: <i>{customErrorData}</i>
                    </span>
                return x;

            case CustomErrorNrs.LIBRARYDOC_ALREADY_EXISTS:
                x =
                    <span style={Styles.getErrorColorStyle()} >
                        Document bestaat reeds in deze category: <i>{customErrorData}</i>
                    </span>
                return x;

            default: return null;
        }

    }
}

