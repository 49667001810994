
import {  MyDateTime } from "./../HelperClasses/MyDate";
import { ISerializable } from './ISerializable';
import { IValidation, IValidationState } from './IValidation'
//import moment = require('moment');


import { IReservableCost, ReservableCost } from "./ReservableCost";


export interface IReservable extends ISerializable<IReservable>, IValidation {
    
    id: string;
    eventId: string;

    description: string;
    details: string;

    startTime: MyDateTime;
    reservationDeadline: MyDateTime;

    reservableCost: Array<IReservableCost>;

    reservationClosed(): boolean;
    clone(): IReservable;
}

export interface IReservableValidationState extends IValidationState {
    descriptionIsValid: boolean;
    startTimeIsValid: boolean;
    reservationDeadlineIsValid: boolean;
}

export class Reservable implements IReservable {

    public id: string = null;
    public eventId: string = null;

    public description: string = null;
    public details: string = null;

    public startTime: MyDateTime = null;
    public reservationDeadline: MyDateTime = null;

    public reservableCost: Array<IReservableCost> = new Array<IReservableCost>();

    constructor() {
    }


    public getValidationState(): IReservableValidationState {

        let vs = {
            descriptionIsValid: this.description && this.description.length > 0 ? true : false,
            startTimeIsValid: this.isvalidDateTime(this.startTime),
            reservationDeadlineIsValid: this.isvalidDateTime(this.reservationDeadline),
            stateIsValid: false,
        } as IReservableValidationState;

        vs.stateIsValid = (vs.descriptionIsValid && vs.startTimeIsValid && vs.reservationDeadlineIsValid); 

        return vs;
    }

    private isvalidDateTime(dateTime: MyDateTime) {
        if (dateTime == null) return true;
        if (dateTime.isValid) return true;
        return false;
    }

    reservationClosed(): boolean {
        if (this.reservationDeadline && this.reservationDeadline.isValid) {
            return this.reservationDeadline.isBefore(MyDateTime.now);
        }
        return false;
    }

    public fromJson(json: Object): Reservable {
        //DO NOT USE 'this.' RETURN A NEW OBJECT
        //console.log("Aircraft.fromJson");

        const trueObject = new Reservable();
        Object.assign<IReservable, Object>(trueObject, json);

        //trueObject.key = trueObject.id;

        //Overloads
        trueObject.startTime = MyDateTime.fromJson(trueObject.startTime);
        trueObject.reservationDeadline = MyDateTime.fromJson(trueObject.reservationDeadline);

        //Objects
        let objs = new Array<IReservableCost>();
        trueObject.reservableCost.forEach(
            (json) => {
                objs.push((new ReservableCost()).fromJson(json));
            }
        )
        trueObject.reservableCost = objs;

        //console.log(trueObject);
        return trueObject;
    }


    public toJson(): Object {
        //console.log("Aircraft.toJson");

        let x = JSON.parse(JSON.stringify(this));

        //Overloads

        if (this.startTime) x.startTime = this.startTime.toJson();
        if (this.reservationDeadline) x.reservationDeadline = this.reservationDeadline.toJson();

        //Objects
        let jsonObjects = new Array<object>();
        this.reservableCost.forEach(
            (aReservableCost) => {
                jsonObjects.push(aReservableCost.toJson());
            })
        x.reservableCost = jsonObjects;

        //console.log(x);
        return x;
    }

    public clone(): IReservable {

        const reservable = Object.assign<Reservable, IReservable>(new Reservable(), this);

        reservable.reservationDeadline = null;
        if (this.reservationDeadline && this.reservationDeadline.isValid) {
            reservable.reservationDeadline = this.reservationDeadline.clone();
        }

        reservable.startTime = null;
        if (this.startTime && this.startTime.isValid) {
            reservable.startTime = this.startTime.clone();
        }
       

        for (var i = 0; i < reservable.reservableCost.length; i++) {
            reservable.reservableCost[i] = this.reservableCost[i].clone();
        }

        return reservable;
    }
}