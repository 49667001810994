import { ICustomEvent, CustomEvent } from "./CustomEvent";
import { IAccount, Account } from "../DTO/Account";
import { ICustomError } from "./CustomError";
import { Http } from "./Http";
import { ApplicationState } from './ApplicationState';
import { Roles } from "./../DTO/Role";



export interface ISessionInfo {

    account: IAccount;
    isAdmin: boolean;
    isAdminOrMngt: boolean;

    logOnAsync(email: string, password: string): Promise<ICustomError>;
    logOnAdminDebugAsync(): Promise<ICustomError>;
    logOff(): void;
    isPasswordCompliantAsync(password: string): Promise<boolean>;

    onChange: ICustomEvent<ISessionInfo>;

}

export class SessionInfo implements ISessionInfo {

    private _account: IAccount;
    public get account(): IAccount { return this._account; } 

    public get isAdmin() {
        if (!this._account) return false;
        if (this._account.roles.findIndex(x => x.code == Roles.Admin) < 0) return false;
        return true;
    }

    public get isAdminOrMngt() {
        if (!this._account) return false;
        if (this._account.roles.findIndex(x => x.code == Roles.Admin) >= 0) return true;
        if (this._account.roles.findIndex(x => x.code == Roles.Mngt) >= 0) return true;
        return false;
    }

    public readonly onChange: ICustomEvent<ISessionInfo> = new CustomEvent<SessionInfo>();
   
    constructor() {
        this._account = null;
    };

    public async logOnAsync(userName: string, password: string) {
        
        const http = new Http();
        const account = await http.postAsyncObject<Account>("api/account/authenticate", { UserName: userName, Password: password }, Account);

        if (account) {
            this._account = account;
            this.onChange.trigger(this);
            return null;
        }
        else {
            this._account = null;
            this.onChange.trigger(this);
            return http.error;
        }
    }

    public async logOnAdminDebugAsync() {

        const http = new Http();
        const account = await http.getAsyncObject<Account>("api/account/authenticateAdminDebug", Account) as IAccount;

        if (account) {
            this._account = account;
            this.onChange.trigger(this);
            return null;
        }
        else {
            this._account = null;
            this.onChange.trigger(this);
            return http.error;
        }
    }

    public logOff(): void {
        this._account = null;

        this.onChange.trigger(this);
    }

    public async isPasswordCompliantAsync(password: string) {

        const http = new Http();

        const compliant = await http.postAsyncPrimitive<boolean>("api/account/passwordcompliant", password);

        if (http.error==null) {
            return compliant;
        }
        else {
            ApplicationState.get().setError(http.error);
            return false;
        }

    }
};
