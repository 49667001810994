import { MyDateTime } from "./../HelperClasses/MyDate";
import { ISerializable } from './ISerializable';
import { IValidation, IValidationState } from './IValidation'
import { ISex } from "./Sex"
import { ICountry } from "./Country"
import { IEvent, Event } from "./Event"
import { EmailHelpers } from './../HelperClasses/EmailHelpers'
import { RRN } from './../HelperClasses/RRN'

export enum CampRegistrationStatus {Open = 1, Approved = 2, Rejected = 3 }

export interface ICampRegistration extends ISerializable<ICampRegistration>, IValidation {
    id: string;
    code: string;
    firstName: string;
    surName: string;
    address: string;
    zip: string;
    city: string;
    gsm: string;
    email: string;
    dateBirth: MyDateTime;
    placeBirth: string;
    nationalNr: string;
    medicalExpires: MyDateTime;
    isSolo: boolean;
    currentLVZCMember: boolean;
    previousKAZMMember: boolean;
    contactGsm: string;
    contactEmail: string;
    weight: number;
    height: number;
    week1: boolean;
    week2: boolean;
    experience: string;
    remarks: string;
    docsKAZM: boolean;
    docsLVZC: boolean;
    status: number;

    event: IEvent;
    country: ICountry;
    nationality: ICountry;
    sex: ISex;

}

export interface ICampRegistrationValidationState extends IValidationState {

    firstNameIsValid: boolean;
    surNameIsValid: boolean;
    addressIsValid: boolean;
    zipIsValid: boolean;
    cityIsValid: boolean;
    gsmIsValid: boolean;
    emailIsValid: boolean;
    dateBirthIsValid: boolean;
    placeBirthIsValid: boolean;
    nationalNrIsValid: boolean;
    medicalExpiresIsValid: boolean;
    contactGsmIsValid: boolean;
    contactEmailIsValid: boolean;
    weightIsValid: boolean;
    heightIsValid: boolean;
    weeksAreValid: boolean;
    eventIsValid: boolean;
    countryIsValid: boolean;
    nationalityIsValid: boolean;
    sexIsValid: boolean;
    

}

export class CampRegistration implements ICampRegistration {
    readonly id: string = null;
    readonly code: string = null;
    firstName: string = null;
    surName: string = null;
    address: string = null;
    zip: string = null;
    city: string = null;
    gsm: string = null;
    email: string = null;
    dateBirth: MyDateTime = null;
    placeBirth: string = null;
    nationalNr: string = null;
    medicalExpires: MyDateTime = null;
    isSolo: boolean = false;
    currentLVZCMember: boolean = false;
    previousKAZMMember: boolean = false;
    contactGsm: string = null;
    contactEmail: string = null;
    weight: number = 0;
    height: number = 0;
    week1: boolean = false;
    week2: boolean = false;
    experience: string = null;
    remarks: string = null;
    docsKAZM: boolean = false;
    docsLVZC: boolean = false;
    status: number = 0;

    event: IEvent = null;
    country: ICountry = null;
    nationality: ICountry = null;
    sex: ISex = null;

    constructor() {
    }

    public getValidationState(): ICampRegistrationValidationState {

        let vs = {
            firstNameIsValid: this.isNotNullOrEmpty(this.firstName),
            surNameIsValid: this.isNotNullOrEmpty(this.surName),
            addressIsValid: this.isNotNullOrEmpty(this.address),
            zipIsValid: this.isNotNullOrEmpty(this.zip),
            cityIsValid: this.isNotNullOrEmpty(this.city),
            gsmIsValid: this.isNotNullOrEmpty(this.gsm),
            emailIsValid: EmailHelpers.isValidEmailAddress(this.email),
            dateBirthIsValid: this.isDateBirthValid(),
            placeBirthIsValid: this.isNotNullOrEmpty(this.placeBirth),
            //nationalNrIsValid: (this.nationalNr == null || RRN.isValid(this.nationalNr)),
            nationalNrIsValid: (RRN.isValid(this.nationalNr)),
            medicalExpiresIsValid: (this.medicalExpires ? this.medicalExpires.isValid : true),
            contactGsmIsValid: this.isNotNullOrEmpty(this.contactGsm),
            contactEmailIsValid: EmailHelpers.isValidEmailAddress(this.contactEmail),
            weightIsValid: this.weight>0,
            heightIsValid: this.height>0,

            weeksAreValid: (this.week1 || this.week2),
            eventIsValid: (this.event != null),
            countryIsValid: (this.country != null),
            nationalityIsValid: (this.nationality != null),
            sexIsValid: (this.sex != null),

            stateIsValid: false
        } as ICampRegistrationValidationState;

        vs.stateIsValid = (
            vs.firstNameIsValid &&
            vs.surNameIsValid &&
            vs.addressIsValid &&
            vs.zipIsValid &&
            vs.cityIsValid &&
            vs.gsmIsValid &&
            vs.emailIsValid &&
            vs.dateBirthIsValid &&
            vs.placeBirthIsValid &&
            vs.nationalNrIsValid &&
            vs.medicalExpiresIsValid &&
            vs.contactGsmIsValid &&
            vs.contactEmailIsValid &&
            vs.weightIsValid &&
            vs.heightIsValid &&
            vs.weeksAreValid &&
            vs.eventIsValid &&
            vs.countryIsValid &&
            vs.nationalityIsValid &&
            vs.sexIsValid ); 

        return vs;
    }

    private isDateBirthValid() {

        if (!this.dateBirth) return false;
        if (!this.dateBirth.isValid) return false;

        //Removed age check per 3.6.8
        //const db = this.dateBirth.clone();
        //if ((db.addYears(14)).isAfterDate(this.event.startDate)) return false;

        return true;
    }

    private isNotNullOrEmpty(field: string) {
        return (field != null && field.length > 0)
    }

    //Serialization
    public fromJson(json: Object): CampRegistration {
        //DO NOT USE 'this.' RETURN A NEW OBJECT
        //console.log("Member.fromJson");

        const trueObject = new CampRegistration();
        Object.assign<ICampRegistration, Object>(trueObject, json);

        //Overloads
        trueObject.dateBirth = MyDateTime.fromJson(trueObject.dateBirth);
        trueObject.medicalExpires = MyDateTime.fromJson(trueObject.medicalExpires);


        //Objects
        if (trueObject.event != null) trueObject.event = (new Event()).fromJson(trueObject.event);

        //console.log(json);
        //console.log(trueObject);
        return trueObject;
    }


    public toJson(): Object {
        //console.log("Aircraft.toJson");

        let json = JSON.parse(JSON.stringify(this));

        //Overloads
        if (this.dateBirth) {
            json.dateBirth = this.dateBirth.toJson();
        }
        if (this.medicalExpires) {
            json.medicalExpires = this.medicalExpires.toJson();
        }

        //Objects
        if (this.event) json.event = this.event.toJson();

        //console.log(x);
        return json;
    }
}