import * as React from 'react';
//import { Redirect } from "react-router-dom";

//import { Routing } from './../HelperClasses/Routing'


export default class RedirectPage extends React.Component<any, any> {

    constructor(props: any) {
        super(props);
    }

    render() {
        
        const jsx =
            <>
                {this.getRedirectJSX()}
            </>

        return jsx;
    }


    private getRedirectJSX() {

        const jsx = 
            <>
                Redirecting....
                
            </>

        return jsx;

        //{Routing.navigateTo(null,null)}
    }

    componentDidMount() {
        //console.log("404 mounted");
    }

    componentWillUnmount() {
        //console.log("404 will be unmounted");
    }
}